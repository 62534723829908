import React from "react";
import { connect } from "react-redux";
import HistImg from "../../assets/img/img_hstry.png";
import AbtBanner from "../../assets/img/img_abtbanr.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import MapImg from "../../assets/img/map.png";
import { history } from "../../../helpers";
import { userService } from "../../../services/user.service";
import PubSub from "pubsub-js";
import { isWebLogin } from "../../../helpers";
import { toastr } from "../../../services";
import { Modal } from "react-responsive-modal";
import Select from "react-select";
import { config } from "../../../config";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import CONTACT_BANNER from "../../assets/img/NewBanner/ContactUs.png";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      name: "",
      lastName: "",
      email: "",
      mobile: "",
      description: "",
      volunteerOptions: [
        { label: "Counselling Service", value: "Counselling Service" },
        { label: "The Seniors Helpline", value: "The Seniors Helpline" },
        {
          label: "Public Education Programme",
          value: "Public Education Programme",
        },
        {
          label: "Arts and Music Programme",
          value: "Arts and Music Programme",
        },
        {
          label: "Home Maintenance Service",
          value: "Home Maintenance Service",
        },
        { label: "Others", value: "Others" },
      ],
      categoriesSelected: [],
      photoIndex: 0,
      isOpen: false,
    };
  }

  handleInputChange = (e) => {
    e.target.value && e.target.value[0].includes(" ")
      ? (e.target.value = "")
      : this.setState({ [e.target.name]: e.target.value });
    if (e.target.name == "email") {
      this.validate(this.state.email);
    }
  };
  componentWillReceiveProps(nextProps) {
    // let volunteerOptions = [];
    // if (sessionStorage.getItem("translation") == "Chinees") {
    //   this.state.listCategory &&
    //     this.state.listCategory.map((item) => {
    //       let obj = { label: item.chineseTitle, value: item.id };
    //       volunteerOptions.push(obj);
    //     });
    //   this.setState({ volunteerOptions: volunteerOptions });
    // } else {
    //   this.state.listCategory &&
    //     this.state.listCategory.map((item) => {
    //       let obj = { label: item.title, value: item.id };
    //       volunteerOptions.push(obj);
    //     });
    //   this.setState({ volunteerOptions: volunteerOptions });
    // }
    // console.log(volunteerOptions);
  }

  async componentDidMount() {
    console.log("ENTERS");
    // GET request using fetch with async/await `${config.apiUrl}/category/create`
    // const response = await fetch(`${config.apiUrl}/category/list?formType=2`);
    // const data = await response.json();
    // this.setState({ listCategory: data.data.rows });
    // let volunteerOptions = [];
    // if (sessionStorage.getItem("translation") == "Chinees") {
    //   this.state.listCategory &&
    //     this.state.listCategory.map((item) => {
    //       let obj = { label: item.chineseTitle, value: item.id };
    //       volunteerOptions.push(obj);
    //     });
    //   this.setState({ volunteerOptions: volunteerOptions });
    // } else {
    //   this.state.listCategory &&
    //     this.state.listCategory.map((item) => {
    //       let obj = { label: item.title, value: item.id };
    //       volunteerOptions.push(obj);
    //     });
    //   this.setState({ volunteerOptions: volunteerOptions });
    // }
    // console.log(data.data.rows,"DATAAAAAAAAAAAA");
    // this.setState({ totalReactPackages: data.total })
  }
  validate = (value) => {
    var emailRegex = /^([a-z])+([a-z0-9_\.\-])+\@(([a-z\-])+\.)+([a-z]{2,3})$/;
    if (emailRegex.test(value)) {
      return true;
    } else {
      return false;
    }
  };

  showLoader() {
    PubSub.publish("msg", true);
  }

  hideLoader() {
    PubSub.publish("msg", false);
  }

  resetForm = () => {
    this.setState({
      open: false,
      submitted: false,
      userId: "",
      name: "",
      lastName: "",
      email: "",
      mobile: "",
      description: "",
    });
  };

  goToHome = () => {
    history.push("/");
  };

  handleRole = (e) => {
    let roles = this.state.volunteerOptions;
    roles &&
      roles.map((role) => {
        if (role.value == e.target.value) {
          role.isChecked = e.target.checked;
        }
      });

    this.setState({ volunteerOptions: roles });
  };
  handleVolunteerChange = (data) => {
    this.setState({ categoryObj: data });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { name, lastName, email, mobile, description, volunteerOptions } =
      this.state;
    let webLoginData = isWebLogin();
    let selected = [];
    volunteerOptions &&
      volunteerOptions.map((role) => {
        if (role.isChecked) {
          selected.push(role);
        }
      });
    console.log(selected.length > 0, "Check");
    // if(webLoginData && webLoginData.id){
    console.log(webLoginData.id, " webLoginData.id");
    this.setState({ submitted: true });
    if (
      name &&
      email &&
      this.validate(email) &&
      mobile &&
      mobile.length == 8 &&
      selected.length > 0
    ) {
      let obj = {
        // "userId" : webLoginData.id,
        name: name,
        lastName: lastName,
        email: email,
        mobile: mobile,
        description: description,
        type: "enquiry",
      };
      let categoryId = [];
      selected &&
        selected.map((item) => {
          categoryId.push(item.label.toString());
        });

      obj["category"] = categoryId.toString();
      this.showLoader();
      userService
        .submitEnquiryForm(obj)
        .then((resp) => {
          this.hideLoader();
          if (resp && resp.status == true) {
            this.setState({ open: true });
          }
        })
        .catch((error) => {
          console.error(error);
          this.hideLoader();
        });
    } else {
      console.log(
        name,
        email,
        this.validate(email),
        mobile,
        mobile.length,
        selected.length
      );
      toastr.error("Mandatory fields are invalid or missing");
    }
    // }
    // else{
    //     toastr.error("Please login")
    // }
  };

  render() {
    const { photoIndex, isOpen } = this.state;
    // let volunteerOptions = [];
    // if(sessionStorage.getItem("translation") == "Chinees"){
    //     this.state.listCategory && this.state.listCategory.map(item=>{
    //         let obj = {"label":item.chineseTitle,"value":item.id}
    //         volunteerOptions.push(obj)
    //     })

    // }else{
    //     this.state.listCategory && this.state.listCategory.map(item=>{
    //         let obj = {"label":item.title,"value":item.id}
    //         volunteerOptions.push(obj)
    //     })
    // }

    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };
    return (
      <div className="con-wrap">
        <Modal
          open={this.state.open}
          onClose={() => this.resetForm()}
          center
          classNames={{
            modal: "form-mod",
          }}
        >
          <div class="con-modal">
            <div class="mod-header">
              <h5>Thank You!</h5>
            </div>
            <div class="mod-body">
              <h6>Your Enquiry form has been successfully submitted!</h6>
            </div>
            <div class="mod-footer">
              <button
                type="button"
                onClick={() => this.resetForm()}
                class="link-btn"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <nav class="breadcrumb">
          <div className="container d-flex">
            <a class="breadcrumb-item" onClick={this.goToHome}>
              <i className="fas fa-home"></i>
            </a>
            <span class="breadcrumb-item active">
              {window.strings.CONTACT.CONTACT_US}
            </span>
          </div>
        </nav>
        <Carousel
          className="con-slide"
          swipeable={false}
          draggable={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          //   autoPlay={this.props.deviceType !== "mobile" ? true : false}
          autoPlay={false}
          showDots={false}
          arrows={false}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="all 1.2s ease 0s"
          transitionDuration={1000}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          deviceType={this.props.deviceType}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div className="abt-banr">
            <img src={CONTACT_BANNER} />
          </div>
          {/* <div className="abt-banr">
                          <img src={AbtBanner}/>
                      </div>
                      <div className="abt-banr">
                          <img src={AbtBanner}/>
                      </div> */}
        </Carousel>

        <div className="container">
          <div className="spad">
            <div className="row">
              <div className="col-lg-5">
                <h3>{window.strings.CONTACT.CONTACT_US}</h3>
                <h4>{window.strings.CONTACT.ADDRESS}</h4>
                <div className="con-box">
                  <h5>{window.strings.FOOTER.ADDRESS_ONE}</h5>
                  <p>{window.strings.FOOTER.ADDRESS_TWO}</p>
                  <p>{window.strings.FOOTER.ADDRESS_THR}</p>
                  <p>{window.strings.FOOTER.ADDRESS_FOU}</p>
                  <p>{window.strings.CONTACT.TEL}: (65) 6354 1191</p>
                  <p>{window.strings.CONTACT.FAX}: (65) 6354 1905</p>
                  <p>{window.strings.CONTACT.EMAIL}: reception@sagecc.org.sg</p>
                </div>
                <p className="mb-1 fnt-wt">
                  {window.strings.CONTACT.OP_HRS_HED}
                </p>
                <p>{window.strings.CONTACT.OP_HRS_TIME}</p>
                {/* <a href="#" className="map-link">{window.strings.CONTACT.VIEW_LOCATION}<i className="fas fa-chevron-right"></i></a> */}
                <div className="map-box">
                  <p className="fnt-wt">
                    {window.strings.CONTACT.CLICK_THE_BELOW_IMAGE}
                  </p>
                  <img
                    src={MapImg}
                    onClick={() => this.setState({ isOpen: true })}
                  />
                  {/* <button type="button" onClick={() => this.setState({ isOpen: true })}>
                                        Open Lightbox
                                    </button> */}
                  {isOpen && (
                    <Lightbox
                      enableZoom={true}
                      mainSrc={MapImg}
                      onCloseRequest={() => this.setState({ isOpen: false })}
                    />
                  )}
                </div>
              </div>

              <div className="col-lg-7">
                <div className="card">
                  <div className="card-header">
                    <h5>{window.strings.CONTACT.ENQUIRY_FORM}</h5>
                  </div>
                  <div className="card-body">
                    <form onSubmit={this.handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>{window.strings.CONTACT.FIRST_NAME}</label>
                            &nbsp;<span class="mandatory">*</span>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              onChange={this.handleInputChange}
                              value={this.state.name}
                              placeholder={
                                window.strings.CONTACT.ENTER_YOUR_FIRST_NAME
                              }
                            />
                            {this.state.submitted && !this.state.name && (
                              <div className="mandatory">
                                {window.strings.CONTACT.NAME_IS_REQUIRED}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>{window.strings.CONTACT.LAST_NAME}</label>
                            <input
                              type="text"
                              name="lastName"
                              onChange={this.handleInputChange}
                              value={this.state.lastName}
                              className="form-control"
                              placeholder={
                                window.strings.CONTACT.ENTER_YOUR_LAST_NAME
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>
                              {window.strings.CONTACT.YOUR_EMAIL_ADDRESS}
                            </label>
                            &nbsp;<span class="mandatory">*</span>
                            <input
                              type="email"
                              name="email"
                              onChange={this.handleInputChange}
                              value={this.state.email}
                              className="form-control"
                              placeholder={
                                window.strings.CONTACT.ENTER_YOUR_EMAIL_ADDRESS
                              }
                            />
                            {this.state.submitted && !this.state.email && (
                              <div className="mandatory">
                                {window.strings.CONTACT.EMAIL_IS_REQUIRED}
                              </div>
                            )}
                            {this.state.submitted &&
                              this.state.email &&
                              !this.validate(this.state.email) && (
                                <div className="mandatory">
                                  {
                                    window.strings.CONTACT
                                      .PLEASE_ENTER_VALID_EMAIL
                                  }{" "}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>
                              {window.strings.CONTACT.YOUR_PHONE_NUMBER}
                            </label>
                            &nbsp;<span class="mandatory">*</span>
                            <input
                              type="number"
                              name="mobile"
                              onChange={this.handleInputChange}
                              value={this.state.mobile}
                              className="form-control"
                              placeholder={
                                window.strings.CONTACT
                                  .ENTER_YOUR_VALID_PHONE_NUMBER
                              }
                            />
                            {this.state.submitted && !this.state.mobile && (
                              <div className="mandatory">
                                {window.strings.CONTACT.MOBILE_IS_REQUIRED}
                              </div>
                            )}
                            {this.state.submitted &&
                              this.state.mobile &&
                              this.state.mobile.length != 8 && (
                                <div className="mandatory">
                                  {
                                    window.strings.CONTACT
                                      .MOBILE_MUST_BE_8_DIGIT
                                  }
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>
                              {window.strings.CONTACT.WRITE_SOMETHING_ABOUT_YOU}
                            </label>
                            <textarea
                              type="textArea"
                              name="description"
                              onChange={this.handleInputChange}
                              value={this.state.description}
                              className="form-control"
                              placeholder={window.strings.CONTACT.ABOUT_YOU}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <label>
                            {window.strings.VOLUNTEER.VOL_CAT}&nbsp;
                            <span class="mandatory">*</span>
                          </label>
                          <div className="col-sm-8">
                            {this.state.volunteerOptions &&
                              this.state.volunteerOptions.map((role, index) => (
                                <div>
                                  <input
                                    type="checkbox"
                                    onChange={this.handleRole}
                                    checked={role.isChecked == true}
                                    value={role.value}
                                  />
                                  <span className="ml-2">{role.label}</span>
                                </div>
                              ))}
                            {/* <div>
                              <input
                                type="checkbox"
                                onChange={this.handleRole}
                                value="Counselling Service"
                              />
                              <span className="ml-2">Counselling Service</span>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                onChange={this.handleRole}
                                value="The Seniors Helpline"
                              />
                              <span className="ml-2">The Seniors Helpline</span>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                onChange={this.handleRole}
                                value="Public Education Programme"
                              />
                              <span className="ml-2">
                                Public Education Programme
                              </span>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                onChange={this.handleRole}
                                value="Arts and Music Programme"
                              />
                              <span className="ml-2">
                                Arts and Music Programme
                              </span>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                onChange={this.handleRole}
                                value="Home Maintenance Service"
                              />
                              <span className="ml-2">
                                Home Maintenance Service
                              </span>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                onChange={this.handleRole}
                                value="Others"
                              />
                              <span className="ml-2">Others</span>
                            </div> */}
                          </div>
                          {/* <div className="form-group">
                                                    <label >{window.strings.VOLUNTEER.VOL_CAT}&nbsp;<span class="mandatory">*</span></label>
                                                        <Select className="state-box"
                                                            styles={{
                                                                control: base => ({
                                                                ...base,
                                                                borderColor: '#ced4da',
                                                                boxShadow: '#FE988D',
                                                                border:'0',
                                                                borderBottom:'1px solid #ced4da',
                                                                borderRadius:'0',
                                                                '&:focus': {
                                                                borderColor: '#ced4da'
                                                                            }
                                                                        }),
                                                                        menu: base => ({
                                                                            ...base,
                                                                            zIndex:"5"
                                                                            }),
                                                                        menuList: base => ({
                                                                            ...base,
                                                                            maxHeight:"140px",
                                                                           })
                                                                    }}
                                                                    
                                                                    value={this.state.categoryObj}
                                                                    onChange={this.handleVolunteerChange}
                                                                    options={volunteerOptions}
                                                                    placeholder={window.strings.VOLUNTEER.VOL_CAT}
                                                                    isMulti
                                                                />   
                                                                {this.state.submitted && !this.state.categoryObj && <div className="mandatory">{window.strings.VOLUNTEER.CATEGORYENTER}</div>}           
                                                    </div> */}
                        </div>
                      </div>
                      <div className="text-left">
                        <button type="submit" className="gradient-btn">
                          {window.strings.CONTACT.SEND}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const actionCreators = {};

const connectedContact = connect(mapStateToProps, actionCreators)(Contact);
export { connectedContact as Contact };
