import { config } from "../config";
import queryString from "query-string";
import { authHeader, handleError, history } from "../helpers";
export const eventService = {
  save,
  getList,
  delete: _delete,
  statusUpdate,
  getMembers,
  createMember,
  delete_eventMember,
  webEventEnquiry,
};

function save(obj) {
  //Post or Put event
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: obj,
  };
  return fetch(`${config.apiUrl}/events/create`, requestOptions).then(
    handleResponse,
    handleError
  );
}

function getList(obj) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let params = queryString.stringify(obj);
  return fetch(
    `${config.apiUrl}/events/listWithCount?` + params,
    requestOptions
  ).then(handleResponse, handleError);
}
function getMembers(obj) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let params = queryString.stringify(obj);
  return fetch(
    `${config.apiUrl}/events/getEventMembers?` + params,
    requestOptions
  ).then(handleResponse, handleError);
}
function webEventEnquiry(obj) {
  const requestOptions = {
    method: "POST",
  };
  let params = queryString.stringify(obj);
  return fetch(
    `${config.apiUrl}/shared/webEventEnquiry?` + params,
    requestOptions
  ).then(handleResponse, handleError);
}

function statusUpdate(obj) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      ...authHeader(),
    },
    body: obj,
  };
  return fetch(`${config.apiUrl}/events/updateStatus`, requestOptions).then(
    handleResponse,
    handleError
  );
}

function _delete(obj) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  let params = queryString.stringify(obj);
  return fetch(
    `${config.apiUrl}/events/deleteEvents?` + params,
    requestOptions
  ).then(handleResponse, handleError);
}

function delete_eventMember(obj) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  let params = queryString.stringify(obj);
  return fetch(
    `${config.apiUrl}/events/deleteEventMember?` + params,
    requestOptions
  ).then(handleResponse, handleError);
}

function createMember(obj) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(obj),
  };
  return fetch(`${config.apiUrl}/events/createMembers`, requestOptions).then(
    handleResponse,
    handleError
  );
}
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // logout();
      }

      if (response.status === 503) {
        history.push("/maintenancePage");
      }
      const error = (data && data.message) || data.msg || response.statusText;
      console.log("error", data);
      return Promise.reject(error);
    }
    return data;
  });
}
