import {chatConstants} from '../../constants'
import {chatService} from '../../services'
import {history} from '../../helpers';
import {config} from '../../config'
import CryptoJS from 'crypto-js';
import {toastr} from '../../services'
import PubSub from 'pubsub-js';
import {store} from '../index.js'

export const chatActions = {
    getList,  
    resetList,
    officialuse,
    resetOfficial,
    getDonarList,
    resetDonarList,
    save,
    saveDonar,
    resetDonar,
    resetSave,
    getStory,
    resetGetStory,

    getParticular_ChannelList,
    resetParticular_ChannelList,
    volunteer_Accept_Channel,
    reset_volunteer_Accept_Channel,
    getMessage_Listen,
    reset_getMessage_Listen  
}
function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
}
function getList(obj){
    return dispatch => {
        showLoader()
        chatService.getList(obj)
        .then(lists=>{
            lists.request = obj
            dispatch({type:chatConstants.FETCH_CHANNEL_SUCCESS,chatList:lists})
            hideLoader() 
        },error => {
            hideLoader() 
            // toastr.error(error)
        })
    }
}
function resetList(){
    return dispatch => {
        dispatch({type:chatConstants.FETCH_CHANNEL_SUCCESS,chatList:''})
    }
}


function getParticular_ChannelList(obj){
    return dispatch => {
        showLoader()
        chatService.getList(obj)
        .then(lists=>{
            lists.request = obj
            dispatch({type:chatConstants.FETCH_PARTICULAR_CHANNEL_SUCCESS,chatList_particularChannel:lists})
            hideLoader() 
        },error => {
            hideLoader() 
            // toastr.error(error)
        })
    }
}
function resetParticular_ChannelList(){
    return dispatch => {
        dispatch({type:chatConstants.FETCH_PARTICULAR_CHANNEL_SUCCESS,chatList_particularChannel:''})
    }
}






function volunteer_Accept_Channel(obj){
    // return dispatch => {
        store.dispatch({type:chatConstants.VOLUNTEER_ACCEPT_CHANNEL_SUCCESS,volunteer_acceptChannel:obj})
    // }
}
function reset_volunteer_Accept_Channel(){
    return dispatch => {
        dispatch({type:chatConstants.VOLUNTEER_ACCEPT_CHANNEL_SUCCESS,volunteer_acceptChannel:''})
    }
}


function getMessage_Listen(obj){
    // return dispatch => {
        ;
        store.dispatch({type:chatConstants.FETCH_CHAT_SUCCESS,volunteerMsg:obj})
    // }
}
function reset_getMessage_Listen(){
    return dispatch => {
        dispatch({type:chatConstants.FETCH_CHAT_SUCCESS,volunteerMsg:''})
    }
}












function getDonarList(obj){
    return dispatch => {
        showLoader()
        chatService.donarList(obj)
        .then(donars => {
            donars.request = obj
            dispatch({type:chatConstants.FETCH_DONAR_LIST,donarList:donars})
            hideLoader()
        },error => {
            hideLoader()
            toastr.error(error)
        })
    }
}
function resetDonarList(){
    return dispatch => {
        dispatch({type:chatConstants.FETCH_DONAR_LIST,donarList:''})
    }
}
function save(obj){
    return dispatch => {
        showLoader()
        chatService.save(obj)
        .then(lists => {
           toastr.success("Story Saved Successfully!!!")
            dispatch({type : chatConstants.SAVE_BACKSTORY,backStoryStatus : lists.status})
            hideLoader() 
        },error => {
            hideLoader() 
            toastr.error(error)
        })
    }
}

function resetSave(){
    return dispatch => {
        dispatch({type:chatConstants.SAVE_BACKSTORY,backStoryStatus:''})
    }
}
function saveDonar(obj){
    return dispatch => {
        showLoader()
        chatService.saveDonar(obj)
        .then(resp => {
            if(resp && resp.status == true){
                toastr.success("Thank you..")
            dispatch({type: chatConstants.SAVE_DONAR,donarStatus:resp.status})
            hideLoader()
            }
        },error => {
            toastr.error(error)
            hideLoader()
        })
    }
}
function resetDonar(){
    return dispatch => {
        dispatch({type:chatConstants.SAVE_DONAR,donarStatus:''})
    }
}
function getStory(obj){
    return dispatch => {
        showLoader()
        chatService.getStory(obj)
        .then(lists => {
            lists.request = obj
            dispatch({type : chatConstants.FETCH_BACKSTORY,backStories:lists})
            hideLoader() 
        },error => {
            hideLoader() 
            toastr.error(error)
        })
    }
}
function resetGetStory(){
    return dispatch => {
        dispatch({type:chatConstants.FETCH_BACKSTORY,backStories:''})
    }
}
function officialuse(obj){
    return dispatch => {
        showLoader()
        chatService.officialuse(obj)
        .then(list => {
            dispatch({type:chatConstants.OFFICIAL_USE_SUCCESS,saveStatus:list.status})
            hideLoader() 
        },error => {
            hideLoader() 
        })
    }
}
function resetOfficial(){
    return dispatch => {
        dispatch({type:chatConstants.OFFICIAL_USE_SUCCESS,saveStatus:''})
    }
}