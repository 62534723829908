import React from 'react';
import { connect } from 'react-redux';
import HistImg from '../../assets/img/img_hstry.png';
import DonationBanner from '../../assets/img/Banner/Donation/Donation.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import OppoImg1 from '../../assets/img/img_vlnter_1.png';
import OppoImg2 from '../../assets/img/img_vlnter_2.png';
import OppoImg3 from '../../assets/img/img_vlnter_3.png';
import donarImg from '../../assets/img/img_donr.png'
import { toastr } from '../../../services';
import {chatActions} from '../../../store/actions'
import Toggle from 'react-toggle';
import {history} from '../../../helpers/history'
import { isWebLogin} from '../../../helpers/authorizedData'
import QRCode from 'qrcode.react';
import {Redirect} from "react-router-dom";
import DONATION_BANNER from '../../assets/img/NewBanner/Donation.png';


 class Donation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
      name:'',
      nric:'',
      contact:'',
      email:'',
      add1:'',
      add2:'',
      paymentBy:'',
      no:'+65',
      status:false,
      user:isWebLogin(),
      submitted:false,
      qrcode:false
        }
    }
    componentWillReceiveProps(newProps){      
      if(newProps && newProps.saveStatus && newProps.saveStatus == true){
          this.props.resetSave()
          this.setState({
            name:'',
            nric:'',
            contact:'',
            email:'',
            add1:'',
            add2:'',
            paymentBy:'',
            no:'+65',
            pay:false,
            status:false,submitted:false
          })
          // history.push('/qrcode')
      }
  }
    statusChange = (status) => {
      var status = this.state.status ? false : true
      this.setState({status})
  }
    handleInputChange = (e) => {
      e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
      if (e.target.name == "email") {
    this.validate(this.state.email)
  }
 }

 validate = (value) => {
  var emailRegex = /^([a-z])+([a-z0-9_\.\-])+\@(([a-z\-])+\.)+([a-z]{2,3})$/;
  if (emailRegex.test(value)) {
    return true
  } else {
    return false;
  }
  }
  nricValidate = (value) => {
    if(!value.match(/^[STFG]\d{7}[A-Z]$/)){
      
      return false
   } 
    else{
      return true
    }
  }
  goToForm = () => {
    this.setState({pay:true})
  }
  goToQRcode = () => {
    history.push('/qrcode')
  }
  goToHome = () => {
    history.push('/')
}
  goToFront = () => {
    this.setState({
      name:'',
      nric:'',
      contact:'',
      email:'',
      add1:'',
      add2:'',
      paymentBy:'',
      pay:false,
      status:false,submitted:false,
    })
  }
  handleSubmit = (e) => {
    e.preventDefault()
    const {name,contact,no,email,nric,add1,add2,status,user,paymentBy} = this.state
    let webLoginData = isWebLogin() 

// if(webLoginData && webLoginData.id){
  this.setState({submitted:true})
    if(name && email && this.validate(email) && nric && contact && contact.length == 8 && this.nricValidate(nric) && paymentBy){

        let obj = {
          email :email,
          callerName:name,
          NRICNumber: nric,
          mobile: contact,
          taxDeduction : status,
          paymentBy :paymentBy
          // userId:this.state.user && this.state.user.id
        }          
        if(add1){
          obj['address1']=add1
        }
        if(add2){
          obj['address2'] = add2
        }
        this.props.save(JSON.stringify(obj))
    }
    else{
        toastr.error('Mandatory fields are missing')
    }
    
  // }
  // else{
  //   toastr.error('Please Login')
  // }
   
}
        render(){
            const responsive = {
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                }
              };  
            return(
              <div className="donate-wrap">
                <nav class="breadcrumb">
                  <div className="container d-flex">
                    <a class="breadcrumb-item"  onClick={this.goToHome}><i className="fas fa-home"></i></a>
                    <span class="breadcrumb-item active">{window.strings.HEADER.DONATION}</span>
                    <span class="breadcrumb-item active">{window.strings.HEADER.DONATION_CHANNELS}</span>
                  </div>
                </nav>
                <Carousel className="donate-slide"
                      swipeable={false}
                      draggable={false}
                      showDots={false}
                      responsive={responsive}
                      ssr={true} // means to render carousel on server-side.
                      infinite={true}
                      // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                      autoPlay={false}
                      arrows={false}
                      autoPlaySpeed={3000}
                      keyBoardControl={true}
                      customTransition="all 1.2s ease 0s"
                      transitionDuration={1000}
                      containerClass="carousel-container"
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                      deviceType={this.props.deviceType}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                  >
                     <div className="abt-banr">
                          <img src={DONATION_BANNER}/>
                      </div>

                     {/* <div className="abt-banr">
                          <img src={DonationBanner}/>
                      </div> */}
                      {/* <div className="abt-banr">
                          <img src={AbtBanner}/>
                      </div>
                      <div className="abt-banr">
                          <img src={AbtBanner}/>
                      </div> */}
                  </Carousel>
                  <div className="container">
                      <div className="spad">
                         <div className="text-center">
                              <h3>{window.strings.HEADER.DONATION}</h3>
                              <h4>{window.strings.HEADER.DONATION_CHANNELS}</h4>
                          </div>
                          <div>
                              <p>{window.strings.DONATION.DONATION_CONTENT_ONE}</p>
                              <p className="mb-5">{window.strings.DONATION.DONATION_TAX}</p>
                              <div className="card">
                                <div className="card-header">
                                  <h5>{window.strings.DONATEFORM.DONARPAT}</h5>
                                </div>
                                  <div className="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-6">
                                              <div className="form-group">
                                                <label>{window.strings.DONATEFORM.CALLME}&nbsp;<span class="mandatory">*</span></label>
                                                <input type="name" value={this.state.name} name="name" className="form-control" placeholder={window.strings.DONATEFORM.FULLNAME} onChange={this.handleInputChange}/>
                                                {this.state.submitted && !this.state.name && <div className="mandatory">{window.strings.DONATEFORM.NAMEERR}</div>}
                                              </div>   
                                            </div>
                                            <div className="col-lg-6">
                                              <div className="form-group">
                                                <label>{window.strings.DONATEFORM.NRIC}&nbsp;<span class="mandatory">*</span></label>
                                                <input type="name" value={this.state.nric} name="nric" onChange={this.handleInputChange} className="form-control" placeholder="Eg: S1234567I" />
                                                {this.state.submitted && !this.state.nric && <div className="mandatory">{window.strings.DONATEFORM.NRREQ}</div>}
                                                {this.state.submitted && this.state.nric && !this.nricValidate(this.state.nric) && <div className="mandatory">{window.strings.DONATEFORM.PLSREQ}</div>}
                                              </div>
                                            </div>
                                            <div className="col-lg-6">
                                              <div className="form-group">
                                                <label>{window.strings.DONATEFORM.CONNO}&nbsp;<span class="mandatory">*</span></label>
                                                <input type="number" value={this.state.contact} name="contact" onChange={this.handleInputChange} className="form-control" placeholder={window.strings.DONATEFORM.CONNO} />
                                                {this.state.submitted && !this.state.contact && <div className="mandatory">{window.strings.DONATEFORM.CONREQ}</div>}
                                                {this.state.submitted && this.state.contact && this.state.contact.length != 8 &&  <div className="mandatory">{window.strings.DONATEFORM.CONREQSS}</div>}

                                              </div>
                                            </div>
                                            <div className="col-lg-6">
                                              <div className="form-group">
                                                <label>{window.strings.DONATEFORM.EMAILADDRESS}&nbsp;<span class="mandatory">*</span></label>
                                                <input type="name" value={this.state.email} name="email" className="form-control" placeholder={window.strings.DONATEFORM.EMAILADDRESS} onChange={this.handleInputChange} />
                                                {this.state.submitted && !this.state.email && <div className="mandatory">{window.strings.DONATEFORM.EMAILENTER}</div>}
                                                {this.state.submitted && this.state.email && !this.validate(this.state.email) && <div className="mandatory">{window.strings.DONATEFORM.EMAILERROR}</div>}

                                              </div>
                                            </div>
                                            <div className="col-lg-6">
                                              <div className="form-group">
                                                <label>{window.strings.DONATEFORM.ADDRESS}1</label>
                                                <input type="name" value={this.state.add1} name="add1" className="form-control" placeholder={window.strings.DONATEFORM.ADDRESS} onChange={this.handleInputChange} />
                                              </div>
                                            </div>
                                            <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>{window.strings.DONATEFORM.PAYMENT}</label>&nbsp;<span class="mandatory">*</span>
                                                <select name="paymentBy" value={this.state.paymentBy} onChange={this.handleInputChange} className="form-control">
                                                  <option value="">{window.strings.DONATEFORM.PAYMENTSELECT}</option>
                                                  <option value="Paynow">{window.strings.DONATEFORM.PAYMENTPAYNOW}</option>
                                                  <option value="Cash">{window.strings.DONATEFORM.PAYMENTCASH}</option>
                                                  <option value="Bank Transfer">{window.strings.DONATEFORM.PAYMENTBANK}</option>
                                                  <option value="Cheque">{window.strings.DONATEFORM.CHEQUE}</option>
                                                </select>
                                                {this.state.submitted && !this.state.paymentBy && <div className="mandatory">{window.strings.DONATEFORM.PAYMENTREQURIED}</div>}
                                              </div>
                                            </div>

                                            <div className="col-lg-6">
                                              <div className="form-group">
                                                <label>{window.strings.DONATEFORM.ADDRESS}2</label>
                                                <input type="name" value={this.state.add2} name="add2" className="form-control" placeholder={window.strings.DONATEFORM.ADDRESS} onChange={this.handleInputChange} />
                                              </div>
                                            </div>
                                            <div className="col-lg-6">
                                            <div className="form-group d-flex">
                                              <label>{window.strings.DONATEFORM.TAXREQ}</label>
                                              <div className="ml-3">
                                                <Toggle defaultChecked= {this.state.status} checked = {this.state.status} onClick={()=>this.statusChange(this.state.status)}/>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                            <div className="text-left">
                                              <button type="button" className="cncl-btn mr-4" onClick={this.goToFront}>{window.strings.DONATEFORM.CANCEL}</button>
                                              <button type="submit" className="gradient-btn">{window.strings.SIGNUPEVENT.SUBMIT}</button>
                                            </div>
                                    </form>
                                  </div>
                                </div>
                              {/* <ul>
                                <li>{window.strings.DONATION.DONATION_NRIC}</li>
                                <li>{window.strings.DONATION.DONATION_NAME}</li>
                                <li>{window.strings.DONATION.DONATION_ADDR}</li>
                                <li>{window.strings.DONATION.DONATION_TEL}</li>
                                <li>{window.strings.DONATION.DONATION_EMAIL}</li>
                              </ul> */}
                              <p>{window.strings.DONATION.DONATION_CONTENT_TWO}</p>
                              <p>{window.strings.DONATION.DONATION_CONTENT_THREE}</p>
                              <h4 className="p-0">{window.strings.DONATION.DONATION_MODE}</h4>
                          </div>
                          <div className="img-gallery">
                              <div className="row">
                                  <div className="col-lg-6 col-12 col-sm-6">
                                      <div className="card">
                                        <h6 className="card-header">{window.strings.DONATION.DONATION_PAYNOW}</h6>
                                          <div className="oppo-box">
                                            <div className="row">
                                              <p className="col-md-4 fnt-bld pr-0">{window.strings.DONATION.PAYNOW_ACC_HED}</p>
                                              <p className="col-md-8">{window.strings.DONATION.PAYNOW_NAME}</p>
                                            </div>
                                            <div className="row">
                                              <p className="col-md-4 fnt-bld pr-0">{window.strings.DONATION.PAYNOW_UEN_HED}</p>
                                              <p className="col-md-8">{window.strings.DONATION.PAYNOW_UEN}</p>
                                            </div>
                                            <div className="row">
                                              <p className="col-md-4 fnt-bld pr-0">{window.strings.DONATION.PAYNOW_NUMBER_HED}</p>
                                              <p className="col-md-8">{window.strings.DONATION.PAYNOW_NUMBER}</p>
                                            </div>
                                            <div className="row">
                                              <p className="col-md-4 fnt-bld pr-0">{window.strings.DONATION.PAYNOW_BANK_HED}</p>
                                              <p className="col-md-8">{window.strings.DONATION.PAYNOW_BANK}</p>
                                            </div>
                                           
                                              {/* <img src={OppoImg1} />
                                              <span>
                                                  <h5>B.X.M. 2020 Sports X-Change Project</h5>
                                                  <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                   scrambled it to make a type specimen book.</p>
                                                   <button className="btn info-btn">Donate Now</button>
                                              </span> */}
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-6 col-12 col-sm-6">
                                      <div className="card">
                                        <h6 className="card-header">{window.strings.DONATION.BANK_TRANSFER}</h6>
                                          <div className="oppo-box">
                                            <div className="row">
                                              <p className="col-md-5 fnt-bld">{window.strings.DONATION.BANK_DBS_HED}</p>
                                              <p className="col-md-7">{window.strings.DONATION.BANK_DBS}</p>
                                            </div>
                                            <div className="row">
                                              <p className="col-md-5 fnt-bld">{window.strings.DONATION.BANK_CODE_HED}</p>
                                              <p className="col-md-7">{window.strings.DONATION.BANK_CODE}</p>
                                            </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-6 col-12  col-sm-6">
                                      <div className="card">
                                      <h6 className="card-header">{window.strings.DONATION.DONATION_CHEQUE}</h6>
                                          <div className="oppo-box">
                                          <p className="mb-1">{window.strings.DONATION.CHEQUE_CONTENT_ONE}</p>
                                          <p>{window.strings.DONATION.CHEQUE_CONTENT_TWO}</p>
                                          {/* <p>{window.strings.DONATION.CHEQUE_CONTENT_THREE}</p> */}
                                          {/* <p>{window.strings.DONATION.CHEQUE_CONTENT_FOUR}</p>  */}

                                              {/* <img src={OppoImg2} />
                                              <span>
                                                  <h5>B.X.M. 2020 Sports X-Change Project</h5>
                                                  <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                   scrambled it to make a type specimen book.</p>
                                                   <button className="btn info-btn">Donate Now</button>
                                              </span> */}
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-6 col-12 col-sm-6">
                                      <div className="card">
                                      <h6 className="card-header">{window.strings.DONATION.DONATION_CASH}</h6>
                                          <div className="oppo-box">
                                          <p>{window.strings.DONATION.CASH_CONTENT}</p>

                                              {/* <img src={OppoImg3} />
                                              <span>
                                                  <h5>B.X.M. 2020 Sports X-Change Project</h5>
                                                  <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                   scrambled it to make a type specimen book.</p>
                                                   <button className="btn info-btn">Donate Now</button>
                                              </span> */}
                                          </div>
                                      </div>
                                  </div>                                  
                              </div>
                          </div>
                          {/* <div className="text-center">
                              <button className="gradient-btn">View More</button>
                          </div> */}

                         {/* {this.state.pay && <div className="card">
                            <div className="card-header">
                              <h5>{window.strings.DONATEFORM.DONARPAT}</h5>
                            </div>
                            <div className="card-body">
                              <form onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label>{window.strings.DONATEFORM.CALLME}&nbsp;<span class="mandatory">*</span></label>
                                        <input type="name" value={this.state.name} name="name" className="form-control" placeholder={window.strings.DONATEFORM.FULLNAME} onChange={this.handleInputChange}/>
                                        {this.state.submitted && !this.state.name && <div className="mandatory">{window.strings.DONATEFORM.NAMEERR}</div>}
                                      </div>   
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label>{window.strings.DONATEFORM.NRIC}&nbsp;<span class="mandatory">*</span></label>
                                        <input type="name" value={this.state.nric} name="nric" onChange={this.handleInputChange} className="form-control" placeholder="Eg: S1234567I" />
                                        {this.state.submitted && !this.state.nric && <div className="mandatory">{window.strings.DONATEFORM.NRREQ}</div>}
                                        {this.state.submitted && this.state.nric && !this.nricValidate(this.state.nric) && <div className="mandatory">{window.strings.DONATEFORM.PLSREQ}</div>}
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label>{window.strings.DONATEFORM.CONNO}&nbsp;<span class="mandatory">*</span></label>
                                        <input type="number" value={this.state.contact} name="contact" onChange={this.handleInputChange} className="form-control" placeholder={window.strings.DONATEFORM.CONNO} />
                                        {this.state.submitted && !this.state.contact && <div className="mandatory">{window.strings.DONATEFORM.CONREQ}</div>}
                                        {this.state.submitted && this.state.contact && this.state.contact.length != 8 &&  <div className="mandatory">{window.strings.DONATEFORM.CONREQSS}</div>}

                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label>{window.strings.DONATEFORM.EMAILADDRESS}&nbsp;<span class="mandatory">*</span></label>
                                        <input type="name" value={this.state.email} name="email" className="form-control" placeholder={window.strings.DONATEFORM.EMAILADDRESS} onChange={this.handleInputChange} />
                                        {this.state.submitted && !this.state.email && <div className="mandatory">{window.strings.DONATEFORM.EMAILENTER}</div>}
                                        {this.state.submitted && this.state.email && !this.validate(this.state.email) && <div className="mandatory">{window.strings.DONATEFORM.EMAILERROR}</div>}

                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label>{window.strings.DONATEFORM.ADDRESS}1</label>
                                        <input type="name" value={this.state.add1} name="add1" className="form-control" placeholder={window.strings.DONATEFORM.ADDRESS} onChange={this.handleInputChange} />
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label>{window.strings.DONATEFORM.ADDRESS}2</label>
                                        <input type="name" value={this.state.add2} name="add2" className="form-control" placeholder={window.strings.DONATEFORM.ADDRESS} onChange={this.handleInputChange} />
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group d-flex">
                                        <label>{window.strings.DONATEFORM.TAXREQ}</label>
                                        <div className="ml-3">
                                          <Toggle defaultChecked= {this.state.status} checked = {this.state.status} onClick={()=>this.statusChange(this.state.status)}/>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-12">
                                      <div className="form-group">
                                        <label>Donate To&nbsp;<span class="mandatory">*</span></label>
                                        <input type="name" value={this.state.donateTo} name="donateTo" className="form-control" placeholder="Donate To" onChange={this.handleInputChange} />
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="form-group">
                                        <label>Remarks&nbsp;<span class="mandatory">*</span></label>
                                        <textarea type="name" value={this.state.remarks} name="remarks" className="form-control" placeholder="Remarks" onChange={this.handleInputChange}  />
                                      </div>
                                    </div>         
                                  </div>
                                  <div className="text-left">
                                     <button onClick={this.goToPay} className="gradient-btn">Next</button>
                                  </div>
                                  <div className="text-left">
                                     <button type="button" className="gradient-btn" onClick={this.goToFront}>Cancel</button>
                                  </div>
                                  
                                  <div className="text-left">
                                  <button type="button" className="cncl-btn mr-4" onClick={this.goToFront}>{window.strings.DONATEFORM.CANCEL}</button>

                                     <button type="submit" className="gradient-btn">{window.strings.DONATEFORM.PROCEED_QRCODE}</button>
                                  </div>
                              </form>
                            </div>
                          </div>} */}

                         {!this.state.pay && <div className="card">
                           <div className="donar-img">
                            <div className="donar-btn">
                                <a className="second-btn" target="_blank" href="https://www.giving.sg/sage-counselling-centre">{window.strings.DONATEFORM.DONGIVE}</a>
                                <button className="prim-btn" type="button" onClick={this.goToQRcode}>{window.strings.DONATEFORM.DONGPAY}</button>
                            </div>
                          </div>
                          </div>}
                         {/* {this.state.qrcode &&<div>
                          <QRCode
    // id="123456"
    value="sELVAn"
    // size={290}
    // level={"H"}
    // includeMargin={true}
  />
                          </div>} */}
                      </div>
                  </div>
                </div>
            )            
        }}

const mapStateToProps = state => {
    return {
      saveStatus : state.chat.donarStatus
    }
}


const actionCreators = {
  save:chatActions.saveDonar,
  resetSave:chatActions.resetDonar,
 }

const connectedDonation = connect(mapStateToProps, actionCreators)(Donation);
export { connectedDonation as Donation };