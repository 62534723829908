import {eventConstants} from '../../constants'
import {eventService} from '../../services'
import {history} from '../../helpers';
import {config} from '../../config'
import CryptoJS from 'crypto-js';
import {toastr} from '../../services'
import PubSub from 'pubsub-js';
export const eventActions = {
    save,
    resetSave,
    getList,
    resetList,
    delete:_delete,
    resetDelete,
    statusUpdate,
    getMembers,
    resetMembers,
    delete_eventMember
}
function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
}
function save(obj){
    return dispatch => {
        showLoader()
        eventService.save(obj)
        .then(event =>{
            if(event && event.status == true){
            toastr.success("Event Saved Successfully...!")
            dispatch({type:eventConstants.SAVE_EVENT_SUCCESS,saveStatus:event.status})
            hideLoader()
            }else{
                hideLoader()
            }
        },error => {
            hideLoader()
           toastr.error(error)
        })
    }
}
function getList(obj){
    return dispatch => {
        showLoader()
        eventService.getList(obj)
        .then(events => {
            if(events){
                events.request = obj
                dispatch({type:eventConstants.FETCH_EVENT_SUCCESS,eventList:events})
                hideLoader()
            }           
        },error => {
            hideLoader()
            toastr.error("please login to see Events")
        })
    }
}
function getMembers(obj){
    return dispatch => {
        showLoader()
        eventService.getMembers(obj)
        .then(events => {
            events.request = obj
            dispatch({type:eventConstants.FETCH_EVENT_MEMBER,eventMembers:events})
            hideLoader()
        },error => {
            hideLoader()
            toastr.error(error)
        })
    }
}
function resetMembers(){
    return dispatch => {
        dispatch({type:eventConstants.FETCH_EVENT_MEMBER,eventMembers:''})
    }
}
function statusUpdate(obj){
    return dispatch => {
        showLoader()
        eventService.statusUpdate(obj)
        .then(events => {
            dispatch({type:eventConstants.EVENT_UPDATE_STATUS,updateStatus:events.status})
            hideLoader()
        },error => {
            toastr.error(error)
            hideLoader()
        })
    }
}
function _delete(obj){
    return dispatch => {
        showLoader()
        eventService.delete(obj)
        .then(event=>{
            if(event && event.status == true){
            toastr.success(event.message)
            dispatch ({type:eventConstants.EVENT_DELETE_SUCCESS,deleteStatus:event.status})
            hideLoader()
            }else{
                hideLoader()
            }
        },error => {
            hideLoader()
            toastr.error(error)
        })
    }
}

function delete_eventMember(obj){
    return dispatch => {
        showLoader()
        eventService.delete_eventMember(obj)
        .then(event=>{
            if(event && event.status == true){
            toastr.success(event.message)
            dispatch ({type:eventConstants.EVENT_DELETE_SUCCESS,deleteStatus:event.status})
            hideLoader()
            }else{
                hideLoader()
            }
        },error => {
            hideLoader()
            toastr.error(error)
        })
    }
}

function resetDelete(){
    return dispatch => {
        dispatch({type:eventConstants.EVENT_DELETE_SUCCESS,deleteStatus:''})
    }
}
function resetList(){
    return dispatch => {
        dispatch({type:eventConstants.FETCH_EVENT_SUCCESS,eventList:''})
    }
}
function resetSave(){
    return dispatch => {
        dispatch({type:eventConstants.SAVE_EVENT_SUCCESS,saveStatus:''})
    }
}