import {emailConstants} from '../../constants'

const initialState = {
    saveStatus:'',
    emailList:[],
    deleteStatus : ''
}
export function template(state=initialState,action){
    switch(action.type){
        case emailConstants.SAVE_EMAIL_SUCCESS:
            return {
                ...state,
                saveStatus:action.saveStatus
            }
            case emailConstants.FETCH_EMAIL_SUCCESS:
                return {
                    ...state,
                    emailList:action.emailList
                }
                case emailConstants.DELETE_EMAIL_SUCCESS:
                    return {
                        ...state,
                        deleteStatus:action.deleteStatus
                    }
            default:
                return state
    }
}