import {appConstants} from '../../constants'

const initialState = {
    saveStatus:'',
    appList:[],
    deleteStatus : '',
    reportList:[],
    dashboardList:[],
    formList:[],
    assignedDeleteStatus : ''
}
export function app(state=initialState,action){
    switch(action.type){
        case appConstants.SAVE_APP_SUCCESS:
            return {
                ...state,
                saveStatus:action.saveStatus
            }
            case appConstants.FETCH_APP_SUCCESS:
                return {
                    ...state,
                    appList:action.appList
                }
                case appConstants.APP_DELETE_SUCCESS:
                    return {
                        ...state,
                        deleteStatus:action.deleteStatus
                    }
                    case appConstants.FETCH_REPORT_SUCCESS:
                        return {
                            ...state,
                            reportList:action.reportList
                        }
                        case appConstants.FETCH_DASHBOARD_SUCCESS:
                            return {
                                ...state,
                                dashboardList:action.dashboardList
                            }
                            case appConstants.FETCH_FORM_SUCCESS:
                                return {
                                    ...state,
                                    formList:action.formList
                                }
                                case appConstants.FETCH_ASSIGNEDFORM_SUCCESS:
                                    return {
                                        ...state,
                                        assignedFormList:action.assignedFormList
                                    }
                                    case appConstants.SAVE_ASSIGNEDFORM_SUCCESS:
                                        return {
                                            ...state,
                                            assignedFormSaveStatus:action.assignedFormSaveStatus
                                    }
                                    case appConstants.ASSIGNEDFORM_DELETE_SUCCESS:
                                        return {
                                            ...state,
                                            assignedDeleteStatus:action.assignedDeleteStatus
                                        }
            default:
                return state
    }
}