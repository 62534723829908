import React,{Component, Fragment} from 'react'
import UploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import {emailActions} from '../../../store/actions'
import { connect } from 'react-redux';
import {history} from '../../../helpers'
import CryptoJS from 'crypto-js';
import {config} from '../../../config';
import {toastr} from '../../../services'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import 'react-quill/dist/quill.bubble.css';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
class CreateEmail extends Component{
    constructor(props){
        super(props);
        this.modules = {
			toolbar: [
		      [{ 'font': [] }],
		      [{ 'size': ['small', false, 'large', 'huge'] }],
		      ['bold', 'italic', 'underline'],
		      [{'list': 'ordered'}, {'list': 'bullet'}],
		      [{ 'align': [] }],
		      [{ 'color': [] }, { 'background': [] }],
		      ['clean']
		    ]
		};

		this.formats = [
		    'font',
		    'size',
		    'bold', 'italic', 'underline', 
		    'list', 'bullet',
		    'align',
		    'color', 'background'
	  	];

        this.state={
            type:'',
            id:'',
            userName:'',
            content:'',
            submitted:false,
        }
    }
    componentDidMount(){
        if(this.props.location && this.props.location.state && this.props.location.state.obj){
            let reqData = this.props.location.state.obj
            this.props.getList(reqData)
        }
    }
    componentWillReceiveProps(newProps){
        if(newProps && newProps.emailList && newProps.emailList.data && newProps.emailList.data.rows && newProps.emailList.data.rows[0]){
            let editData = newProps.emailList.data.rows[0]
            this.setState({
                userName:editData.name,type:editData.type,content:editData.content,id:editData.id
            })
         this.props.resetList()
        }
        if(newProps && newProps.saveStatus && newProps.saveStatus == true){
            this.props.resetSave()
            history.goBack()
        }
    }
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }
  
    rteChange = (html) => {
        this.setState({content:html})	
    }
    
    listPath = ()=> {
        history.push('/admin/email')
    }
    handleTypeChange = (e) => {
        this.setState({
            type:e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault()
        const {userName,type,id,content} = this.state
        // let content = this.state.content && this.state.content.replace(/<[^>]*>/g, '')
        this.setState({submitted:true})
        if(userName && content && type){
           
            let obj = {
               name:userName,
               type:type,
               content:content
            }
            if(id){
                obj['templateId'] = id
            }
            this.props.save(JSON.stringify(obj))
        }else{
            toastr.error('Mandatory fields are missing')
        }
       
    }
    render(){
        return(
            <Fragment> 
                <section>
                    <div className="tit-sec">{this.state.id ? <h4>Edit Email Template</h4> : <h4>Add Email Template</h4>}</div>
                        <div class="card">
                                <div class="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-tab">                                           
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Name&nbsp;<span class="mandatory">*</span></label>
                                                    <div className="col-sm-8">
                                                    <input type="text" name="userName" value={this.state.userName} onChange={this.handleInputChange} placeholder="Enter Name" className="form-control"/>
                                                    {this.state.submitted && !this.state.userName && <div className="mandatory">Name is required</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label class="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Type&nbsp;<span class="mandatory">*</span></label>
                                                    <div className="col-sm-8">
                                                    <select value={this.state.type} className="form-control" onChange={this.handleTypeChange}>
                                                        <option value=''>Select Type</option>
                                                            <option value='signup'>Sign Up</option>
                                                            <option value='forgotPassword'>Forgot Password</option>
                                                            <option value='donorForm'>Donor Form</option>
                                                            <option value='enquiryForm'>Enquiry Form</option>
                                                            <option value='abuseOrSuicidal'>Abuse or Suicidal</option>
                                                            <option value='volunteerContactForm'>Volunteer ContactForm</option>
                                                            <option value='counsellingForm'>Counsellor Form</option>
                                                            <option value='HMSForm'>HMS Form</option>
                                                            <option value='WebEventEnquiry'>Web Event Enquiry</option>
                                                        </select>
                                                        {this.state.submitted && !this.state.type && <div className="mandatory">Type is required</div>}
                                                    </div>
                                                </div>
                                            </div>                                          
                                            <div className="col-md-10">
                                            <div className="form-group row">
                                                <label className="col-sm-2 col-xl-2 mb-md-1 col-form-label pr-0">Content&nbsp;<span class="mandatory">*</span></label>
                                                <div className="col-sm-9 pl-md-2 pl-lg-0">
                                                {/* <textarea type="text" name="content" value={this.state.content} onChange={this.handleInputChange} placeholder="Enter Content" className="form-control"/> */}
                                                
                                            <ReactQuill theme="snow"  modules={this.modules}
                                                formats={this.formats} onChange={this.rteChange}
                                            value={this.state.content || ''} placeholder="Ex :  MailID : admin@gmail.com Password: 123456"/>
                                                {this.state.submitted && !this.state.content && <div className="mandatory">Please enter your content</div>}
                                                </div>
                                            <div>
                                        </div>
                                        </div>
                                        </div>
                                        </div>                                     
                                        <div className="bottom-btn">
                                            <button type="button" className="btn cancel-btn" onClick={this.listPath}>Cancel</button>
                                            <button type="submit" className="btn add-btn"> Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                </section>
            </Fragment>
        )
    }
}
function mapStateToProps(state){
    return {
      saveStatus : state.template.saveStatus,
      emailList : state.template.emailList
    }
}
const actionCreators = {
   save : emailActions.save,
   resetSave : emailActions.resetSave,
   getList : emailActions.getList,
   resetList : emailActions.resetList
}
const saveUser = connect(mapStateToProps, actionCreators)(CreateEmail);
export { saveUser as CreateEmail };
