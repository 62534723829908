import {combineReducers} from 'redux';
import {users} from './user.reducer'
import {authentication} from './authentication.reducer'
import {category} from './category.reducer'
import {task} from './task.reducer'
import {events} from './event.reducer'
import {appoinment} from './appoinment.reducer'
import {rating} from './rating.reducer'
import {chat} from './chat.reducer'
import {template} from './email.reducer'
import {app} from './appMaster.reducer'
const rootReducer = combineReducers({
    users,
    authentication,
    category,
    task,
    events,
    appoinment,
    rating,
    chat,
    template,
    app
})

export default rootReducer