import React ,{Component} from 'react'
import UploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import {history} from '../../../helpers';
import {appActions} from '../../../store/actions';
import {config} from '../../../config'
import {toastr} from '../../../services'
import Toggle from 'react-toggle';

class CreateApp extends Component{
    constructor(props){
        super(props);
        this.state={
            title:'',
            id:'',
            description:'',
            status:false,
            submitted:false
        }
    }
    componentDidMount(){
        if(this.props.location && this.props.location.state && this.props.location.state.obj){
            let reqData = this.props.location.state.obj
            this.props.getList(reqData)
        }
    }
    componentWillReceiveProps(newProps){
        if(newProps && newProps.appList && newProps.appList.data && newProps.appList.data.rows && newProps.appList.data.rows[0]){
            let editData = newProps.appList.data.rows[0]
            this.setState({
               id:editData.id && editData.id,title:editData.name && editData.name,
               description:editData.description && editData.description,
               status:editData.status
            })
        }
        if(newProps && newProps.saveStatus && newProps.saveStatus === true){
            this.props.resetSave()
            history.goBack()
        }
    }
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }
    listPath = () => {
        history.push('/admin/app')
    }
    statusChange = (status) => {
        var status = this.state.status ? false : true
        this.setState({status})
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const {title,description,id,status} = this.state
        this.setState({submitted:true})
        if(title){
            let obj = {
                name:title,
                status
            }
            if(description){
                obj['description'] = description
            }
            if(id){
                obj['settingsId'] = id
            }
            this.props.save(JSON.stringify(obj))
        }else{
            toastr.error('Mandatory fields are missing')

        }
    }
    render(){
        return(
            <div>
                 <section>
                    <div className="tit-sec">{this.state.id ? <h4>Edit App Settings</h4> : <h4>Add App Settings</h4>}</div>
                            <div class="card">
                                <div class="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-tab">
                                        <div className="col-md-6">
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Title&nbsp;<span class="mandatory">*</span></label>
                                                <div className="col-sm-8">
                                                <input type="text" name="title" value={this.state.title} onChange={this.handleInputChange} placeholder="Enter Title" className="form-control"/>
                                                {this.state.submitted && !this.state.title && <div className="mandatory">Title field is required</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Description</label>
                                                <div className="col-sm-8">
                                                <input type="text" name="description" value={this.state.description} onChange={this.handleInputChange} placeholder="Enter Description" className="form-control"/>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Status</label>
                                                        <div className="col-sm-8 mt-2">
                                                             <Toggle defaultChecked= {this.state.status} checked = {this.state.status} onClick={()=>this.statusChange(this.state.status)}/>
                                                        </div>                                         
                                                    </div>
                                                </div>
                                   
                                        </div>
                                        <div class="bottom-btn">
                                                <button type="button" className="btn cancel-btn" onClick={this.listPath}>Cancel</button>
                                                <button type="submit" className="btn add-btn"> Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                </section>
            </div>
        )
    }
}
function mapStateToProps(state){
    return {
       saveStatus : state.app.saveStatus,
       appList:state.app.appList,

    }
}
const actionCreators = {
    save : appActions.save,
    getList : appActions.getList,
    resetSave:appActions.resetSave

}
const appUser = connect(mapStateToProps, actionCreators)(CreateApp);
export { appUser as CreateApp };