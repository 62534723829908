import React from 'react';
import { connect } from 'react-redux';
import AbtBanner from '../../assets/img/img_abtbanr.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import BrdImg from '../../assets/img/img_stry_1.png';
import {history} from '../../../helpers'
import MaintenanceImg from '../../assets/img/img_maintenance.png'
import PHOTO_BANNER from '../../assets/img/NewBanner/PhotoGallery.png';
import PlsImg from '../../assets/img/icn_pls.png';
import videoImg1 from '../../assets/img/VideoImg/video1.png';
import videoImg2 from '../../assets/img/VideoImg/video2.png';
import videoImg3 from '../../assets/img/VideoImg/video3.png';

 class VideosGallery extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
        }
    }
    goToHome = () => {
        history.push('/')
    }
        render(){
            const responsive = {
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                }
              }; 
            return(
            <div className="vido-wrap">
                <nav class="breadcrumb">
                    <div className="container d-flex">
                        <a class="breadcrumb-item" onClick={this.goToHome}><i className="fas fa-home"></i></a>
                        <span class="breadcrumb-item active">{window.strings.HEADER.GALLERY}</span>
                        <span class="breadcrumb-item active">{window.strings.HEADER.VIDEOS_GALLERY}</span>
                    </div>
                </nav>

                <Carousel className="board-slide"
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        autoPlay={false}
                        arrows={false}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        customTransition="all 1.2s ease 0s"
                        transitionDuration={1000}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        deviceType={this.props.deviceType}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                    >
                       <div className="abt-banr">
                            <img src={PHOTO_BANNER}/>
                        </div>
                    </Carousel>
                <div className="container">
                    <div className="spad">
                        <div className="text-center">
                                <h3>{window.strings.SUCCESSSTORIES.SUCCESS_SAGECC}</h3>
                                {/* <h4>{window.strings.HEADER.VIDEOS_GALLERY}</h4> */}
                        </div>
                        <div id="accordion">
                            <div className="card">
                                <div class="card-header" id="headingOne">
                                    <button className="btn collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <h5 class="mb-0">{window.strings.HEADER.VIDEOS_GALLERY}</h5><img src={PlsImg} />
                                                {/* <img src={MinusImg}/> */}
                                        </button>
                                </div>
                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div class="card-body">
                                        <div className="img-gallery">
                                            <div className="row">
                                                <div className="col-md-4 col-12">
                                                    <div className="vido-box">
                                                        <a href="https://youtu.be/gzlqJpgJJRw" target="_blank">
                                                            <img src={videoImg1} />
                                                            <div>
                                                                <h5>{window.strings.GALLERY.VIDEOS_GALLERY_1}</h5>
                                                                <p>{window.strings.GALLERY.HEAR_VOL}</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <div className="vido-box">
                                                        <a href="https://youtu.be/QJwX0QrHDA4" target="_blank">
                                                            <img src={videoImg2} />
                                                            <div>
                                                                <h5>{window.strings.GALLERY.VIDEOS_GALLERY_2}</h5>
                                                                <p>{window.strings.GALLERY.WE_CARE}</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <div className="vido-box">
                                                        <a href="https://youtu.be/NtsnuuAzF1k" target="_blank">
                                                            <img src={videoImg3} />
                                                            <div>
                                                                <h5>{window.strings.GALLERY.VIDEOS_GALLERY_3}</h5>
                                                                <p>{window.strings.GALLERY.WE_CARE_VIDO3}</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div> 

            {/* <div className="maintenance-wrap">
                <div className="container">
                    <div className="spad">
                            <h3>{window.strings.MAINTENANCE.UNDER_MAINTENANCE}</h3>
                            <div className="m-img">
                                <img src={MaintenanceImg} />
                            </div>
                    </div>
                </div>
            </div> */}

                
                
                    {/* <div className="container">
                        <div className="spad">
                           <div className="text-center">
                                <h3>{window.strings.SUCCESSSTORIES.SUCCESS_SAGECC}</h3>
                                <h4>{window.strings.HEADER.VIDEOS_GALLERY}</h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </p>
                            </div>
                            <div className="img-gallery">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <img src={BrdImg} />
                                        <span>
                                            <h6>Board Person Name</h6>
                                            <p>Role</p>
                                        </span>
                                    </div>
                                    <div className="col-lg-3">
                                        <img src={BrdImg} />
                                        <span>
                                            <h6>Board Person Name</h6>
                                            <p>Role</p>
                                        </span>
                                    </div>
                                    <div className="col-lg-3">
                                        <img src={BrdImg} />
                                        <span>
                                            <h6>Board Person Name</h6>
                                            <p>Role</p>
                                        </span>
                                    </div>
                                    <div className="col-lg-3">
                                        <img src={BrdImg} />
                                        <span>
                                            <h6>Board Person Name</h6>
                                            <p>Role</p>
                                        </span>
                                    </div>
                                    <div className="col-lg-3">
                                        <img src={BrdImg} />
                                        <span>
                                            <h6>Board Person Name</h6>
                                            <p>Role</p>
                                        </span>
                                    </div>
                                    <div className="col-lg-3">
                                        <img src={BrdImg} />
                                        <span>
                                            <h6>Board Person Name</h6>
                                            <p>Role</p>
                                        </span>
                                    </div>
                                    <div className="col-lg-3">
                                        <img src={BrdImg} />
                                        <span>
                                            <h6>Board Person Name</h6>
                                            <p>Role</p>
                                        </span>
                                    </div>
                                    <div className="col-lg-3">
                                        <img src={BrdImg} />
                                        <span>
                                            <h6>Board Person Name</h6>
                                            <p>Role</p>
                                        </span>
                                    </div>
                                    <div className="col-lg-3">
                                        <img src={BrdImg} />
                                        <span>
                                            <h6>Board Person Name</h6>
                                            <p>Role</p>
                                        </span>
                                    </div>
                                    <div className="col-lg-3">
                                        <img src={BrdImg} />
                                        <span>
                                            <h6>Board Person Name</h6>
                                            <p>Role</p>
                                        </span>
                                    </div>
                                    <div className="col-lg-3">
                                        <img src={BrdImg} />
                                        <span>
                                            <h6>Board Person Name</h6>
                                            <p>Role</p>
                                        </span>
                                    </div>
                                    <div className="col-lg-3">
                                        <img src={BrdImg} />
                                        <span>
                                            <h6>Board Person Name</h6>
                                            <p>Role</p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
            </div>
            )            
        }}

const mapStateToProps = state => {
    return {}
}


const actionCreators = { }

const connectedVideosGallery = connect(mapStateToProps, actionCreators)(VideosGallery);
export { connectedVideosGallery as VideosGallery };