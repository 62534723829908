import {config} from '../config';
import queryString from "query-string"
import {authHeader,handleError,history} from '../helpers'

export const chatService = {
	getList,
	officialuse,
	save,
	donarList,
	saveDonar,
	getStory
}
function getList(obj){ //Get chatlist
    const requestOptions = {
        method : "GET",
        headers : {'Content-Type': 'application/json','accept' : 'application/json',...authHeader()},
    }
let params = queryString.stringify(obj)
    return fetch(`${config.apiUrl}/chats/list?`+params,requestOptions).then(handleResponse)

}
function save (obj){
	const requestOptions = {
		method : 'POST',
		headers : {'Content-Type': 'application/json','accept' : 'application/json',...authHeader()},
		body: obj
	}
	return fetch(`${config.apiUrl}/form/createStaticPage`,requestOptions).then(handleResponse,handleError)
}
function saveDonar (obj){
	const requestOptions = {
		method : 'POST',
		headers : {'Content-Type': 'application/json','accept' : 'application/json',...authHeader()},
		body : obj
	}
	return fetch(`${config.apiUrl}/form/createDonationForm`,requestOptions).then(handleResponse,handleError)
}
function getStory(obj){
	const requestOptions = {
        method : "GET",
        headers : {'Content-Type': 'application/json','accept' : 'application/json',...authHeader()},
    }
let params = queryString.stringify(obj)
    return fetch(`${config.apiUrl}/form/listStaticPage?`+params,requestOptions).then(handleResponse,handleError)
}
function donarList(obj){
	const requestOptions = {
		method: 'GET',
		headers : {'Content-Type': 'application/json','accept' : 'application/json',...authHeader()}
	}
	let params = queryString.stringify(obj)
	return fetch(`${config.apiUrl}/form/listDonationForm?`+params,requestOptions).then(handleResponse,handleError)
}
function officialuse(obj){
	const requestOptions = { 
		method:'POST',
		headers : authHeader()
	}
	return fetch(`${config.apiUrl}/`,requestOptions).then(handleResponse)
}
function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				// logout();
			}

			if(response.status === 503){
				history.push('/maintenancePage')
			}

			const error = (data && data.message) || (data.msg) || response.statusText;
			return Promise.reject(error);
		}
		return data;
	});
}