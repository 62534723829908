import React from "react";
import { connect } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ServiceImg from "../../assets/img/img_srvc.png";
import PlsImg from "../../assets/img/icn_pls.png";
import MinusImg from "../../assets/img/icn_minus.png";
import BannerImg from "../../assets/img/img_banr.png";
import {
  authorizedData,
  isWebLogin,
  history,
  isPincodeValid,
} from "../../../helpers";
import UserFemale from "../../assets/img/urs-f.png";
import UserMale from "../../assets/img/urs-m.png";
// import { Widget,addResponseMessage, addLinkSnippet, addUserMessage  } from 'react-chat-widget';
// import 'react-chat-widget/lib/styles.css';
import { WebChat } from "./WebChat";
import AGE from "../../assets/img/HomeSlider/Age.jpg";
import COUN_SERVICE from "../../assets/img/Banner/AboutUs/abtus.jpg";
import sagevid from "../../assets/img/Banner/AboutUs/sagevid.mp4";

import ART_MUSIC from "../../assets/img/HomeSlider/ArtMusicPro.jpg";
import Menu from "../Ui/WebHeader/Menu";
import { isMobile } from "react-device-detect";
import { config } from "../../../config";
import COUNSELLING from "../../assets/img/Banner/HomepageBanners/1 Counselling_ed.png";
import SHL from "../../assets/img/Banner/HomepageBanners/2 SHL.png";
import PEP from "../../assets/img/Banner/HomepageBanners/3 Public Education.png";
import SLIDE_COUNSELLING from "../../assets/img/Banner/OurWork/Counselling.jpg";
import SLIDE_HELPLINE from "../../assets/img/Banner/OurWork/SHL.png";
import SLIDE_PEP from "../../assets/img/Banner/OurWork/PEP.JPG";
import SLIDE_ART from "../../assets/img/Banner/Volunteer/Art_Ambassador.JPG";
import SLIDE_HMS from "../../assets/img/Banner/OurWork/hmsNew.png";
import App_Banner from "../../assets/img/Sagebanner.png";
import HOME_BANNER from "../../assets/img/NewBanner/Home.png";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventImage: "",
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.loggedIn && newProps.loggedIn.loggedIn) {
      // this.triggerFunc(false)
    }
    if (isWebLogin()) {
      this.setState({ isLogin: true });
    } else {
      this.setState({ isLogin: false });
    }
  }
  async componentDidMount() {
    console.log("ENTERS");
    // GET request using fetch with async/await `${config.apiUrl}/category/create`
    const response = await fetch(
      `${config.apiUrl}/events/listWithCount?eventType=upcoming&type=web`
    );
    // const response = await fetch('http://192.168.1.24:5006/events/listWithCount?eventType=upcoming&type=web');
    const data = await response.json();
    console.log(data.data.rows, "DATAAAAAAAAAAAA");
    if (data.data.rows && data.data.rows.length != 0) {
      let imageUrls = config.imgPath + "/events/" + data.data.rows[0].imageUrl;
      let d = data.data.rows[0].imageUrl
        ? this.setState({ eventImage: imageUrls })
        : "";
    } else {
      this.setState({ eventImage: AGE });
    }

    // this.setState({ totalReactPackages: data.total })
  }
  OpenClose = () => {
    sessionStorage.setItem("selectedParentPath", "videosGallery");
    sessionStorage.setItem("selectedChildPath", "photosGallery");
    history.push("/photosGallery");
  };
  openClose1 = () => {
    sessionStorage.setItem("selectedTabInWork", "counsellingSerRoute");
    history.push("/ourWork");
  };
  openClose2 = () => {
    sessionStorage.setItem("selectedTabInWork", "seniorHelpRoute");
    history.push("/ourWork");
  };
  openClose3 = () => {
    sessionStorage.setItem("selectedTabInWork", "educationProgramRoute");
    history.push("/ourWork");
  };

  handleNewUserMessage = (newMessage) => {
    console.log(`New message incoming! ${newMessage}`);
  };
  counsellingSerRoute = () => {
    sessionStorage.setItem("selectedTabInWork", "counsellingSerRoute");
    history.push("/ourWork");
  };
  seniorHelpRoute = () => {
    sessionStorage.setItem("selectedTabInWork", "seniorHelpRoute");
    history.push("/ourWork");
  };
  educationProgramRoute = () => {
    sessionStorage.setItem("selectedTabInWork", "educationProgramRoute");
    history.push("/ourWork");
  };
  artProgramRoute = () => {
    sessionStorage.setItem("selectedTabInWork", "artProgramRoute");
    history.push("/ourWork");
  };
  homeMaintenanceRoute = () => {
    sessionStorage.setItem("selectedTabInWork", "homeMaintenanceRoute");
    history.push("/ourWork");
  };
  navigateOurHistory = () => {
    sessionStorage.setItem("selectedParentPath", "ourHistory");
    sessionStorage.setItem("selectedChildPath", "");
    history.push("/ourHistory");
  };
  navigateVolunteer = () => {
    sessionStorage.setItem("selectedParentPath", "volunteersOpportunities");
    sessionStorage.setItem("selectedChildPath", "");
    history.push("/volunteersOpportunities");
  };
  navigateDonation = () => {
    sessionStorage.setItem("selectedParentPath", "donation");
    sessionStorage.setItem("selectedChildPath", "");
    history.push("/donation");
  };
  navigateFortuneBag = () => {
    sessionStorage.setItem("selectedParentPath", "fortuneBag");
    sessionStorage.setItem("selectedChildPath", "");
    history.push("/fortuneBag");
  };

  render() {
    const { eventImage } = this.state;
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };

    const ServiceResponsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };

    return (
      <div className="w-home">
        <div>
          <Carousel
            className="home-caro"
            swipeable={false}
            draggable={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlay={false}
            showDots={false}
            arrows={false}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            <div>
              <img src={HOME_BANNER} />
            </div>

            {/* <div onClick={this.openClose1}><img src={COUNSELLING} /></div>
                            <div onClick={this.openClose1}><img src={SHL} /></div>
                            <div onClick={this.openClose1}><img src={PEP} /></div> */}

            {/* <div onClick={ this.OpenClose}><img  src={AGE} /></div>
                            <div onClick={this.openClose1}><img src={COUN_SERVICE} /></div>
                            <div onClick={() => this.OpenClose2()}>
                            <img src={ART_MUSIC} /></div>
                            <div className="text-center mt-5"><img src={eventImage} /></div> */}
          </Carousel>
        </div>

        {/* <div >
      <Widget  handleNewUserMessage={this.handleNewUserMessage} title="Sage Counselling Center"
          subtitle="Chat World" />
    </div> */}
        {/* <Carousel className="home-slide"
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        arrows={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        autoPlaySpeed={1000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        deviceType={this.props.deviceType}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                    >
                        <div className="h-text">
                            <h3>{window.strings.HOME.EXPRESSIVE_THERAPEUTIC_ACTIVITY_FOR_SENIORS}</h3>
                            <p>{window.strings.HOME.FOR_ENQUIRY_ON_COUNSELLING_SERVICE_AND_PROGRAMMES_YOU_MAY_CALL_US}</p>
                            <button className="btn info-btn">{window.strings.HOME.MORE_INFO}</button>
                        </div>
                        <div className="h-img-box">
                            <img src={BannerImg}/>
                        </div>
                    </Carousel> */}
        {/* <div className="service-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-12 col-lg-7">
                                <h3>SAGECC Fortune Bag Event</h3>
                                <p>SAGE Counselling Centre is raising $100,000  to  bring care and cheers to 2,000 needy residents living in one/two-room HDB rental flats in mid-January 2022 for Chinese New Year.
                                </p>
                                <p>At the same time, funds raised will also help run our services such as Counselling Programme, The Seniors Helpline, Home Maintenance Services and other programmes to promote the well-being of older persons and their caregivers.
                                </p>
                                <div><button className="btn more-btn" onClick={this.navigateFortuneBag}>Check out Fortune Bag</button></div>
                            </div>
                            <div className="col-xl-4 col-12 col-lg-5">
                                <img src="http://www.sagecc.org.sg/static/media/fortunebag1.99b8554b.png" className="rounded" onClick={this.navigateFortuneBag} />
                            </div>
                        </div>
                    </div>
                </div> */}
        <div className="service-wrap">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-12 col-lg-6">
                {/* <h3>{window.strings.HOME.OUR_SERVICE_PGM}</h3> */}
                <h3>{window.strings.HOME.WHO_ARE_WE}</h3>
                <p>{window.strings.HOME.SERVICE_PGM_CONTENT}</p>
                <div>
                  <button
                    className="btn more-btn"
                    onClick={this.navigateOurHistory}
                  >
                    {window.strings.HOME.ABOUT_US}
                  </button>
                </div>
              </div>
              <div className="col-xl-6 col-12 col-lg-6">
                {/* <img src={ServiceImg} /> */}
                {/* <img src={COUN_SERVICE} className="rounded" /> */}
                <video width="100%" className="rounded" controls>
                  <source src={sagevid}></source>
                </video>
              </div>
            </div>
          </div>
        </div>
        <div className="row bg-white">
          <div className="col-12">
            <img src={App_Banner} className="rounded w-100" />
          </div>
        </div>

        <div className="service-slide">
          <div className="container">
            <h3>{window.strings.HOME.OUR_SERVICES}</h3>
            <Carousel
              swipeable={false}
              draggable={false}
              responsive={ServiceResponsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={this.props.deviceType !== "mobile" ? true : false}
              // autoPlay={false}
              autoPlaySpeed={5000}
              keyBoardControl={true}
              customTransition="all 1.2s ease 0s"
              transitionDuration={1000}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              deviceType={this.props.deviceType}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              <div className="service-slide-box">
                <img src={SLIDE_COUNSELLING} />
                <h2>{window.strings.OURWORK.COUNSELLING_SERVICE}</h2>
                <p>{window.strings.HOME.COUNSELLING_SLIDE_CONTENT}</p>
                <button
                  className="btn more-btn"
                  onClick={this.counsellingSerRoute}
                >
                  {window.strings.HOME.LEARN_MORE}
                </button>
              </div>
              <div className="service-slide-box">
                <img src={SLIDE_HELPLINE} />
                <h2>{window.strings.OURWORK.THE_SENIORS_HELPLINE}</h2>
                <p>{window.strings.HOME.HELPLINE_SLIDE_CONTENT}</p>
                <button className="btn more-btn" onClick={this.seniorHelpRoute}>
                  {window.strings.HOME.LEARN_MORE}
                </button>
              </div>
              <div className="service-slide-box">
                <img src={SLIDE_PEP} />
                <h2>{window.strings.OURWORK.PUBLIC_EDUCATION_PROGRAMME}</h2>
                <p>{window.strings.HOME.PEP_SLIDE_CONTENT}</p>
                <button
                  className="btn more-btn"
                  onClick={this.educationProgramRoute}
                >
                  {window.strings.HOME.LEARN_MORE}
                </button>
              </div>
              <div className="service-slide-box">
                <img src={SLIDE_ART} />
                <h2>{window.strings.OURWORK.ART_PROGRAMME}</h2>
                <p>{window.strings.HOME.ART_SLIDE_CONTENT}</p>
                <button className="btn more-btn" onClick={this.artProgramRoute}>
                  {window.strings.HOME.LEARN_MORE}
                </button>
              </div>
              <div className="service-slide-box hms-slide">
                <img src={SLIDE_HMS} />
                <h2>{window.strings.OURWORK.HOME_MAINTENANCE_SERVICES}</h2>
                <p>
                  <span>{window.strings.OURWORK.HOME_CONTENT}</span>
                  <ul>
                    <li>{window.strings.OURWORK.HOME_LIST_TWO}</li>
                    <li>{window.strings.OURWORK.HOME_LIST_THREE}</li>
                  </ul>
                </p>
                {/* <p>{window.strings.HOME.HMS_SLIDE_CONTENT}</p> */}
                <button
                  className="btn more-btn"
                  onClick={this.homeMaintenanceRoute}
                >
                  {window.strings.HOME.LEARN_MORE}
                </button>
              </div>
            </Carousel>
          </div>
        </div>
        <div className="help-wrap">
          <div className="container">
            <h4 className="center-border">
              {window.strings.HOME.YOU_CAN_HELP}
            </h4>
            <p>{window.strings.HOME.YOU_CAN_HELP_CONTENT}</p>
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="card">
                  <div className="vol-bg">
                    <div className="help-text">
                      <h4>{window.strings.HOME.BE_A_VOLUNTEER}</h4>
                      <p>{window.strings.HOME.VOLUNTEER_CONTENT}</p>
                      <button
                        className="btn info-btn"
                        onClick={this.navigateVolunteer}
                      >
                        {window.strings.HOME.MORE_INFO}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="card">
                  <div className="donar-bg">
                    <div className="help-text">
                      <h4>{window.strings.HOME.BE_A_DONOR}</h4>
                      <p>{window.strings.HOME.DONOR_CONTENT}</p>
                      <button
                        className="btn info-btn"
                        onClick={this.navigateDonation}
                      >
                        {window.strings.HOME.MORE_INFO}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 
<div class="container">
    <div class="chat-wrap">
        <div class="row">
            <div class="col-sm-6">
                <div class="card">
                    <div class="row">
                        <div class="col-sm-4 col-4 pr-0">
                            <div class="users-container">
                                <div class="chat-search-box">
                                    <div class="input-group">
                                        <input class="form-control" placeholder="Search"/>
                                        <div class="input-group-btn">
                                            <button type="button" class="btn sr-btn">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="new-chat">
                                    <button>Send Request<i className="fas fa-paper-plane"></i></button>
                                </div>
                                <ul class="users">
                                    <li class="person" data-chat="person4">
                                        <div class="user">
                                            <img src={UserFemale}/>
                                            <span class="status offline"></span>
                                        </div>
                                        <p class="name-time">
                                            <span class="name">Lisa</span>
                                            <span class="time">08/02/2019</span>
                                        </p>
                                    </li>
                                    <li class="person" data-chat="person5">
                                        <div class="user">
                                            <img src={UserMale}/>
                                            <span class="status away"></span>
                                        </div>
                                        <p class="name-time">
                                            <span class="name">Jin</span>
                                            <span class="time">05/02/2019</span>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-sm-8 col-8 pl-0">
                            <div class="selected-user">
                               <span class="name">Ask SAGE CC</span>
                            </div>
                            <div class="chat-container">
                                <ul class="chat-box chatContainerScroll">
                                    <li class="chat-left">
                                        <div class="chat-avatar">
                                            <img src={UserFemale}/>
                                            <div class="chat-name">Lisa</div>
                                        </div>
                                        <div class="chat-text">Lorem Ipsum is simply dummy text</div>
                                        <div class="chat-hour">08:57 <span class="fa fa-check-circle"></span></div>
                                    </li>
                                    <li class="chat-right">
                                        <div class="chat-hour">08:59 <span class="fa fa-check-circle"></span></div>
                                        <div class="chat-text">Lorem Ipsum is simply dummy text of the printing</div>
                                        <div class="chat-avatar">
                                            <img src={UserMale}/>
                                            <div class="chat-name">Jin</div>
                                        </div>
                                    </li>
                                </ul>
                                <div class="form-group mt-3 mb-0 send-box">
                                    <textarea class="form-control" rows="3" placeholder="Type your message here...">
                                    </textarea><button className="gradient-btn"><i className="fas fa-paper-plane"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>

</div> */}

        <WebChat isLogin={this.state.isLogin} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const actionCreators = {};

const connectedHome = connect(mapStateToProps, actionCreators)(Home);
export { connectedHome as Home };
