import React,{Component, Fragment} from 'react'
import {userActions} from '../../../store/actions'
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { history,ReactPagination} from '../../../helpers';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import {toastr} from '../../../services'
import Select from 'react-select';
import {config} from '../../../config'
class FetchUser extends Component{
    constructor(props){
        super(props);
        this.state={
            userList:[],
            itemsPerPage:10,
            page:1,
            searchTxt:'',
            totalCount:'',
            userType:''
        }
    }
    componentDidMount(){
        this.list()
    }
    componentWillReceiveProps(newProps){

        if(newProps && newProps.userList && newProps.userList.data){
            let data = newProps.userList && newProps.userList.data && newProps.userList.data.rows
            let request = newProps.userList && newProps.userList.request ? newProps.userList.request : []
            let totalCount = newProps.userList && newProps.userList.data && newProps.userList.data.count ? newProps.userList.data.count : []
            if(data.length == 0){
                toastr.error("Result Not Found")
            }
            this.setState({userList:data,totalCount,pageCount:totalCount/request.itemsPerPage})
            this.props.resetList()
        }
        if(newProps && newProps.deleteStatus && newProps.deleteStatus == true){
            this.props.resetDelete()
            this.getList()
        }
    }
    list = () => {
        if(this.props && this.props.userList && this.props.userList.status == true){
            let request = this.props.userList && this.props.userList.request ? this.props.userList.request : "";
            if(request.itemsPerPage || request.page || request.searchTxt){
                this.setState({
                    itemsPerPage:request.itemsPerPage,
                    page:request.page,
                    searchTxt:request.searchTxt
                },()=>{
                    this.getList()
                })
            }else{
                this.getList()
            }
        }
        else{
            this.getList()
        }
    }
    getList = ()=> {
        const {userType} = this.state
        let obj = {}
        if(this.state.searchTxt){
            obj['page'] = this.state.page
            obj['itemsPerPage'] = this.state.itemsPerPage
            obj['searchTxt'] = this.state.searchTxt
            // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
            // obj['roleId'] = this.state.authorizedData ? this.state.authorizedData.roleId : ""
        }
        else{
            obj['page'] = this.state.page
            obj['itemsPerPage'] = this.state.itemsPerPage
            // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
            // obj['roleId'] = this.state.authorizedData ? this.state.authorizedData.roleId : ""
        }
        if(userType){
            obj['role'] = userType
        }
        this.props.getList(obj)
    }
    onChange = (data)=> {
        if(this.state.page !== (data.selected+1)){
            this.setState({
                page:data.selected +1
            }, ()=> {
                this.getList()
            })
        }
    }
    handleUserChange = (event) => {
      this.setState({
          userType:event.target.value
      },()=>{
          this.getList()
      })
    }
    formPath = () => {
        history.push('/admin/user/add')
    }
    resetSearch = () => {
        
        this.setState({ searchTxt : '',page : 1,userType:'' },() => {
            this.getList()
        })      
}
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }
    statusChange = (user) => {
        const formData = new FormData()
        const obj = {
            status : user.status ? false : true,
            userId:user.id
        }
        formData.append("userData",JSON.stringify(obj))
        this.props.save(formData)
    }
    handleUserTypes = () => {
        history.push('/admin/user/userTypes')
    }
    itemEdit = (userId) =>{
        history.push('/admin/user/edit/'+userId,{obj:{userId}})
    }
    itemDelete = (userId) => {
         let obj = {
            userId
        }
        this.props.delete(obj)
    }
    handleDelete = (userId) => {
        let message = '';
        const toastrConfirmOptions = {
            onOk: () => { this.itemDelete(userId) },
            onCancel: () => console.log('CANCEL: clicked')
        };
        toastr.customConfirm(message, toastrConfirmOptions,'Do you want to delete ?');

    }
    getNewResult = (e) => {
        e.preventDefault();
        if(this.state.searchTxt){
            this.setState({ page : 1 },() => {
            this.getList()
            })
        }else{
            toastr.error("Please type to search")
        }        
    }
    render(){
        const {itemsPerPage,page} = this.state
        let userList = this.state.userList && this.state.userList.map((users,index)=>{
           
            return <tr>
                <td>{(page - 1) * itemsPerPage + index  + 1}</td>
                <td>{users.firstName}</td>
                <td>{users.lastName}</td>
                <td>{users.userName}</td>
                <td>{users.mobile}</td>
                <td>{users.gender}</td>
                {users.role == "3" ? <td>Counsellor</td> : users.role == "4" ? <td>Volunteers</td> : users.role == "5" ? <td>Normal User</td> : <td></td>} 
         <td><Toggle onClick={()=>this.statusChange(users)}  defaultChecked={users.status}
                        Checked={users.status}/></td>
                <td>
                    <a className="tooltip-wrap" onClick={()=>this.itemEdit(users.id)}>
                        <EditIcon className="edit-icon"/>
                        <div className="tooltip">
                            <div className="arrow"></div>
                            <div className="tooltip-inner">Edit</div>
                        </div>
                    </a>
                    <a className="tooltip-wrap" onClick={()=>this.handleDelete(users.id)}>
                        <DeleteIcon className="delete-icon"/>
                        <div className="tooltip">
                            <div className="arrow"></div>
                            <div className="tooltip-inner">Delete</div>
                        </div>
                    </a>
                </td>
            </tr>
        })
        return(
            <Fragment>
                <div className="tit-sec">
                    <div className="row">
                        <div className="col-6 col-md-6">
                            <h4>User List</h4>
                        </div>
                        <div className="col-6 col-md-6 text-right"> 
                            <button className="btn add-btn" onClick={this.formPath}>
                                <i className="fas fa-plus"></i>Add user
                            </button>
                        </div>
                    {/* <div className="col-6 col-md-7 d-flex flex-row justify-content-end align-items-center content-align mb-2 pl-0"> 
                    <Button color="primary" variant="contained" onClick={this.formPath}>Add User
                    </Button></div> */}
                    </div>
                </div>
               <div className="card">
               <div className="card-body">
                            <div className="pt-2 mb-3">
                                <div className="">
                                    <form onSubmit={this.getNewResult} class="align-items-center" >
                                        <div class="input-group">
                                        <div className="col-md-6 input-fields">
                                            <div className="form-group">
                                                <label>Select User</label>
                                                    <select value={this.state.userType} onChange={this.handleUserChange}>
                                                    <option value=''>Select User</option>
                                                    <option value="3">Counsellors</option>       
                                                    <option value="4">Volunteers</option>    
                                                    <option value="5">Normal Users</option>
                                                    </select>
                                            </div>
                                        </div>
                                        </div>
                                           <div className="col-md-4">
                                               <div className="input-group">
                                               <input type="text" placeholder="Search.." name="searchTxt" value={this.state.searchTxt} onChange={this.handleInputChange} aria-label="Recipient's username" formcontrolname="customSearch" class="form-control ng-pristine ng-valid ng-touched" />
                                            <div class="input-group-append">
                                                <button type="submit" class="btn btn-sm btn-dark" ><i className="fas fa-search"></i></button>
                                                <div class="pl-3">
                                                    <button _ngcontent-wdd-c125="" type="button" onClick={this.resetSearch} class="btn btn-outline-secondary btn-rounded btn-icon">
                                                    <i class="fas fa-redo-alt"></i>
                                                    </button>
                                                </div>
                                            </div>
                                               </div>
                                           </div>
                                      
                                    </form>
                                </div>
                            </div>
               <table className="table">
                <thead className="table-decor">
                <tr>
                        <th>S.No</th>
                        <th>Firstname</th>
                        <th>Lastname</th>
                        <th>Username</th>
                        <th>Mobile</th>
                        <th>Gender</th>
                        <th>User Type</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                   <tbody>
                   {userList}
                   </tbody>
                </table>
               </div>
               {this.state.userList && this.state.userList.length == 0 && <div className="table-responsive">
                            <h6 className="text-center">No record found</h6>
                            </div>}
               </div>
               {this.state.userList && this.state.userList.length > 0 && <ReactPagination PageDetails={{pageCount: this.state.pageCount, onPageChange: this.onChange, activePage: (this.state.page - 1), perPage: this.state.itemsPerPage, totalCount: this.state.totalCount}}/>}
            </Fragment>
        )
    }
}
function mapStateToProps(state){
    return {
        userList : state.users.userList,
        saveStatus:state.users.saveStatus,
        deleteStatus:state.users.deleteStatus,
    }
}

// const mapDispatchToProps = (dispatch) => { return { addData: ()=> dispatch(addData) } }

const actionCreators = {
    getList:userActions.getList,
    getUser : userActions.getUser,
    delete:userActions.delete,
    save:userActions.save,
    resetDelete : userActions.resetDelete,
    resetList : userActions.resetList
    
}


const connectedUser = connect(mapStateToProps, actionCreators)(FetchUser);
export { connectedUser as FetchUser };