import React from 'react';
import { connect } from 'react-redux';
import AbtBanner from '../../assets/img/img_abtbanr.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import EvntImg1 from '../../assets/img/img_evnt_1.png';
import EvntImg2 from '../../assets/img/img_evnt_2.png';
import EvntImg3 from '../../assets/img/img_evnt_3.png';
import EvntImg4 from '../../assets/img/img_evnt_4.png';
import EvntImg5 from '../../assets/img/img_evnt_5.png';
import EvntImg6 from '../../assets/img/img_evnt_6.png';
import { eventActions } from '../../../store/actions';
import { toastr } from '../../../services'
import { config } from '../../../config'
import { history, isWebLogin } from '../../../helpers'
import { EventDetail } from './EventDetail';
import { Modal } from 'react-responsive-modal';
import MaintenanceImg from '../../assets/img/img_maintenance.png'

import EVENT_BANNER from '../../assets/img/NewBanner/EventBanner.jpg';

import AprilWorkshop from '../../assets/img/Banner/Event/AprilWorkshop.png';
import JuneWorkshop from '../../assets/img/Banner/Event/JuneWorkshop.png';
import MayWorkshop from '../../assets/img/Banner/Event/MayWorkshop.png';
import April2021 from '../../assets/img/Banner/Event/April2021.png';
import December2020 from '../../assets/img/Banner/Event/December2020.jpg';
import January2021 from '../../assets/img/Banner/Event/January2021.jpg';
import March2021 from '../../assets/img/Banner/Event/March2021.png';
import PhotographyWorkshop from '../../assets/img/Banner/Event/PhotographyWorkshop.png';
import EventsBanner from '../../assets/img/Banner/Event/Events.png';
import PastEvent from '../../assets/img/Banner/Event/PastEvnt.jpg';
import UpEvnt1 from '../../assets/img/Banner/Event/UpcomEvnt1.jpg';
import UpEvnt2 from '../../assets/img/Banner/Event/UpcomEvnt2.jpg';
import UpEvnt3 from '../../assets/img/Banner/Event/UpcomEvnt3.jpg';

import PastEventTwo from '../../assets/img/Banner/Event/PastEvents/2.png';
import PastEventThree from '../../assets/img/Banner/Event/PastEvents/3.png';
import PastEventFour from '../../assets/img/Banner/Event/PastEvents/4.png';
import PastEventFive from '../../assets/img/Banner/Event/PastEvents/5.png';
import PastEventSix from '../../assets/img/Banner/Event/PastEvents/6.png';
import PastEventSeven from '../../assets/img/Banner/Event/PastEvents/7.png';
import PastEventEight from '../../assets/img/Banner/Event/PastEvents/8.png';
import PastEventNine from '../../assets/img/Banner/Event/PastEvents/9.png';
import PastEventTen from '../../assets/img/Banner/Event/PastEvents/10.png';
import PastEventEleven from '../../assets/img/Banner/Event/PastEvents/11.png';
import PastEventTwelve from '../../assets/img/Banner/Event/PastEvents/12.png';
import PastEventThirteen from '../../assets/img/Banner/Event/PastEvents/13.png';

import Poster1 from '../../assets/img/Banner/Event/Poster 1.png';
import Poster2 from '../../assets/img/Banner/Event/Poster 2.png';
import Poster3 from '../../assets/img/Banner/Event/Poster 3.png';
import Poster4 from '../../assets/img/Banner/Event/Poster 4.png';
import Poster5 from '../../assets/img/Banner/Event/Poster 5.png';
import Poster6 from '../../assets/img/Banner/Event/Poster 6.png';
import Poster7 from '../../assets/img/Banner/Event/Poster 7.png';


class Events extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }


    componentWillReceiveProps(newProps) {
        if (newProps && newProps.eventList && newProps.eventList.data) {
            let data = newProps.eventList && newProps.eventList.data && newProps.eventList.data.rows
            this.setState({ eventList: data })
            this.props.resetList()
        }

        if (isWebLogin() && !this.state.called && newProps.loggedIn && newProps.loggedIn.loggedIn) {
            this.setState({ called: true }, () => {
                this.getEventList()
            })
        }
    }

    componentDidMount() {
        this.getEventList()
    }

    getEventList = () => {
        let obj = {
            type: 'web',
            eventType: "upcoming"
        }
        this.props.getList(obj)
    }
    navigatePage = (eventId) => {
        history.push('/eventDetail/' + eventId, { obj: { eventId, type: 'web' } })
        // history.replace({ pathname: 'eventDetail/'+eventId, obj:{eventId,type:'web'}});
    }
    goToHome = () => {
        history.push('/')
    }
    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    render() {
        const responsive = {
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 1,
                slidesToSlide: 1 // optional, default to 1.
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 1,
                slidesToSlide: 1 // optional, default to 1.
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                slidesToSlide: 1 // optional, default to 1.
            }
        };

        const { open } = this.state;

        return (
            <div className="evnt-wrap">

                <Modal open={open} onClose={this.onCloseModal} center
                    classNames={{
                        modal: 'evnt-mod',
                    }}>
                    <EventDetail />
                </Modal>

                <nav class="breadcrumb">
                    <div className="container d-flex">
                        <a class="breadcrumb-item" onClick={this.goToHome}><i className="fas fa-home"></i></a>
                        <span class="breadcrumb-item active">{window.strings.HEADER.EVENTS}</span>
                    </div>
                </nav>
                <Carousel className="evnt-slide"
                    swipeable={false}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                    autoPlay={false}
                    arrows={false}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    customTransition="all 1.2s ease 0s"
                    transitionDuration={1000}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    deviceType={this.props.deviceType}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                >
                    <div className="evnt-banr">
                        <img src={EVENT_BANNER} />
                    </div>

                    {/* <div className="evnt-banr">
                            <img src={EventsBanner}/>
                        </div> */}
                    {/* <div className="evnt-banr">
                          <img src={AprilWorkshop}/>
                      </div>
                      <div className="evnt-banr">
                          <img src={JuneWorkshop}/>
                      </div>
                      <div className="evnt-banr">
                          <img src={MayWorkshop}/>
                      </div>

                      <div className="evnt-banr">
                          <img src={April2021}/>
                      </div>
                      <div className="evnt-banr">
                          <img src={December2020}/>
                      </div>
                      <div className="evnt-banr">
                          <img src={January2021}/>
                      </div>
                      <div className="evnt-banr">
                          <img src={March2021}/>
                      </div> 
                      <div className="evnt-banr">
                          <img src={PhotographyWorkshop}/>
                      </div> */}
                </Carousel>
                <div className="container">
                    <div className="spad">
                        <div className="text-center">
                            <h3>{window.strings.EVENT.EVENT_SAGECC}</h3>
                            <h4>{window.strings.EVENT.EVENT_HEAD}</h4>
                            <div className="text-left">
                                <p>{window.strings.EVENT.EVENT_CONTENT_ONE}</p>
                                <p>{window.strings.EVENT.EVENT_CONTENT_TWO}</p>
                                {/* <p>{window.strings.EVENT.EVENT_UPCOMING}</p>
                                    <p>{window.strings.EVENT.EVENT_PAST}</p> */}
                            </div>
                        </div>
                        <div className="img-gallery p-0">
                            <div className="row">
                                {
                                    this.state.eventList && this.state.eventList.map(item => (
                                        <div className="col-lg-4">
                                            <div className="card">
                                                <div className="card-body">
                                                    <img src={config.imgPath + '/events/' + item.imageUrl} onClick={() => this.navigatePage(item.id)} />
                                                    {/* <img src={config.imgPath+'/events/'+item.imageUrl} onClick={()=>this.onOpenModal(item.id)} /> */}
                                                    <span>
                                                        <h6>{item.title}</h6>
                                                        <p>{item.description}</p>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            {/* <div className="row">
                                    <div className="col-lg-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <img src={EvntImg1} />
                                                <span>
                                                    <h6>Fun With Dots</h6>
                                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                     scrambled it to make a type specimen book.</p>
                                                </span>
                                            </div>
                                        </div>
                                    </div> */}
                            {/* <div className="col-lg-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <img src={EvntImg2} />
                                                <span>
                                                    <h6>Expressive Therapeutic Activity For Seniors</h6>
                                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                        scrambled it to make a type specimen book.</p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <img src={EvntImg3} />
                                                <span>
                                                    <h6>Calendar Of Events SAGECC</h6>
                                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                       scrambled it to make a type specimen book.</p>
                                                </span>
                                            </div>
                                        </div>
                                    </div> */}
                            {/* <div className="col-lg-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <img src={EvntImg4} />
                                                <span>
                                                    <h6>Councelling Referral</h6>
                                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                        scrambled it to make a type specimen book.</p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <img src={EvntImg5} />
                                                <span>
                                                    <h6>Art Expressive Therapy for Seniors</h6>
                                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                      scrambled it to make a type specimen book.</p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <img src={EvntImg6} />
                                                <span>
                                                    <h6>Home Maintenance Service Information</h6>
                                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                        scrambled it to make a type specimen book.</p>
                                                </span>
                                            </div>
                                        </div>
                                    </div> */}
                        </div>
                    </div>
                    <div id="accordion">
                        <div class="up-evnt">
                            <div class="card-header" id="headingOne">
                                <button className="btn collapsed  collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <h5>{window.strings.EVENT.EVENT_UPCOMING}</h5><i className="fas fa-chevron-right"></i>
                                </button>
                            </div>
                            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                <div class="card-body">
                                    <div class="evnt-banr">
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <img src={JuneWorkshop} />
                                            </div>
                                            {/* <div className="col-md-6 col-12">
                                                            <img src={Poster1}/>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <img src={Poster2}/>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <img src={Poster3}/>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <img src={Poster4}/>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <img src={Poster5}/>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <img src={Poster6}/>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <img src={Poster7}/>
                                                        </div> */}
                                        </div>
                                    </div>
                                    <h4>{window.strings.EVENT.EVENT_POSTPONE}</h4>
                                    {/* <div className="maintenance-wrap">
                                                <h3>{window.strings.MAINTENANCE.UNDER_MAINTENANCE}</h3>
                                                     <div className="m-img">
                                                             <img src={MaintenanceImg} />
                                                     </div>
                                             </div> */}
                                </div>
                            </div>
                        </div>
                        <div class="past-evnt">
                            <div class="card-header" id="headingTwo">
                                <button className="btn collapsed  collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                    <h5>{window.strings.EVENT.EVENT_PAST}</h5><i className="fas fa-chevron-right"></i>
                                </button>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                <div class="card-body">
                                    <div className="evnt-banr">
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                {/* <img src={AprilWorkshop}/> */}
                                                <img src={PastEventTwo} />
                                            </div>
                                            <div className="col-md-6 col-12">
                                                {/* <img src={April2021}/> */}
                                                <img src={PastEventThree} />
                                            </div>
                                            <div className="col-md-6 col-12">
                                                {/* <img src={December2020}/> */}
                                                <img src={PastEventFour} />
                                            </div>
                                            <div className="col-md-6 col-12">
                                                {/* <img src={January2021}/> */}
                                                <img src={PastEventFive} />
                                            </div>
                                            <div className="col-md-6 col-12">
                                                {/* <img src={PastEvent}/> */}
                                                <img src={PastEventSix} />
                                            </div>
                                            <div className="col-md-6 col-12">
                                                {/* <img src={March2021}/> */}
                                                <img src={PastEventSeven} />
                                            </div>
                                            <div className="col-md-6 col-12">
                                                {/* <img src={PhotographyWorkshop}/> */}
                                                <img src={PastEventEight} />
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <img src={PastEventNine} />
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <img src={PastEventTen} />
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <img src={PastEventEleven} />
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <img src={PastEventTwelve} />
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <img src={PastEventThirteen} />
                                            </div>

                                        </div>
                                    </div>
                                    {/* <div className="maintenance-wrap">
                                                <h3>{window.strings.MAINTENANCE.UNDER_MAINTENANCE}</h3>
                                                     <div className="m-img">
                                                             <img src={MaintenanceImg} />
                                                     </div>
                                             </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        eventList: state.events.eventList,
        loggedIn: state.authentication
    }
}


const actionCreators = {
    getList: eventActions.getList,
    resetList: eventActions.resetList,
}

const connectedEvents = connect(mapStateToProps, actionCreators)(Events);
export { connectedEvents as Events };