import React,{Component,Fragment} from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import {history,ReactPagination} from '../../../helpers'
import {appActions} from '../../../store/actions'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {toastr} from '../../../services'
import {config} from '../../../config'
import Toggle from 'react-toggle';

class FetchApp extends Component{
    constructor(props){
        super(props);
            this.state={
                appList:[],
                limit:10,
                page:1,
                searchTxt:'',
                totalCount:''
            }
        
    }
    componentDidMount(){
       this.list()
    }
    componentWillReceiveProps(newProps){
       
        if(newProps && newProps.appList && newProps.appList.data){
            let data = newProps.appList && newProps.appList.data && newProps.appList.data.rows
            let request = newProps.appList && newProps.appList.request ? newProps.appList.request : []
            let totalCount = newProps.appList && newProps.appList.data && newProps.appList.data.count ? newProps.appList.data.count : []
            // if(data.length == 0){
            //     toastr.error("Result Not Found")
            // }
            this.setState({appList:data,totalCount,pageCount:totalCount/request.limit})
            this.props.resetList()
        }
        if(newProps && newProps.deleteStatus && newProps.deleteStatus === true){
    
            this.props.resetDelete()
            this.list()
        }
    }
    list = () => {
      
        if(this.props && this.props.appList && this.props.appList.status == true){
            let request = this.props.appList && this.props.appList.request ? this.props.appList.request : "";
            if(request.limit || request.page || request.searchTxt){
                this.setState({
                    limit:request.limit,
                    page:request.page,
                    searchTxt:request.searchTxt
                },()=>{
                    this.getList()
                })
            }else{
                this.getList()
            }
        }
        else{
            this.getList()
        }
    }
    getList = () => {
        let obj = {}
        if(this.state.searchTxt){
            obj['page'] = this.state.page
            obj['limit'] = this.state.limit
            obj['searchTxt'] = this.state.searchTxt
            obj['type'] = 'app'
        }
        else{
            obj['page'] = this.state.page
            obj['limit'] = this.state.limit
            obj['type'] = 'app'
        }
        this.props.getList(obj)
    }
    onChange = (data)=> {
        // window.scrollTo({top : 10, left:100,behavior: 'smooth'});
        if(this.state.page !== (data.selected+1)){
            this.setState({
                page:data.selected +1
            }, ()=> {
                this.getList()
            })
        }
    }
    formPath = () => {
        history.push('/admin/app/add')
    }
    resetSearch = () => {
        
        this.setState({ searchTxt : '',page : 1 },() => {
            this.getList()
        })      
}
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }
    itemEdit = (id) => {
        history.push('/admin/app/edit/'+id,{obj:{settingsId:id,type:'app'}})
    }
    itemDelete = (settingsId) => {
        let obj = {
            settingsId
       }
       this.props.delete(obj)
   }
   handleDelete = (id) => {
       let message = '';
       const toastrConfirmOptions = {
           onOk: () => { this.itemDelete(id) },
           onCancel: () => console.log('CANCEL: clicked')
       };
       toastr.customConfirm(message, toastrConfirmOptions,'Do you want to delete ?');

   }
   statusChange = (item) => {
    let status = item.status ? false : true
    let obj = {
        status : status,
        settingsId:item.id
    }
    this.props.save(JSON.stringify(obj))
    this.getList()
   
}
    getNewResult = (e) => {
        e.preventDefault();
        if(this.state.searchTxt){
            this.setState({ page : 1 },() => {
            this.getList()
            })
        }else{
            toastr.error("Please type to search")
        }        
    }
    render(){
        const {limit,page} = this.state
        let appList = this.state.appList && this.state.appList.map((items,index)=>{
            if(items){
            return <tr>
                <td>{(page - 1) * limit + index  + 1}</td>
                <td>{items.name}</td>
                <td>{items.description}</td>
                 <td><Toggle onClick={()=>this.statusChange(items)}  defaultChecked={items.status}
                    Checked={items.status}/></td>
                <td>
                    {/* <a className="tooltip-wrap" onClick={()=>this.itemEdit(items.id)}>
                        <EditIcon className="edit-icon"/>
                        <div className="tooltip">
                            <div className="arrow"></div>
                            <div className="tooltip-inner">Edit</div>
                        </div>
                    </a> */}
                    <a className="tooltip-wrap" onClick={()=>this.handleDelete(items.id)}>
                        <DeleteIcon className="delete-icon"/>
                        <div className="tooltip">
                            <div className="arrow"></div>
                            <div className="tooltip-inner">Delete</div>
                        </div>
                    </a>
                </td>
            </tr>
            }
        })
        return(
        <Fragment>
            <div className="row tit-sec">
                <div className="col-6 col-md-5">
                  <h4>App Master Settings</h4>
                </div>
                <div className="col-6 col-md-7 text-right"> 
                    <button className="btn add-btn" onClick={this.formPath}><i className="fas fa-plus"></i>Add Setting</button>
                    {/* <Button color="primary" variant="contained" onClick={this.formPath}>Add Category
                    </Button> */}
                </div>
            </div>
               <div className="card">
               <div className="card-body">
               <div className="pt-2 mb-3">
                                    {/* <form onSubmit={this.getNewResult} class="align-items-center" >
                                       <div className="d-flex justify-content-end">
                                        <div className="col-md-5 col-lg-3">
                                            <div class="input-group">
                                                <input type="text" placeholder="Search.." name="searchTxt" value={this.state.searchTxt} onChange={this.handleInputChange} aria-label="Recipient's username" formcontrolname="customSearch" class="form-control ng-pristine ng-valid ng-touched" />
                                                <div class="input-group-append">
                                                    <button type="submit" class="btn btn-sm btn-dark" ><i className="fas fa-search"></i></button>
                                                    <div class="pl-3">
                                                        <button _ngcontent-wdd-c125="" type="button" onClick={this.resetSearch} class="btn btn-outline-secondary btn-rounded btn-icon">
                                                        <i class="fas fa-redo-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </form> */}
                            </div>
                <div className="table-wrap">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                            <th>S.No</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                    <tbody>
                            {appList}
                    </tbody>
                    </table>
                    </div>
                    {this.state.appList && this.state.appList.length == 0 && <div>
                            <h6 className="text-center py-4">No Record Found</h6>
                            </div>}
                </div>
               </div>
               </div>
               {this.state.appList && this.state.appList.length > 0 && <ReactPagination PageDetails={{pageCount: this.state.pageCount, onPageChange: this.onChange, activePage: (this.state.page - 1), perPage: this.state.limit, totalCount: this.state.totalCount}}/>}

            </Fragment>
        )
    }
}
function mapStateToProps(state){
    return {
       appList:state.app.appList,
       deleteStatus : state.app.deleteStatus
    }
}
const actionCreators = {
    getList : appActions.getList,
    save : appActions.save,
    delete:appActions.delete,
    resetDelete : appActions.resetDelete,
    resetList: appActions.resetList
}
const connectedApp = connect(mapStateToProps, actionCreators)(FetchApp);
export { connectedApp as FetchApp };