import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import { history, ReactPagination } from '../../../helpers';
import { userActions, appActions, categoryActions, getVolunteerTaskForCSV } from '../../../store/actions';
import { toastr } from '../../../services';
import Select from 'react-select';
import VisibilityIcon from '@material-ui/icons/Visibility';
import * as moment from 'moment';
import { exportCsv } from 'json2csv-export';

class FetchVolunteerTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      searchTxt: '',
      volunteerTaskList: [],
      volunteerTaskListForCsv: [],
      category: null,
    };
  }
  componentDidMount() {
    this.list();
    this.props.getUserList({ roleId: 4 });
    this.getCategories();
  }
  componentWillReceiveProps(newProps) {
    if (newProps && newProps.volunteerTask && newProps.volunteerTask.data) {
      let data = newProps.volunteerTask && newProps.volunteerTask.data && newProps.volunteerTask.data.rows;
      let request = newProps.volunteerTask && newProps.volunteerTask.request ? newProps.volunteerTask.request : [];
      let totalCount =
        newProps.volunteerTask && newProps.volunteerTask.data && newProps.volunteerTask.data.count
          ? newProps.volunteerTask.data.count
          : [];
      if (data.length == 0) {
        toastr.error('Result Not Found');
      }
      this.setState({
        volunteerTaskList: data,
        totalCount,
        pageCount: totalCount / request.limit,
      });
      this.props.resetVolunteerTask();
    }

    if (newProps && newProps.userList && newProps.userList.data) {
      let data = newProps.userList && newProps.userList.data && newProps.userList.data.rows;
      // let request = newProps.userList && newProps.userList.request ? newProps.userList.request : []
      // let totalCount = newProps.userList && newProps.userList.data && newProps.userList.data.count ? newProps.userList.data.count : []
      // if(data.length == 0){
      //     toastr.error("Result Not Found")
      // }
      this.setState({ volunteersList: data });
      this.props.resetUser();
    }
    if (newProps && newProps.saveStatus && newProps.saveStatus == true) {
      this.props.resetSave();
      this.list();
    }
    if (newProps && newProps.deleteStatus && newProps.deleteStatus == true) {
      this.props.resetDelete();
      this.list();
    }
  }
  list = () => {
    if (this.props && this.props.volunteerTaskList && this.props.volunteerTaskList.status == true) {
      let request =
        this.props.volunteerTaskList && this.props.volunteerTaskList.request
          ? this.props.volunteerTaskList.request
          : '';
      if (request.limit || request.page || request.searchTxt) {
        this.setState(
          {
            limit: request.limit,
            page: request.page,
            // searchTxt:request.searchTxt
          },
          () => {
            this.getList();
          }
        );
      } else {
        this.getList();
      }
    } else {
      this.getList();
    }
  };
  getList = ({ categoryId = null } = {}) => {
    let obj = {
      page: this.state.page,
      limit: this.state.limit,
      roleId: 4,
    };

    if (categoryId) {
      obj.categoryId = categoryId;
    }

    if (this.state.volunteerObj && this.state.volunteerObj.value) {
      obj['userId'] = this.state.volunteerObj.value;
    }

    if (this.state.fromDate && this.state.toDate) {
      if (this.state.fromDate <= this.state.toDate) {
        obj['fromDate'] = moment(this.state.fromDate).format('YYYY-MM-DD').toString();
        obj['toDate'] = moment(this.state.toDate).format('YYYY-MM-DD').toString();
      } else {
        toastr.error('Invalid Date');
        return;
      }
    } else if ((!this.state.fromDate && this.state.toDate) || (this.state.fromDate && !this.state.toDate)) {
      toastr.error('From date and To date required');
      return;
    }
    this.props.getVolunteerTask(obj);
  };

  handleCsvDownload = async (event, done) => {
    let obj = {
      page: this.state.page,
      limit: 999999,
      roleId: 4,
      category: this.state.category,
    };

    if (this.state.volunteerObj && this.state.volunteerObj.value) {
      obj['userId'] = this.state.volunteerObj.value;
    }

    if (this.state.fromDate && this.state.toDate) {
      if (this.state.fromDate <= this.state.toDate) {
        obj['fromDate'] = moment(this.state.fromDate).format('YYYY-MM-DD').toString();
        obj['toDate'] = moment(this.state.toDate).format('YYYY-MM-DD').toString();
      } else {
        return;
      }
    }
    const { data } = await getVolunteerTaskForCSV(obj);
    if (data) {
      exportCsv({
        header: {
          sl: 'S/N',
          id: 'ID',
          volunteer: 'Volunteer',
          date: 'Date',
          start_time: 'Start Time',
          end_time: 'End Time',
          duration: 'Duration',
          caller: 'Caller',
          address: 'Address',
          postal_code: 'Postal Code',
          tel_no: 'Tel No',
          sex: 'Sex',
          age: 'Age',
          race: 'Race',
          lang: 'Lang',
          dwelling_type: 'Dwelling Type',
          shl_info: 'SHL Info',
          caller_type: 'Caller Type',
          problem: 'Problem',
          type_of_counselling: 'Type of counselling',
          infoformation_referral: 'Information & Referral',
          call_content: 'Call Content',
          caller_type: 'Caller Type',
          issue_type: 'Issue Type',
          issues: 'Issues',
          counselling_associate_program: 'Counselling/Associate Program',
          face_to_face_counselling: 'Face to Fae Counselling',
          aggreable_to_counselling: 'Aggreable to Counselling',
        },
        data: Array.isArray(data.rows)
          ? data.rows.map((item, index) => ({
              sl: index + 1,
              id: item.id,
              volunteer: `${item.User.firstName} ${item.User.lastName || ''}`,
              date: item.Forms?.[0]?.formJson?.[0]?.date,
              race: item.Forms?.[0]?.formJson?.[1]?.race,
              description: item.Forms?.[0]?.formJson?.[2]?.description,
              start_time: item.Forms?.[0]?.formJson?.[0]?.startTime,
              end_time: item.Forms?.[0]?.formJson?.[3]?.endtime,
              issue_type: item.Forms?.[0]?.formJson?.[3]?.issueType,
              caller_type: item.Forms?.[0]?.formJson?.[3]?.callerType,
              duration: item.Forms?.[0]?.formJson?.[3]?.diffTime,
              caller: item.Forms?.[0]?.formJson?.[1]?.callerName,
              address: item.Forms?.[0]?.formJson?.[1]?.address1,
              postal_code: item.Forms?.[0]?.formJson?.[1]?.postalCode,
              tel_no: item.Forms?.[0]?.formJson?.[1]?.mobileNo,
              sex: item.Forms?.[0]?.formJson?.[1]?.gender,
              age: item.Forms?.[0]?.formJson?.[1]?.age,
              lang: item.Forms?.[0]?.formJson?.[1]?.language,
              dwelling_type: item.Forms?.[0]?.formJson?.[1]?.dweelingType,
              shl_info: item.Forms?.[0]?.formJson?.[1]?.reason,
              problem: item.Forms?.[0]?.formJson?.[2]?.pblmCategory.join(', '),
              type_of_counselling: item.Forms?.[0]?.formJson?.[2]?.typeOfCCounselling,
              // infoformation_referral: 'Information & Referral',
              // call_content: 'Call Content',
              // issues: 'Issues',
              // counselling_associate_program: 'Counselling/Associate Program',
              // face_to_face_counselling: 'Face to Fae Counselling',
              // aggreable_to_counselling: 'Aggreable to Counselling',
            }))
          : [],
        filename: 'volunteer_task',
      });
    }
  };

  getCsvData = () => {
    return this.state.volunteerTaskListForCsv;
  };

  getCategories = () => {
    this.props.getCategoryList({ page: 1, limit: 1000 });
  };

  formPath = () => {
    history.push('/admin/volunteer/add', { roleObj: { roleId: 4 } });
  };
  resetSearch = () => {
    this.setState({ searchTxt: '', page: 1, fromDate: '', toDate: '', volunteerObj: '', category: null }, () => {
      this.getList();
    });
  };
  onChange = (data) => {
    if (this.state.page !== data.selected + 1) {
      this.setState(
        {
          page: data.selected + 1,
        },
        () => {
          this.getList();
        }
      );
    }
  };
  statusChange = (user) => {
    const formData = new FormData();
    const obj = {
      status: user.status ? false : true,
      userId: user.id,
    };
    formData.append('userData', JSON.stringify(obj));
    this.props.save(formData);
  };
  // handleInputChange = (e) => {
  //     e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
  // }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getNewResult = (e) => {
    e.preventDefault();
    if (
      this.state.searchTxt ||
      (this.state.volunteerObj && this.state.volunteerObj.value) ||
      this.state.fromDate ||
      this.state.toDate
    ) {
      this.setState({ page: 1 }, () => {
        this.getList();
      });
    } else {
      toastr.error('Search Required or Invalid');
    }
  };
  // itemEdit = (userId) =>{
  //     history.push('/admin/volunteer/edit/'+userId,{obj:{userId,roleId:4}})
  // }
  navigateToForm = (item) => {
    history.push('/admin/volunteertask/' + item.id, {
      obj: { clockInOutId: item.id },
    });
  };

  handleVolunteerChange = (data) => {
    this.setState({ volunteerObj: data });
  };

  handleCategoryChange = (data) => {
    this.setState({ category: data });
    this.getList({ categoryId: data.value });
  };

  // itemDelete = (userId) => {
  //      let obj = {
  //         userId
  //     }
  //     this.props.delete(obj)
  // }
  // handleDelete = (userId) => {
  //     let message = '';
  //     const toastrConfirmOptions = {
  //         onOk: () => { this.itemDelete(userId) },
  //         onCancel: () => console.log('CANCEL: clicked')
  //     };
  //     toastr.customConfirm(message, toastrConfirmOptions,'Do you want to delete ?');

  // }
  render() {
    const { limit, page } = this.state;

    let volunteerOptions = [];
    this.state.volunteersList &&
      this.state.volunteersList.map((item) => {
        let obj = { label: item.userName, value: item.id };
        volunteerOptions.push(obj);
      });

    let volunteerList =
      this.state.volunteerTaskList &&
      this.state.volunteerTaskList.map((item, index) => {
        return (
          <tr>
            <td>{(page - 1) * limit + index + 1}</td>
            <td>{item.User && item.User.userName ? item.User.userName : ''}</td>
            <td>{item.Category && item.Category.title ? item.Category.title : ''}</td>
            <td>{item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY  HH:mm') : ''}</td>
            <td>{item.clockTimeDifference}</td>
            {/* <td><Toggle onClick={()=>this.statusChange(item)}  defaultChecked={item.status}
                        Checked={item.status}/></td> */}
            <td>
              {/* <a className="tooltip-wrap" onClick={()=>this.itemEdit(item.id)}>
                        <EditIcon className="edit-icon"/>
                        <div className="tooltip">
                            <div className="arrow"></div>
                            <div className="tooltip-inner">Edit</div>
                        </div>
                    </a>
                    <a className="tooltip-wrap" onClick={()=>this.handleDelete(item.id)}>
                        <DeleteIcon className="delete-icon"/>
                        <div className="tooltip">
                            <div className="arrow"></div>
                            <div className="tooltip-inner">Delete</div>
                        </div>
                    </a> */}
              <a className="tooltip-wrap" onClick={() => this.navigateToForm(item)}>
                <VisibilityIcon className="visibility-icon" />
                {/* <div className="tooltip"><div className="arrow"></div><div className="tooltip-inner">View Form</div></div> */}
              </a>
            </td>
          </tr>
        );
      });

    const categories = this.props.categoryList?.data?.rows;
    const categoryList = Array.isArray(categories)
      ? categories.map((item) => ({
          label: item.title,
          value: item.id,
        }))
      : [];

    return (
      <Fragment>
        <div className="row tit-sec">
          <div className="col-6 ">
            <h4>Volunteer Task List</h4>
          </div>
          <div className="col-6">
            <div className="d-flex justify-content-end " style={{ gap: 10 }}>
              <div className="">
                <div className="form-group row">
                  <div className="col-sm-12">
                    <Select
                      className="state-box"
                      styles={{
                        control: (base) => ({
                          ...base,
                          borderColor: 'hsl(0,0%,80%)',
                          boxShadow: '#FE988D',
                          '&:focus': {
                            borderColor: '#80bdff',
                          },
                          width: '200px',
                        }),
                        menu: (base) => ({
                          ...base,
                          zIndex: '5',
                        }),
                        menuList: (base) => ({
                          ...base,
                          maxHeight: '230px',
                        }),
                      }}
                      value={this.state.category}
                      onChange={this.handleCategoryChange}
                      options={categoryList}
                      placeholder="Select Category"
                    />
                  </div>
                </div>
              </div>
              <div style={{}}>
                {/* <CSVLink
                  data={this.getCsvData}
                  headers={headers}
                  filename={`data_volunteer_task`}
                  className="btn add-btn mr-2"
                  // target="_blank"
                  asyncOnClick={true}
                  onClick={this.handleCsvDownload}
                >
                  Download Forms
                </CSVLink> */}

                {this.state.category && (
                  <button className="btn add-btn mr-2" onClick={this.handleCsvDownload}>
                    Download Forms
                  </button>
                )}

                <button className="btn add-btn" onClick={this.formPath}>
                  <i className="fas fa-plus"></i>Add Volunteer
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="pt-2 mb-3">
              <form onSubmit={this.getNewResult} class="align-items-center">
                <div className="d-flex justify-content-end flex-wrap">
                  <div className="col-md-7 col-lg-3">
                    <div className="form-group row">
                      <label className="col-sm-12">
                        Select From date&nbsp;<span class="mandatory">*</span>
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="date"
                          className="form-control"
                          name="fromDate"
                          value={this.state.fromDate}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-7 col-lg-3">
                    <div className="form-group row">
                      <label className="col-sm-12">
                        Select To date&nbsp;<span class="mandatory">*</span>
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="date"
                          className="form-control"
                          name="toDate"
                          value={this.state.toDate}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-7 col-lg-3">
                    <div className="form-group row">
                      <label className="col-sm-12">
                        Select Volunteer&nbsp;<span class="mandatory">*</span>
                      </label>
                      <div className="col-sm-12">
                        <Select
                          className="state-box"
                          styles={{
                            control: (base) => ({
                              ...base,
                              borderColor: 'hsl(0,0%,80%)',
                              boxShadow: '#FE988D',
                              '&:focus': {
                                borderColor: '#80bdff',
                              },
                            }),
                            menu: (base) => ({
                              ...base,
                              zIndex: '5',
                            }),
                            menuList: (base) => ({
                              ...base,
                              maxHeight: '230px',
                            }),
                          }}
                          value={this.state.volunteerObj}
                          onChange={this.handleVolunteerChange}
                          options={volunteerOptions}
                          placeholder="Select Volunteer"
                          // isMulti
                        />
                        {this.state.submitted && !this.state.volunteerObj && (
                          <div className="mandatory">Volunteer field is required</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-5 col-lg-3 m-top">
                    <div class="input-group">
                      {/* <input type="text" placeholder="Search.." name="searchTxt" value={this.state.searchTxt} onChange={this.handleInputChange} aria-label="Recipient's username" formcontrolname="customSearch" class="form-control ng-pristine ng-valid ng-touched" /> */}
                      <div class="input-group-append">
                        <button type="submit" class="btn btn-sm btn-dark">
                          <i className="fas fa-search"></i>
                        </button>
                        <div class="pl-3">
                          <button
                            _ngcontent-wdd-c125=""
                            type="button"
                            onClick={this.resetSearch}
                            class="btn btn-outline-secondary btn-rounded btn-icon"
                          >
                            <i class="fas fa-redo-alt"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="table-wrap">
              <div class="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>User Name</th>
                      <th>Category Name</th>
                      <th>Clock in date</th>
                      <th>clock in duration</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{volunteerList}</tbody>
                </table>
              </div>
              {this.state.volunteerTaskList && this.state.volunteerTaskList.length == 0 && (
                <div>
                  <h6 className="text-center py-4">No Record Found</h6>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.volunteerTaskList && this.state.volunteerTaskList.length > 0 && (
          <ReactPagination
            PageDetails={{
              pageCount: this.state.pageCount,
              onPageChange: this.onChange,
              activePage: this.state.page - 1,
              perPage: this.state.limit,
              totalCount: this.state.totalCount,
            }}
          />
        )}
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    userList: state.users.userRole,
    volunteerTask: state.users.volunteerTask,
    saveStatus: state.users.saveStatus,
    deleteStatus: state.users.deleteStatus,
    categoryList: state.category.categoryList || [],
  };
}
const actionCreators = {
  getUserList: userActions.getUser,
  getVolunteerTask: userActions.getVolunteerTask,
  resetVolunteerTask: userActions.resetVolunteerTask,
  save: userActions.save,
  resetSave: userActions.resetSave,
  resetUser: userActions.resetUser,
  delete: userActions.delete,
  resetDelete: userActions.resetDelete,
  getList: appActions.getForm,
  resetList: appActions.resetFormList,
  getCategoryList: categoryActions.getList,
};
const Volunteer = connect(mapStateToProps, actionCreators)(FetchVolunteerTask);
export { Volunteer as FetchVolunteerTask };
