import {ratingConstants} from '../../constants'
import {ratingService} from '../../services'
import {history} from '../../helpers';
import {config} from '../../config'
import CryptoJS from 'crypto-js';
import {toastr} from '../../services'
import PubSub from 'pubsub-js';
export const ratingActions = {
    getList,
    resetList,
    getNotificationList,
    pushNotification,
    resetNotificationList,
    resetPushNotification,
    getSupportList,
    resetSupportList
}
function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
}
function getList(obj){
    return dispatch => {
        showLoader()
        ratingService.getList(obj)
        .then(lists=>{
            lists.request = obj
            dispatch({type:ratingConstants.FETCH_RATINGS_SUCCESS,ratingList:lists})
            hideLoader()
        },error => {
            toastr.error(error)
        })
    }
}
function resetList(){
    return dispatch => {
        dispatch({type:ratingConstants.FETCH_RATINGS_SUCCESS,ratingList:''})
    }
}
function getNotificationList(obj){
    return dispatch => {
        showLoader()
        ratingService.getNotificationList(obj)
        .then (lists => {
            lists.request = obj
            dispatch({type:ratingConstants.FETCH_NOTIFICATION_SUCCESS,notificationList:lists})
            hideLoader()
        },error => {
            toastr.error(error)
            hideLoader()
        })
    }
}
function resetNotificationList(){
    return dispatch => {
        dispatch({type:ratingConstants.FETCH_NOTIFICATION_SUCCESS,notificationList:''})
    }
}
function getSupportList(obj){
    return dispatch => {
        showLoader()
        ratingService.getSupportList(obj)
        .then(list => {
            list.request = obj
            dispatch({type:ratingConstants.FETCH_SUPPORT_SUCCESS,supportList : list})
            hideLoader()
        },error => {
            hideLoader()
            toastr.error(error)
        })
    }
}
function resetSupportList(){
    return dispatch => {
        dispatch({type:ratingConstants.FETCH_SUPPORT_SUCCESS,supportList:''})
    }
}
function pushNotification(obj){
    return dispatch => {
        showLoader()
        ratingService.pushNotification(obj)
        .then (lists => {
            toastr.success("Notification send successfully...!")
            dispatch({type:ratingConstants.PUSH_NOTIFICATION_SUCCESS,notificationStatus:lists.status})
            hideLoader()
        },error => {
            hideLoader()
            toastr.error(error)
        })
    }
}
function resetPushNotification(){
    return dispatch => {
        dispatch({type:ratingConstants.PUSH_NOTIFICATION_SUCCESS,notificationStatus:''})
    }
}