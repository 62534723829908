import React,{Component,Fragment} from 'react'
import UploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import {history,ReactPagination,isAdminLogin} from '../../../helpers';
import {userActions} from '../../../store/actions'
import {toastr} from '../../../services'
import Toggle from 'react-toggle';
import Select from 'react-select';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
class FetchCounsellor extends Component{
    constructor(props){
        super(props);
        this.state={
            page:1,
            itemsPerPage:10,
            searchTxt:'',
        }
    }
    componentDidMount(){
        this.list()
    }
    componentWillReceiveProps(newProps){
        
        if(newProps && newProps.userList && newProps.userList.data){
            let data = newProps.userList && newProps.userList.data && newProps.userList.data.rows
            let request = newProps.userList && newProps.userList.request ? newProps.userList.request : []
            let totalCount = newProps.userList && newProps.userList.data && newProps.userList.data.count ? newProps.userList.data.count : []
            if(data.length == 0){
                toastr.error("Result Not Found")
            }
            this.setState({userList:data,totalCount,pageCount:totalCount/request.itemsPerPage})
           this.props.resetUser()
        }
        if(newProps && newProps.saveStatus && newProps.saveStatus == true){
            this.props.resetSave()
           this.list()
        }
        if(newProps && newProps.deleteStatus && newProps.deleteStatus == true){
            this.props.resetDelete()
            this.list()
        }
      
    }
    list = () => {
        if(this.props && this.props.userList && this.props.userList.status == true){
            let request = this.props.userList && this.props.userList.request ? this.props.userList.request : "";
            if(request.itemsPerPage || request.page || request.searchTxt){
                this.setState({
                    itemsPerPage:request.itemsPerPage,
                    page:request.page,
                    searchTxt:request.searchTxt
                },()=>{
                    this.getList()
                })
            }else{
                this.getList()
            }
        }
        else{
            this.getList()
        }
    }
    getList = () => {
        let obj = {}
        if(this.state.searchTxt){
            obj['page'] = this.state.page
            obj['itemsPerPage'] = this.state.itemsPerPage
            obj['searchTxt'] = this.state.searchTxt
            // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
            obj['roleId'] = 3
        }
        else{
            obj['page'] = this.state.page
            obj['itemsPerPage'] = this.state.itemsPerPage
            // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
            obj['roleId'] = 3
        }
        this.props.getUserList(obj)
    }
    
    formPath = () => {
       history.push('/admin/counsellor/add',{roleObj:{roleId:3}})
    }
    resetSearch = () => {
        
        this.setState({searchTxt  : '',page : 1},() => {
            this.getList()
        })      
    }
    handleStatusChange = (e) => {
        this.setState({
            appStatus:e.target.value
        },() => {
            this.getList()
        })
    }
   
    handleTypeChange = (e) => {
        this.setState({
            appType:e.target.value
        },() => {
            this.getList()
        })
    }
    onChange = (data)=> {
        if(this.state.page !== (data.selected+1)){
        this.setState({
            page:data.selected +1
        }, ()=> {
            this.getList()
        })
    }
}
statusChange = (user) => {
    const formData = new FormData()
    const obj = {
        status : user.status ? false : true,
        userId:user.id
    }
    formData.append("userData",JSON.stringify(obj))
    this.props.save(formData)
}
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }
    itemEdit = (userId) =>{
        history.push('/admin/counsellor/edit/'+userId,{obj:{userId,roleId:3}})
    }
    itemDelete = (userId) => {
         let obj = {
            userId
        }
        this.props.delete(obj)
    }
    handleDelete = (userId) => {
        let message = '';
        const toastrConfirmOptions = {
            onOk: () => { this.itemDelete(userId) },
            onCancel: () => console.log('CANCEL: clicked')
        };
        toastr.customConfirm(message, toastrConfirmOptions,'Do you want to delete ?');

    }
    getNewResult = (e) => {
        e.preventDefault();
        if(this.state.searchTxt){
            this.setState({ page : 1 },() => {
            this.getList()
            })
        }else{
            toastr.error("Please type to search")
        }        
    }
    render(){
        const {itemsPerPage,page} = this.state
        let counsellorList = this.state.userList && this.state.userList.map((user,index) => {
            return <tr>
            <td>{(page - 1) * itemsPerPage + index  + 1}</td>
            <td>{user.firstName}</td>
            <td>{user.lastName ? user.lastName : '-'}</td>
            <td>{user.userName}</td>
            <td>{user.mobile}</td>
            <td>{user.gender ? user.gender : '-'}</td>
            <td><Toggle onClick={()=>this.statusChange(user)}  defaultChecked={user.status}
                        Checked={user.status}/></td>
            <td>
                    <a className="tooltip-wrap" onClick={()=>this.itemEdit(user.id)}>
                        <EditIcon className="edit-icon"/>
                        <div className="tooltip">
                            <div className="arrow"></div>
                            <div className="tooltip-inner">Edit</div>
                        </div>
                    </a>
                    <a className="tooltip-wrap" onClick={()=>this.handleDelete(user.id)}>
                        <DeleteIcon className="delete-icon"/>
                        <div className="tooltip">
                            <div className="arrow"></div>
                            <div className="tooltip-inner">Delete</div>
                        </div>
                    </a>
            </td>
            
        </tr>
        })
        
        return(
            <Fragment>
            <div className="row tit-sec">
                <div className="col-6 col-md-5">
                    <h4>Counsellors List</h4>
                </div>
                <div className="col-6 col-md-7 text-right"> 
                    <button className="btn add-btn" onClick={this.formPath}><i className="fas fa-plus"></i>Add Counsellor</button>
                </div>
            </div>
           <div className="card">
           <div className="card-body">
                        <div className="pt-2 mb-3">
                            <form onSubmit={this.getNewResult} class="align-items-center" >
                                <div className="d-flex justify-content-end">
                                    <div className="col-md-5 col-lg-3">
                                        <div class="input-group">
                                        <input type="text" placeholder="Search.." name="searchTxt" value={this.state.searchTxt} onChange={this.handleInputChange} aria-label="Recipient's username" formcontrolname="customSearch" class="form-control ng-pristine ng-valid ng-touched" />
                                            <div class="input-group-append">
                                                <button type="submit" class="btn btn-sm btn-dark" ><i className="fas fa-search"></i></button>
                                                <div class="pl-3">
                                                    <button _ngcontent-wdd-c125="" type="button" onClick={this.resetSearch} class="btn btn-outline-secondary btn-rounded btn-icon">
                                                        <i class="fas fa-redo-alt"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
            <div className="table-wrap">
                <div class="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>User Name</th>
                                <th>Mobile</th>
                                <th>Gender</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {counsellorList}
                        </tbody>
                    </table>
                </div>
                {this.state.userList && this.state.userList.length == 0 && <div>
                            <h6 className="text-center py-4">No Record Found</h6>
                            </div>}
            </div>

           </div>
           </div>
           {this.state.userList && this.state.userList.length > 0 && <ReactPagination PageDetails={{pageCount: this.state.pageCount, onPageChange: this.onChange, activePage: (this.state.page - 1), perPage: this.state.itemsPerPage, totalCount: this.state.totalCount}}/>}
        </Fragment>

        )
    }
}
function mapStateToProps(state){
    return {
        userList : state.users.userRole,
        saveStatus : state.users.saveStatus,
        deleteStatus:state.users.deleteStatus,
    }
}
const actionCreators = {
   getUserList : userActions.getUser,
   save : userActions.save,
   resetSave:userActions.resetSave,
   resetUser : userActions.resetUser,
   delete:userActions.delete,
   resetDelete : userActions.resetDelete,
}
const Counsellor = connect(mapStateToProps, actionCreators)(FetchCounsellor);
export {Counsellor as FetchCounsellor };