import React,{Component, Fragment} from 'react'
import { connect } from 'react-redux';
import {history} from '../../../helpers'
import {userActions} from '../../../store/actions'
import {toastr} from '../../../services/toastr.service'
class ForgetPassword extends Component{
    constructor(props){
        super(props);
        this.state={
            email:'',
            text:false,
            text1:true,
            text2:false,
            otp:'',
            emailError:'',
            otpError:'',
            password:'',
            passwordError:'',
            confirmpassword:'',
            confirmpasswordError:''
        }
    }
    componentWillReceiveProps(newProps){
        if(newProps && newProps.nextedIn){
            this.setState({
                text:false,
                text1:true
            })
        }else if(newProps && newProps.otpIn){
            this.setState({
                text1:false,
                text2:true
            })
        }
    }
    loginSubmit = (e) => {
        history.push('/admin/login')
    }
    emailChange = (event) => {
		this.setState({
			email: event.target.value,
			emailError: ""
		});
    }
    otpChange = (event) => {
		this.setState({
			otp: event.target.value,
			otpError: ""
		});
    }
    passwordChange = (event) => {
		this.setState({
			password: event.target.value,
			passwordError: ""
		});
	}
    confirmpasswordChange = (event) => {
		this.setState({
			confirmpassword: event.target.value,
			confirmpasswordError: ""
		});
    }
    
    isNumber = (val) => {
		if(!isNaN(val)){
			return true		
		}else{
			return false
		}
	}
	emailSubmit = (e) => {
		e.preventDefault();
		var pattern = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
		if (this.state.email == "") {
			this.setState({
				emailError: "Email or Mobile Number is required"
			})
		} 
		// else if (!pattern.test(this.state.email)) {
		// 	this.setState({
		// 		emailError: "Invalid Email"
		// 	})
		// } 
		else {
			let obj = {
				email : this.state.email,
				// isAdmin: true,
			}
			// if(this.isNumber(this.state.email)){
			// 	obj['mobile'] = this.state.email
			// }else{
			// 	obj['email'] = this.state.email
			// }
			this.props.forgotPassword(JSON.stringify(obj));
		}
    }
    otpSubmit = (e) => {
		e.preventDefault();
		if (this.state.otp == "") {
			this.setState({
				otpError: "OTP is required"
			})
		} else {
			let obj = {
				email : this.state.email,
				otp : this.state.otp,
				type : "forgotPassword"
			}
			// if(this.isNumber(this.state.email)){
			// 	obj['mobile'] = this.state.email
			// }else{
			// 	obj['email'] = this.state.email
			// }
			this.props.otp(obj);
		}
    }
    passwordSubmit = (e) => {
		e.preventDefault();

		this.setState({})
		if (this.state.password == "") {
			this.setState({
				passwordError: "Password is required"
			})
		} else if (this.state.confirmpassword == "") {
			this.setState({
				confirmpasswordError: "Confirm password is required"
			})
		}
		else if (this.state.password == this.state.confirmpassword) {
			let obj = {
				email : this.state.email,
				 password : this.state.password
			}

			// if(this.isNumber(this.state.email)){
			// 	obj['mobile'] = this.state.email
			// }else{
			// 	obj['email'] = this.state.email
			// }

			this.props.pwd(obj);		
			// toastr.success("Password Updated")	
			history.push('/admin/login');
		}
		else {
			toastr.error("Password does not match...");
		}
	}
    render(){
        return(
            <Fragment>
                <div className="col-12 col-md-7 col-xl-5 mr-xl-auto pl-md-5">
					<div className="auth-form-light login-box text-left px-3 py-3 py-md-5 px-md-4 px-sm-5" >
                        <h5>Forgot Password</h5>
                        <form>
                            {this.state.text && <div className="form-group">
                               <div>
                                    <label className="font-weight-light">Enter Email</label>
                                    <input type="text" className="form-control" placeholder="Enter Mobile.no or Email" value={this.state.email} onChange={this.emailChange}/>
                               </div> 
                                <div>
                                    <button onClick={this.emailSubmit}>Send OTP</button>
                                    <div className="my-3 d-flex justify-content-center align-items-center">
                                            <a className="auth-link text-black" onClick={this.loginSubmit}>Back To Login</a>
                                    </div>
                                </div>
                            </div>}

                            {this.state.text1 && <div className='form-group'>
										<label className="font-weight-light" htmlFor="otp">Enter Your OTP</label>
										<input type="text" className="form-control form-control-lg" name="otp" placeholder="Enter the OTP" value={this.state.otp} onChange={this.otpChange} />
										<div style={{ color: "red" }}>{this.state.otpError}</div>
										<br />
										<div className="d-flex justify-content-between">
											<button className="btn btn-back font-weight-medium btn-fw auth-form-btn mr-1" onClick={this.loginSubmit}>Back To Login</button>
											<button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" onClick={this.otpSubmit}>SUBMIT</button>
										</div>
									</div>}
                                    {this.state.text2 && <div className='form-group'>
										<label className="font-weight-light" htmlFor="password">Enter New Password</label>
										<input type="text" className="form-control form-control-lg" name="password" type="password" placeholder="Enter New Password" value={this.state.password} onChange={this.passwordChange} />
										<div style={{ color: "red" }}>{this.state.passwordError}</div>
										<label className="font-weight-light mt-3" htmlFor="confirmpassword">Confirm Password</label>
										<input type="text" className="form-control form-control-lg" name="confirmpassword" type="password" placeholder="Re-enter your New Password" value={this.state.confirmpassword} onChange={this.confirmpasswordChange} />
										<div style={{ color: "red" }}>{this.state.confirmpasswordError}</div>
										<br />
										<div className="d-flex justify-content-between">
											<button className="btn btn-back font-weight-medium btn-fw auth-form-btn mr-1" onClick={this.loginSubmit}>Back To Login</button>
											<button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" onClick={this.passwordSubmit}>SUBMIT</button>
										</div>
									</div>}
                        </form>
                    </div>
                </div>
            </Fragment>
        )
    }
}
function mapState(state) {
    return {
        user:state.authentication.user,
        nextedIn:state.authentication.nextedIn,
        otpIn:state.authentication.otpIn
    }
}

const actionCreators = {
    forgotPassword:userActions.forgotPassword,
    otp:userActions.otp,
    pwd:userActions.pwd
}

const connectedForgetpasswordPage = connect(mapState, actionCreators)(ForgetPassword);
export { connectedForgetpasswordPage as ForgetPassword };