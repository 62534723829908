import React from 'react';
import { connect } from 'react-redux';
import RoomIcon from '@material-ui/icons/Room';
import { Link } from 'react-router-dom';
import { authorizedData, isWebLogin, history, isPincodeValid } from "../../../../helpers";
import Menu from './Menu';
import logo from '../../../assets/img/logo-dark.png';
import PubSub from 'pubsub-js';
import { WebLogin } from './WebLogin'
import { VolunteerSignUp } from '../../Volunteer/VolunteerSignUp'
import { Modal } from 'react-responsive-modal';
import { userActions } from '../../../../store/actions'
import { toastr } from '../../../../services';
// import { VolunteerSignUp } from '../../Volunteer'
import { isMobile } from 'react-device-detect';

class WebHeader extends React.Component {
	constructor(props) {
		super(props);
		this.defaultMenu =
			this.state = {
				translation: sessionStorage.getItem("translation") ? sessionStorage.getItem("translation") : "English",
				menuData: this.getMenuArray(),
				loginData: [],
				isWebLogin: isWebLogin(),
				isLogin: false,
				isMenuClose: false
			}
	}


	getMenuArray = () => {
		return [
			{ parentName: window.strings.HEADER.HOME, path: "/" },
			{ parentName: window.strings.HEADER.ABOUT, path: "ourHistory", child: [{ childName: window.strings.HEADER.OUR_HISTORY, path: "ourHistory" }, { childName: window.strings.HEADER.OUR_BOARD_COMITTEE, path: "ourBoardComittee" }, { childName: window.strings.HEADER.OUR_FINANCIAL_ANNUAL_REPORTS, path: "ourFinancialReport" }] },
			{ parentName: window.strings.HEADER.OUR_WORK, path: "ourWork" },
			{
				parentName: window.strings.HEADER.GALLERY, path: "videosGallery", child: [
					// {childName : window.strings.HEADER.EVENTS,path : "events"},
					{ childName: window.strings.HEADER.VIDEOS_GALLERY, path: "videosGallery" }, { childName: window.strings.HEADER.PHOTOS_GALLERY, path: "photosGallery" }, { childName: window.strings.HEADER.SUCCESS_STORIES, path: "successStories" }]
			},
			{
				parentName: window.strings.HEADER.VOLUNTEER, path: "volunteersOpportunities", child: [
					{ childName: window.strings.HEADER.VOLUNTEER_OPPORTUNITIES, path: "volunteersOpportunities" },
					{ childName: window.strings.HEADER.STORIES_OF_VOLUNTEERS, path: "storiesOfVolunteers" },
				]
			},
			{
				parentName: window.strings.HEADER.DONATION, path: "donation"
				// parentName: window.strings.HEADER.DONATION, path: "donation", child: [
				// 	{ childName: window.strings.HEADER.DONATION, path: "donation" },
				// 	{ childName: window.strings.HEADER.FORTUNE_BAG, path: "fortuneBag" }
				// ]
			},
			// {parentName : "Event"}

			{ parentName: window.strings.HEADER.EVENT, path: "events" }
		]
	}

	componentDidMount() {
		let activeClass = 'head-bar';
		this.setState({ activeClass });

		window.addEventListener('scroll', () => {
			activeClass = 'head-scroll';
			if (window.scrollY === 0) {
				activeClass = 'head-bar';
			}
			this.setState({ activeClass });
		});

		if (isWebLogin()) {
			console.log("web", isWebLogin())
			this.setState({ isLogin: true, loginData: isWebLogin() })
		} else {
			this.setState({ isLogin: false, loginData: isWebLogin() })
		}
	}

	componentWillReceiveProps(newProps) {
		if (newProps.loggedIn && newProps.loggedIn.loggedIn) {
			this.triggerFunc(false)
		}
		this.setState({ menuData: this.getMenuArray() })

		if (isWebLogin()) {
			this.setState({ isLogin: true, loginData: isWebLogin() })
		} else {
			this.setState({ isLogin: false, loginData: isWebLogin() })
		}
	}

	triggerFunc = (value) => {
		// this.setState({ open: value })
		this.setState({ open: value })
		if (value == true) {
			this.setState({ ope: value })
		} else {
			this.setState({ open: value, volunteerSignup: false })
		}
	}
	handleSignup = (value) => {
		this.setState({ open: value, volunteerSignup: true })
	}
	handleLogin = (value) => {
		this.setState({ open: value, volunteerSignup: false })
	}
	languageChange = (translation) => {
		// this
		this.setState({ translation }, () => {
			sessionStorage.setItem("translation", translation)
			PubSub.publish('translation', translation);
		})
	}

	navigateHomePage = () => {
		sessionStorage.setItem("selectedParentPath", "home");
		sessionStorage.setItem("selectedChildPath", "");
		history.push('/')
	}

	navigateContactPage = () => {
		history.push('/contact')
	}

	resetSearch = () => {
		this.setState({ search: '' })
	}

	webLogout = () => {
		const toastrConfirmOptions = {
			onOk: () => {
				userActions.webLogout();
				// userActions.resetLogin();
				// this.getCategoryList();
				// this.triggerFunc(true);
				toastr.success("Logout Successfully")
			},
			onCancel: () => console.log('CANCEL: clicked')
		};
		toastr.customConfirm('', toastrConfirmOptions, 'Do you want to Logout ?');
	}

	menuClose = () => {
		this.setState((prevstate => ({ isMenuClose: prevstate.isMenuClose ? false : true })))
	}

	render() {
		const { menuData, loginData } = this.state;
		let menusLists = menuData && menuData.map((item, key) => {
			return <Menu key={key} isMobile={isMobile} menuClose={this.menuClose} item={item} showChild={true} resetSearch={() => this.resetSearch()} />
		});

		return (
			<div>

				{/* Login,Register and Forgot password Modal */}
				<div className="ht-mod">
					<Modal open={this.state.open} onClose={() => this.triggerFunc(false)} center
						classNames={{
							modal: 'login-mod',
						}}>
						{!this.state.volunteerSignup && <WebLogin triggerFunc={(value) => this.triggerFunc(value)} handleSignup={(value) => this.handleSignup(value)} />}
						{/* {this.state.volunteerSignup && <VolunteerSignUp  triggerFunc={(value) => this.triggerFunc(value)}/>} */}
					</Modal>

					{this.state.volunteerSignup && <Modal open={this.state.open} onClose={() => this.triggerFunc(false)} center
						classNames={{
							modal: 'signup-mod',
						}}>
						<VolunteerSignUp triggerFunc={(value) => this.triggerFunc(value)} handleLogin={(value) => this.handleLogin(value)} />
					</Modal>}
				</div>

				<header className="header-section">
					<div className={`${this.state.activeClass}`}>
						<div className="header-top">
							<div className="container">
								<div className="ht-left">
									<h5>{window.strings.HEADER.SENIORS_HELPLINE} : 1800 555 5555</h5>
								</div>
								<div className="ht-right">
									<div className="ht-social">
										<a href="https://www.facebook.com/sagecc/" className="icon-box f-icon"><i className="fab fa-facebook-square"></i></a>
										{/* <a href="#" className="icon-box t-icon"><i className="fab fa-twitter-square"></i></a> */}
										{/* <a href="#" className="icon-box l-icon"><i className="fab fa-linkedin-square"></i></a> */}
									</div>
									<div className="ht-dropdown">
										{this.state.translation == "Chinees" && <div type="button" onClick={() => this.languageChange("English")}>
											English
										</div>}
										{this.state.translation == "English" && <div type="button" onClick={() => this.languageChange("Chinees")}>
											中文
										</div>}
										{/* <div type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										中文
										</div> */}

										{/* <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
											<button class="dropdown-item" type="button">Language</button>
										</div> */}
									</div>
									{/* 									
									---Login
									{this.state.isLogin && <div className="nav-icon">
										<div className="dropdown">
											<div className="dropdown-toggle usr-box" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<i className="fa fa-user"></i>
												<span className="usr-text text-truncate">{(loginData && loginData.userName)}</span>
											</div>
											<div className="dropdown-menu dropdown-box" aria-labelledby="dropdownMenuButton">
												<button className="dropdown-item" type="button" onClick={this.webLogout}><i className="fa fa-sign-out"></i>Logout</button>
											</div>

										</div>
									</div>}
									{!this.state.isLogin && <div className="nav-icon">
										{!isWebLogin() && <Link to="#" className="login-panel" onClick={() => this.triggerFunc(true)} > {window.strings.HEADER.LOGIN}</Link>}
									</div>} ----*/}

									{/* <div className="si-up">
										<Link to="#" >Sign Up</Link>
									</div> */}
								</div>
							</div>
						</div>
						<div className="sub-header">
							<div className="container px-sm-0">
								<div className="row">
									<div className="col-lg-2 col-5 col-md-3 order-1 order-md-1">
										<a>
											<img src={logo} alt={"logo"} onClick={this.navigateHomePage} />
										</a>
									</div>
									<div className="col-lg-8 col-12 col-md-6 order-4 order-md-2">
										<nav className="nav-bar">
											<ul className={isMobile ? "mob-menu" : "nav-menu"}>
												{isMobile && <button onClick={this.menuClose} className="mob-btn">
													{!this.state.isMenuClose && <div><i className="fas fa-bars"></i>Menu</div>}
													{this.state.isMenuClose && <div><i className="fas fa-times"></i>Close</div>}
												</button>}
												{isMobile ? (this.state.isMenuClose && menusLists) : menusLists}
											</ul>
										</nav>
									</div>
									{/* <div className="col-lg-3 col-4 col-md-2 order-2 order-md-3">
												<div className="input-group">
													<form onSubmit={(e) => this.getProduct(e)}>
														<input className="form-control" type="text" name="search" value={this.state.search} onChange={this.handleInputChange} placeholder="Search Here" />
														<button type="submit"><i className="ti-search"></i></button>
													</form>
												</div>
										</div> */}
									<div className="col-lg-2 col-7 col-md-3 order-3 order-md-4 text-right">
										<button className="gradient-btn" onClick={this.navigateContactPage}>{window.strings.HEADER.CONTACTUS}</button>
									</div>
								</div>
								{/* <div className="col-lg-2 col-4 order-3 order-lg-4">
										<button className="gradient-btn" onClick={this.navigateContactPage}>Contact Us</button>
									</div> */}

								{/* <div className="col-lg-1 col-md-1 order-lg-4 order-xs-2 mfloatl3">
											<ul className="nav-right">
												<li className="cart-icon">
													<Link to="/wishlist">
														<i className="icon_heart_alt"></i>
														{isWebLogin() && this.state.wishListCount > 0 && <span>{this.state.wishListCount}</span>}
													</Link>
												</li>
												<CartBox handleRegisterPage={(value) => this.handleRegisterPage(value)} triggerFunc={(value) => this.triggerFunc(value)} />
												<li className="cart-icon">
													{isWebLogin() && <Link onClick={() => this.navigateNotification()}>
														<i className="far fa-bell"></i>
														{isWebLogin() && this.state.unreadCount > 0 && <span>{this.state.unreadCount}</span>}
													</Link>}
												</li>
											</ul>
										</div> */}
							</div>
						</div>
						{/* </div> */}

					</div>
				</header>
			</div>
		)
	}

}

const mapStateToProps = state => {
	return {}
}


const actionCreators = {}

const connectedWebHeader = connect(mapStateToProps, actionCreators)(WebHeader);
export { connectedWebHeader as WebHeader };