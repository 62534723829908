import {config} from '../config';
import queryString from "query-string"
import {authHeader,handleError,history} from '../helpers'
export const appoinmentService = {
	getList,
	updateStatus,
	createAppointment
}

function getList(obj){ //Get appoinments
    const requestOptions = {
        method : "GET",
        headers : {'Content-Type': 'application/json','accept' : 'application/json',...authHeader()},
    }
let params = queryString.stringify(obj)
    return fetch(`${config.apiUrl}/appointments/list?`+params,requestOptions).then(handleResponse,handleError)

}
function updateStatus(obj){
	const requestOptions = {
		method:'POST',
		headers : {'Content-Type': 'application/json','accept' : 'application/json',...authHeader()},
		body: obj
	}
	return fetch(`${config.apiUrl}/appointments/updateStatus`,requestOptions).then(handleResponse,handleError)
}

function createAppointment(obj){
	const requestOptions = {
		method:'POST',
		headers : {'Content-Type': 'application/json','accept' : 'application/json',...authHeader()},
		body: obj
	}
	return fetch(`${config.apiUrl}/appointments/create`,requestOptions).then(handleResponse,handleError)
}

function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				// logout();
			}

			if(response.status === 503){
				history.push('/maintenancePage')
			}
			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}
		return data;
	});
}
