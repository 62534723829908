import {config} from '../config';
import queryString from "query-string"
import {authHeader,handleError,history} from '../helpers'
export const ratingService = {
	getList,
	getNotificationList,
	pushNotification,
	getSupportList,
}
function getList(obj){ //Get ratinglist
    const requestOptions = {
        method : "GET",
        headers : {'Content-Type': 'application/json','accept' : 'application/json',...authHeader()},
    }
let params = queryString.stringify(obj)
    return fetch(`${config.apiUrl}/ratings/list?`+params,requestOptions).then(handleResponse)

}


function getNotificationList(obj) { //Get All list 
	const requestOptions = {
		method: 'GET',
		headers: {'Content-Type': 'application/json','accept' : 'application/json',...authHeader()},
	};
	let params = queryString.stringify(obj);
	return fetch(`${config.apiUrl}/shared/list?`+params, requestOptions).then(handleResponse,handleError);
}
function getSupportList(obj){
	const requestOptions = {
		method : 'GET',
		headers : {'Content-Type': 'application/json','accept' : 'application/json',...authHeader()},
	};
	let params = queryString.stringify(obj);
	return fetch(`${config.apiUrl}/shared/list?`+params , requestOptions).then(handleResponse,handleError)
}
function pushNotification(obj) { //Add and Update
	const requestOptions = {
		headers: { ...authHeader(),'Content-Type': 'application/json','accept' : 'application/json'},
		body: obj,
		method : 'POST'
	};	
	return fetch(`${config.apiUrl}/shared/createNotification`, requestOptions).then(handleResponse,handleError);
}
function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				// logout();
			}

			if(response.status === 503){
				history.push('/maintenancePage')
			}
			const error = (data && data.message) || (data.msg) || response.statusText;
			return Promise.reject(error);
		}
		return data;
	});
}