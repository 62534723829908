import {taskConstants} from '../../constants'
import {taskService} from '../../services'
import {history} from '../../helpers';
import {config} from '../../config'
import CryptoJS from 'crypto-js';
import {toastr} from '../../services'
import PubSub from 'pubsub-js';
export const taskActions = {
    getTask,
    saveTask,
    resetSaveTask,
    resetGetTask,
    delete : _delete,
    resetDelete
}
function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
}

function getTask(obj){
    return dispatch => {
        showLoader()
        taskService.getTask(obj)
        .then(tasks=>{
            tasks.request = obj
            dispatch({type:taskConstants.FETCH_TASK_SUCCESS,taskList:tasks})
            hideLoader()
        },error => {
            toastr.error(error)
            hideLoader()
        })
    }
}
function resetGetTask(){
    return dispatch => {
        dispatch({type:taskConstants.FETCH_TASK_SUCCESS,taskList:''})
    }
}
function saveTask(obj){
    return dispatch => {
        showLoader()
        taskService.saveTask(obj)
        .then(task =>{
            if(task && task.status == true){
            toastr.success('Task Saved successfully...!')
            dispatch({type:taskConstants.SAVE_TASK_SUCCESS,saveStatus:task.status})
            hideLoader()
            }else{
                hideLoader()
            }
        },error => {
            hideLoader()
           toastr.error(error)
        })
    }
}
function resetSaveTask(){
    return dispatch => {
        dispatch({type:taskConstants.SAVE_TASK_SUCCESS,saveStatus:''})
    }
}

function _delete(obj){
    return dispatch => {
        showLoader()
        taskService.delete(obj)
        .then (task => {
            if(task && task.status == true){
            toastr.success(task.message)
            dispatch({type:taskConstants.TASK_DELETE_SUCCESS,deleteStatus:task.status})
            hideLoader()
            }else{
                hideLoader()
            }
        },error => {
            hideLoader()
            toastr.error(error)
        })
    }
}

function resetDelete(){
    return dispatch => {
        dispatch({type:taskConstants.TASK_DELETE_SUCCESS,deleteStatus:''})
    }
}