import React ,{Component} from 'react'
import UploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import {history} from '../../../helpers';
import {categoryActions,userActions} from '../../../store/actions';
import {config} from '../../../config'
import {toastr} from '../../../services'
import Select from 'react-select';

class CreateCategory extends Component{
    constructor(props){
        super(props);
        this.state={
            title:'',
            id:'',
            chineseTitle :'',
            description:'',
            submitted:false,
           
        }
    }

    componentWillReceiveProps(newProps){
        if(newProps && newProps.categoryList && newProps.categoryList.data && newProps.categoryList.data.rows && newProps.categoryList.data.rows[0]){
            let editData = newProps.categoryList.data.rows[0]
            this.setState({
               id:editData.id && editData.id,
               title:editData.title && editData.title,
               chineseTitle:editData.chineseTitle && editData.chineseTitle,
               description:editData.description && editData.description,
               imagePreviewUrl:config.imgPath+'/categories/' +editData.imageName,
               getVolunteerData : editData.getVolunteerData,
               getcategoryFormData : editData.formType,
            })
        }

        if(newProps && newProps.userRole && newProps.userRole.data){
            let volunteersList = newProps.userRole && newProps.userRole.data && newProps.userRole.data.rows
            this.setState({volunteersList})
            this.props.resetUser()
        }
        let categoryDataFormType = [{id:1,name: 'Volunteer Form'},{id:2,name:'Enquiry Form'}];

        if(categoryDataFormType && this.state.getcategoryFormData){
            // let obj = {}
            let categoryForm = []
            console.log("user",this.state.getVolunteerData)
             categoryDataFormType.map(items => {
                this.state.getcategoryFormData.length > 0 && this.state.getcategoryFormData.map(data => {
                if((items && items.id) == (data && data)){
                    
                    let obj = {"label":items.name,"value":items.id}
                    categoryForm.push(obj)
                }
            })
            })
            this.setState({categoryForm})
        }
        
        if(this.state.volunteersList && this.state.getVolunteerData){
            // let obj = {}
            let volunteerObj = []
            console.log("user",this.state.getVolunteerData)
            this.state.volunteersList.map(items => {
                this.state.getVolunteerData && this.state.getVolunteerData.length > 0 && this.state.getVolunteerData.map(data => {
                if((items && items.id) == (data && data.id)){
                    
                    let obj = {"label":items.userName,"value":items.id}
                    volunteerObj.push(obj)
                }
            })
            })
            this.setState({volunteerObj})
        }
    
        if(newProps && newProps.saveStatus && newProps.saveStatus === true){
            this.props.resetSave()
            history.goBack()
        }
    }

    componentDidMount(){
        if(this.props.location && this.props.location.state && this.props.location.state.obj){
            let reqData = this.props.location.state.obj
            this.props.getList(reqData)
        }
        this.getVolunteerList()
    }

    getVolunteerList = () => {
        this.props.getUser({roleId:4})
    }

 
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }
    listPath = () => {
        history.push('/admin/category')
    }
    handleImgChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                fileData: file,
                imageName: file.name,
                imagePreviewUrl: reader.result
            })
        }
        if (e.target.files && e.target.files[0]) {
            reader.readAsDataURL(file)
        }
    }

    handleVolunteerChange = (data) => {      
        this.setState({volunteerObj:data})    
    }

    handleCategoryForm = (data) => {      
        this.setState({categoryForm:data})    
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const {title,description,id,fileData,volunteerObj,categoryForm,chineseTitle} = this.state;
        console.log(this.state,"STATE STATE STATE STATE STATE STATE")
        this.setState({submitted:true})
        
        if(title && volunteerObj && volunteerObj.length >= 1 && categoryForm.length >= 1){
            const formData = new FormData()
            let obj = {
                title,chineseTitle
            }
            let formTypeId = []
            categoryForm && categoryForm.map(item => { 
                formTypeId.push(item.value.toString())
            })
            obj['formType'] = formTypeId;
            let volunteerId = []
            volunteerObj && volunteerObj.map(item => {
                // let obj = {
                //     id : item.value
                // }
                volunteerId.push(item.value.toString())
            })
            obj['volunteerId'] = volunteerId;
            if(description){
                obj['description'] = description
            }
            if(id){
                obj['categoryId'] = id
            }
            formData.append('categoryObject',JSON.stringify(obj))
            fileData && formData.append('categoryImage',fileData)
            this.props.save(formData)
        }else{
            toastr.error('Mandatory fields are missing')

        }
    }
    render(){
        let  categoryForms = [{id:1,name: 'Volunteer Form'},{id:2,name:'Enquiry Form'}];
        let categoryFormType = [];
        categoryForms && categoryForms.map(item=>{
            let obj = {"label":item.name,"value":item.id}
            categoryFormType.push(obj)
        })
        let volunteerOptions = []
        this.state.volunteersList && this.state.volunteersList.map(item=>{
            let obj = {"label":item.userName,"value":item.id}
            volunteerOptions.push(obj)
        })
        return(
            <div>
                 <section>
                    <div className="tit-sec">{this.state.id ? <h4>Edit Category</h4> : <h4>Add Category</h4>}</div>
                            <div class="card">
                                <div class="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-tab row">
                                        <div className="col-md-6">
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Title&nbsp;<span class="mandatory">*</span></label>
                                                <div className="col-sm-8">
                                                <input type="text" name="title" value={this.state.title} onChange={this.handleInputChange} placeholder="Enter Title" className="form-control"/>
                                                {this.state.submitted && !this.state.title && <div className="mandatory">Title field is required</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Chinese Title&nbsp;<span class="mandatory">*</span></label>
                                                <div className="col-sm-8">
                                                <input type="text" name="chineseTitle" value={this.state.chineseTitle} onChange={this.handleInputChange} placeholder="Enter Chinese Title" className="form-control"/>
                                                {this.state.submitted && !this.state.chineseTitle && <div className="mandatory">Chinese Title field is required</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Description</label>
                                                <div className="col-sm-8">
                                                <input type="text" name="description" value={this.state.description} onChange={this.handleInputChange} placeholder="Enter Description" className="form-control"/>

                                                </div>
                                            </div>
                                        </div>
                                   
                                        <div className="col-md-6">
                                            <div className="form-group row">
                                            <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Image</label>
                                                <div className="col-sm-8">
                                                    <div className="choose-wrap">
                                                        <label className="choo-btn" for="file"> 
                                                            <UploadIcon className="mr-2"/>Choose File
                                                            <input type="file" name="image" id="file" single="" accept="image/*" onChange={this.handleImgChange} />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <img src={this.state.imagePreviewUrl} height="80px" width="80px" />
                                                    </div>
                                                {/* <input type="file" name="image" id="file" single="" accept="image/*" onChange={this.handleImgChange}  className="input-box"/>
                                                <label for="file" className="input-btn"><UploadIcon />Choose File</label> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Select Form Type&nbsp;<span class="mandatory">*</span></label>
                                                    <div className="col-sm-8">
                                                        <Select className="state-box"
                                                            styles={{
                                                                control: base => ({
                                                                ...base,
                                                                borderColor: 'hsl(0,0%,80%)',
                                                                boxShadow: '#FE988D',
                                                                '&:focus': {
                                                                borderColor: '#80bdff'
                                                                            }
                                                                        }),
                                                                        menu: base => ({
                                                                            ...base,
                                                                            zIndex:"5"
                                                                            }),
                                                                        menuList: base => ({
                                                                            ...base,
                                                                            maxHeight:"140px",
                                                                           })
                                                                    }}
                                                                    
                                                                    value={this.state.categoryForm}
                                                                    onChange={this.handleCategoryForm}
                                                                    options={categoryFormType}
                                                                    placeholder="Select Form Type"
                                                                    isMulti
                                                                />   
                                                                {this.state.submitted && !this.state.categoryForm && <div className="mandatory">Form Type field is required</div>}           
                                                        </div>                        
                                                    </div>
                                                </div>
                                        <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Select Volunteer&nbsp;<span class="mandatory">*</span></label>
                                                    <div className="col-sm-8">
                                                        <Select className="state-box"
                                                            styles={{
                                                                control: base => ({
                                                                ...base,
                                                                borderColor: 'hsl(0,0%,80%)',
                                                                boxShadow: '#FE988D',
                                                                '&:focus': {
                                                                borderColor: '#80bdff'
                                                                            }
                                                                        }),
                                                                        menu: base => ({
                                                                            ...base,
                                                                            zIndex:"5"
                                                                            }),
                                                                        menuList: base => ({
                                                                            ...base,
                                                                            maxHeight:"140px",
                                                                           })
                                                                    }}
                                                                    
                                                                    value={this.state.volunteerObj}
                                                                    onChange={this.handleVolunteerChange}
                                                                    options={volunteerOptions}
                                                                    placeholder="Select Volunteer"
                                                                    isMulti
                                                                />   
                                                                {this.state.submitted && !this.state.volunteerObj && <div className="mandatory">Volunteer field is required</div>}           
                                                        </div>                        
                                                    </div>
                                                </div>
                                        </div>
                                        <div class="bottom-btn mt-4">
                                                <button type="button" className="btn cancel-btn" onClick={this.listPath}>Cancel</button>
                                                <button type="submit" className="btn add-btn"> Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                </section>
            </div>
        )
    }
}
function mapStateToProps(state){
    return {
       saveStatus : state.category.saveStatus,
       categoryList:state.category.categoryList,
       userRole:state.users.userRole,
    }
}
const actionCreators = {
    save : categoryActions.save,
    getList : categoryActions.getList,
    resetSave:categoryActions.resetSave,
    getUser : userActions.getUser,
    resetUser : userActions.resetUser
}
const categoryUser = connect(mapStateToProps, actionCreators)(CreateCategory);
export { categoryUser as CreateCategory };