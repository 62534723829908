import React from 'react';
import { connect } from 'react-redux';
import AbtBanner from '../../assets/img/img_abtbanr.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import StryImg1 from '../../assets/img/img_stry_1.png';
import {history} from '../../../helpers'
import GalleryBanner from '../../assets/img/Banner/Gallery/GalleryBanner.png';
import TESTIMONIAL_BANNER from '../../assets/img/NewBanner/Testimonial.png';

 class SuccessStories extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
        }
    }
    goToHome = () => {
        history.push('/')
    }
        render(){
            const responsive = {
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                }
              }; 
            return(
            <div className="stry-wrap">
                <nav class="breadcrumb">
                    <div className="container d-flex">
                        <a class="breadcrumb-item" onClick={this.goToHome}><i className="fas fa-home"></i></a>                      
                        <span class="breadcrumb-item active">{window.strings.HEADER.GALLERY}</span>
                        <span class="breadcrumb-item active">{window.strings.HEADER.SUCCESS_STORIES}</span>
                    </div>
                </nav>
                <Carousel className="stry-slide success-slide"
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        autoPlay={false}
                        arrows={false}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        customTransition="all 1.2s ease 0s"
                        transitionDuration={1000}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        deviceType={this.props.deviceType}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                    >
                        <div className="abt-banr">
                            <img src={TESTIMONIAL_BANNER}/>
                        </div>

                       {/* <div className="abt-banr">
                            <img src={GalleryBanner}/>
                        </div> */}
                        {/* <div className="abt-banr">
                            <img src={AbtBanner}/>
                        </div>
                        <div className="abt-banr">
                            <img src={AbtBanner}/>
                        </div> */}
                    </Carousel>
                    <div className="container">
                        <div className="spad">
                           <div className="text-center">
                                <h3>{window.strings.SUCCESSSTORIES.SUCCESS_SAGECC}</h3>
                                <h4>{window.strings.HEADER.SUCCESS_STORIES}</h4>
                                {/* <h4>{window.strings.OURWORK.HMS}</h4> */}

                                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </p> */}
                            </div>
                            {/* <div className="hms-testimonial">
                                                
                             </div> */}
                             {/* <div>
                             <div className="card">
                                 <div className="card-body">
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_T1}</p>
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_T2}</p>
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_T3}</p>
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_T4}</p>
                                </div>
                             </div>
                             <div className="card">
                                 <div className="card-body">
                                                     <p>{window.strings.OURWORK.HMS_CONTENT_V1}</p>
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_V2}</p>
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_V3}</p>
                                </div>
                             </div>
                             <div className="card">
                                 <div className="card-body">
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_LIM1}</p>
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_LIM2}</p>
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_LIM3}</p>
                                </div>
                             </div>
                            </div> */}

                                <div id="carouselExampleIndicators" class="carousel slide caros-wrap" data-ride="carousel">
                                <ol class="carousel-indicators">
                                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                                </ol>
                                <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <div className="caros-box">
                                    <div className="caros-head"><h3>{window.strings.OURWORK.HMS}</h3></div>
                                    <div className="caros-body">
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_ONE}</p>
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_TWO}</p>
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_THREE}</p>
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_FOUR}</p>
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_FIVE}</p>
                                    </div>
                                    </div>
                                    </div>
                                    <div class="carousel-item">
                                    <div className="caros-box">
                                    <div className="caros-head"><h3>{window.strings.SUCCESSSTORIES.TESTI_MONIAL} 1</h3></div>
                                    <div className="caros-body">
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_T1}</p>
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_T2}</p>
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_T3}</p>
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_T4}</p>
                                    </div>
                                    </div>
                                    </div>
                                    <div class="carousel-item">
                                    <div className="caros-box">
                                    <div className="caros-head"><h3>{window.strings.SUCCESSSTORIES.TESTI_MONIAL} 2</h3></div>
                                    <div className="caros-body">
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_V1}</p>
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_V2}</p>
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_V3}</p>
                                    </div>
                                    </div>
                                    </div>
                                    <div class="carousel-item">
                                    <div className="caros-box">
                                    <div className="caros-head"><h3>{window.strings.SUCCESSSTORIES.TESTI_MONIAL} 3</h3></div>
                                    <div className="caros-body">
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_LIM1}</p>
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_LIM2}</p>
                                                    <p>{window.strings.OURWORK.HMS_CONTENT_LIM3}</p>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Next</span>
                                </a>
                                </div>


                            {/* <div className="card">
                                <div className="stry-box">
                                    <div className="row">
                                        <div className="col-lg-3 col-sm-3 col-12"><img src={StryImg1} /></div>
                                        <div className="col-lg-8 col-sm-8 col-12">
                                            <h5>User Name</h5>
                                            <h6>Location, SG</h6>
                                            <p className="text-truncate">"Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.""
                                            </p>
                                            <button className="btn info-btn">Read More</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="stry-box">
                                    <div className="row">
                                        <div className="col-lg-3 col-sm-3 col-12"><img src={StryImg1} /></div>
                                        <div className="col-lg-8 col-sm-8 col-12">
                                            <h5>User Name</h5>
                                            <h6>Location, SG</h6>
                                            <p className="text-truncate">"Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.""
                                            </p>
                                            <button className="btn info-btn">Read More</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="stry-box">
                                    <div className="row">
                                        <div className="col-lg-3 col-sm-3 col-12"><img src={StryImg1} /></div>
                                        <div className="col-lg-8 col-sm-8 col-12">
                                            <h5>User Name</h5>
                                            <h6>Location, SG</h6>
                                            <p className="text-truncate">"Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.""
                                            </p>
                                            <button className="btn info-btn">Read More</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="spad text-center"> 
                                <button className="gradient-btn">View More</button>
                            </div>*/}
                        </div>
                    </div>
            </div>
            )            
        }}

const mapStateToProps = state => {
    return {}
}


const actionCreators = { }

const connectedSuccessStories = connect(mapStateToProps, actionCreators)(SuccessStories);
export { connectedSuccessStories as SuccessStories };