import React from 'react';
import { connect } from 'react-redux';
import MaintenanceImg from '../../assets/img/img_maintenance.png'
import {history} from '../../../helpers'

class MaintenancePage extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
        }
    }

    backnavigation = () => {
        history.goBack() 
    }

    render(){
        return(
            <div className="maintenance-wrap">
                <div className="container">
                    <div className="spad">
                            <h3>Our site is under Maintenance!</h3>
                            <div className="m-img">
                                <img src={MaintenanceImg} />
                            </div>
                            <button className="cncl-btn" onClick={this.backnavigation}>Back</button>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {}
}

const actionCreators = {
   
   }

const connectedMaintenancePage = connect(mapStateToProps, actionCreators)(MaintenancePage);
export { connectedMaintenancePage as MaintenancePage };