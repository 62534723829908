import React from 'react';
import { connect } from 'react-redux';
import VltImg from '../../assets/img/img_vlntrpic_2.png';
import AbtBanner from '../../assets/img/img_abtbanr.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {history} from '../../../helpers'
import Select from 'react-select';
import {toastr} from '../../../services'
import {config} from '../../../config';
import PubSub from 'pubsub-js';


import Art_Ambassador from '../../assets/img/Banner/Volunteer/Art_Ambassador.JPG';
import CA from '../../assets/img/Banner/Volunteer/CaVolunteer.png';
import HMS2 from '../../assets/img/Banner/Volunteer/HMS2.jpeg';
import RW_Ambassadors from '../../assets/img/Banner/Volunteer/RwaVolunteer.png';
import SHL from '../../assets/img/Banner/Volunteer/SHL.png';
import Specialist_Volunteers from '../../assets/img/Banner/Volunteer/Specialist_Volunteers.jpeg';
import VOLUNTEER_BANNER from '../../assets/img/NewBanner/Volunteer.png';


 class StoriesOfVolunteers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            name:'',
            email:'',
            address:'',
            mobile:'',
        }
    }
    handleInputChange = (e) => {
      e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
      if (e.target.name == "email") {
     this.validate(this.state.email)
  }
 }
       
    passCheck = (value) => {
        const passCheck = /^(?=.*[A-Z])/;
        if(passCheck.test(value) && ((value && value.length) == 8)){
            return true
        }else{
            return false
        }
    }
    async componentDidMount() {
        console.log("ENTERS");
        // GET request using fetch with async/await `${config.apiUrl}/category/create`
        const response = await fetch(`${config.apiUrl}/category/list`);
        const data = await response.json();
        this.setState({listCategory:data.data.rows})
        console.log(data.data.rows,"DATAAAAAAAAAAAA");
        // this.setState({ totalReactPackages: data.total })
   }
    validate = (value) => {
      var emailRegex = /^([a-z])+([a-z0-9_\.\-])+\@(([a-z\-])+\.)+([a-z]{2,3})$/;
      if (emailRegex.test(value)) {
        return true
      } else {
        return false;
      }
      }
      handleVolunteerChange = (data) => {      
        this.setState({categoryObj:data})    
    }
    handleSubmit = (e) => {
        e.preventDefault()
        const {name,email,mobile,address,categoryObj} = this.state
        this.setState({submitted:true})
        if(name && email && this.validate(email) && mobile && mobile.length == 8 && address && categoryObj){
            console.log('STATE',this.state);
            let obj ={
                'name':name,
                'email':email,
                'mobile':mobile,
                'address':address,
            }
            let categoryId = []
            categoryObj && categoryObj.map(item => {
                categoryId.push(item.label.toString())
            });
           
            obj['category'] = categoryId.toString();;
             (async () => {               
                            const rawResponse = await fetch(`${config.apiUrl}/form/mailVolunteerDetails`, {
                                method: 'POST',
                                headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(obj)
                            });
                            const content = await rawResponse.json();
                            console.log(content);
                            if(content.status == true)
                            {
                              
                                toastr.success('Thank you')
                                this.setState({name:'',email:'',mobile:'',address:'',categoryObj:'',submitted:false})
                        
                            }
              })();
          
        }
        else{

           toastr.error('Mandatory fields are missing')
        }
       
    }
    goToHome = () => {
        history.push('/')
    }
        render(){
            let volunteerOptions = []
        this.state.listCategory && this.state.listCategory.map(item=>{
            let obj = {"label":item.title,"value":item.id}
            volunteerOptions.push(obj)
        })
            const responsive = {
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                }
              };  
            return(
              <div className="stry-wrap">
              <nav class="breadcrumb">
                <div className="container d-flex">
                  <a class="breadcrumb-item" onClick={this.goToHome}><i className="fas fa-home"></i></a>
                    <span class="breadcrumb-item active">{window.strings.HEADER.VOLUNTEER}</span>
                    <span class="breadcrumb-item active">{window.strings.HEADER.STORIES_OF_VOLUNTEERS}</span>
                </div>
              </nav>
              <Carousel className="stry-slide"
                      swipeable={false}
                      draggable={false}
                      showDots={false}
                      responsive={responsive}
                      ssr={true} // means to render carousel on server-side.
                      infinite={true}
                      autoPlay={false}
                      arrows={false}
                    //   autoPlay={this.props.deviceType !== "mobile" ? true : false}
                      autoPlaySpeed={3000}
                      keyBoardControl={true}
                      customTransition="all 1.2s ease 0s"
                      transitionDuration={1000}
                      containerClass="carousel-container"
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                      deviceType={this.props.deviceType}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                  >
                       <div className="vol-banr">
                          <img src={VOLUNTEER_BANNER}/>
                      </div>


{/* 
                        <div className="vol-banr">
                            <img src={Art_Ambassador}/>
                        </div>
                        <div className="vol-banr">
                            <img src={CA}/>
                        </div>
                        <div className="vol-banr">
                            <img src={HMS2}/>
                        </div>

                        <div className="vol-banr">
                            <img src={RW_Ambassadors}/>
                        </div>
                        <div className="vol-banr">
                            <img src={SHL}/>
                        </div>
                        <div className="vol-banr">
                            <img src={Specialist_Volunteers}/>
                        </div> */}
                  </Carousel>
                  <div className="container">
                      <div className="spad">
                         <div className="text-center">
                              <h3>{window.strings.VOLUNTEER.VOLUNTEER}</h3>
                              <h4>{window.strings.VOLUNTEER.STORIES_OF_VOLUNTEERS}</h4>
                        </div>
                        <div className="row">
                        <div className="col-md-12">
                        <div className="stry-vol">
                              <h5>{window.strings.VOLUNTEER.EXPERIENCE_SENIOR_HELPLINE}</h5>
                              <p>{window.strings.VOLUNTEER.EXPERIENCE_CONTENT_ONE}</p>
                              <p>{window.strings.VOLUNTEER.EXPERIENCE_YEARS}</p>
                              <p>{window.strings.VOLUNTEER.EXPERIENCE_YEARS_ONE}</p>
                              <p>{window.strings.VOLUNTEER.EXPERIENCE_YEARS_TWO}</p>
                              <p>{window.strings.VOLUNTEER.EXPERIENCE_YEARS_THREE}</p>
                              <p>{window.strings.VOLUNTEER.EXPERIENCE_CONTENT_TWO}</p>
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="card">
                                    <div className="card-header">
                                        <h5>{window.strings.VOLUNTEER.VOL_SIGNUP_FORM}</h5>
                                    </div>
                                    <div className="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>{window.strings.VOLUNTEER.VOL_NAME}</label>&nbsp;<span class="mandatory">*</span>
                                                    <input type="text" className="form-control" name="name" value={this.state.name} onChange={this.handleInputChange} placeholder={window.strings.VOLUNTEER.VOL_NAME} />
                                                    {this.state.submitted && !this.state.name && <div className="mandatory">{window.strings.VOLUNTEER.NAMEENTER}</div>}

                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>{window.strings.VOLUNTEER.VOL_ADDRESS}</label>&nbsp;<span class="mandatory">*</span>
                                                    <input type="text" className="form-control" name="address" value={this.state.address} onChange={this.handleInputChange}  placeholder={window.strings.VOLUNTEER.VOL_ADDRESS} />
                                                    {this.state.submitted && !this.state.address && <div className="mandatory">{window.strings.VOLUNTEER.ADDRESSENTER}</div>}

                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>{window.strings.VOLUNTEER.VOL_PHONE_NUMBER}</label>&nbsp;<span class="mandatory">*</span>
                                                    <input type="number" name="mobile" value={this.state.mobile} onChange={this.handleInputChange} className="form-control" placeholder={window.strings.VOLUNTEER.VOL_PHONE_NUMBER} />
                                                    {this.state.submitted && !this.state.mobile && <div className="mandatory">{window.strings.VOLUNTEER.MOBILEENTER}</div>}
                                                   {this.state.submitted && this.state.mobile && this.state.mobile.length != 8 && <div className="mandatory">{window.strings.VOLUNTEER.MOBILEERROR}</div>}
                                       
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>{window.strings.VOLUNTEER.VOL_EMAIL_ADDRESS}</label>&nbsp;<span class="mandatory">*</span>
                                                    <input type="email" name="email"  value={this.state.email} onChange={this.handleInputChange} className="form-control" placeholder={window.strings.VOLUNTEER.VOL_EMAIL_ADDRESS}  />
                                                    {this.state.submitted && !this.state.email && <div className="mandatory">{window.strings.VOLUNTEER.EMAILENTER}</div>}
                                                    {this.state.submitted && this.state.email && !this.validate(this.state.email) && <div className="mandatory">{window.strings.VOLUNTEER.EMAILERROR}</div>}
                                       
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label >{window.strings.VOLUNTEER.VOL_CAT}&nbsp;<span class="mandatory">*</span></label>
                                                        <Select className="state-box"
                                                            styles={{
                                                                control: base => ({
                                                                ...base,
                                                                borderColor: '#ced4da',
                                                                boxShadow: '#FE988D',
                                                                border:'0',
                                                                borderBottom:'1px solid #ced4da',
                                                                borderRadius:'0',
                                                                '&:focus': {
                                                                borderColor: '#ced4da'
                                                                            }
                                                                        }),
                                                                        menu: base => ({
                                                                            ...base,
                                                                            zIndex:"5"
                                                                            }),
                                                                        menuList: base => ({
                                                                            ...base,
                                                                            maxHeight:"140px",
                                                                           })
                                                                    }}
                                                                    
                                                                    value={this.state.categoryObj}
                                                                    onChange={this.handleVolunteerChange}
                                                                    options={volunteerOptions}
                                                                    placeholder={window.strings.VOLUNTEER.VOL_CAT}
                                                                    isMulti
                                                                />   
                                                                {this.state.submitted && !this.state.categoryObj && <div className="mandatory">{window.strings.VOLUNTEER.CATEGORYENTER}</div>}           
                                                    </div>
                                                </div>
                                     

  
                                        </div>
                                        <div className="text-left">
                                            <button type="submit" className="gradient-btn">{window.strings.CONTACT.SEND}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            </div> */}
                        </div>

                          {/* <div className="card">
                              <div className="stry-box">
                                  <div className="row">
                                      <div className="col-lg-3"><img src={VltImg} /></div>
                                      <div className="col-lg-8">
                                          <h5>User Name</h5>
                                          <h6>Location, SG</h6>
                                          <p className="text-truncate">"Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                              when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                              when an unknown printer took a galley of type and scrambled it to make a type specimen book.""
                                          </p>
                                          <button className="btn info-btn">Read More</button>
                                      </div>
                                  </div>
                              </div>
                          </div> */}

                          {/* <div className="spad text-center">
                              <button className="gradient-btn">View More</button>
                          </div> */}
                      </div>
                  </div>
          </div>
            )            
        }}

const mapStateToProps = state => {
    return {}
}


const actionCreators = { }

const connectedStoriesOfVolunteers = connect(mapStateToProps, actionCreators)(StoriesOfVolunteers);
export { connectedStoriesOfVolunteers as StoriesOfVolunteers }; 