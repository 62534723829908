import React,{Component,Fragment} from 'react'
import UploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import {history,ReactPagination} from '../../../helpers';
import {ratingActions} from '../../../store/actions'
import {toastr} from '../../../services'
import Toggle from 'react-toggle';
class FetchNotification extends Component{
    constructor(props){
        super(props);
        this.state={
            page:1,
            limit:10,
            searchTxt:''
        }
    }
    componentDidMount(){
        this.list()
     
    }
    componentWillReceiveProps(newProps){

        if(newProps && newProps.notificationList && newProps.notificationList.data){
            let data = newProps.notificationList && newProps.notificationList.data && newProps.notificationList.data.rows
            let request = newProps.notificationList && newProps.notificationList.request ? newProps.notificationList.request : []
            let totalCount = newProps.notificationList && newProps.notificationList.data && newProps.notificationList.data.count ? newProps.notificationList.data.count : []
            if(data.length == 0){
                toastr.error("Result Not Found")
            }
            this.setState({notificationList:data,totalCount,pageCount:totalCount/request.limit})
           this.props.resetList()
        }
    }
    list = () => {
        if(this.props && this.props.notificationList && this.props.notificationList.status == true){
            let request = this.props.notificationList && this.props.notificationList.request ? this.props.notificationList.request : "";
            if(request.size || request.page || request.searchTxt){
                this.setState({
                    limit:request.limit,
                    page:request.page,
                    searchTxt:request.searchTxt
                },()=>{
                    this.getList()
                })
            }else{
                this.getList()
            }
        }
        else{
            this.getList()
        }
    }
    getList = () => {
       
        let obj = {}
        if(this.state.searchTxt){
            obj['page'] = this.state.page
            obj['limit'] = this.state.limit
            obj['searchTxt'] = this.state.searchTxt
            obj['type'] = "notification"
            // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
            // obj['roleId'] = this.state.authorizedData ? this.state.authorizedData.roleId : ""
        }
        else{
            obj['page'] = this.state.page
            obj['limit'] = this.state.limit
            obj['type'] = "notification"
            // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
            // obj['roleId'] = this.state.authorizedData ? this.state.authorizedData.roleId : ""
        }
       
        this.props.getNotificationList(obj)
    }
    
    resetSearch = () => {
        
        this.setState({searchTxt  : '',page : 1},() => {
            this.getList()
        })      
    }
    formPath = () => {
        history.push('/admin/pushnotification')
    }
   
    onChange = (data)=> {
        if(this.state.page !== (data.selected+1)){
        this.setState({
            page:data.selected +1
        }, ()=> {
            this.getList()
        })
    }
}
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }
    
    pageNavigation = () => {
        history.push('/admin/pushnotification')
    }
    getNewResult = (e) => {
        e.preventDefault();
        if(this.state.searchTxt){
            this.setState({ page : 1 },() => {
            this.getList()
            })
        }else{
            toastr.error("Please type to search")
        }        
    }
    render(){
        const {limit,page} = this.state
        let notificationList = this.state.notificationList && this.state.notificationList.map((noti,index) => {
            return <tr>
            <td>{(page - 1) * limit + index  + 1}</td>
            <td>{(noti.to && noti.to.userName ? noti.to.userName : '-')}</td>
            <td>{(noti.to && noti.to.email ? noti.to.email : '-')}</td>
            <td>{noti.title}</td>
            <td>{noti.description}</td>
                  
            
        </tr>
        })
        return(
            <Fragment>
                <div className="row tit-sec">
                    <div className="col-6 col-md-5">
                        <h4>Notification</h4>
                    </div>
                    <div className="col-6 col-md-7 text-right"> 
                        <button className="btn add-btn" onClick={this.formPath}><i className="fas fa-paper-plane"></i>Send Notification</button>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="pt-2 mb-3">
                            <div className="d-flex justify-content-end">
                                <form onSubmit={this.getNewResult} class="align-items-center" >
                                    <div class="input-group">
                                      <input type="text" placeholder="Search.." name="searchTxt" value={this.state.searchTxt} onChange={this.handleInputChange} aria-label="Recipient's username" formcontrolname="customSearch" class="form-control ng-pristine ng-valid ng-touched" />
                                        <div class="input-group-append">
                                            <button type="submit" class="btn btn-sm btn-dark" ><i className="fas fa-search"></i></button>
                                            <div class="pl-3">
                                                <button _ngcontent-wdd-c125="" type="button" onClick={this.resetSearch} class="btn btn-outline-secondary btn-rounded btn-icon">
                                                <i class="fas fa-redo-alt"></i>
                                                </button>
                                            </div>
                                        </div>
                                      </div>
                                </form>
                            </div>
                        </div>
            <div className="table-wrap">
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Title</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                                {notificationList}
                        </tbody>
                    </table>
                </div>
                {this.state.notificationList && this.state.notificationList.length == 0 && <div>
                            <h6 className="text-center py-4">No Record Found</h6>
                            </div>}
            </div>
           </div>

           </div>
           {this.state.notificationList && this.state.notificationList.length > 0 && <ReactPagination PageDetails={{pageCount: this.state.pageCount, onPageChange: this.onChange, activePage: (this.state.page - 1), perPage: this.state.limit, totalCount: this.state.totalCount}}/>}
        </Fragment>

        )
    }
}
function mapStateToProps(state){
    return {
        notificationList : state.rating.notificationList
    }
}
const actionCreators = {
    getNotificationList : ratingActions.getNotificationList,
    resetList : ratingActions.resetNotificationList
}
const Notification = connect(mapStateToProps, actionCreators)(FetchNotification);
export {Notification as FetchNotification };