import React,{Component,Fragment} from 'react'
import UploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import {history,ReactPagination} from '../../../helpers';
import {chatActions} from '../../../store/actions'
import {toastr} from '../../../services'
import Toggle from 'react-toggle';
class FetchDonars extends Component{
    constructor(props){
        super(props);
        this.state={
            page:1,
            limit:10,
            searchTxt:'',
            chatList:[]
        }
    }
    componentDidMount(){
        this.list()
     
    }
    componentWillReceiveProps(newProps){

        if(newProps && newProps.donarList && newProps.donarList.data){
            let data = newProps.donarList && newProps.donarList.data && newProps.donarList.data.rows
            let request = newProps.donarList && newProps.donarList.request ? newProps.donarList.request : []
            let totalCount = newProps.donarList && newProps.donarList.data && newProps.donarList.data.count ? newProps.donarList.data.count : []
            if(data.length == 0){
                toastr.error("Result Not Found")
            }
            this.setState({donarList:data,totalCount,pageCount:totalCount/request.limit})
           this.props.resetList()
        }
    }
    list = () => {
        if(this.props && this.props.donarList && this.props.donarList.status == true){
            let request = this.props.donarList && this.props.donarList.request ? this.props.donarList.request : "";
            if(request.size || request.page || request.searchTxt){
                this.setState({
                    limit:request.limit,
                    page:request.page,
                    searchTxt:request.searchTxt
                },()=>{
                    this.getList()
                })
            }else{
                this.getList()
            }
        }
        else{
            this.getList()
        }
    }
    getList = () => {
       
        let obj = {}
        if(this.state.searchTxt){
            obj['page'] = this.state.page
            obj['limit'] = this.state.limit
            obj['searchTxt'] = this.state.searchTxt
            // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
            // obj['roleId'] = this.state.authorizedData ? this.state.authorizedData.roleId : ""
        }
        else{
            obj['page'] = this.state.page
            obj['limit'] = this.state.limit
            // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
            // obj['roleId'] = this.state.authorizedData ? this.state.authorizedData.roleId : ""
        }
       
        this.props.getDonarList(obj)
    }
    
    resetSearch = () => {
        
        this.setState({searchTxt  : '',page : 1},() => {
            this.getList()
        })      
    }
   
    onChange = (data)=> {
        if(this.state.page !== (data.selected+1)){
        this.setState({
            page:data.selected +1
        }, ()=> {
            this.getList()
        })
    }
}
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }
    getNewResult = (e) => {
        e.preventDefault();
        if(this.state.searchTxt){
            this.setState({ page : 1 },() => {
            this.getList()
            })
        }else{
            toastr.error("Please type to search")
        }        
    }
    render(){
        const {limit,page} = this.state
        let donarList = this.state.donarList && this.state.donarList.map((items,index)=>{
            if(items && items.id != 0){
            return <tr>
                <td>{(page - 1) * limit + index  + 1}</td>
                <td>{items.callerName}</td>
                <td>{items.email}</td>
                <td>{items.mobile}</td>
                <td>{items.NRICNumber}</td>
                {/* <td><img src={config.apiUrl +`/uploads/categories/`+ items.imageName}  height="50px" width="50px" ></img></td>
                <td>
                    <a className="tooltip-wrap" onClick={()=>this.itemEdit(items.id)}>
                        <EditIcon className="edit-icon"/>
                        <div className="tooltip">
                            <div className="arrow"></div>
                            <div className="tooltip-inner">Edit</div>
                        </div>
                    </a>
                    <a className="tooltip-wrap" onClick={()=>this.handleDelete(items.id)}>
                        <DeleteIcon className="delete-icon"/>
                        <div className="tooltip">
                            <div className="arrow"></div>
                            <div className="tooltip-inner">Delete</div>
                        </div>
                    </a>
                </td> */}
            </tr>
            }
        })
        return(
       
        <Fragment>
        <div className="row tit-sec">
            <div className="col-6 col-md-5">
              <h4>Donors List</h4>
            </div>
        </div>
           <div className="card">
           <div className="card-body">
           <div className="pt-2 mb-3">
                                <form onSubmit={this.getNewResult} class="align-items-center" >
                                   <div className="d-flex justify-content-end">
                                    <div className="col-md-5 col-lg-3">
                                        <div class="input-group">
                                            <input type="text" placeholder="Search.." name="searchTxt" value={this.state.searchTxt} onChange={this.handleInputChange} aria-label="Recipient's username" formcontrolname="customSearch" class="form-control ng-pristine ng-valid ng-touched" />
                                            <div class="input-group-append">
                                                <button type="submit" class="btn btn-sm btn-dark" ><i className="fas fa-search"></i></button>
                                                <div class="pl-3">
                                                    <button _ngcontent-wdd-c125="" type="button" onClick={this.resetSearch} class="btn btn-outline-secondary btn-rounded btn-icon">
                                                    <i class="fas fa-redo-alt"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </form>
                        </div>
            <div className="table-wrap">
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                        <tr>
                        <th>S.No</th>
                        <th>Donar Name</th>
                    <th>Email</th>
                       <th>Mobile</th>
                      <th>NRIC Number</th>
                            </tr>
                        </thead>
                <tbody>
                        {donarList}
                </tbody>
                </table>
                </div>
                {this.state.donarList && this.state.donarList.length == 0 && <div>
                        <h6 className="text-center py-4">No Record Found</h6>
                        </div>}
            </div>
           </div>
           </div>
           {this.state.donarList && this.state.donarList.length > 0 && <ReactPagination PageDetails={{pageCount: this.state.pageCount, onPageChange: this.onChange, activePage: (this.state.page - 1), perPage: this.state.limit, totalCount: this.state.totalCount}}/>}

        </Fragment>
        )
    }
}
function mapStateToProps(state){
    return {
      donarList : state.chat.donarList
    }
}
const actionCreators = {
  getDonarList:chatActions.getDonarList,
  resetList: chatActions.resetDonarList
}
const Donar = connect(mapStateToProps, actionCreators)(FetchDonars);
export {Donar as FetchDonars };