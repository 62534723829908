import React, { Component } from 'react'
// import logo from './logo.svg';
import {Router,Route,Redirect,Switch} from "react-router-dom";
import {FetchUser,CreateUser,Sidebar,Header,Login,Dashboard,CreateCategory,FetchCategory,CreateTask,FetchTask,
FetchEvent,CreateEvent,ForgetPassword,FetchAppoinment,CreateAppoinment,FetchRating,FetchChat,OfficialUse,ViewTask,
FetchNotification,PushNotification,FetchCounsellor,FetchVolunteer,CreateEmail,FetchEmail,CreateDonate,FetchDonate,FetchNormalUser,
FetchEventMember,CreateAdmin,FetchAdmin,FetchSupport,CreateApp,FetchApp,FetchReport,FetchForm,FetchDonars,ViewSupport,
FetchAssignedForm,Create_AssignForm,FetchVolunteerTask,ViewNormalUser} from './admin/components'
import {history} from './helpers'
import MainLayout from './layouts/MainLayout'
import WebLayout from './layouts/WebLayout'
import EmptyLayout from './layouts/EmptyLayout'
import PubSub from 'pubsub-js';
import {isAdminLogin} from './helpers/authorizedData'
import {RotateCircleLoading} from 'react-loadingg';
import QRcode from './web/components/Donation/QRcode'
import {Home,OurHistory,OurFinancialReport,OurBoardComittee,VideosGallery,PhotosGallery,SuccessStories,Events,SignupEvent,
  Donation,FortuneBag,StoriesOfVolunteers,VolunteerOpportunities,VolunteerSignUp,CounsellingServiceForm,OurWork,Contact,
  EventDetail,MaintenancePage, PrivacyPolicy} from './web/components'
// import {OurHistory,OurFinancialReport,OurBoardComittee} from './web/components/About' 

// import './App.css';
// import './style.css';
import Button from '@material-ui/core/Button';
import LocalizedStrings from 'react-localization';
import { EnglishTranslation, ChineesTranslation } from './web/webHelpers'

function backNavigation() {
  history.goBack()
}

const WebRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <WebLayout>
          <Component {...matchProps} />
        </WebLayout>
      )}
    />
  );
};


const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={props => (
      isAdminLogin() ?
        <MainLayout><Component {...props} /></MainLayout> : <Redirect to={{ pathname: '/admin/login' }} />

    )} />
  );
};

const EmptyRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <EmptyLayout>
          <Component {...matchProps} />
        </EmptyLayout>
      )}
    />
  );
};

const MaintenanceModeRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={matchProps => (
				<EmptyLayout>
					<Component {...matchProps} />
				</EmptyLayout>
			)}
		/>
	);
};

const NotFound = () => {
  return <div className="page-notfound"> <img src={process.env.PUBLIC_URL + "/assets/img/img_notfound.png"} alt="" />
    <h3 className="mb-3 mt-2">Page not found</h3>
    <Button color="primary" variant="contained" onClick={backNavigation}> Back</Button>
  </div>;
};


class App extends Component {
  constructor() {
    super();
    this.state = {
      loader: false
    }
  }

  componentWillMount(){
    sessionStorage.removeItem("selectedTabInWork");
    if(sessionStorage.getItem("translation") == "Chinees"){
      window.strings = new LocalizedStrings(ChineesTranslation);
      sessionStorage.setItem("translation","Chinees")     
}else{
  window.strings = new LocalizedStrings(EnglishTranslation);
  sessionStorage.setItem("translation","English")
}
  }

  componentDidMount() {
    this.subcri = PubSub.subscribe('msg', (msg, data) => {
      this.setState({ loader: data });
    });

    this.subcri = PubSub.subscribe('translation', (msg, data) => {
      this.fetchLanguageJson(data)
      // this.setState({ loader: data });
    });

  }

  fetchLanguageJson = (language) => {
		if (language && language.includes('English')) {
      window.strings = new LocalizedStrings(EnglishTranslation);
			this.setState({ language : "English" })
		} else {
      window.strings = new LocalizedStrings(ChineesTranslation);
			this.setState({ language : "Chinees" })
		}
	  }


  render() {
    return (
      <div className="main-page">
        {/* <Router history={history} > */}
        <Router history={history}>

          <Switch>
            <Redirect exact from="/admin" to="/admin/login"/>
            <Route exact path="/admin/login" component={Login}></Route>
            <Route exact path="/admin/forgetpassword" component={ForgetPassword}></Route>

            <AuthRoute exact path="/admin/dashboard" component={Dashboard}></AuthRoute>
            {/* <AuthRoute exact path="/admin/user" component={FetchUser}></AuthRoute> */}
            <AuthRoute exact path="/admin/normal" component={FetchNormalUser}></AuthRoute>
            {/* <AuthRoute exact path = "/admin/user/edit/:id" component={CreateUser}></AuthRoute> */}
            <AuthRoute exact path="/admin/user/view/:id" component={ViewNormalUser}></AuthRoute>
            <AuthRoute exact path = "/admin/volunteertask/:id" component = {FetchForm}></AuthRoute>            

            <AuthRoute exact path="/admin/user/add" component={CreateUser}></AuthRoute>
            <AuthRoute exact path="/admin/user/edit/:id" component={CreateUser}></AuthRoute>

            <AuthRoute exact path="/admin/category/add" component={CreateCategory}></AuthRoute>
            <AuthRoute exact path="/admin/category" component={FetchCategory}></AuthRoute>
            <AuthRoute exact path="/admin/category/edit/:id" component={CreateCategory}></AuthRoute>

            <AuthRoute exact path="/admin/app/add" component={CreateApp}></AuthRoute>
            <AuthRoute exact path="/admin/app" component={FetchApp}></AuthRoute>
            <AuthRoute exact path="/admin/app/edit/:id" component={CreateApp}></AuthRoute>

            <AuthRoute exact path="/admin/task" component={FetchTask}></AuthRoute>
            <AuthRoute exact path="/admin/task/add" component={CreateTask}></AuthRoute>
            <AuthRoute exact path="/admin/task/edit/:id" component={CreateTask}></AuthRoute>
            <AuthRoute exact path="/admin/task/view/:id" component={ViewTask}></AuthRoute>



            <AuthRoute exact path="/admin/manageAdmin" component={FetchAdmin}></AuthRoute>
            <AuthRoute exact path="/admin/manageAdmin/add" component={CreateAdmin}></AuthRoute>
            <AuthRoute exact path="/admin/manageAdmin/edit/:id" component={CreateAdmin}></AuthRoute>

            <AuthRoute exact path="/admin/event" component={FetchEvent}></AuthRoute>
            <AuthRoute exact path="/admin/eventmembers" component={FetchEventMember}></AuthRoute>

            <AuthRoute exact path="/admin/event/add" component={CreateEvent}></AuthRoute>
            <AuthRoute exact path="/admin/event/edit/:id" component={CreateEvent}></AuthRoute>
            <AuthRoute exact path="/admin/members" component={FetchEventMember}></AuthRoute>

            <AuthRoute exact path="/admin/booking" component={FetchAppoinment}></AuthRoute>
            {/* <AuthRoute exact path="/admin/appoinment/add" component={CreateAppoinment}></AuthRoute>
            <AuthRoute exact path="/admin/appoinment/edit/:id" component={CreateAppoinment}></AuthRoute> */}
            {/* 
            <AuthRoute exact path = "/admin/rating" component={FetchRating}></AuthRoute>
            <AuthRoute exact path = "/admin/chat" component={FetchChat}></AuthRoute>

            <AuthRoute exact path = "/admin/official" component={OfficialUse}></AuthRoute> */}
            <AuthRoute exact path = '/admin/donar' component={FetchDonars}></AuthRoute>
            
            <AuthRoute exact path = "/admin/notification" component={FetchNotification}></AuthRoute>
            <AuthRoute exact path = "/admin/pushnotification" component={PushNotification}></AuthRoute>
            <AuthRoute exact path = "/admin/support" component = {FetchSupport}></AuthRoute>
            <AuthRoute exact path = "/admin/support/view/:id" component = {ViewSupport}></AuthRoute>
            <AuthRoute exact path = "/admin/report" component = {FetchReport}></AuthRoute>
            <AuthRoute exact path = "/admin/form" component = {FetchForm}></AuthRoute>            
            <AuthRoute exact path = "/admin/counsellor" component={FetchCounsellor}></AuthRoute>
            <AuthRoute exact path = "/admin/counsellor/add" component={CreateUser}></AuthRoute>
            <AuthRoute exact path = "/admin/counsellor/edit/:id" component={CreateUser}></AuthRoute>
            {/* <AuthRoute exact path="/admin/counsellor" component={FetchCounsellor}></AuthRoute>
            <AuthRoute exact path="/admin/counsellor/add" component={CreateUser}></AuthRoute>
            <AuthRoute exact path="/admin/counsellor/edit/:id" component={CreateUser}></AuthRoute> */}
            <AuthRoute exact path="/admin/volunteer" component={FetchVolunteer}></AuthRoute>
            <AuthRoute exact path="/admin/volunteer/add" component={CreateUser}></AuthRoute>
            <AuthRoute exact path="/admin/volunteer/edit/:id" component={CreateUser}></AuthRoute>

            <AuthRoute exact path="/admin/volunteertask" component={FetchVolunteerTask}></AuthRoute>



            <AuthRoute exact path="/admin/email/add" component={CreateEmail}></AuthRoute>
            <AuthRoute exact path="/admin/email" component={FetchEmail}></AuthRoute>
            <AuthRoute exact path="/admin/email/edit/:id" component={CreateEmail}></AuthRoute>
            <AuthRoute exact path="/admin/backStory/add" component={CreateDonate}></AuthRoute>
            <AuthRoute exact path="/admin/backStory" component={FetchDonate}></AuthRoute>
            <AuthRoute exact path="/admin/backStory/edit/:id" component={CreateDonate}></AuthRoute>
            <AuthRoute exact path="/admin/assignform" component={FetchAssignedForm}/>
            <AuthRoute exact path="/admin/assignform/add" component={Create_AssignForm}/>

            {/* Web Route  */}
            <WebRoute exact path="/" component={Home} />
            <WebRoute exact path="/ourHistory" component={OurHistory} />
            <WebRoute exact path="/ourBoardComittee" component={OurBoardComittee} />
            <WebRoute exact path="/ourFinancialReport" component={OurFinancialReport} />
            <WebRoute exact path="/videosGallery" component={VideosGallery} />
            <WebRoute exact path="/photosGallery" component={PhotosGallery} />
            <WebRoute exact path="/successStories" component={SuccessStories} />
            <WebRoute exact path="/events" component={Events} />
            <WebRoute exact path="/signUpEvent" component={SignupEvent} />
            <WebRoute exact path="/eventDetail/:eventId" component={EventDetail} />
            <WebRoute exact path="/donation" component={Donation} />
            <WebRoute exact path="/fortuneBag" component={FortuneBag} />
            <WebRoute exact path="/storiesOfVolunteers" component={StoriesOfVolunteers} />
            <WebRoute exact path="/volunteersOpportunities" component={VolunteerOpportunities} />
            <WebRoute exact path="/volunteerSignUp" component={VolunteerSignUp} />
            <WebRoute exact path="/counsellingServiceForm" component={CounsellingServiceForm} />
            <WebRoute exact path="/ourWork" component={OurWork} />
            <WebRoute exact path="/contact" component={Contact} />
            <WebRoute exact path="/privacy-policy" component={PrivacyPolicy} />
            <WebRoute exact path="/qrcode" component={QRcode}/>
           

            <MaintenanceModeRoute path="/maintenancePage" component={MaintenancePage}/>
            <EmptyRoute path="*" component={NotFound} />

          </Switch>
        </Router>


        {this.state.loader ?
          <div className="loader">
            <RotateCircleLoading />
          </div>
          :
          <div className="loader-hide">
            <RotateCircleLoading />
          </div>
        }
      </div>
    )
  }
}

export default App;
