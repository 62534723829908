import React,{Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {taskActions,categoryActions,userActions} from '../../../store/actions'
import UploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import {history} from '../../../helpers'
import Toggle from 'react-toggle';
import moment from 'moment';
import {config} from '../../../config'
import {toastr} from '../../../services'

class CreateTask extends Component{
    constructor(props){
        super(props);
        this.state={
            title:'',
            description:'',
            // startTime:'',
            // endTime:'',
            address:'',
            createdBy:'',
            // taskStatus:'todo',
            slots:'',
            id:'',
            userId:[],
            categoryId:'',
            categoryObj:'',
            volunteerObj:'',
            // taskEndDate:'',
            submitted:false,
            status:false
        }
    }
    componentDidMount(){
        this.list()
    }
    componentWillReceiveProps(newProps){
        if(newProps && newProps.categoryList && newProps.categoryList.data){
            let data = newProps.categoryList && newProps.categoryList.data && newProps.categoryList.data.rows
            this.setState({categoryList:data})
            this.props.resetList()
        }
        if(newProps && newProps.userRole && newProps.userRole.data){
            let volunteersList = newProps.userRole && newProps.userRole.data && newProps.userRole.data.rows
            this.setState({volunteersList})
        }
        if(newProps && newProps.taskList && newProps.taskList.data){
            let editData = newProps.taskList && newProps.taskList.data && newProps.taskList.data && newProps.taskList.data.rows[0]
            this.setState({title:editData.title,description:editData.description,
                // taskStatus:editData.taskStatus,startTime:editData.workingTime && editData.workingTime.startTime && editData.workingTime.startTime,endTime:editData.workingTime && editData.workingTime.endTime && editData.workingTime.endTime,taskEndDate:editData.taskEndDate && moment(editData.taskEndDate).format('YYYY-MM-DD'),
                status:editData.status,address:editData.address,imagePreviewUrl:editData.imageUrl,id:editData.id,categoryId:editData.categoryId,users:editData.userId && editData.userId,
                imagePreviewUrl:config.apiUrl +'/uploads/categories/' + (editData.Category && editData.Category.imageName),imageName:editData.imageUrl && editData.imageUrl,
                formDetailsObj : {"label":(editData.FormDetail && editData.FormDetail.name),"value":(editData.FormDetail && editData.FormDetail.id)}
            })
            this.props.resetGetTask()
        }
        if(this.state.categoryList && this.state.categoryId){
            let obj = {}
            this.state.categoryList.map(items => {
                if((items && items.id) == (this.state.categoryId && this.state.categoryId)){
                     obj = {"label":items.title,"value":items.id,"items" :items}
                    this.handleCategoryChange(obj,false)                     
                }
            })
            
            this.setState({categoryObj:obj})
        }
        if(this.state.volunteersList && this.state.users){
            let obj = {}
            let volunteerObj = []
            console.log("user",this.state.users)
            this.state.volunteersList.map(items => {
                this.state.users && this.state.users.length > 0 && this.state.users.map(userId => {
                if((items && items.id) == (userId && userId)){
                    let obj = {"label":items.userName,"value":items.id}
                    volunteerObj.push(obj)
                }
            })
            })
            this.setState({volunteerObj})
        }
        if(newProps && newProps.saveStatus && newProps.saveStatus === true){
            this.props.resetSaveTask()
            history.goBack()
        }
    }
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }
    // handleTaskStatus = (e) => {
    //     this.setState({
    //         taskStatus:e.target.value
    //     })
    // }
    list = () => {
        if(this.props.location && this.props.location.state && this.props.location.state.obj){
            let reqData = this.props.location.state.obj
            this.props.getTask(reqData)
        }
        // this.props.getUser({roleId:4})
        this.props.getList({getFormDetails : true})
    }
    listPath = () => {
        history.push('/admin/task')
    }
    handleCategoryChange = (data,isReset) => {
            if(isReset){
                this.setState({categoryObj:data,categoryId:data && data.value,volunteersList : data.items && data.items.getVolunteerData,
                    formDetails : data.items && data.items.formDetails,volunteerObj : "",formDetailsObj : ""
                })
            }else{
                this.setState({categoryObj:data,categoryId:data && data.value,imagePreviewUrl : config.apiUrl +`/uploads/categories/`+ (data.items && data.items.imageName),
                    volunteersList : data.items && data.items.getVolunteerData,
                    formDetails : data.items && data.items.formDetails
                })
            }
       
    }
    handleVolunteerChange = (data) => {      
        this.setState({volunteerObj:data})               
    }

    handleFormDetailsChange = (data) => {      
        this.setState({formDetailsObj:data})               
    }

    statusChange = (status) => {
        var status = this.state.status ? false : true
        this.setState({status})
    }
    handleImgChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                fileData: file,
                imageName: file.name,
                imagePreviewUrl: reader.result
            })
        }
        if (e.target.files && e.target.files[0]) {
            reader.readAsDataURL(file)
        }
    }
    handleSubmit = (e) => {
        e.preventDefault()
        // const user = []
        // this.state.volunteerObj && this.state.volunteerObj.map(item => {
        //     user.push((item.value && item.value.toString()))
        // })
        // this.setState({user}) 
        const {title,description,slots,address,imagePreviewUrl,fileData,status,categoryId,
            userId,id,categoryObj,imageName,volunteerObj,formDetailsObj} = this.state       
        this.setState({submitted:true})        
        if( categoryId && (categoryObj && categoryObj.value) && (volunteerObj && volunteerObj.length > 0)  ){ //&& (formDetailsObj && formDetailsObj.value >= 1)
            const formData = new FormData()
            let volunteerId = []
            volunteerObj && volunteerObj.map(item => {
                // let obj = {
                //     id : item.value
                // }
                volunteerId.push(item.value.toString())
            })
            let obj = {
                // title,
                // taskStatus,
                // startTime,
                // endTime,
                // slots,
                // status,
                // userId:user,
                categoryId,
                // userId,
                createdBy:2,
                userId : volunteerId,
                // formDetailsId : formDetailsObj && formDetailsObj.value,
                title : categoryObj.items && categoryObj.items.title,
            }
            
            // if(imagePreviewUrl){
            //     obj['imageUrl'] = imagePreviewUrl
            // }
            if(description){
                obj['description'] = description
            }
            if(address){
                obj['address'] = address
            }
            // if(taskEndDate){
            //     obj['taskEndDate'] = taskEndDate
            // }
            if(id){
                obj['taskId'] = id
            }
            formData.append('taskObject',JSON.stringify(obj))
            // fileData && formData.append('taskImage',fileData)
            this.props.saveTask(formData)
        }
        else{
            toastr.error('Mandatory fields are missing')
        }
    }
    render(){
        let categoryOptions = []
        this.state.categoryList && this.state.categoryList.map(item => {
            if(item && item.id != 0){
            let obj = {"label":item.title,"value":item.id,"items" :item}
            categoryOptions.push(obj)
            }
        })
        
        let volunteerOptions = []
        this.state.volunteersList && this.state.volunteersList.map(item=>{
            let obj = {"label":item.userName,"value":item.id}
            volunteerOptions.push(obj)
        })

        let formDetailsOptions = []
        this.state.formDetails && this.state.formDetails.map(item=>{
            let obj = {"label":item.name,"value":item.id}
            formDetailsOptions.push(obj)
        })
        
        return(
            <Fragment>
                 <section>
                    <div className="tit-sec">{this.state.id ? <h4>Edit Task</h4> : <h4>Add Task</h4>}</div>
                        <div className="card">
                                <div className="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-tab">
                                        <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Select Category&nbsp;<span class="mandatory">*</span></label>
                                                        <div className="col-sm-8">
                                                            <Select className="state-box"
                                                                styles={{
                                                                        control: base => ({
                                                                        ...base,
                                                                        borderColor: 'hsl(0,0%,80%)',
                                                                        boxShadow: '#FE988D',
                                                                        '&:focus': {
                                                                        borderColor: '#80bdff'
                                                                                    }
                                                                                }),
                                                                        menu: base => ({
                                                                        ...base,
                                                                        zIndex:"5"
                                                                        }),
                                                                        menuList: base => ({
                                                                            ...base,
                                                                            maxHeight:"230px"
                                                                            })
                                                                    }}
                                                                   
                                                                        value={this.state.categoryObj}
                                                                        onChange={(data) => this.handleCategoryChange(data,false)}
                                                                        options={categoryOptions}
                                                                        placeholder="Select Category"
                                                                    />  
                                                                    {this.state.submitted && !this.state.categoryObj && <div className="mandatory">Category field is required</div>}            
                                                            </div>                        
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Select Volunteer&nbsp;<span class="mandatory">*</span></label>
                                                    <div className="col-sm-8">
                                                        <Select className="state-box"
                                                            styles={{
                                                                control: base => ({
                                                                ...base,
                                                                borderColor: 'hsl(0,0%,80%)',
                                                                boxShadow: '#FE988D',
                                                                '&:focus': {
                                                                borderColor: '#80bdff'
                                                                            }
                                                                        }),
                                                                        menu: base => ({
                                                                            ...base,
                                                                            zIndex:"5"
                                                                            }),
                                                                            menuList: base => ({
                                                                                ...base,
                                                                                maxHeight:"230px"
                                                                                })
                                                                    }}
                                                                    
                                                                    value={this.state.volunteerObj}
                                                                    onChange={this.handleVolunteerChange}
                                                                    options={volunteerOptions}
                                                                    placeholder="Select Volunteer"
                                                                    isMulti
                                                                />   
                                                                {this.state.submitted && !this.state.volunteerObj && <div className="mandatory">Volunteer field is required</div>}           
                                                        </div>                        
                                                    </div>
                                                </div>


                                                {/* <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Title&nbsp;<span class="mandatory">*</span></label>
                                                    <div className="col-sm-8">
                                                        <Select className="state-box"
                                                            styles={{
                                                                control: base => ({
                                                                ...base,
                                                                borderColor: 'hsl(0,0%,80%)',
                                                                boxShadow: '#FE988D',
                                                                '&:focus': {
                                                                borderColor: '#80bdff'
                                                                            }
                                                                        }),
                                                                        menu: base => ({
                                                                            ...base,
                                                                            zIndex:"5"
                                                                            }),
                                                                        menuList: base => ({
                                                                            ...base,
                                                                            maxHeight:"230px"
                                                                            })
                                                                    }}
                                                                    
                                                                    value={this.state.formDetailsObj}
                                                                    onChange={this.handleFormDetailsChange}
                                                                    options={formDetailsOptions}
                                                                    placeholder="Select Form"
                                                                    // isMulti
                                                                />   
                                                                {this.state.submitted && !this.state.formDetailsObj && <div className="mandatory">Form field is required</div>}           
                                                        </div>                        
                                                    </div>
                                                </div> */}
                                                    

{/* 
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Title&nbsp;<span class="mandatory">*</span></label>
                                                        <div className="col-sm-8">
                                                        <input type="text" name="title" value={this.state.title} onChange={this.handleInputChange} placeholder="Enter Title" className="form-control"/>
                                                        {this.state.submitted && !this.state.title && <div className="mandatory">Title field is required</div>}
                                                        </div>
                                                    </div>
                                                </div> */}



                                                
                                            {/* <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label class="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Description</label>
                                                    <div className="col-sm-8">
                                                    <textarea type="text" name="description" value={this.state.description} onChange={this.handleInputChange} placeholder="Enter Description" className="form-control"/>

                                                    </div>
                                                </div>
                                             </div> */}
                                                {/* <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-xl-3 mb-md-1  col-form-label pr-0">Address</label>
                                                        <div className="col-sm-8">
                                                            <textarea type="text" name="address" value={this.state.address} onChange={this.handleInputChange} placeholder="Enter Address" className="form-control"/>
                                                        </div>                                            
                                                    </div>
                                                </div> */}
                                                {/* <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Status</label>
                                                        <div className="col-sm-8 mt-2">
                                                             <Toggle defaultChecked= {this.state.status} checked = {this.state.status} onClick={()=>this.statusChange(this.state.status)}/>
                                                        </div>                                         
                                                    </div>
                                                </div> */}
                                                {/* <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Task Status&nbsp;<span class="mandatory">*</span></label>
                                                        <div className="col-sm-8">
                                                        <select value={this.state.taskStatus} onChange={this.handleTaskStatus} className="form-control">
                                                            <option value="todo">Todo</option>
                                                            <option value="inprogress">Inprogress</option>
                                                            <option value="done">Done</option>
                                                        </select>                                                   
                                                        </div>                                         
                                                    </div>
                                                </div> */}
                                                {/* <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Task End Date</label>
                                                        <div className="col-sm-8">
                                                        <input type="date" name="taskEndDate" value={this.state.taskEndDate} onChange={this.handleInputChange} className="form-control" min={moment().format('YYYY-MM-DD')}/>                                                 
                                                        </div>                                         
                                                    </div>
                                                </div> */}
                                            {/* <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Start Time&nbsp;<span class="mandatory">*</span></label>
                                                    <div className="col-sm-8">
                                                        <input type="time" name="startTime" value={this.state.startTime} onChange={this.handleInputChange} placeholder="Enter Start Time" className="form-control"/>
                                                        {this.state.submitted && !this.state.startTime && <div className="mandatory">Please select start time</div>}
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">End Time&nbsp;<span class="mandatory">*</span></label>
                                                    <div className="col-sm-8">
                                                        <input type="time" name="endTime" value={this.state.endTime} onChange={this.handleInputChange} placeholder="Enter End Time" className="form-control"/>
                                                        {this.state.submitted && !this.state.endTime && <div className="mandatory">Please select end time</div>}
                                                    </div>
                                                </div>
                                            </div> */}
                                               
                                              
                                            {/* <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-xl-3 mb-md-1  col-form-label pr-0">Slots</label>
                                                        <div className="col-sm-8">
                                                            <input type="text" name="slots" value={this.state.slots} onChange={this.handleInputChange} placeholder="Enter Slot" className="form-control"/>

                                                        </div>                                            
                                                    </div>
                                                </div> 
                                            </div> */}
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                    <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Task Image&nbsp;</label>
                                                        <div className="col-sm-8">
                                                            {/* <div className="choose-wrap">
                                                                <label className="choo-btn" for="file"> 
                                                                    <UploadIcon className="mr-2"/>Choose File
                                                                    <input type="file" name="imageName" id="file" single="" accept="image/*" onChange={this.handleImgChange} />
                                                                   
                                                                </label>
                                                            </div> */}
                                                            <div>
                                                                <img src={this.state.imagePreviewUrl} height="80px" width="80px" />
                                                                {/* {this.state.submitted && !this.state.imageName && <div className="mandatory">Image is required</div>} */}
                                                            </div>
                                                            {/* <input type="file" name="image" id="file" single="" accept="image/*" onChange={this.handleImgChange}  className="input-box"/>
                                                            <label for="file" className="input-btn"><UploadIcon />Choose File</label> */}
                                                        </div>
                                                    </div>
                                                </div>                       
                                        </div>
                                        <div className="bottom-btn">
                                            <button type="button" className="btn cancel-btn" onClick={this.listPath}>Cancel</button>
                                            <button type="submit" className="btn add-btn">Submit</button>

                                                {/* <Button type="button" variant="contained" onClick={this.listPath}>Cancel</Button>
                                                <Button color="primary" variant="contained" type="submit" className="ml-2">
                                                    Submit
                                                </Button> */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                </section>
            </Fragment>
        )
    }
}
function mapStateToProps(state){
    return {
        categoryList:state.category.categoryList,
        userRole:state.users.userRole,
        saveStatus : state.task.saveStatus,
        taskList : state.task.taskList,
    }
}
const actionCreators = {
    saveTask:taskActions.saveTask,
    getList : categoryActions.getList,
    resetList: categoryActions.resetList,
    getUser : userActions.getUser,
    getTask:taskActions.getTask,
    resetGetTask : taskActions.resetGetTask,
    resetSaveTask : taskActions.resetSaveTask

}
const Task = connect(mapStateToProps, actionCreators)(CreateTask);
export { Task as CreateTask };