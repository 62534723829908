import React,{Component,Fragment} from 'react'
import UploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Select from 'react-select';
import {history} from '../../../helpers'
import Toggle from 'react-toggle';
class CreateAppoinment extends Component{
    constructor(props){
        super(props);
        this.state={
            id:'',
            title:'',
            description:'',
            userId:'',
            toUser:'',
            type:'',
            aptmntTime:'',
            endTime:'',
            aptmntStatus:'yettostart',
            status:false,
            sessionId:'',
            submitted:false
        }
    }
    handleaptmntStatus = (e) => {
        this.setState({
            aptmntStatus:e.target.value
        })
    }
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }
    statusChange = (status) => {
        var status = this.state.status ? false : true
        this.setState({status})
    }
    listPath = () => {
        history.push('/admin/appoinment')
    }
    handleImgChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                fileData: file,
                imageName: file.name,
                imagePreviewUrl: reader.result
            })
        }
        if (e.target.files && e.target.files[0]) {
            reader.readAsDataURL(file)
        }
    }
    render(){
        return(
            <Fragment>
                 <section>
                    {this.state.id ? <h4>Edit Appoinment</h4> : <h4>Create Appoinment</h4>}
                    <div>
                        <div>
                            <div class="card">
                                <div class="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div>
                                            <div className="  row">
                                        <div className="col-md-6">
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Title&nbsp;<span class="mandatory">*</span></label>
                                                <div className="col-sm-7">
                                                <input type="text" name="title" value={this.state.title} onChange={this.handleInputChange} placeholder="Enter Title" className="form-control"/>
                                                {this.state.submitted && !this.state.title && <div className="mandatory">Title field is required</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group row">
                                                <label class="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Description</label>
                                                <div className="col-sm-7">
                                                <input type="text" name="description" value={this.state.description} onChange={this.handleInputChange} placeholder="Enter Description" className="form-control"/>

                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Appoinment Time&nbsp;<span class="mandatory">*</span></label>
                                                <div className="col-sm-7">
                                                <input type="time" name="aptmntTime" value={this.state.aptmntTime} onChange={this.handleInputChange} placeholder="Enter Appoinment Time" className="form-control"/>
                                                {this.state.submitted && !this.state.aptmntTime && <div className="mandatory">Please select appoinment time</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">End Time&nbsp;<span class="mandatory">*</span></label>
                                                <div className="col-sm-7">
                                                <input type="time" name="endTime" value={this.state.endTime} onChange={this.handleInputChange} placeholder="Enter End Time" className="form-control"/>
                                                {this.state.submitted && !this.state.endTime && <div className="mandatory">Please select end time</div>}
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Appoinment Status&nbsp;<span class="mandatory">*</span></label>
                                                <div className="col-sm-7">
                                                <select value={this.state.aptmntStatus} onChange={this.handleaptmntStatus} className="form-control">
                                                    <option value="yettostart">Yet to start</option>
                                                    <option value="ongoing">Ongoing</option>
                                                    <option value="done">Done</option>
                                                </select>      
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Appoinment Type&nbsp;<span class="mandatory">*</span></label>
                                                <div className="col-sm-7">
                                                <input type="radio" value="e-chat" checked={this.state.type == "e-chat"} onChange={this.handleInputChange} name="type"/><span>E-chat</span>
                                                <input type="radio" value="online" checked={this.state.type == "online"} onChange={this.handleInputChange} name="type"/><span>Online</span>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className=" row">
                                        <div className="col-md-6">
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Status</label>
                                                <div className="col-sm-7">
                                               <Toggle defaultChecked= {this.state.status} checked = {this.state.status} onClick={()=>this.statusChange(this.state.status)}/>
                                                </div>                                         
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group row">
                                            <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Image</label>

                                                <div className="col-sm-7">
                                                    <div className="row m-0">
                                             <input type="file" name="image" id="file" single="" accept="image/*" onChange={this.handleImgChange}  className="input-box"/>
                                                <label for="file" className="input-btn"><UploadIcon />Choose File</label>
                                                </div>
                                                <div>
                                                    <img src={this.state.imagePreviewUrl} height="80px" width="80px" />
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        
                                        </div>
                
                                       
                                          
                                        <div class="text-right">
                                                <Button type="button" variant="contained" onClick={this.listPath}>Cancel</Button>
                                                <Button color="primary" variant="contained" type="submit" className="ml-2">
                                                    Submit
                                                </Button>
                                            </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}
function mapStateToProps(state){
    return {
     

    }
}
const actionCreators = {
  

}
const Appoinment = connect(mapStateToProps, actionCreators)(CreateAppoinment);
export {Appoinment as CreateAppoinment };