import React from 'react';
import { connect } from 'react-redux';
import AbtBanner from '../../assets/img/img_abtbanr.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import PlsImg from '../../assets/img/icn_pls.png';
import MinusImg from '../../assets/img/icn_minus.png';
import GalleryImg1 from '../../assets/img/img_glry_1.png';
import GalleryImg2 from '../../assets/img/img_glry_2.png';
import GalleryImg3 from '../../assets/img/img_glry_3.png';
import GalleryImg4 from '../../assets/img/img_glry_4.png';
import GalleryImg5 from '../../assets/img/img_glry_5.png';
import GalleryImg6 from '../../assets/img/img_glry_6.png';
import GalleryImg7 from '../../assets/img/img_glry_7.png';
import GalleryImg8 from '../../assets/img/img_glry_8.png';
import GalleryImg9 from '../../assets/img/img_glry_9.png';
import {history} from '../../../helpers';
import AGE from '../../assets/img/HomeSlider/Age.jpg';
import GalleryBanner from '../../assets/img/Banner/Gallery/GalleryBanner.png';
import PHOTO_BANNER from '../../assets/img/NewBanner/PhotoGallery.png';

 class PhotosGallery extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
        }
    }
    goToHome = () => {
        history.push('/')
    }
        render(){
            const responsive = {
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                }
              }; 
            return(
            <div className="photo-wrap">
                <nav class="breadcrumb">
                    <div className="container d-flex">
                        <a class="breadcrumb-item" onClick={this.goToHome}><i className="fas fa-home"></i></a>                      
                        <span class="breadcrumb-item active">{window.strings.HEADER.GALLERY}</span>
                        <span class="breadcrumb-item active">{window.strings.HEADER.PHOTOS_GALLERY}</span>
                    </div>
                </nav>
                <Carousel className="photo-slide"
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}                                                
                        autoPlay={false}
                        showDots={false}
                        arrows={false}
                        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        customTransition="all 1.2s ease 0s"
                        transitionDuration={1000}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        deviceType={this.props.deviceType}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                    >
                        <div className="abt-banr">
                            <img src={PHOTO_BANNER}/>
                        </div>

                        {/* <div className="abt-banr">
                            <img src={GalleryBanner}/>
                        </div>
                       <div className="abt-banr">
                            <img src={AGE}/>
                        </div> */}
                        
                        {/* <div className="abt-banr">
                            <img src={AbtBanner}/>
                        </div> */}
                    </Carousel>
                    <div className="container">
                        <div className="spad">
                           <div className="text-center">
                                <h3>{window.strings.SUCCESSSTORIES.SUCCESS_SAGECC}</h3>
                                <h4>{window.strings.HEADER.PHOTOS_GALLERY}</h4>
                                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </p> */}
                            </div>
                            <div id="accordion">
                                    <div class="card">
                                        <div class="card-header" id="headingOne">
                                            <button className="btn collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <h5 class="mb-0">{window.strings.GALLERY.SUICIDE_PREVEN}</h5><img src={PlsImg} />
                                                {/* <img src={MinusImg}/> */}
                                            </button>
                                        </div>
                                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div class="card-body">
                                                <div className="row">
                                                    <div className="col-lg-4 col-sm-6 col-12"><img src={GalleryImg1}/></div>
                                                    <div className="col-lg-4 col-sm-6 col-12"><img src={GalleryImg2}/></div>
                                                    <div className="col-lg-4 col-sm-6 col-12"><img src={GalleryImg3}/></div>
                                                    <div className="col-lg-4 col-sm-6 col-12"><img src={GalleryImg4}/></div>
                                                    <div className="col-lg-4 col-sm-6 col-12"><img src={GalleryImg5}/></div>
                                                    <div className="col-lg-4 col-sm-6 col-12"><img src={GalleryImg6}/></div>
                                                    <div className="col-lg-4 col-sm-6 col-12"><img src={GalleryImg7}/></div>
                                                    <div className="col-lg-4 col-sm-6 col-12"><img src={GalleryImg8}/></div>
                                                    <div className="col-lg-4 col-sm-6 col-12"><img src={GalleryImg9}/></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="card">
                                        <div class="card-header" id="headingTwo">
                                            <button class="btn collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                <h5>{window.strings.GALLERY.SINGAPORE_NIGHT}</h5><img src={PlsImg} />
                                            </button>
                                        </div>
                                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                            <div class="card-body">
                                                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                        </div>
                    </div>
            </div>
            )            
        }}

const mapStateToProps = state => {
    return {}
}


const actionCreators = { }

const connectedPhotosGallery = connect(mapStateToProps, actionCreators)(PhotosGallery);
export { connectedPhotosGallery as PhotosGallery };