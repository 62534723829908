export const chatConstants = {
    FETCH_CHANNEL_SUCCESS : "FETCH_CHANNEL_SUCCESS",
    FETCH_PARTICULAR_CHANNEL_SUCCESS : "FETCH_PARTICULAR_CHANNEL_SUCCESS",

    FETCH_CHAT_SUCCESS : "FETCH_CHAT_SUCCESS",
    VOLUNTEER_ACCEPT_CHANNEL_SUCCESS : "VOLUNTEER_ACCEPT_CHANNEL_SUCCESS",


    OFFICIAL_USE_SUCCESS : 'OFFICIAL_USE_SUCCESS',
    SAVE_BACKSTORY : 'SAVE_BACKSTORY',
    FETCH_BACKSTORY : 'FETCH_BACKSTORY',

    FETCH_DONAR_LIST : 'FETCH_DONAR_LIST',
    SAVE_DONAR : 'SAVE_DONAR'
}