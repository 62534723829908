import React,{Component,Fragment} from 'react'
import UploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import {history,ReactPagination,isAdminLogin} from '../../../helpers';
import {appoinmentActions,userActions} from '../../../store/actions'
import {toastr} from '../../../services'
import Toggle from 'react-toggle';
import Select from 'react-select';
import { Modal } from 'react-responsive-modal';
import moment from 'moment';
var tz = require('moment-timezone');

class FetchAppoinment extends Component{
    constructor(props){
        super(props);
        this.state={
            page:1,
            limit:10,
            searchTxt:'',
            userData: [],
            toUserId:'',
            apmntDate:'',
            user:isAdminLogin()
        }
    }
    componentDidMount(){
        this.list()
        // this.props.userList();
        
        
    }
    componentWillReceiveProps(newProps){
        if(newProps && newProps.appoinmentList && newProps.appoinmentList.data){
            let data = newProps.appoinmentList && newProps.appoinmentList.data && newProps.appoinmentList.data.rows
            let request = newProps.appoinmentList && newProps.appoinmentList.request ? newProps.appoinmentList.request : []
            let totalCount = newProps.appoinmentList && newProps.appoinmentList.data && newProps.appoinmentList.data.count ? newProps.appoinmentList.data.count : []
            if(data.length == 0){
                toastr.error("Result Not Found")
            }
            this.setState({appoinmentList:data,totalCount,pageCount:totalCount/request.limit})
           this.props.resetList()
        }
        // if(newProps && newProps.userRole && newProps.userRole.data){
        //     let data = newProps.usersList && newProps.usersList.data && newProps.usersList.data.rows
        //     this.setState({userData:data})
        // }
      
        if(newProps && newProps.userRole && newProps.userRole.data){
            let data = newProps.userRole && newProps.userRole.data && newProps.userRole.data.rows
            this.setState({counsellorData:data})
        }
        // if(this.state.userData){
        //     let counsellorData = []
        //     let volunteerData = []
        //     this.state.userData && this.state.userData.map(users => {
        //         if((users && users.role && users.role) == 3){
        //             counsellorData.push(users)
        //         }else if((users && users.role && users.role) == 4){
        //             volunteerData.push(users)
        //         }
        //     })
        //     this.setState({counsellorData,volunteerData})
        // }
       
        if(newProps && newProps.saveStatus && newProps.saveStatus == true){
            this.props.resetStatus()
            this.getList()
        }
    }
    list = () => {
        if(this.props && this.props.appoinmentList && this.props.appoinmentList.status == true){
            let request = this.props.appoinmentList && this.props.appoinmentList.request ? this.props.appoinmentList.request : "";
            if(request.size || request.page || request.searchTxt){
                this.setState({
                    limit:request.limit,
                    page:request.page,
                    searchTxt:request.searchTxt
                },()=>{
                    this.getList()
                })
            }else{
                this.getList()
            }
        }
        else{
            this.getList()
        }
    }
    getList = () => {
        const {appStatus,appType} = this.state
        let obj = {}
        if(this.state.searchTxt){
            obj['page'] = this.state.page
            obj['limit'] = this.state.limit
            obj['searchTxt'] = this.state.searchTxt
            obj['type'] = 'web'
        }
        else{
            obj['page'] = this.state.page
            obj['limit'] = this.state.limit
            obj['type'] = 'web'
        }
        if(appStatus){
            obj['appointmentStatus'] = appStatus
        }
        if(appType){
            obj['appointmentType'] = appType
        }
        this.props.getList(obj)
        this.props.getUser({roleId:3})
    }
    
    formPath = () => {
        history.push('/admin/appoinment/add')
    }
    resetSearch = () => {
        
        this.setState({searchTxt  : '',page : 1 ,appStatus:'',appType:''},() => {
            this.getList()
        })      
    }
    handleStatusChange = (e) => {
        this.setState({
            appStatus:e.target.value
        },() => {
            this.getList()
        })
    }
    handleStatus = (status,app, e) => {
        if((status == "ignored")){
            let obj = {
                appointmentId : app.id,
                approvedBy : this.state.user && this.state.user.id,
                approvedStatus: status,
                status : true,
                userId:app.userId,
                type:'web'
            }
                this.props.updateStatus(JSON.stringify(obj))
        }
       else if(app && app.toUserId && status && app.appointmentDate && app.appointmentTime && app.endTime){
        let obj = {
            appointmentId : app.id,
            toUserId : app.toUserId  ,
            approvedBy : this.state.user && this.state.user.id,
            approvedStatus: status,
            appointmentDate:app.appointmentDate,
            endTime: app.endTime,
            appointmentTime:app.appointmentTime,
            userId:app.userId,
            // appointmentStatus : app.appointmentStatus,
            status : true,
            type:'web',
            channelType:app && app.type
        }
            this.props.updateStatus(JSON.stringify(obj))
        }else{
            toastr.error("Please Select User or Date or Time Properly")
        }
      
     
    }
    handleTypeChange = (e) => {
        this.setState({
            appType:e.target.value
        },() => {
            this.getList()
        })
    }
    onChange = (data)=> {
        if(this.state.page !== (data.selected+1)){
        this.setState({
            page:data.selected +1
        }, ()=> {
            this.getList()
        })
    }
}
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }
    handleVolunteerChange = (data,app) => {
        this.setState({volunteerObj:data,toUserId:data && data.value,appointmentId:app && app.id},() => {
            this.updateStatus()
        })
    }
    // handleCounsellorChange = (data,app) => {
    //     this.setState({toUserId:data && data.value,counsellorObj:data,appointmentId:app && app.id})
    //     console.log("couns",this.state.counsellorObj)
    // }
    handleCounsellorChange = (e,app) => {
    
       console.log("counsellor",e.target.value,app)
       let obj = {
        appointmentId : app.id,
        toUserId : e.target.value,
        approvedBy : this.state.user && this.state.user.id,
        // approvedStatus: 'approved',
        // appointmentStatus : app.appointmentStatus,
        status : true,
        userId:app.userId,
        type:'web',
        channelType:app && app.type
    }
  
    this.props.updateStatus(JSON.stringify(obj))
    }
    dateChange = (e,app) => {
        // this.setState({
        //     apmntDate:e.target.value
        // })
        if(app && app.toUserId){
            let obj = {
                appointmentId : app.id,
                appointmentDate : e.target.value,
                approvedBy : this.state.user && this.state.user.id,
                toUserId : app.toUserId ,
                status : true,
                userId:app.userId,
                type:'web',
                channelType:app && app.type
            }
        this.props.updateStatus(JSON.stringify(obj))
        }else{
            toastr.error('Please Select User')
        }
        
    } 
    updateTime = (e,app) => {
        // this.setState({
        //     apmntDate:e.target.value
        // })
      
        if(app && app.toUserId){
            let obj = {
                appointmentId : app.id,
                appointmentTime : (moment(e.target.value,'hh:mm A').format("HH:mm")),
                // appointmentTime : moment(e.target.value,"hh:mm A").tz('Asia/Singapore').format('HH:mm'),
                approvedBy : this.state.user && this.state.user.id,
                toUserId : app.toUserId ,
                status : true,
                userId:app.userId,
                type:'web',
                channelType:app && app.type
            }
        this.props.updateStatus(JSON.stringify(obj))
        }else{
            toastr.error('Please Select User')
        }
        
    }
    updateEndTime = (e,app) => {   
        if(app && app.toUserId){
            let obj = {
                appointmentId : app.id,
                // endTime : moment(e.target.value,"hh:mm A").tz('Asia/Singapore').format('HH:mm'),
                endTime : (moment(e.target.value,'hh:mm A').format("HH:mm")),
                approvedBy : this.state.user && this.state.user.id,
                toUserId : app.toUserId ,
                status : true,
                userId:app.userId,
                type:'web',
                channelType:app && app.type
            }
        this.props.updateStatus(JSON.stringify(obj))
        
        }else{
            toastr.error('Please Select User')
        }
        
    }
    updateStatus = () => {
        let obj = {
            appointmentId : this.state.appointmentId,
            toUserId : this.state.toUserId,
            approvedBy : 1,
            appointmentStatus : 'yettostart',
            status : 'approve'
        }
        this.props.updateStatus(JSON.stringify(obj))
    }
    triggerFunc = (value) =>{
        if(value == true){
            this.setState({
                open:value
            })
        }else{
            this.setState({
                open:value
            })
        }
    }
    getNewResult = (e) => {
        e.preventDefault();
        if(this.state.searchTxt){
            this.setState({ page : 1 },() => {
            this.getList()
            })
        }else{
            toastr.error("Please type to search")
        }        
    }
    render(){
        let counsellorOptions = []
        this.state.counsellorData && this.state.counsellorData.map(item => {
            let obj = {"label":item.userName,"value":item.id}
            counsellorOptions.push(obj)
        })

        let volunteerOptions = []
        this.state.volunteerData && this.state.volunteerData.map(item=>{
            let obj = {"label":item.userName,"value":item.id}
            volunteerOptions.push(obj)
        })
        const {limit,page} = this.state
        let appointmentList = this.state.appoinmentList && this.state.appoinmentList.map((app,index) => {
            return <tr>
            <td>{(page - 1) * limit + index  + 1}</td>
            <td>{app.firstName}</td>
            <td>{app.email}</td>
            <td>{app.mobile}</td>
            <td>{app.type}</td>
        <td>
        {this.state.counsellorData && this.state.counsellorData.map((user,key) => {
                     if((user && user.id) == (app.requestedUser))
                        return user.userName
                    })}
        </td>
            {app.toUserDetails && app.toUserDetails.userName ? <td>{app.toUserDetails.userName}</td> : <td>
             
                    <select onChange={(e) => this.handleCounsellorChange(e,app)} disabled={(app.approvedStatus == "ignored")}>
                    <option selected value="">Select Name</option>
                    {this.state.counsellorData && this.state.counsellorData.map((user,key) => {
                       return <option key={key} value={user.id}>{user.userName}</option>
    
                    })}
                     </select>
                  
                    </td>}
                    <td><input type="time" onChange={(e) => this.updateTime(e,app)} value={app.appointmentTime ? app.appointmentTime : ''} disabled={(app.approvedStatus == "ignored")}/></td>
                    <td><input type="time" onChange={(e) => this.updateEndTime(e,app)} value={app.endTime ? app.endTime : ''} disabled={(app.approvedStatus == "ignored")}/></td>
            <td><input type="date" onChange={(e) => this.dateChange(e,app)} value={app.appointmentDate ? moment(app.appointmentDate).format('YYYY-MM-DD') : ''} min={moment().format('YYYY-MM-DD')} disabled={(app.approvedStatus == "ignored")}/></td>
            {/* <td><Select className=""
                    styles={{
                        control: base => ({
                        ...base,
                         borderColor: 'hsl(0,0%,80%)',
                        boxShadow: '#FE988D',
                        '&:focus': {
                        borderColor: '#80bdff'
                                    }
                        })
                    }}
                    value={this.state.volunteerData && this.state.volunteerData.map(user => {
                        if((user && user.id) == (app && app.toUserId)){
                            return {"label":user.userName,"value":user.id}                       
                        }else {
                            return ''
                        }
                    })}                                          
                    // value={this.state.volunteerObj}
                    onChange={(e) => this.handleVolunteerChange(e,app)}
                     options={volunteerOptions}
                    placeholder="Select Volunteer"
                    />   </td> */}
            <td>{app.appointmentStatus}</td>
            {app.approvedStatus == "approved" ?
            <td>Approved</td> : app.approvedStatus == "ignored" ? <td>Ignored</td> :
            <td>
                <button type="button" onClick={(e) => this.handleStatus("approved",app)} className="btn btn-success mr-2">Approve</button>
                <button type="button" onClick={() => this.handleStatus("ignored",app)} className="btn btn-danger">Ignore</button>
            </td>}
             {/* <td>
                <button type="button" onClick={() => this.triggerFunc(true)} className="btn btn-success mr-2">Approve</button>
                <button type="button" onClick={() => this.handleStatus("ignored",app)} className="btn btn-danger">Ignore</button>
            </td> */}
        </tr>
        })
        
        return(
            <Fragment>
            <div className="row tit-sec">
                <div className="col-6 col-md-5">
                    <h4>Appointments</h4>
                </div>
            </div>
            <Modal open={this.state.open} onClose={() => this.triggerFunc(false)} center blockScroll={false}
                    classNames={{
                        modal:'notify'
                    }}>
                        
                        <div class="text-right mt-3">
                            <button className="btn cancel-btn" onClick={() => this.triggerFunc(false)}>Cancel</button>
                            <button className="btn add-btn" onClick={() => this.onSubmit(this.state.content)}>Submit</button>
                        </div>
                       
                    </Modal>
           <div className="card">
           <div className="card-body">
                <div className="pt-2 mb-3">
                    <form onSubmit={this.getNewResult} class="align-items-center" >
                        <div class="input-group justify-content-end">
                                     <div className="col-md-3 input-fields">
                                            <div className="form-group">
                                                <label>Appointment Status</label>
                                                    <select value={this.state.appStatus} onChange={this.handleStatusChange} className="form-control">
                                                    <option value=''>Select Status</option>
                                                    <option value="yettostart">Yet to start</option>       
                                                    <option value="ongoing">Ongoing</option>    
                                                    <option value="done">Done</option>
                                                    </select>
                                            </div>
                                        </div>
                                     <div className="col-md-3 input-fields">
                                            <div className="form-group">
                                                <label>Appointment Type</label>
                                                    <select value={this.state.appType} onChange={this.handleTypeChange} className="form-control">
                                                    <option value=''>Select Type</option>
                                                    {/* <option value="e-chat">E-chat</option>        */}
                                                    <option value="video">Video</option> 
                                                    <option value="audio">Audio</option>       
                                                    </select>
                                            </div>
                                        </div>                                 
                                     <div className="col-md-1 m-top">
                                      {/* <div className="input-group"> */}
                                      {/* <input type="text" placeholder="Search.." name="searchTxt" value={this.state.searchTxt} onChange={this.handleInputChange} aria-label="Recipient's username" formcontrolname="customSearch" class="form-control ng-pristine ng-valid ng-touched" /> */}
                                        {/* <div class="input-group-append"> */}
                                            {/* <button type="submit" class="btn btn-sm btn-dark" ><i className="fas fa-search"></i></button> */}
                                            <div class="pl-3">
                                                <button _ngcontent-wdd-c125="" type="button" onClick={this.resetSearch} class="btn btn-outline-secondary btn-rounded btn-icon">
                                                <i class="fas fa-redo-alt"></i>
                                                </button>
                                            </div>
                                        {/* </div> */}
                                      {/* </div> */}
                                  </div>
                        </div>
                    </form>
                </div>
            <div className="table-wrap">
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th className="ts-sec">Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th >Type</th>
                            <th>Requested User</th>
                            <th className="tl-sec">Counsellors</th>
                            <th className="tm-sec">Appointment Time</th>
                            <th className="tm-sec">End Time</th>
                            <th className="tm-sec">Appointment Date</th>
                            {/* <th className="tl-sec">Volunteers</th> */}
                            <th className="tm-sec">Appointment Status</th>
                            <th className="tl-sec">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {appointmentList}
                    </tbody>
                </table>
            </div>
            </div>
           </div>
                {this.state.appoinmentList && this.state.appoinmentList.length == 0 && <div className="table-responsive">
                            <h6 className="text-center">No record found</h6>
                            </div>}
           </div>
           {this.state.appoinmentList && this.state.appoinmentList.length > 0 && <ReactPagination PageDetails={{pageCount: this.state.pageCount, onPageChange: this.onChange, activePage: (this.state.page - 1), perPage: this.state.limit, totalCount: this.state.totalCount}}/>}
        </Fragment>

        )
    }
}
function mapStateToProps(state){
    return {
        appoinmentList:state.appoinment.appoinmentList,
        userRole:state.users.userRole,
        usersList : state.users.userList,
        saveStatus : state.appoinment.saveStatus
    }
}
const actionCreators = {
    getList:appoinmentActions.getList,
    resetList:appoinmentActions.resetList,
    userList:userActions.getList,
    getUser : userActions.getUser,
    resetUser: userActions.resetUser,
    updateStatus: appoinmentActions.updateStatus,
    resetStatus : appoinmentActions.resetStatus
}
const Appoinment = connect(mapStateToProps, actionCreators)(FetchAppoinment);
export {Appoinment as FetchAppoinment };