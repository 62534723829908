import {chatConstants} from '../../constants'

const initialState = {
    chatList : [],
    saveStatus: '',
    backStoryStatus : '',
    backStories:'',
    donarList :[],
    donarStatus : ''
}

export function chat(state = initialState,action){
    switch(action.type){
        case chatConstants.FETCH_CHANNEL_SUCCESS:
            return {
                ...state,
                chatList:action.chatList
            }


            case chatConstants.FETCH_PARTICULAR_CHANNEL_SUCCESS:
                return {
                    ...state,
                    chatList_particularChannel:action.chatList_particularChannel
                }

                case chatConstants.VOLUNTEER_ACCEPT_CHANNEL_SUCCESS:
                    return {
                        ...state,
                        volunteer_acceptChannel:action.volunteer_acceptChannel
                    }

                case chatConstants.FETCH_CHAT_SUCCESS:
                    return {
                        ...state,
                        volunteerMsg:action.volunteerMsg
                    }


            case chatConstants.OFFICIAL_USE_SUCCESS:
                return {
                    ...state,
                    saveStatus :action.saveStatus
                }
                case chatConstants.SAVE_BACKSTORY:
                    return {
                        ...state,
                        backStoryStatus : action.backStoryStatus
                    }
                    case chatConstants.FETCH_BACKSTORY:
                        return {
                            ...state,
                            backStories : action.backStories
                        }
                        case chatConstants.FETCH_DONAR_LIST:
                            return {
                                ...state,
                                donarList : action.donarList
                            }
                            case chatConstants.SAVE_DONAR:
                                return {
                                    ...state,
                                    donarStatus:action.donarStatus
                                }
            default:
                return state
    }
}