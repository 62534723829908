export const ChineesTranslation = {
    "en" : {
        "HEADER" : {
            "HOME" : "主页",
            "EVENT" : "活动",
            "ABOUT" : "关于我们",
            "OUR_WORK" : "我们的工作",
            "GALLERY" : "画册",
            "VOLUNTEER" : "义工",
            "DONATION" : "捐款",
            "OUR_HISTORY": "我们的中心",
            "OUR_BOARD_COMITTEE": "董事会委员",
            "OUR_FINANCIAL_ANNUAL_REPORTS":"年刊和财务报告",
            "EVENTS":"活动",
            "VIDEOS_GALLERY":"视频库",
            "PHOTOS_GALLERY":"相片库",
            "SUCCESS_STORIES":"回馈",
            "VOLUNTEER_OPPORTUNITIES":"义工机会",
            "STORIES_OF_VOLUNTEERS":"义工分享",
            "ONLINE_SIGNUP_FORM":"网上报名形成",          
            "CONTACTUS" : "联系我们",
            "DONATION_CHANNELS" : "捐赠方式",
            "SENIORS_HELPLINE" : "乐龄援助热线",
            "LOGIN" : "登录",      
        },
        "FOOTER" : {
            "SITE_MAP" : "网站地图",
            "ABOUT" : "关于我们",
            "OUR_WORK" : "我们的工作",
            "GALLERY" : "画册",
            "VOLUNTEER" : "义工",
           "DONATION" : "捐款",
           "EVENT" : "活动",
           "ADDRESS" : "地址",
           "CONTACT_US" : "联系我们",
           "TEL" : "电话",
           "FAX" : "传真",
           "EMAIL" : "电子邮件",
           "FOLLOW":"游览脸书",
           "ADDRESS_ONE":"乐龄辅导中心",
           "ADDRESS_TWO":"1号裕廊西＃06-04 裕廊坊",
           "ADDRESS_THR":"购物中心 （Jurong Point）",
           "ADDRESS_FOU":"邮区 648886",
        } ,
        "HOME" : {
            "EXPRESSIVE_THERAPEUTIC_ACTIVITY_FOR_SENIORS" : "老年人的富有表现力的治疗活动",
            "FOR_ENQUIRY_ON_COUNSELLING_SERVICE_AND_PROGRAMMES_YOU_MAY_CALL_US" : "有关咨询服务和计划的咨询，您可以在周一至周五，上午 8.30至下午5.30的办公时间内致电@ 6354-1191",
            "MORE_INFO" : "更多信息",
            "OUR_SERVICE_PGM" : "我们的服务与活动项目",
            "WHO_ARE_WE":"关于我们",
            "YOU_CAN_HELP" : "您可以随时为他们提供帮助。",
            "BE_A_VOLUNTEER" : "成为义工",
            "BE_A_DONOR" : "成为捐助者",
            "SERVICE_PGM_CONTENT":"乐龄辅导中心是一个非营利，非宗教性的社会服务机构。中心的目标是提升年长者和他们的看护者的整体福利, 并特别关注他们的心理健康和社交沟通能力。乐龄辅导中心是一个非营利和非宗教性的社会服务机构。 辅导中心的目标是为了提升年长者和看护者的整体福利, 并特别关注他们的心理健康和社交沟通能力。我们会努力帮助社区里有所需要的年长者，以及改善他们的生活，也让他们有机会积极的学习和得到更充实的生活。",
            "VOLUNTEER_CONTENT":"您是一个好的聆听者吗？ 您愿意和年长者交朋友吗？ 您有时间和年长者一起参与活动吗？ 欢迎您加入我们的机构， 成为我们的义工。 ",
            "DONOR_CONTENT":"非常感谢您的捐款，这些捐款将用在我们所服务的年长者和他们的家人或看护者的需要。",
            "ABOUT_US":"关于我们",
            "OUR_SERVICES":"我们的服务",
            "LEARN_MORE":"了解更多",
            "COUNSELLING_SLIDE_CONTENT":"通过面对面辅导，我们为年长者、看护者以及其他家庭成员提供讨论问题的平台，协助他们面对个人、社交或心理挑战。",
            "HELPLINE_SLIDE_CONTENT":"乐龄援助热线是国家社会理事会（NCSS）所支持的免费服务热线，适用于五十岁以上的年长者或任何想要 查询或谈论与年长者有关问题人。自2005年以来，乐龄援助热线服务年长者和他们的看护者，提供聆听的渠道。",
            "PEP_SLIDE_CONTENT":"我们与社区合作伙伴联办各种讲座、文化之旅以及培训工作坊，以鼓励年长者终身学习，积极面对老龄化，提升年长者的社交 技能和情绪调节能力。",
            "ART_SLIDE_CONTENT":"艺术和音乐计划鼓励并授权年长者过有意义和充实的生活。",
            "HMS_SLIDE_CONTENT":"HMS向符合条件的个人提供免费的基本家庭维护服务，这些服务由一群熟练的，有经验的杂工，电工和水管工提供，他们大多是老年人。",

        },
        "ABOUT" : {
            "ABOUT" : "关于我们",
            "OUR_HISTORY" : "我们的历史",
            "OUR_HOSTORY_ESTABLISHED_IN_1991" : "乐龄辅导中心成立于1991年的乐龄援助热线 （1800-353 8633）正式启动，为任何需要谈论与年长者有关的问题的人提供倾听的机会。两年后，乐龄辅导中心作为新加坡年长者行动小组的一项特殊计划而成立，向有需要的年长者及其家人提供免费的面对面辅导。",
            "OUR_HISTORY_IN_2005_SAGE_COUNSELLING_CENTRE" : "2005年，乐龄辅导中心设置了由国家福利理事会（National Council of Social Service NCSS)支持的全国性的乐龄援助热线（1800-555-5555）。",
            "OUR_HISTORY_SINCE_2008_THE_CENTRE_HAS_DEVELOPED" : "自2008年以来，中心已发展成为独立的专业辅导中心。我们成为国家福利理事会正式会员和获得慈善资格。",

            "OUR_VISION" : "我们的愿景",
            "OUR_VISION_STORY" : "成为卓越的社会服务机构，专门为年长者，其家庭成员和看护者提供心理辅导。",

            "ANN_REPORT":"年刊",
            "FINANCIAL_REPORT":"财务报告",

            "OUR_MISSION" : "我们的宗旨",
            "OUR_MISSION_STORY" : "通过年长者的积极参与，提升他们的能力，促进他们的社交活动，并特别关注年长者健康的心理。",

            "OUR_VALUES" : "我们的价值观",
            "OUR_VALUES_SELFLESS_IN_SERVICE" : "● 无私的服务",
            "ACCEPTANCE" : "● 接纳",
            "GENUINENESS" : "● 真诚",
            "EXCELLENCE" : "● 卓越",
            "COMMITMENT" : "● 承诺",
            "COMPASSION" : "● 同理",
            

            "OUR_COMMITMENT" : "我们的承诺",
            "SAGE_COUNSELLING_CENTRE_IS_COMMITTED" : "乐龄辅导中心致力于",
            "OUR_COMMITMENT_I" : "增强公众对年长者的社交和情感需求的认识和了解。",
            "OUR_COMMITMENT_II" : "通过让年长者参与终生学习和义工服务，提升年长者的自信心并协助他们发挥潜能。",
            "OUR_COMMITMENT_III" : "通过培训看护者及促进跨代关系，强化有年长者的家庭。",
            "OUR_COMMITMENT_IV" : "与社区合作伙伴一同建立一个具有包容性的社会， 使年长者能够被接受和尊重且被赋予权力， 让他们可以继续在社区中保持活跃，同时也可在家中获得安老。",
            "OUR_COMMITMENT_V" : "与社区合作伙伴合作，努力建立一个包容性社会， 让老年人被接受，尊重和授 权，使其在社区中保持活跃并在家中安老。",

            "BOARDCHAIRMAN":"主席",
            "BOARDCICECHAIR":"副主席",
            "HONORARYSECRETARY":"秘书",
            "HONORARYTREASURER":"财政",
            "ASSISTANTTREASURER":"副财政",
            "BOARDMEMBER":"委员",
            "KOHENGSOONTIM":"许英舜先生",
            "KANSEEKWAN":"简舒敏博士",
            "LEESOOKWAN":"李素君女士",
            "ROBERTFUQIGUO":"符气国先生",
            "SENGYUHMING":"盛裕祯小姐",
            "CHANKINMING":"陈建民医生",
            "ZULKIFLIMOHAMMED":"楚其斐先生",
            "TANKIANCHOONPBM":"陈建存先生",
            "CHENGYUANSHAN":"程元善博士",
            "JOYCHEWOONAI":"周恩爱博士",
            "LIMWEELIM":"林伟林先生",
            "HONORARYADVISIOR":"名誉顾问",
            "LUIHAHWAHELENAPBM":"雷榎华博士",
            "ESTHERTANNEEYIUKUMYUPB":"陈姚锦如博士",
            "LEGALADVISIOR":"法律顾问",
            "CHARLESLEONG":"Mr Charles Leong",

            "HED":"乐龄辅导中心",
            "SUBHED":"管理委员会和名誉顾问 财政年2020/2021 & 2021/2022",
        
            "MUL_PBM":"PB, PBM, BBM",
            "PBM":"PBM",
        } ,
        "VOLUNTEER" : {
            "VOLUNTEER" : "义工",
            "VOLUNTEER_CONTENT_ONE":"乐龄辅导中心开展了一系列的服务项目， 鼓励年长者参与各项活动，在社区中保持活跃，积极生活，并当义工回馈社会。",
            "VOLUNTEER_CONTENT_TWO":"我们活跃的义工来自各行各业，其中大多数是年长者。他们正在不断学习和提高自己，以服务他人。 ",
            "VOLUNTEER_OPPORTUNITIES" : "义工机会",
            "VOLUNTEER_OPPORTUNITIES_CONTENT":"所有义工都必须经过训练， 才能正式加入我们的服务行业。",
            "SENIORS_HELPLINERS" : " 乐龄援助热线义工",
            "SENIORS_HELPLINERS_STORY_ONE" : "义工接听热线电话，向五十岁以上的年长者或任何想要查询或谈论与年长者有关问题的人提供帮助。",
            "SENIORS_HELPLINERS_STORY_TWO":"有兴趣当义工的人，需要经过职前培训。",
            "SENIORS_HELPLINERS_STORY_THREE":"热线值班时间如下：",            
            "SENIORS_HELPLINERS_STORY_FOUR":"周一到周五  ",            
            "SENIORS_HELPLINERS_STORY_FIVE":"第一时段: 上午九点至中午十二点",            
            "SENIORS_HELPLINERS_STORY_SIX":"第二时段: 中午十二点至下午四点",            
            "SENIORS_HELPLINERS_STORY_SEVEN":"第三时段: 下午四点至傍晚七点",            
            "SENIORS_HELPLINERS_STORY_EIGHT":"周六",            
            "SENIORS_HELPLINERS_STORY_NINE":"第四时段: 上午九点至下午一点",            
            "SENIORS_HELPLINERS_STORY_TEN":"最低承诺：完成培训后，每周值班一次，最少服务一年。",
            "FRONT_DESK_VOLUNTEERS" : "柜台接待义工",
           "FRONT_DESK_VOLUNTEERS_STORY" : "接听来电并在前台与访客打招呼。",
           "COUNSELLING_ASSOCIATES" : "辅导助理",
           "COUNSELLING_ASSOCIATES_STORY_ONE" : "辅导助理通过家访或打电话来关怀孤独或独居年长者，建设他们的身心健康。",
           "COUNSELLING_ASSOCIATES_STORY_TWO" : "有兴趣当义工的人，需要经过职前培训。",
           "COUNSELLING_ASSOCIATES_STORY_THREE" : "最低承诺：",
           "COUNSELLING_ASSOCIATES_STORY_FOUR" : "完成培训后，每月与年长者进行四次联系（家访或打电话），为期至少一年。",
           "COUNSELLING_ASSOCIATES_STORY_FOUR_HAF":"每次联系后，都需要提交报告。",
        //    "COUNSELLING_ASSOCIATES_STORY_FIVE" : "探访或打电话时间必须依照年长者及义工的安排。",
           "COUNSELLING_ASSOCIATES_STORY_SIX" : "定时：",
           "COUNSELLING_ASSOCIATES_STORY_SEVEN" : "家访或打电话时间必须依照年长者及义工的安排。",
           "REMINISCENCE_WALK_AMBASSADORS" : "怀旧大使（义工）",
           "REMINISCENCE_WALK_AMBASSADORS_STORY" : "怀旧义工在接受不同新加坡的遗产步道的培训后，以怀旧义工的身份带领年长者参观文化之旅， 陈述遗产步道的历史， 让年长者回忆及分享他们的故事。 怀旧义工需要完成国家文物局主办的培训和心肺复苏术（CPR）及自发式外接心脏起搏器（AED）的应用。",
           "MUSIC_AMBASSADORS" : "艺术和音乐大使",
           "MUS_AMBASSADORS":"音乐大使 :",
           "MUSIC_AMBASSADORS_STORY" : "年长者在歌声的伴奏下，学习弹奏新乐器（四弦琴）的技巧；并增强在舞台上表演的信心， 提升友爱精神，为慈善活动，社区医院，疗养院和乐龄中心带来欢乐。",
           "ART_AMBASSADORS":"艺术大使 : ",
           "ART_AMBASSADORS_STORY":"年长者学会通过艺术交流和表达自己的感受和想法。年长者创作的艺术品将在网上展览中展出，这些艺术品的销售收益将捐赠给乐龄辅导中心 。",
           "SPECIALIST_VOLUNTEERS" : "义工导师",
           "SPECIALIST_VOLUNTEERS_STORY" : "我们的专属义工以培训课程和讲座 为年长者和义工添加知识与技能",
           "HMS_VOLUNTEERS" : "家居维修义工",
           "HMS_VOLUNTEERS_STORY" : "家居维修服务的义工在全岛范围内执行简单的维修任务，以改善无收入或低收入年长者的生活条件。",
        //    "HMS_VOLUNTEERS_STORY_LIST" : "独立且值得信赖（您将单独进行工作）",
           "HMS_VOLUNTEERS_STORY_TRUST" : "独立且值得信赖（您将单独进行工作）",
           "HMS_VOLUNTEERS_STORY_TRAVEL" : "服务的范围是全岛性的",
           "HMS_VOLUNTEERS_STORY_LIC" : "持有电气或水喉匠牌照",
           "HMS_VOLUNTEERS_STORY_TOOL" : "具备自己的专用工具箱",
           "HMS_VOLUNTEERS_STORY_PAY" : "能够先支付工作所需的材料费用（中心将在一个月内偿还材料费用和津贴）",
           "HMS_VOLUNTEERS_STORY_COMFORT" : "耐心应对挑剔的客户 ",
           "HMS_VOLUNTEERS_STORY_CHALLENGE" : "能够接受富有挑战性的居住环境",
           "HMS_VOLUNTEERS_STORY_COMMUNI" : "完成工作后，必须拍照发送给乐龄辅导中心",
           "HMS_VOLUNTEERS_STORY_INFO" : "对客户的个人资料必须严加保密",
           "HMS_VOLUNTEERS_STORY_DOC" : "能够通过WhatsApp提交完成工作所需的文档和照片",
           "AD_HOC_EVENTS_VOLUNTEERS" : "不定时义工",
           "AD_HOC_EVENTS_VOLUNTEERS_STORY" : "在中心组织的特别活动中，提供适当的帮助，例如：摄影、登记，分发传单。",
           
           "STORIES_OF_VOLUNTEERS":"义工分享",
           "EXPERIENCE_SENIOR_HELPLINE":"乐龄援助热线的义工分享",
           "EXPERIENCE_CONTENT_ONE":"我是张玛丽，在1995年以成为“乐龄援助热线”（The Seniors Helpline）的义工。 起初，这个团队很小。通过为期三个月的每周培训，我学会了如何友善与年长者沟通， 并通过谈话为他们提供心理情感支持。",
           "EXPERIENCE_YEARS":"这些年来， 我接触不同的来电者",
           "EXPERIENCE_YEARS_ONE":"1）询问有关年长者服务的信息。",
           "EXPERIENCE_YEARS_TWO":"2）经常定期的来电者，会详细分享他们的问题和日常生活。",
           "EXPERIENCE_YEARS_THREE":"3）寻求在情感关系或工作方面问题的协助。",
           "EXPERIENCE_CONTENT_TWO":"通过我26年在热线服务的经验，我学会了什么是真正的聆听，而不仅仅是提供答案。当来电者表达感谢时是我最大的满足。 在乐龄热线当义工让我度过充实而有意义的时光。",
           "VOL_SIGNUP_FORM":"网上报名表格",
           "VOL_NAME":" 姓名",
           "VOL_ADDRESS":"地址",
           "VOL_EMAIL_ADDRESS":"电话号码",
           "VOL_PHONE_NUMBER":"电子邮件地址",
           "VOL_CAT":"选择类别",
            "NAMEENTER":"请输入你的名字",
            "EMAILENTER":"请输入您的电子邮件",
            "EMAILERROR":"请输入有效的电子邮件",
            "MOBILEENTER":"请输入您的手机号码",
            "MOBILEERROR":"手机号码无效（必须为8位数字)",
            "ADDRESSENTER":"请输入您的地址",
            "CATEGORYENTER":"类别字段为必填项"
        } ,
        "COUNSELLINGFORM":{
            "languageList" : ["英语","中国人","马来语","泰米尔语"],
            "raceList": [
                {value: 'chinese',name: "中国人"},
                {value: 'malay',name: "马来语"},
                {value: 'indian', name: '印度人'},
                {value: 'eurasian',name: '欧亚的'},
                {value: 'others',name: '其他'},
              ],
              "serviceList": [
                {value:'audio', name: '电话'},
                {value: 'video',name: '视频'}
              ],

            "ISSUE":"问题/关注",
            "TELLAS":"请告诉我们您面对的问题或关注的事项",
            "ENTERCONTACTADD":"输入您的有效联系地址",
            "CONTACTADD":"您的联系地址",
            "SELECTCOUNS":"选择您的咨询服务",
            "COUNSELINGSER":"辅导服务",
            "ENTEREMAIL":"输入您的电子邮件地址",
            "EMAILADDRESS":"您的电子邮件地址",
            "ENTERCONTACT":"输入您的联系电话",
            "CONTACTNO":"联系电话",
            "ENTERNATION":"输入您的国籍",
            "NATIONALITY":"国籍",
            "SELECTLANG":"选择语言",
            "LANGUAGE":"语言",
            "SELECTRACE":"选择你的种族",
            "RACE":"种族",
            "MALE":"男性",
            "FEMALE":"女性",
            "AGEERROR":"输入您的年龄",
            "GENDER":"性别",
            "AGE":"年龄",
            "LASTERROR":"输入您的名字",
            "LASTNAME":"姓氏",
            "NAMEERRROR":"输入您的名字",
            "FIRSTNAME":"名字",
            "COUNSELLINGSERVICEFORM":"辅导服务申请表",
            "ISSNAME":"名字为必填项",
            "CONTACTREQ":"联系电话为必填项",
            "CONREQ":"联络电话无效（必须为8位数字)",
            "EMAILENTER":"请输入您的电子邮件",
            "EMAILERROR":"请输入有效的电子邮件",
            "COUNSREQ":"需要咨询服务",
            "CANCEL":"取消",
            "SUBMIT":"提交",
            "THANKYOU":"谢谢你!",
            "AAAA01":"您的咨询服务请求表已成功提交!",
            "AAAA02":"您要下载表格吗？",
            "AAAA03":"下载表格",
            "AAAA04":"我们的工作",
            "AAAA05":"辅导服务",
            "AAAA06":"在线申请表",

        },
        "CONTACT" : {
            "CONTACT_US" : "联系我们",
            "ADDRESS" : "地址",
            "TEL" : "电话",
            "FAX" : "传真",
            "EMAIL" : "电子邮件",
           "VIEW_LOCATION" : " 在Google地图上查看位置",
           "CLICK_THE_BELOW_IMAGE" : "点击下面的图片放大",

           "ENQUIRY_FORM" : "查询表格",

           "FIRST_NAME" : "名字",
           "ENTER_YOUR_FIRST_NAME" : "输入您的名字",

           "LAST_NAME" : "姓名",
           "ENTER_YOUR_LAST_NAME" : "输入您的姓名",

           "YOUR_EMAIL_ADDRESS" : "电子邮件",
           "ENTER_YOUR_EMAIL_ADDRESS" : "输入您的电子邮件",

           "YOUR_PHONE_NUMBER" : "电话号码",
           "ENTER_YOUR_VALID_PHONE_NUMBER" : "输入您的电话号码",

           
           "WRITE_SOMETHING_ABOUT_YOU" : "请写下你的询问 ",
        //    "ABOUT_YOU" : "关于你的东西",

           "SEND" : "发送",

           "NAME_IS_REQUIRED" : "名称为必填项",
           "EMAIL_IS_REQUIRED" : "电子邮件为必填项",
           "MOBILE_IS_REQUIRED" : "需要手机",

           "PLEASE_ENTER_VALID_EMAIL" : "请输入有效的电子邮件",
           "MOBILE_MUST_BE_8_DIGIT" : "手机必须是8位数字",
        } ,
        "OURWORK" : {
            "OUR_WORK" : "我们的工作",
            "OUR_WORK_CONTENT_ONE":"我们为面临心理、社交和情感问题的年长者，及其家庭成员和看护者提供服务。",
            "OUR_WORK_CONTENT_TWO":"近年来，我们推出了新的项目，以促进身体健康和情绪稳定的年长者，积极参与活动，放缓衰老。",
            "OUR_WORK_CONTENT_THREE":"作为社会中年长者的领导者并充当他们的声音，本中心还推广公共教育计划，藉着讲习班、讲座、教育研讨会和论坛来接触年长者。",
            "COUNSELLING_SERVICE" : "辅导服务",
            "THE_SENIORS_HELPLINE" : "乐龄援助热线",
            "PUBLIC_EDUCATION_PROGRAMME":"公众教育计划", 
            "CREST_BUANGKOK":"邻里咨询小组@安谷及万国",   
            "CREST_SAGE":"邻里咨询小组@安谷及万国",   
            "CREST_CRITERIA":"邻里咨询小组 (CREST) 资格", 
            "CREST_RESIDENTS":"居住在安谷及万国的居民",
            "CREST_AGE":"18岁及以上 (可以是看护者",
            "CREST_FOR_WHO":"有心理健康风险或被诊断患有心理问题的个人",
            "CREST_OPERATING_HOURS_HEAD":"办公时间",
            "CREST_OPERATING_HOURS1":"星期一至五",
            "CREST_OPERATING_HOURS2":"上午八时半至下午5时半",
            "CREST_REFERRAL1":"邻里咨询小组 (CREST) 的转介",
            "CREST_REFERRAL2":"请填写邻里咨询小组 (CREST) 的转介表格并电邮至",
            "CREST_REFERRAL3":"点击此处下载转介表格",
            "CREST_DESCRIPTION":"邻里咨询小组 (CREST) 主要提高公众对心理健康和失智症的意识、促进提早识别有风险以及为病患和看护者提供情绪援助，并且在有必要是把个人与相关保健和社区看护服务联系起来",
            "ART_PROGRAMME":"艺术和音乐课程",
            "HOME_MAINTENANCE_SERVICES":"家居维修服务",
            "COUNSELLING":"辅导服务",
            "COUNSELLING_CONTENT":"通过面对面辅导，我们为年长者、看护者以及其他家庭成员提供讨论问题的平台，协助他们面对个人、社交或心理挑战。",
            "COUNSELLING_OUR_CRITERIA":"服务对象",
            "COUNSELLING_CRITERIA_ONE":"50岁及以上的新加坡公民或永久居民",
            "COUNSELLING_CRITERIA_TWO":"看护者以及年长者的家庭成员",
            "COUNSELLING_CRITERIA_THREE":"看护者和家人无年龄限制",
            "OUR_COUNSELLING_FEES":"辅导费用",
            "COUNSELLING_FEES_PR":"新加坡人永久居民",
            "COUNSELLING_FEES_ONE":"新加坡公民或永久居民免费",
            "COUNSELLING_FEES_TWO":"非新加坡公民",
            "COUNSELLING_FEES_THREE":"辅导服务 - 每小时五十元",
            "COUNSELLING_FEES_FOUR":"家访辅导 – 每小时一百元",
            "COUNSELLING_OUR_OPERATION_HOURS":"热线服务时段",
            "COUNSELLING_OPERATION_WORK_HOURS":"周一至周五：上午8:30 至 12:30",
            // "COUNSELLING_OPERATION_LUNCH_HOURS":"周一至周五：下午1:30至5:30",
            "COUNSELLING_OPERATION_LUNCH_CHIN_HOURS":"下午1:30至5:30",

            "COUNSELLING_OPERATING_HOURS":"热线服务时段",
            "COUNSELLING_OPERATING_HOURS_MF":"周一至周五",
            "COUNSELLING_OPERATING_HOURS_MF_TIME":"上午八点半至 下午十二点半",
            "COUNSELLING_OPERATING_HOURS_MF1_TIME":"下午一点半至下午五点半",
            // "COUNSELLING_OPERATING_HOURS_LUNCH":"午餐（封闭）",
            // "COUNSELLING_OPERATING_HOURS_LUNCH_TIME":"( 十二点半中午 - 一点半下午 )",
            "COUNSELLING_REFERRAL_HEAD":"转介",
            "DOWNLOAD_FORM":"下载表格",
            "DOWNLOAD_HMS_FORM":"下载HMS推荐表格",
            "SENIORS_HELPLINE_HOURS_MF":"周一至五",
            "SENIORS_HELPLINE_HOURS_MF_TIME":"上午九点至傍晚七点",
            "SENIORS_HELPLINE_HOURS_SAT":"周六",
            "SENIORS_HELPLINE_HOURS_SAT_TIME":"上午九点至下午一点",
            "SENIORS_HELPLINE_HOURS_CLOSE":"周日和公共假期休息",
            "SENIORS_HELPLINE_NUMBER_LABEL":"乐龄援助热线",
            "SENIORS_HELPLINE_NUMBER":"1800 555 5555",

            "COUNSELLING_MORE_INFO":"如需转介，请填写个案转介表格，电邮至 counselling@sagecc.org.sg",
            "COUNSELLING_REFERRAL":"如需转介，请填写客户转介表，然后 通过电子邮件将其发送回给我们",
            "COUNSELLING_INFO":"如需转介，请填写个案转介表格，电邮至 ",
            "COUNSELLING_FORM":"辅导表格",
            "GROUP_WORK":"团体辅导",
            "GROUP_WORK_ONE":"“表达性艺术治疗”是专为年长者组织的为期六周的团体辅导服务。每期为8至12人开班。通过表达性艺术治疗为年长者提供一个抒发内心情绪的平台，借此帮助他们发掘自身应对困难的能力以及潜能。",
            "GROUP_WORK_TWO":"“我的自传”创作小组为年长者提供叙述个人故事的机会。通过回顾个人经历，帮助年长者为过往贺彩，发掘人生方向，并展望未来。每期为8至12人开班，为期八周。",
            // "GROUP_WORK_AIM":"该小组的工作旨在增加老年人之间的互动和社交，并改善他们的心理情感状态。",
            "GROUP_WORK_THREE":"欲知详情请致电6354 1191 或点击",
            "GROUP_WORK_HERE":"这里",
            "SENIORS_HELPLINE_0NE":"乐龄援助热线是国家社会理事会（NCSS）所支持的免费服务热线，适用于五十岁以上的年长者或任何想要查询或谈论与年长者有关问题的人。自2005年开始，为年长者和他们的看护者，提供聆听的渠道。",
            "SENIORS_HELPLINE_HOURS":"热线服务时段：",
            "SENIORS_HELPLINE_HOURS_ONE":"周一至五：     上午九点至傍晚七点",
            "SENIORS_HELPLINE_HOURS_TWO":"周六：   上午九点至下午一点 ",
            "SENIORS_HELPLINE_HOURS_THREE":"周日和公共假期休息",
            "PUBLIC_EDUCATION_PROGRAMME_CONTENT":"我们与社区合作伙伴联办各种讲座、文化之旅以及培训工作坊，以鼓励年长者终身学习，积极面对老龄化，提升年长者的社交技能和情绪调节能力。",
            "CREST_CONTENT":"邻里咨询小组是互联中心（AIC）与卫生部共同制定的《社区心理健康总蓝图》的方案之一，让心理健康问题和失智症病患能够在住家附近得到早期治疗，并确保他们在社区里得到充",
            "PUBLIC_TALKS":"讲座与工作坊",
            "PUBLIC_TALKS_CONTENT":"向公众传授老年学的常识，以提高公众对老龄化课题的认识，提升公众对年长者的关注。",
            "PUBLIC_TALKS_CONTENT_HOPE":"",
            "PUBLIC_REMINISCENCE":"文化之旅",
            "PUBLIC_REMINISCENCE_CONTENT":"自2018年12月以来，本中心与国家文物局（National Heritage Board）合作培训年长者作为文化之旅的义工大使。通过主办各种文化之旅，让孤单的年长者参观博物院，参与文化徒步；由受过培训的年长者当向导。",
            "ART_PROGRAMME_CONTENT":"艺术和音乐计划鼓励年长者过有意义和充实的生活。要了解更多信息或加入计划，请点击以下的微型网站：",
            "HOME_CONTENT":"家居维修服务为以下人员提供免费的基本的家居修服务：",
            "HOME_LIST_ONE":"",
            "HOME_LIST_TWO":"五十岁以上",
            "HOME_LIST_THREE":"持有一张蓝色的社保（CHAS）卡或ComCare 计划（ComCare Assistance Scheme）的受益人。 （资格文件在申请时必须有效。）",   
            "TABLE_HEAD_ONE":"津贴的类型",
            "TABLE_CATEGORY_A":"类别 A",
            "TABLE_ROW_ONE":"ComCare计划",
            "TABLE_ROW_ONE_CONTENT":"资格文件在申请时必须有效。",
            "TABLE_ROW_TWO_REPLACE":"我们不更换有缺陷的物品 不安装新项目。",
            // "TABLE_ROW_TWO_NEW":"不安装新项目。",
            "TABLE_CATEGORY_B":"类别 B",
            "TABLE_ROW_THREE":"蓝色社保CHAS 计划",
            "TABLE_ROW_THREE_CONTENT":"每次申请最多3个项目，例如：客厅的灯管有故障、主卧室的马桶阻塞或厨房水槽漏水。", 
            "SERVICES":"我们提供基本的：",
            "SERVICES_CONTENT_ONE":"这些服务将由一群熟练的，有经验的技术人员，电工和水喉匠提供，他们大多数是年长者。 ",
            "SERVICES_CONTENT_TWO":"其他机构若要为他们的受益者提出申请，请填写家居维修服务的申请表格，电邮：",
            "SERVICES_CONTENT_TWO_MAIL":"hms@sagecc.org.sg,",
            "SERVICES_CONTENT_TWO_COPY":" 并附上该年长者的证件：",
            "SERVICES_CONTENT_THREE":"如果该机构填写了申请表，则可以省略复印年长者的居民证（正面和背面）",
            "SERVICES_CONTENT_FOUR":"补贴证明证件",
            "SERVICES_CONTENT_FIVE":"拍照需维修的部位",
            "SERVICES_CONTENT_SIX":"您可以隐藏年长者居民证的前4个数字。我们的员工将在5个工作日内与您联系。",
            "SERVICES_CONTENT_SEVEN":"对于寻求服务的个人，请联系您最靠近的Cluster Support (专为年长者服务的中心)，家庭服务中心，乐龄活动中心或任何机构，让这些中心转介给我们。",
            "SERVICES_CONTENT_Ei":"欲了解更多信息，请拨打： 6354 1191 或点击",
            "HMS":"回馈",
            "HMS_CONTENT_ONE":"感谢您为我独居又生病的年迈母亲提供迅速的服务，让义工上门安装水龙头。",
            "HMS_CONTENT_TWO":"随着我们老龄化的社会，在你们的机构和义工的帮助下，我们的建国和立国一代可以安享晚年。",
            "HMS_CONTENT_THREE":"",
            "HMS_CONTENT_FOUR":"",
            "HMS_CONTENT_FIVE":"永CK",
            "HMS_CONTENT_T1":"陈太太（匿名）五十六岁已婚，有两个孩子，深感看护责任极其沉重， 通过政府综合诊所转介来寻求辅导服务。 她是家中的老幺，有一位年迈92岁的母亲， 不但中风患者， 同时也患上失智症。",
            "HMS_CONTENT_T2":"陈太太与母亲关系亲密，对于母亲的身体衰退及可能将面对的死亡，她感到迷茫。 虽然自己育有两个孩子而其他兄弟姐妹都健在， 在面对自己的情绪及领悟到年迈母亲有一天将要离世， 倍感压力及无比失落。",
            "HMS_CONTENT_T3":"在辅导初期，她无法控制泪水，对看护母亲的责任感到无助又沉重。 她觉得缺乏家人的支持， 偶尔感到沮丧，甚至想结束自己的生命。 在陈太太接受六次的辅导过程中， 她烦躁不安的情绪开始稳定， 尝试想办法解除压力。 她也处理了对母亲的内疚感， 接受她已经尽力让母亲过她剩余的晚年， 也接受母亲有一天会离开世间，与她的创造者会面的事实。",
            "HMS_CONTENT_T4":"通过辅导过程， 辅导员让陈太太找出她拥有的强点及在逆境中拥有的韧性， 增加她的自我意识，让她有勇气去面对未来的逆境。",
            "HMS_CONTENT_V1":"霍先生（匿名），76岁，因情绪沮丧， 被转介到乐龄辅导中心的辅导服务。 在辅导的过程的初期， 他透露了情绪低落的主要原因是失业，无法找到适合的工作。 过去几年，他的太太及至亲的朋友陆续离世，虽然他目前与女儿及她的家人同住，与他们的关系极其疏远，让他觉得家人没有敬老尊贤，甚至感到与家人社交隔离， 自己感到孤单难过，没有可以倾诉心事的对象。在辅导的过程中， 他得到慰藉， 有一个管道让他能够倾诉他的委屈及苦恼。",
            "HMS_CONTENT_V2":"在其中一次的辅导面谈时，霍先生表露出很强的自杀念头， 辅导员即刻疏解他的情绪困扰及聆听他的感受，关心并开导他，辅导员在确定他的自杀念头已经理清， 心情不再郁闷才让霍先生回家。",
            "HMS_CONTENT_V3":"霍先生在陷入他的黑暗时期，得到辅导员的支持及鼓励，纾解了他的焦虑及不安，使他能够勇敢地面对前面的挑战。 在经过霍先生的同意下，乐龄辅导中心转介他参加乐龄日间中心的活动。 他目前开朗豁达得多， 不再有自杀念头， 他衷心的感激乐龄辅导中心的辅导员对他的帮助。",
            "HMS_CONTENT_LIM1":"林先生，70岁，由临终关怀中心转介寻求辅导服务。林先生夫妇膝下无子， 住在三房式组屋。 两夫妻原本在2016年当清洁工人， 林先生除了长期服抗忧郁症的药，他也是中风患者， 也呈现膀光， 吞咽困难的问题。 在乐龄辅导中心接受辅导期间， 他面临爱妻得第四期癌症的生死离别，心中十分悲痛， 前途感到茫然及空虚。 不久之后，林太太也离世了。 ",
            "HMS_CONTENT_LIM2":"从临终医院接妻子回家渡过最后的日子期间，辅导员在家访时，发现林先生家里的灯泡都坏了。 暗淡的空间间接影响他的情绪。乐龄辅导中心安排家居维修服务人员为他换上新的灯泡，房子的开关板已经陈旧，也换上新的。林先生每天煮热水冲凉，这个动作极其危险，随时可以发生意外， 辅导员决定为他安装热水器， 林先生就不必跌跌撞撞的提着从厨房煮开的热水到冲凉房冲凉了。  林先生的精神面貌也随着居家环境的改善而改观。",
            "HMS_CONTENT_LIM3":"在他感到最黑暗及悲哀时， 定期的面谈辅导让他的心理及情绪得到调整。16次的面谈后，他的情绪逐渐稳定， 开始独立应付日常生活。 林先生得到支持，让他有力量从在哀痛中走出来， 面对角色的改变。 一年后， 他的情绪有好转， 不再沮丧。 乐龄辅导中心安排了助理辅导员（义工） 定期跟进及关心他。",
            "VOLUNTEERING":"志愿服务 ",
            "DONATION":"捐款",
            "OTHERS":"其他",
            "PUBLIC_CONTACT":"欲知详情请致电6354 1191 或点击",
            "TABLE_REMARKS":"评论",
            "TABLE_REQUEST":"我们不接受紧急请求。",
            "GROUP_WORK_ONE_HEAD":"表达性艺术治疗",
            "GROUP_WORK_TWO_HEAD":"我的自传",
            "STORY_CHHUA":"楚华先生的故事",
            "THATCHED_GRASS":"茅草-苏鹏辉先生 v3",

        },
        "DONATION":{
            "DONATION_CONTENT_ONE" :"捐助乐龄辅导中心的款项，均可免税。需填写以下表格，我们将邮寄收据到您提供的地址。 ",
            "DONATION_TAX":"请提交以下详细信息：",
            "DONATION_NRIC":"居民证号码NRIC",
            "DONATION_NAME":"英文姓名",
            "DONATION_ADDR":"地址",
            "DONATION_TEL":"电话号码",
            "DONATION_EMAIL":"电邮",
            "DONATION_CONTENT_TWO" :"您的捐款将帮助我们改善年长者的生活。",
            "DONATION_CONTENT_THREE" :"您可以通过以下方法为乐龄辅导中心做出献捐。",        
            "DONATION_PAYNOW":"电子转账 (PayNow)", 
            "PAYNOW_ACC_HED":"帐户名称 :",           
            "PAYNOW_NAME":"乐龄辅导中心 SAGE Counselling Centre",  
            "PAYNOW_UEN_HED":"机构识别号码 (UEN):",          
            "PAYNOW_UEN":"T08SS0025E",            
            "BANK_TRANSFER":"银行转账",  
            "BANK_DBS_HED":"星展银行有限公司DBS :",          
            "BANK_DBS":"005-901741-0（往来账户）",    
            "BANK_CODE_HED":"银行代码 :",        
            "BANK_CODE":"7171，分支机构代码 ：005",   
            "PAYNOW_BANK_HED":"银行 :",         
            "PAYNOW_BANK":"星展银行有限公司 DBS", 
            "PAYNOW_NUMBER_HED":"银行帐号 ：",           
            "PAYNOW_NUMBER":"005-901741-0 (往来账户)",
            "DONATION_CHEQUE":"支票",
            "CHEQUE_CONTENT_ONE":"要以支票捐赠，请划线， 并抬头写下：SAGE COUNSELLING CENTRE，然后邮寄至以下地址： 1号裕廊西中央2号，＃06-04",
            "CHEQUE_CONTENT_TWO":"裕廊坊购物中心 (Jurong Point), 邮区 648886。",
            // "CHEQUE_CONTENT_THREE":"区 648886。",
            // "CHEQUE_CONTENT_FOUR":"新加坡648886",
            "DONATION_CASH":"现金",
            "CASH_CONTENT":"您可以在工作日的办公时间（上午8:30至傍晚5:30）到位于 1 裕廊西 Jurong West Central 2，＃06-04 裕廊坊 购物中心 （Jurong Point），邮区 648886 的办公室。",
        },
        "EVENT":{
            "EVENT_SAGECC":"乐龄辅导中心",
            "EVENT_HEAD":"活动",
            "EVENT_CONTENT_ONE":"除了我们的核心服务和活动项目，我们还组织各种活动，例如：论坛，研讨会，讲座和培训讲习班，以激发终身学习，并增强年长者的社交及生活技能。",
            "EVENT_CONTENT_TWO":"为了跟上老龄化人口的增长步伐并为年长者提供更多完善服务，我们还需要筹集资金，为年长者开展更多丰富而有意义的活动。",
            "EVENT_UPCOMING":"接下来的活动",
            "EVENT_PAST":"过去的事件",
            "EVENT_POSTPONE":"因受到疫情的影响,本机构的所有面对面活动已延期举行。",

        },
        "SUCCESSSTORIES":{
            "SUCCESS_SAGECC":"乐龄辅导中心",
            "TESTI_MONIAL":"回馈",

        },
        "GALLERY":{
            "SUICIDE_PREVEN":"SAGECC预防自杀论坛2018",
            "SINGAPORE_NIGHT":"老年人的富有表现力的治疗活动（新加坡夜节）",
            "VIDEOS_GALLERY_1":"Join SAGE Counselling Centre as a Volunteer!",
            "VIDEOS_GALLERY_2":"乐龄援助热线 - 电话辅导",
            "VIDEOS_GALLERY_3":"The Senior Helpline - Tele-befriending (English)",
            "HEAR_VOL":"Hear from our volunteers why they volunteer",
            "WE_CARE":"我们关心， 我们倾听",
            "WE_CARE_VIDO3":"We Listen We Care",

        },

        "SIGNUPEVENT":{
            "SIGNUP_FOR_EVENT":"报名参加活动",
            "FULL_NAME":"全名",
            "CON_NO":"联系电话",
            "CON_WHAPP":"可通过Whatsapp与之联系？",
            "YES":"是的",
            "NO":"不",
            "EMAIL":"您的电子邮件地址",
            "DOB":"多宝",
            "GEN":"性别",
            "MALE":"男性",
            "FEMALE":"女性",
            "Other":"其他",
            "LANG":"所讲语言",
            "HOW":"您如何知道此事件",
            "BACK":"后退",
            "SUBMIT":"提交",
            "EN_NAME":"输入你的名字 ",
            "NAME_RE":"名称为必填项",
            "EN_CON":"输入您的联系电话",
            "CON_RE":"联系电话为必填项",
            "CON_INVALID":"联络电话无效（必须为8位数字）",
            "EN_EMAIL":"输入您的电子邮件地址",
            "EMAIL_RE":"电子邮件为必填项 ",
            "EMAIL_INVALID":"电子邮件无效",
            "EN_DOB":"输入您的DOB",
            "SEL_LANG":"- 选择语言 - ",
            "SEL_OPT":"选择你的选项",
            "RE_REQ":"原因为必填项",
        },
        "DONATEFORM":{
            "DONARPAT":"捐献人资料",
            "CALLME":"捐献人姓名",
            "NRIC":"居民证号码 / 公司注册号码",
            "FULLNAME":"全名",

            "PAYMENT":"付款方法",
            "PAYMENTSELECT":"选择付款方式",
            "PAYMENTPAYNOW":"电子转账 (PayNow)",
            "PAYMENTCASH":"现金",
            "PAYMENTBANK":"银行转帐",
            "PAYMENTREQURIED":"需要付款方式",

            "NAMEERR":"名称为必填项",
            "NRREQ":"需要NRIC",
            "PLSREQ":"请输入有效的NRIC，字母应为大写",
            "EMAILENTER":"电子邮件为必填项",
            "EMAILADDRESS":"电子邮件",
            "EMAILERROR":"输入有效邮件地址",
            "CONNO":"联络电话",
            "CONREQ":"需要联系",
            "CONREQSS":"联系人必须为8位数字",
            "TAXREQ":"扣税",
            "ADDRESS":"地址",
            "ENADD":"地址为必填项",
            "NEXT":"下一个",
            "DONGIVE":"通过Giving.  Sg 捐款",
            "DONGPAY":"通过 电子转账（PayNow） 捐款",
            "CANCEL":"取消",
            "PROCEED_QRCODE":"进入二维码",
            "QR_CODE":"扫描二维码",
            "CHEQUE":"支票",

        },
        "CHAT":{
            "SEND":"发送请求",
            "ASK":"询问SAGE CC",
        },
        "MAINTENANCE":{
            "UNDER_MAINTENANCE":"正在维修",
        }
    }
}