//  Local
// let BASE_PATH = "http://192.168.1.28:5006"
// let IMG_PATH = "http://192.168.1.28:5006/uploads"

// Local
// let BASE_PATH = "http://localhost:5006";
// let IMG_PATH = "http://localhost:5006/uploads";
// let BASE_PATH = "https://sagecc.org.sg:3000";
// let IMG_PATH = "https://sagecc.org.sg:3000/uploads"
// let API_PATH = `${BASE_PATH}`;
// let BASE_NAME = '/#'
// let HASH_NAME = '/#' //without Hash Route Means --> '/'
// let SOCKET_URL = "https://sagecc.org.sg:3001"

let BASE_PATH = process.env.REACT_APP_BASE_URL;
let IMG_PATH = process.env.REACT_APP_IMG_PATH;
let API_PATH = `${BASE_PATH}`;
let BASE_NAME = '/#';
let HASH_NAME = '/#'; //without Hash Route Means --> '/'
let SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

// live
// let BASE_PATH = "http://70.32.75.14:5006"
// let IMG_PATH = "http://70.32.75.14:5006/uploads"

// Demo
// let BASE_PATH = "http://ec2-13-250-8-106.ap-southeast-1.compute.amazonaws.com/sagecc"
// let API_PATH = "http://70.32.75.14:5006";
// let IMG_PATH = "http://70.32.75.14:5006/uploads";
// let BASE_NAME = 'sagecc/#';
// let HASH_NAME = '/sagecc/#' //without Hash Route Means --> 'sagecc/'
// let SOCKET_URL = "http://70.32.75.14:5007"

// export const config = {
// 	apiUrl: `${BASE_PATH}`,
// 	imgPath: `${IMG_PATH}`,
//     baseName: '/',
//     phaseKey : 'Sea!@#$%food^&*',
// };

export const config = {
  production: false,
  basePath: BASE_PATH,
  apiUrl: API_PATH,
  socket_url: SOCKET_URL,
  imgPath: IMG_PATH,
  phaseKey: 'Sea!@#$%food^&*',
  baseName: BASE_NAME, // if demoGis server change to 'demoGis/#' or '/#
  hash: window.location.hash,
  hashName: HASH_NAME, //hashname must be /#
  //In PackageJson file -> include this for demo -> homepage : "http://ec2-13-250-8-106.ap-southeast-1.compute.amazonaws.com/sagecc"
};
