import React,{Component,Fragment} from 'react'
import UploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import {history,ReactPagination} from '../../../helpers';
import {ratingActions} from '../../../store/actions'
import {toastr} from '../../../services'
import Toggle from 'react-toggle';
import Card from '@material-ui/core/Card';
class OfficialUse extends Component{
    constructor(props){
        super(props);
        this.state={
            follow_up:'',
            others:'',
            staffname:'',
            date:'',
            follow:false,
            counsellingType:'',
            othersCheck:false
        }
    }
    
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }
   
    handleData = (data) => {
        this.setState({counsellingType:data})
        
    }
    handleFollowChange = () => {
        let follow = this.state.follow ? false : true
        this.setState({
            follow
        })
    }
    handleOthersChange = () => {
        let othersCheck = this.state.othersCheck ? false : true
        this.setState({
            othersCheck
        })
    }
    listPath = () => {
        history.goBack()
    }
    handleSubmit = (e) => {
        e.preventDefault()
        console.log("follow",this.state.date)
    }
    render(){
        let dataArray = ['Referred for Face-to Face Counselling','Referred for Counselling Associate Programme','Referred for Tele-befriending ']
        return(
            <Fragment>
               <div className="">
                   <h4>For Official Use Only</h4>
                <div className="card">
                    <div className="col-md-12 col-xl-9 p-0">
                     
                        <div className="card-body">
                            <form onSubmit={this.handleSubmit}>
                               <body>
                               <div>
                                    <div className="form-group">
                                        {dataArray && dataArray.map(datas => (
                                            <div className="form-group">
                                                <input type="checkbox" checked={datas == this.state.counsellingType} onChange={()=>this.handleData(datas)}/><span className="off-check">{datas}</span>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <hr></hr>
                                <div className="form-group ">
                                    <input type="checkbox" checked={this.state.follow} onChange={this.handleFollowChange}/><span className="off-check">Follow-Up</span>
                                    {this.state.follow && <div className="col-sm-12">
                                        <input type="text" className=" text-line" value={this.state.follow_up} name="follow_up" onChange={this.handleInputChange}/>
                                    </div>}
                                </div>
                                <div className="form-group">
                                <input type="checkbox" checked={this.state.othersCheck} onChange={this.handleOthersChange}/><span className="off-check">Others</span>
                                {this.state.othersCheck && <div className="col-sm-12">
                                   
                                      <input type="text" className="text-line" value={this.state.others} name="others" onChange={this.handleInputChange}/>
                                    </div>}
                                </div>
                                <div className="form-group">
                                    <label className="col-md-12 col-xl-4 mb-md-1 col-form-label pr-0">Name of staff I/C</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="text-line" value={this.state.staffname} name="staffname" onChange={this.handleInputChange}/>

                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-md-12 col-xl-4 mb-md-1 col-form-label pr-0">Date</label>
                                    <div className="col-sm-6">
                                        <input type="date" className="text-line" value={this.state.date} name="date" onChange={this.handleInputChange} />
                                    </div>                   
                                </div>
                                <div class="text-right">
                                                <Button type="button" variant="contained" onClick={this.listPath}>Cancel</Button>
                                                <Button color="primary" variant="contained" type="submit" className="ml-2">
                                                    Submit
                                                </Button>
                                            </div>
                               </body>
                            </form>
                        </div>
                       
                    </div>
                </div>
                </div>
            </Fragment>

        )
    }
}
function mapStateToProps(state){
    return {
       
    }
}
const actionCreators = {
   
}
const Official = connect(mapStateToProps, actionCreators)(OfficialUse);
export {Official as OfficialUse };