import React,{Component, Fragment} from 'react'
import UploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import {history} from '../../../helpers';
import {eventActions} from '../../../store/actions'
import Toggle from 'react-toggle';
import moment from 'moment';
import {config} from '../../../config'
import {toastr} from '../../../services'
class CreateEvent extends Component{
    constructor(props){
        super(props);
        this.state={
            id:'',
            title:'',
            description:'',
            startDate:'',
            endDate:'',
            location:'',
            address:'',
            eventType:'',
            eventStatus:'yettostart',
            status:false,
            startTime:'',
            endTime:'',
            participants:'',
            tot_counts:'',
            ava_counts:'',
            submitted:false,
        }
    }
    componentDidMount(){
        if(this.props.location && this.props.location.state && this.props.location.state.obj){
            let reqData = this.props.location.state.obj
            this.props.getList(reqData)
        }
    }
    componentWillReceiveProps(newProps){
        if(newProps && newProps.eventList && newProps.eventList.data && newProps.eventList.data.rows && newProps.eventList.data.rows[0]){
            let editData = newProps.eventList.data.rows[0]
            this.setState({
               id:editData.id,title:editData.title,description:editData.description,startDate:moment(editData.startDate).format('YYYY-MM-DD'),endDate:moment(editData.endDate).format('YYYY-MM-DD'),
               eventStatus:editData.eventStatus,eventType:editData.eventType,status:editData.status,participants:editData.participants,imageName:editData.imageUrl && editData.imageUrl,tot_counts:editData.maxCount && editData.maxCount,
               address:editData.address,location:editData.location,imagePreviewUrl:config.imgPath + '/events/'+editData.imageUrl,startTime:editData.workingTime && editData.workingTime.startTime,endTime : editData.workingTime && editData.workingTime.endTime
            })
            this.props.resetList()
        }
        if(newProps && newProps.saveStatus && newProps.saveStatus === true){
            this.props.resetSave()
            history.goBack()
        }
    }
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }
    handleEventStatus = (e) => {
        this.setState({
            eventStatus:e.target.value
        })
    }
    statusChange = (status) => {
        var status = this.state.status ? false : true
        this.setState({status})
    }
    handleImgChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                fileData: file,
                imageName: file.name,
                imagePreviewUrl: reader.result
            })
        }
        if (e.target.files && e.target.files[0]) {
            reader.readAsDataURL(file)
        }
    }
    listPath = () => {
        history.push('/admin/event')
    }
    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({submitted:true})
        const {title,description,startDate,endDate,location,address,eventStatus,eventType,status,imageName,participants,id,fileData,tot_counts,startTime,endTime} = this.state
        if(title && eventStatus && eventType && startDate && endDate && (fileData || imageName) && startTime && endTime && location){
            const formData = new FormData()
            // const startTime1 = moment(startTime,"hh:mm A").tz('Asia/Singapore').format('HH:mm')
            // const endTime1 = moment(endTime,"hh:mm A").tz('Asia/Singapore').format('HH:mm')
            let obj = {
                title,
                eventStatus,
                eventType,
                startDate,
                // status,
                endDate,
                location,
                startTime,
                endTime
            }
            if(description){
                obj['description'] = description
            }
            // if(location){
            //     obj['location'] = location
            // }
            if(address){
                obj['address'] = address
            }
            if(participants){
                obj['participants'] = participants
            }
            if(tot_counts){
                obj['maxCount'] = tot_counts
            }
            // if(startTime){
            //     obj['startTime'] = startTime
            // }
            // if(endTime){
            //     obj['endTime'] = endTime
            // }
            if(id){
                obj['eventId'] = id
            }
            formData.append('eventObject',JSON.stringify(obj))
            fileData && formData.append('eventImage',fileData)
            this.props.save(formData)
        }else{
            toastr.error('Mandatory fields are missing')
        }
    }
    render(){
        return(
            <Fragment>
                <section>
                    <div className="tit-sec">{this.state.id ? <h4>Edit Event</h4> : <h4>Add Event</h4>}</div>
                            <div class="card">
                                <div class="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-tab">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Title&nbsp;<span class="mandatory">*</span></label>
                                                            <div className="col-sm-8">
                                                                <input type="text" name="title" value={this.state.title} onChange={this.handleInputChange} placeholder="Enter Title" className="form-control"/>
                                                                {this.state.submitted && !this.state.title && <div className="mandatory">Title field is required</div>}
                                                            </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label class="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Description</label>
                                                            <div className="col-sm-8">
                                                                <input type="text" name="description" value={this.state.description} onChange={this.handleInputChange} placeholder="Enter Description" className="form-control"/>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Start Date&nbsp;<span class="mandatory">*</span></label>
                                                            <div className="col-sm-8">
                                                                <input type="date" name="startDate" value={this.state.startDate} onChange={this.handleInputChange}  className="form-control" min={moment().format('YYYY-MM-DD')}/>
                                                                {this.state.submitted && !this.state.startDate && <div className="mandatory">Please select Start Date</div>}
                                                            </div>
                                                    </div>
                                                </div>
                                            <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label class="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">End Date&nbsp;<span class="mandatory">*</span></label>
                                                            <div className="col-sm-8">
                                                                <input type="date" name="endDate" value={this.state.endDate} onChange={this.handleInputChange}  className="form-control" disabled={!this.state.startDate} min={moment(this.state.startDate).format('YYYY-MM-DD')}/>
                                                                {this.state.submitted && !this.state.endDate && <div className="mandatory">Please select End Date</div>}
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Location&nbsp;<span class="mandatory">*</span></label>
                                                            <div className="col-sm-8">
                                                                <input type="text" name="location" value={this.state.location} onChange={this.handleInputChange} placeholder="Enter Location" className="form-control"/>
                                                                {this.state.submitted && !this.state.location && <div className="mandatory">Please select event location</div>}

                                                            </div>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label class="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Address</label>
                                                            <div className="col-sm-8">
                                                                <textarea type="text" name="address" value={this.state.address} onChange={this.handleInputChange} placeholder="Enter Address" className="form-control"/>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Start Time&nbsp;<span class="mandatory">*</span></label>
                                                <div className="col-sm-8">
                                                <input type="time" name="startTime" value={this.state.startTime} onChange={this.handleInputChange} placeholder="Enter Start Time" className="form-control"/>
                                                {this.state.submitted && !this.state.startTime && <div className="mandatory">Please select Start Time</div>}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">End Time&nbsp;<span class="mandatory">*</span></label>
                                                <div className="col-sm-8">
                                                <input type="time" name="endTime" value={this.state.endTime} onChange={this.handleInputChange} placeholder="Enter End Time" className="form-control"/>
                                                {this.state.submitted && !this.state.endTime && <div className="mandatory">Please select End Time</div>}

                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                            <div className="  row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Event Type&nbsp;<span class="mandatory">*</span></label>
                                                            <div className="col-sm-8">
                                                                <div className="radio-btn">
                                                                    <label>
                                                                        <input type="radio" value="training" checked={this.state.eventType == "training"} onChange={this.handleInputChange} name="eventType"/><span>Training</span>
                                                                    </label>
                                                                    <label>
                                                                        <input type="radio" value="public" checked={this.state.eventType == "public"} onChange={this.handleInputChange} name="eventType"/><span>Public</span>
                                                                    </label>
                                                                </div>
                                                                {this.state.submitted && !this.state.eventType && <div className="mandatory">Please select Event type</div>}
                                                            </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label class="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Event Status</label>
                                                            <div className="col-sm-8">
                                                                <select value={this.state.eventStatus} onChange={this.handleEventStatus} className="form-control">
                                                                    <option value="yettostart">Yet to start</option>
                                                                    <option value="ongoing">Ongoing</option>
                                                                    <option value="done">Done</option>
                                                                </select>

                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Total Count</label>
                                                            <div className="col-sm-8">
                                                                <input type="text" name="tot_counts" value={this.state.tot_counts} onChange={this.handleInputChange} placeholder="Enter Count" className="form-control"/>

                                                            </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                            <div className="form-group row">
                                            <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Image&nbsp;<span class="mandatory">*</span></label>
                                                <div className="col-sm-8">
                                                    
                                                    <div className="choose-wrap">
                                                        <label className="choo-btn" for="file"> 
                                                            <UploadIcon className="mr-2"/>Choose File
                                                            <input type="file" name="imageName" id="file" single="" accept="image/*" onChange={this.handleImgChange} />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <img src={this.state.imagePreviewUrl} height="80px" width="80px" />
                                                        {this.state.submitted && !this.state.imageName && <div className="mandatory">Image is required</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                                {/* <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Status</label>
                                                            <div className="col-sm-8"> */}
                                                                {/* <input type="radio" value="true" checked={this.state.status == "true"} onChange={this.handleInputChange} name="status"/><span>True</span>
                                                                <input type="radio" value="false" checked={this.state.status == "false"} onChange={this.handleInputChange} name="status"/><span>False</span> */}
                                                                {/* <Toggle defaultChecked= {this.state.status} checked = {this.state.status} onClick={()=>this.statusChange(this.state.status)}/>
                                                            </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className="  row">
                                                
                                                {/* <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label class="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Participants</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" name="participants" value={this.state.participants} onChange={this.handleInputChange} className="form-control"/>
                                                            </div>
                                                    </div>
                                                </div> */}
                                            {/* </div>
                                            <div className="row"> */}
                                            {/* <div className="col-md-6">
                                            <div className="form-group row">
                                            <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Image&nbsp;<span class="mandatory">*</span></label>
                                                <div className="col-sm-8">
                                                    
                                                    <div className="choose-wrap">
                                                        <label className="choo-btn" for="file"> 
                                                            <UploadIcon className="mr-2"/>Choose File
                                                            <input type="file" name="imageName" id="file" single="" accept="image/*" onChange={this.handleImgChange} />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <img src={this.state.imagePreviewUrl} height="80px" width="80px" />
                                                        {this.state.submitted && !this.state.imageName && <div className="mandatory">Image is required</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        </div>
                                        </div>      
                                        <div class="bottom-btn">
                                            <button type="button" className="btn cancel-btn" onClick={this.listPath}>Cancel</button>
                                            <button type="submit" className="btn add-btn">Submit </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                </section>
            </Fragment>
        )
    }
}
function mapStateToProps(state){
    return {
        saveStatus:state.events.saveStatus,
        eventList:state.events.eventList
    }
}
const actionCreators = {
    save : eventActions.save,
    getList : eventActions.getList,
    resetSave: eventActions.resetSave,
    resetList: eventActions.resetList
}
const Event = connect(mapStateToProps, actionCreators)(CreateEvent);
export { Event as CreateEvent };