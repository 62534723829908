import React from 'react';
import { connect } from 'react-redux';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ServiceImg from '../../assets/img/img_srvc.png';
import PlsImg from '../../assets/img/icn_pls.png';
import MinusImg from '../../assets/img/icn_minus.png';
import BannerImg from '../../assets/img/img_banr.png';
import { authorizedData, isWebLogin, senduserMsg_Emit, getUserMsg_Listen, getAcceptedChannel_Listen, history, isPincodeValid } from "../../../helpers";
import UserFemale from '../../assets/img/ChatUsr.png';
import UserMale from '../../assets/img/ChatUsr.png';
import { chatActions, appoinmentActions } from '../../../store/actions'
import { config } from '../../../config';
import { toastr } from '../../../services';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ScrollToBottom from 'react-scroll-to-bottom';
import ChatImg from '../../assets/img/img_chatbotwtxt.png'

class WebChat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedChannel: [],
            userChats: [],
            chatList: [],
            isOpen: false,
            called: false,
            message: ""
        }
    }

    componentWillReceiveProps(newProps) {

        if (!isWebLogin()) {
            this.setState({ chatList: [], userChats: [], selectedChannel: [], isOpen: false, isLogin: false, called: false }, () => {
                this.props.resetList()
                this.props.resetParticular_ChannelList()
                this.props.reset_getMessage_Listen()
                this.props.reset_volunteer_Accept_Channel()
            })
        }

        //get all channel list
        if (newProps && newProps.chatList && newProps.chatList.data && newProps.chatList.data.chatData) {
            let chatData = newProps.chatList && newProps.chatList.data && newProps.chatList.data.chatData
            this.setState({ chatList: chatData })
            this.props.resetList()

            if (chatData && chatData.length > 0) {
                this.getParticularChatChannels(chatData[0])
            }
        }

        //particular Channel list
        if (newProps && newProps.chatList_particularChannel && newProps.chatList_particularChannel.data && newProps.chatList_particularChannel.data.chatData) {
            let chatData = newProps.chatList_particularChannel && newProps.chatList_particularChannel.data && newProps.chatList_particularChannel.data.chatData
            this.setState({ userChats: chatData })
            this.props.resetParticular_ChannelList()

        }

        //get chats from volunteer
        if (newProps && newProps.volunteerMsg && newProps.volunteerMsg.chatData) {
            let chatData = newProps.volunteerMsg && newProps.volunteerMsg && newProps.volunteerMsg.chatData
            let userData = newProps.volunteerMsg && newProps.volunteerMsg && newProps.volunteerMsg.userData ? newProps.volunteerMsg.userData : []
            let userChats = this.state.userChats

            const found = userChats.some(item => item.id == chatData.id);

            if (((chatData && chatData.fromUserId) == (this.state.selectedChannel && this.state.selectedChannel.userId))) {
                if (!found) {
                    let obj = {
                        id: chatData.id,
                        message: chatData.message,
                        fromUserId: chatData.fromUserId,
                        fromUser: { userName: userData.userName }
                    }
                    userChats.push(obj)
                    this.setState({ userChats })
                }
            }

            this.props.reset_getMessage_Listen()
        }

        //accepted msg from volunteer
        if (newProps && newProps.volunteer_acceptChannel && newProps.volunteer_acceptChannel.data && newProps.volunteer_acceptChannel.data) {
            let chatData = newProps.volunteer_acceptChannel && newProps.volunteer_acceptChannel.data && newProps.volunteer_acceptChannel.data
            let chatList = this.state.chatList
            const found = chatList.some(item => item.id == chatData.id);
            if (!found) {
                if (chatList && chatList.length > 0) {
                    let newArray = [chatData]
                    chatList.map(item => {
                        newArray.push(item)
                    })
                    chatList = newArray
                    toastr.success("Your request is approved")
                } else {
                    chatList = [chatData]
                }
                this.setState({ chatList, userChats: [] })
            }
            this.props.reset_volunteer_Accept_Channel()
            this.getParticularChatChannels(chatData)
        }

        if (newProps && newProps.createStatus && newProps.createStatus == 200) {
            this.props.resetAppointment()
            // this.getList()
        }

        //login 
        if (isWebLogin() && !this.state.called && newProps && newProps.loggedIn && newProps.loggedIn.loggedIn) {
            // history.push('/admin/dashboard')
            // this.props.triggerFunc(false)
            this.setState({ called: true }, () => {
                this.getChatChannels();
                getAcceptedChannel_Listen();
            })
        }
    }

    componentDidMount() {
        this.getChatChannels();
        getAcceptedChannel_Listen();
    }

    requestChat = () => {
        let isWebLoginData = isWebLogin()
        const { firstName, lastName, email, id, gender, mobile } = isWebLoginData
        if (isWebLoginData && isWebLoginData.id) {
            let obj = {
                "firstName": firstName,
                "lastName": lastName,
                "email": email,
                "userId": id,
                "type": "request",
                "appointmentStatus": "yettostart",
                // "status": "true or false",
                // "sessionId": "1(For online counselling)",
                // "approvedBy": "1",
                // "appointmentTime": "10.00",
                // "endTime": "12.00",
                // "address1": "12.00",
                // "address2": "12.00",
                // "age": "18",
                // "concern": "string",
                // "counsellingService": "string",
                "gender": gender,
                // "language": "English",
                "mobile": mobile,
                // "nationality": "Indian",
                // "race": "Asian"
            }
            this.props.createAppointment(JSON.stringify(obj))
            getAcceptedChannel_Listen()
        } else {
            toastr.error("Please login to continue chat")
        }
    }

    getChatChannels = (channelId) => {
        let isWebLoginData = isWebLogin()
        if (isWebLoginData && isWebLoginData.id) {
            let obj = {
                userId: isWebLoginData && isWebLoginData.id
            }
            this.props.getList(obj)
        }
    }

    getParticularChatChannels = (selectedChannel) => {
        this.setState({ selectedChannel: selectedChannel })
        let isWebLoginData = isWebLogin();
        if (isWebLoginData && isWebLoginData.id) {
            let obj = {
                channelId: selectedChannel.id
            }
            this.props.getParticular_ChannelList(obj)
            getUserMsg_Listen(selectedChannel.id)
        }

    }

    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }

    handleOpenClose = (isOpen) => {
        let isOpenVal = isOpen ? false : true
        this.setState({ isOpen: isOpenVal })
    }

    sendMsg = (e) => {
        e.preventDefault()
        console.log(this.state.chatList)
        const { fromUserDetails, id } = this.state.selectedChannel
        let isWebLoginData = isWebLogin()
        if (this.state.message) {
            senduserMsg_Emit({ "appointmentId": id, "fromUserId": isWebLoginData && isWebLoginData.id, "toUserId": fromUserDetails && fromUserDetails.id, "message": this.state.message, "type": "e-chat", "isRead": "false" })

            let userChats = this.state.userChats

            let obj = {
                message: this.state.message,
                fromUserId: isWebLoginData && isWebLoginData.id,
                fromUser: { userName: isWebLoginData && isWebLoginData.userName }
            }
            userChats.push(obj)

            this.setState({ userChats, message: '' })
            //  this._scrollRef.scrollTop = 0;

            //  this._scrollRef.scrollTop = 5
            //  this._scrollRef.scrollBottom = 0;
            // this.setState({ message : '' })

            // var objDiv = document.getElementById("chatScroll");
            // objDiv.scrollTop = 100;
            // document.getElementById('chatScroll').scrollIntoView(false);
        } else {
            toastr.error("Please enter message")
        }
    }

    render() {
        console.log("channel", this.state.chatList, "id", this.state.selectedChannel && this.state.selectedChannel.id)
        let isWebLoginData = isWebLogin()
        let channelList = this.state.chatList && this.state.chatList.map(item => {
            // return <li class="person" data-chat="person4" onClick={() => this.getParticularChatChannels(item)}>

            if (item && item.toUserDetails && item.toUserDetails.id) {
                return <li class={(((this.state.selectedChannel && this.state.selectedChannel.id) == (item && item.id)) ? "person active-user" : 'person')} data-chat="person4" onClick={() => this.getParticularChatChannels(item)}>

                    <div class="user">
                        <img src={UserMale} />
                        {/* <img src={config.imgPath+(item.fromUserDetails && item.fromUserDetails.profileImg) } />
                <span class="status offline"></span> */}
                    </div>
                    <p class="name-time">
                        <span class="name">{item.fromUserDetails && item.fromUserDetails.userName}</span>
                        {/* <span class="time">08/02/2019</span> */}
                    </p>
                </li>
            }
        })

        let chats = this.state.userChats && this.state.userChats.map(item => {
            if (((item && item.fromUserId) == (isWebLoginData && isWebLoginData.id))) {
                return <li class="chat-right">
                    {/* <div class="chat-hour">08:59 <span class="fa fa-check-circle"></span></div> */}
                    <div class="chat-text ">{item.message}</div>
                    <div class="chat-avatar">
                        <img src={UserMale} />
                        <div class="chat-name">{item && item.fromUser && item.fromUser.userName}</div>
                    </div>
                </li>
            } else {
                return <li class="chat-left">
                    <div class="chat-avatar">
                        <img src={UserFemale} />
                        <div class="chat-name">{item && item.fromUser && item.fromUser.userName}</div>
                    </div>
                    <div class="chat-text">{item.message}</div>
                    {/* <div class="chat-hour">08:57 <span class="fa fa-check-circle"></span></div> */}
                </li>
            }

        })
        let defaultMessage = <div className="text-left"><img src={ChatImg} /></div>

        return (
            <div class="container">
                <div className="chat-sec">
                    <div class="chat-wrap">
                        {this.state.isOpen && <div class="card">
                            <div class="row">
                                <div class="col-sm-4 col-5 pr-0">
                                    <div class="users-container">
                                        <div className="new-chat">
                                            <button onClick={this.requestChat}>{window.strings.CHAT.SEND}<i className="fas fa-paper-plane"></i></button>
                                        </div>
                                        {/* <PerfectScrollbar> */}
                                        <div className="chat-user">
                                            <ul class="users">
                                                {channelList}
                                            </ul>
                                        </div>
                                        {/* </PerfectScrollbar> */}
                                    </div>
                                </div>
                                <div class="col-sm-8 col-7 pl-0">
                                    <div class="selected-user">
                                        <span class="name">{window.strings.CHAT.ASK}</span>
                                    </div>
                                    <div class="chat-container">
                                        {/* <PerfectScrollbar containerRef={(ref) => {this._scrollRef = ref;}}> */}
                                        <ScrollToBottom>
                                            <ul class="chat-box chatContainerScroll" id="chatScroll">
                                                {this.state.userChats && this.state.userChats.length > 0 ? chats : defaultMessage}
                                            </ul>
                                        </ScrollToBottom>
                                        {/* </PerfectScrollbar> */}
                                        {this.state.selectedChannel && this.state.selectedChannel.length != 0 && <div class="form-group mt-3 mb-0 send-box">
                                            <form onSubmit={this.sendMsg}>
                                                <input type="text" class="form-control" name="message" value={this.state.message}
                                                    onChange={this.handleInputChange} rows="3" placeholder="Type your message here..." />
                                                <button type="submit" className="gradient-btn"><i className="fas fa-paper-plane"></i></button>
                                            </form>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div className="chat-icon" onClick={() => this.handleOpenClose(this.state.isOpen)}>
                        {!this.state.isOpen && <button className="gradient-btn"><i className="fas fa-comment-dots"></i></button>}
                        {this.state.isOpen && <button className="gradient-btn"><i className=" fas fa-times"></i></button>}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.authentication,
        chatList: state.chat.chatList,
        volunteer_acceptChannel: state.chat.volunteer_acceptChannel,
        volunteerMsg: state.chat.volunteerMsg,
        chatList_particularChannel: state.chat.chatList_particularChannel,
    }
}


const actionCreators = {
    getList: chatActions.getList,
    resetList: chatActions.resetList,
    createAppointment: appoinmentActions.createAppointment,
    resetAppointment: appoinmentActions.resetAppointment,
    getParticular_ChannelList: chatActions.getParticular_ChannelList,
    resetParticular_ChannelList: chatActions.resetParticular_ChannelList,
    reset_volunteer_Accept_Channel: chatActions.reset_volunteer_Accept_Channel,
    reset_getMessage_Listen: chatActions.reset_getMessage_Listen
}

const connectedWebChat = connect(mapStateToProps, actionCreators)(WebChat);
export { connectedWebChat as WebChat };