import {config} from '../config';
import queryString from "query-string"
import {authHeader,handleError,history} from '../helpers'
export const userService = {
    getList,
    getVolunteerTask,
    save,
    login,
    getAdmins,
    delete:_delete,
    getUserTypes,
    getUser,
    forgotPassword,
    otp,
    pwd,
    submitEnquiryForm,
    counsellingServiceForm,
    // downloadForm,
    logout,
}

function getList(obj){ //Get userList
    const requestOptions = {
        method : "GET",
        headers : authHeader()
    }
let params = queryString.stringify(obj)
    return fetch(`${config.apiUrl}/users/list?`+params,requestOptions).then(handleResponse,handleError)

}
function getUserTypes(){
    const requestOptions = {
        method:'GET',
    }
    return fetch(`${config.apiUrl}/users/userTypes`,requestOptions).then(handleResponse,handleError)
}
function getUser(obj){
    const requestOptions = {
        method:'GET',
        headers:authHeader()
    }
    let params = queryString.stringify(obj)
    return fetch(`${config.apiUrl}/users/getUser?`+params,requestOptions).then(handleResponse,handleError)
}

function getVolunteerTask(obj){
    const requestOptions = {
        method:'GET',
        headers:authHeader()
    }
    let params = queryString.stringify(obj)
    return fetch(`${config.apiUrl}/tasks/getClockDetails?`+params,requestOptions).then(handleResponse,handleError)
}

function getAdmins(){
    const requestOptions = {
        method : 'GET',
        headers : authHeader()
    }
    return fetch(`${config.apiUrl}/users/getAdminRoles`,requestOptions).then(handleResponse,handleError)
}
function save(obj){ //Post or Put
    const requestOptions = {
        method:'POST',
        body : obj
    }
    return fetch(`${config.apiUrl}/users/create`,requestOptions).then(handleResponse,handleError)

}
function login(obj){
    const requestOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json','accept' : 'application/json'},
        body: obj
    }
    return fetch(`${config.apiUrl}/auth/login`,requestOptions).then(handleResponse,handleError).then(user=>{
        return user
    })
}
function logout(){
    localStorage.removeItem('user')
}
function _delete(obj){
    const requestOptions = {
        method: "DELETE",
        headers:authHeader()
    }
    let params = queryString.stringify(obj)
    return fetch(`${config.apiUrl}/users/deleteUser?`+params,requestOptions).then(handleResponse,handleError)
}
function forgotPassword(obj){
    const requestOptions = {
        method:'POST',
        headers: { 'Content-Type': 'application/json','accept' : 'application/json'},
        body : obj
    }
    return fetch(`${config.apiUrl}/auth/forgotPassword`,requestOptions).then(handleResponse).then(user => {
        return user
    })
}
function otp(obj){
    const requestOptions = {
        method:'POST',
        headers: { 'Content-Type': 'application/json','accept' : 'application/json'},
        body:obj
    }
    return fetch(`${config.apiUrl}/auth/verifyOtp`,requestOptions).then(handleResponse).then(user=>{
        return user
    })
}
function pwd(obj){
    const requestOptions = {
        method:'POST',
        headers: { 'Content-Type': 'application/json','accept' : 'application/json'},
        body : obj
    }
    return fetch(`${config.apiUrl}/auth/resetPassword`,requestOptions).then(handleResponse).then(user=>{
        return user
    })
}


    function submitEnquiryForm(obj){
        const requestOptions = {
            method:'POST',
            headers : {'Content-Type': 'application/json','accept' : 'application/json',...authHeader()},
            body : JSON.stringify(obj)
        }
        return fetch(`${config.apiUrl}/shared/createSupport`,requestOptions).then(handleResponse,handleError)    
    }

    function counsellingServiceForm(obj){
        const requestOptions = {
            method:'POST',
            headers : {'Content-Type': 'application/json','accept' : 'application/json',...authHeader()},
            body : JSON.stringify(obj)
        }
        return fetch(`${config.apiUrl}/appointments/create`,requestOptions).then(handleResponse,handleError)    
    }
    // function downloadForm(obj){
    //     const requestOptions = {
    //         method:'GET',
    //         headers : {'Content-Type': 'application/json','accept' : 'application/json',...authHeader()},
    //         // body : JSON.stringify(obj)
    //     }
    //     let params = queryString.stringify(obj)
    //     return fetch(`${config.apiUrl}/form/downloadForm?`+params,requestOptions).then(handleResponse,handleError)
    // }

function handleResponse(response) {
    console.log("res",response)
	return response.text().then(text => {
        const data = text && JSON.parse(text);
        console.log("date",data)
		if (!response.ok) {
			if (response.status === 401) {
				// logout();
            }
            
            if(response.status === 503){
				history.push('/maintenancePage')
			}
            const error = (data && data.errors && data.errors[0].message) || (data && data.message) || (data.msg) || response.statusText;
          
			return Promise.reject(error);
		}
		return data;
	});
}
