import {config} from '../config';
import queryString from "query-string"
import {authHeader,handleError,history} from '../helpers'

export const appService = {
	save,
    getList,
    getForm,
    getReportList,
    getdashboardList,
    delete:_delete,
    getAssignedForm,
    delete_AssignForm,
    save_AssignForm
}

function save(obj){
    const requestOptions = {
        method:'POST',
        body:obj,
        headers:{'Content-Type': 'application/json','accept' : 'application/json',...authHeader()},
    }
    return fetch(`${config.apiUrl}/shared/createAppSettings`,requestOptions).then(handleResponse,handleError)
}
function getList(obj){
	const requestOptions = {
		method:'GET',
		headers:{'Content-Type': 'application/json','accept' : 'application/json',...authHeader()},
	}
	let params = queryString.stringify(obj)
	return fetch(`${config.apiUrl}/shared/list?`+params,requestOptions).then(handleResponse,handleError)
}
function getForm(obj){
    const requestOptions = {
        method : 'GET',
        headers : {'Content-Type': 'application/json','accept' : 'application/json',...authHeader()}
    }
    let params = queryString.stringify(obj)
    return fetch(`${config.apiUrl}/form/list?`+params,requestOptions).then(handleResponse,handleError)
}

function getAssignedForm(obj){
    const requestOptions = {
        method : 'GET',
        headers : {'Content-Type': 'application/json','accept' : 'application/json',...authHeader()}
    }
    let params = queryString.stringify(obj)
    return fetch(`${config.apiUrl}/formDetails/list?`+params,requestOptions).then(handleResponse,handleError)
}

function save_AssignForm(obj){
    const requestOptions = {
        method:'POST',
        body:obj,
        headers:{'Content-Type': 'application/json','accept' : 'application/json',...authHeader()},
    }
    return fetch(`${config.apiUrl}/formDetails/create`,requestOptions).then(handleResponse,handleError)
}

function getReportList(obj){
    const requestOptions = {
        method:'GET',
        headers:{'Content-Type': 'application/json','accept' : 'application/json',...authHeader()},
    }
    let params = queryString.stringify(obj)
    return fetch(`${config.apiUrl}/shared/list?`+params,requestOptions).then(handleResponse,handleError)
}
function getdashboardList(obj){
    const requestOptions = {
        method: 'GET',
        headers : {'Content-Type': 'application/json','accept' : 'application/json',...authHeader()},
    }
    let params = queryString.stringify(obj)
    return fetch(`${config.apiUrl}/shared/dashboard`+params,requestOptions).then(handleResponse,handleError)

}
function _delete(obj){
    const requestOptions = {
        method: "DELETE",
        headers:authHeader()
    }
    let params = queryString.stringify(obj)
    return fetch(`${config.apiUrl}/shared/deleteAppSettings?`+params,requestOptions).then(handleResponse,handleError)
}
function delete_AssignForm(obj){
    const requestOptions = {
        method: "DELETE",
        headers:authHeader()
    }
    let params = queryString.stringify(obj)
    return fetch(`${config.apiUrl}/formDetails/remove?`+params,requestOptions).then(handleResponse,handleError)
}
function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				// logout();
            }
            
            if(response.status === 503){
				history.push('/maintenancePage')
			}
			const error = (data && data.message && data.message.errors && data.message.errors[0] && data.message.errors[0].message) || (data.msg) || response.statusText;
			return Promise.reject(error);
		}
		return data;
	});
}
