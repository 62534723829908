import {config} from '../config';
import queryString from "query-string"
import {authHeader,handleError,history} from '../helpers'
 
export const emailService = {
	save,
	getList,
	delete:_delete,
}

function save(obj){
    const requestOptions = {
        method:'POST',
        body:obj,
        headers:{'Content-Type': 'application/json','accept' : 'application/json',...authHeader()},
    }
    return fetch(`${config.apiUrl}/template/createTemplate`,requestOptions).then(handleResponse,handleError)
}
function getList(obj){
	const requestOptions = {
		method:'GET',
		headers:{'Content-Type': 'application/json','accept' : 'application/json',...authHeader()},
	}
	let params = queryString.stringify(obj)
	return fetch(`${config.apiUrl}/template/list?`+params,requestOptions).then(handleResponse,handleError)
}
function _delete(obj){
    const requestOptions = {
        method: "DELETE",
        headers:authHeader()
    }
    let params = queryString.stringify(obj)
    return fetch(`${config.apiUrl}/template/deleteTemplate?`+params,requestOptions).then(handleResponse,handleError)
}
function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				// logout();
			}

			if(response.status === 503){
				history.push('/maintenancePage')
			}
			const error = (data && data.message) || (data.msg) || response.statusText;
			return Promise.reject(error);
		}
		return data;
	});
}
