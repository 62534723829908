import React from 'react';

import ReactPaginate from 'react-paginate';
export function ReactPagination(props) {
  return (
    <div className="page-wrap">
      {props.PageDetails.totalCount != 0 && <label className="label-title"> Total Number of Records : {props.PageDetails.totalCount}</label>}
      <ReactPaginate previousLabel={"<"}
        nextLabel={">"}
        breakLabel={<a>...</a>}
        breakClassName={"break-me"}
        pageCount={props.PageDetails.pageCount}
        marginPagesDisplayed={2}
        onPageChange={props.PageDetails.onPageChange}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        forcePage={props.PageDetails.activePage}
      />


    </div >
  )
}
