import React from 'react';
import { connect } from 'react-redux';
import HistImg from '../../assets/img/img_hstry.png';
import AbtBanner from '../../assets/img/img_abtbanr.png';
import AboutUsBanner from '../../assets/img/Banner/AboutUs/AboutUsBanner.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {history} from '../../../helpers'
import VisionImg from '../../assets/img/Vision.png';
import MissionImg from '../../assets/img/Mission.png';
import HMSImg from '../../assets/img/Banner/OurWork/HMS.jpg';
import CENTRE_BANNER from '../../assets/img/NewBanner/AboutOurCentre.png';
import CVImg from '../../assets/img/CoreValues.png'

 class OurHistory extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
        }
    }
    goToHome = () => {
        history.push('/')
    }
        render(){
            const responsive = {
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                }
              };  
            return(
            <div className="hist-wrap">
                <nav class="breadcrumb">
                    <div className="container d-flex">
                        <a class="breadcrumb-item" onClick={this.goToHome}><i className="fas fa-home"></i></a>
                        {/* <a class="breadcrumb-item" href="#">About</a> */}
                        <span class="breadcrumb-item active">{window.strings.HEADER.ABOUT}</span>
                        <span class="breadcrumb-item active">{window.strings.HEADER.OUR_HISTORY}</span>
                    </div>
                </nav>
                <Carousel className="hist-slide"
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        autoPlay={false}
                        arrows={false}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        customTransition="all 1.2s ease 0s"
                        transitionDuration={1000}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        deviceType={this.props.deviceType}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                    >
                        <div className="abt-banr">
                            <img src={CENTRE_BANNER}/>
                        </div>
                        {/* <div className="abt-banr">
                            <img src={AbtBanner}/>
                        </div>
                        <div className="abt-banr">
                            <img src={AbtBanner}/>
                        </div> */}
                    </Carousel>
                <div>
                    <div className="spad">
                        <div className="container">
                            <div className="hist-box">
                                <h4>{window.strings.ABOUT.OUR_HISTORY}</h4>
                                <p>{window.strings.ABOUT.OUR_HOSTORY_ESTABLISHED_IN_1991}</p>
                                <p>{window.strings.ABOUT.OUR_HISTORY_IN_2005_SAGE_COUNSELLING_CENTRE}</p>
                                <p>{window.strings.ABOUT.OUR_HISTORY_SINCE_2008_THE_CENTRE_HAS_DEVELOPED}</p> 
                            </div>
                        </div>
                        <div className="mission-vision">
                            <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <img src={VisionImg}/>
                                    <h4>{window.strings.ABOUT.OUR_VISION}</h4>
                                    <p>{window.strings.ABOUT.OUR_VISION_STORY}</p>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <img src={MissionImg}/>
                                    <h4>{window.strings.ABOUT.OUR_MISSION}</h4>
                                    <p>{window.strings.ABOUT.OUR_MISSION_STORY}</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="value-box">
                                <div className="row">
                                    <div className="col-lg-9 col-12 col-md-8">
                                        <img src={CVImg} className="rounded" />
                                    </div>
                                    <div className="col-lg-3 col-12 col-md-4">
                                        <h4>{window.strings.ABOUT.OUR_VALUES}</h4>
                                        <ul className="value-list color-text">
                                            <li>{window.strings.ABOUT.OUR_VALUES_SELFLESS_IN_SERVICE_CLR}</li>
                                            <li>{window.strings.ABOUT.ACCEPTANCE_CLR}</li>
                                            <li>{window.strings.ABOUT.GENUINENESS_CLR}</li>
                                            <li>{window.strings.ABOUT.EXCELLENCE_CLR}</li>
                                            <li>{window.strings.ABOUT.COMMITMENT_CLR}</li>
                                            <li>{window.strings.ABOUT.COMPASSION_CLR}</li>
                                        </ul>
                                        <ul className="value-list">
                                            <li>{window.strings.ABOUT.OUR_VALUES_SELFLESS_IN_SERVICE}</li>
                                            <li>{window.strings.ABOUT.ACCEPTANCE}</li>
                                            <li>{window.strings.ABOUT.GENUINENESS}</li>
                                            <li>{window.strings.ABOUT.EXCELLENCE}</li>
                                            <li>{window.strings.ABOUT.COMMITMENT}</li>
                                            <li>{window.strings.ABOUT.COMPASSION}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="commit-box">
                                <h4>{window.strings.ABOUT.OUR_COMMITMENT}</h4>
                                <p>{window.strings.ABOUT.SAGE_COUNSELLING_CENTRE_IS_COMMITTED}:</p>
                                <ul className="commit-list">
                                    <li>{window.strings.ABOUT.OUR_COMMITMENT_I}</li>
                                    <li>{window.strings.ABOUT.OUR_COMMITMENT_II}</li>
                                    <li>{window.strings.ABOUT.OUR_COMMITMENT_III}</li>
                                    <li>{window.strings.ABOUT.OUR_COMMITMENT_IV}</li>
                                    <li>{window.strings.ABOUT.OUR_COMMITMENT_V}</li>
                                </ul>
                            </div>
                        </div>

                        {/* <div className="row m-0">
                            <div className="col-lg-7">
                                <h3>{window.strings.ABOUT.ABOUT}</h3>
                                <h4>{window.strings.ABOUT.OUR_VISION}</h4>
                                <p>{window.strings.ABOUT.OUR_VISION_STORY}</p>
                                <h4>{window.strings.ABOUT.OUR_MISSION}</h4>
                                <p>{window.strings.ABOUT.OUR_MISSION_STORY}</p>
                                <h4>{window.strings.ABOUT.OUR_HISTORY}</h4>
                                <p>{window.strings.ABOUT.OUR_HOSTORY_ESTABLISHED_IN_1991}
                                </p>
                                <p>{window.strings.ABOUT.OUR_HISTORY_IN_2005_SAGE_COUNSELLING_CENTRE}</p>
                                    <p>{window.strings.ABOUT.OUR_HISTORY_SINCE_2008_THE_CENTRE_HAS_DEVELOPED}</p>
                            </div>
                            <div className="col-lg-5">
                                <img src={HistImg} className="hist-img" />
                            </div>
                        </div> */}
                      
                        {/* <h4>Our Philosophy of Service</h4>
                        <p>To provide gero-counselling for the elderly and their caregivers with professionalism, respect, concern, commitment, and competence.</p> */}
                        {/* <h4>{window.strings.ABOUT.OUR_VALUES}</h4>
                        <ul className="value-list color-text">
                            <li>{window.strings.ABOUT.OUR_VALUES_SELFLESS_IN_SERVICE_CLR}</li>
                            <li>{window.strings.ABOUT.ACCEPTANCE_CLR}</li>
                            <li>{window.strings.ABOUT.GENUINENESS_CLR}</li>
                            <li>{window.strings.ABOUT.EXCELLENCE_CLR}</li>
                            <li>{window.strings.ABOUT.COMMITMENT_CLR}</li>
                            <li>{window.strings.ABOUT.COMPASSION_CLR}</li>
                        </ul>
                        <ul className="value-list">
                            <li>{window.strings.ABOUT.OUR_VALUES_SELFLESS_IN_SERVICE}</li>
                            <li>{window.strings.ABOUT.ACCEPTANCE}</li>
                            <li>{window.strings.ABOUT.GENUINENESS}</li>
                            <li>{window.strings.ABOUT.EXCELLENCE}</li>
                            <li>{window.strings.ABOUT.COMMITMENT}</li>
                            <li>{window.strings.ABOUT.COMPASSION}</li>
                        </ul>
                       <h4>{window.strings.ABOUT.OUR_COMMITMENT}</h4>
                                <p>{window.strings.ABOUT.SAGE_COUNSELLING_CENTRE_IS_COMMITTED}:</p>
                                <ul className="commit-list">
                                    <li>{window.strings.ABOUT.OUR_COMMITMENT_I}</li>
                                    <li>{window.strings.ABOUT.OUR_COMMITMENT_II}</li>
                                    <li>{window.strings.ABOUT.OUR_COMMITMENT_III}</li>
                                    <li>{window.strings.ABOUT.OUR_COMMITMENT_IV}</li>
                                    <li>{window.strings.ABOUT.OUR_COMMITMENT_V}</li>
                                </ul>
                         */}
                                    
                        {/* <h4>Our Funders</h4>
                        <p>SAGE Counselling Centre is jointly funded by the Ministry of Health and National Council of Social Service via Community Chest</p>
                        <h4>Our Multi-level Service Directions</h4> */}
                        {/* <ul className="multi-list">
                            <li> Clinical level: Intervention / Remedial work</li>
                            <li>Provide individual & group counselling, expressive therapy, case management, and referral</li>
                            <li>Preventive level:</li>
                            <li>Conduct public educational talks, workshops, seminars in community facilities to create self-awareness which in turn promote self-care and mental wellness of seniors</li>
                            <li>Community level</li>
                            <li>Participate in community & nation-wide activities & collaborate with other social service sector partners which work with older generation.</li>
                        </ul> */}
                    </div>
                </div>
            </div>
            )            
        }}

const mapStateToProps = state => {
    return {}
}


const actionCreators = { }

const connectedOurHistory = connect(mapStateToProps, actionCreators)(OurHistory);
export { connectedOurHistory as OurHistory };