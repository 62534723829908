import {config} from '../config';
import queryString from "query-string"
import {authHeader,handleError,history} from '../helpers'
export const taskService = {
    getTask,
    saveTask,
    delete:_delete,
    updateStatus
   
}

function getTask(obj){ //Get TaskList
    const requestOptions = {
        method : "GET",
        headers : authHeader()
    }
let params = queryString.stringify(obj)
    return fetch(`${config.apiUrl}/tasks/list?`+params,requestOptions).then(handleResponse,handleError)

}

function saveTask(obj){ //Post or Put Task
    const requestOptions = {
        method:'POST',
        headers:authHeader(),
        body : obj
    }
    return fetch(`${config.apiUrl}/tasks/create`,requestOptions).then(handleResponse,handleError)

}
function updateStatus(obj){
    const requestOptions = {
        method:'POST',
        headers: {'Content-Type': 'application/json','accept' : 'application/json',...authHeader()},
        body: obj
    }
    return fetch(`${config.apiUrl}/tasks/updateStatus`,requestOptions).then(handleResponse,handleError)
}
function _delete(obj){
    const requestOptions = {
        method: "DELETE",
        headers:authHeader()
    }
    let params = queryString.stringify(obj)
    return fetch(`${config.apiUrl}/tasks/deleteTask?`+params,requestOptions).then(handleResponse,handleError)
}

function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				// logout();
            }
            
            if(response.status === 503){
				history.push('/maintenancePage')
			}
			const error = (data && data.message) || (data && data.errors && data.errors[0].message) || (data.msg) || response.statusText;
			return Promise.reject(error);
		}
		return data;
	});
}
