import React from 'react';
import { connect } from 'react-redux';
import HistImg from '../../assets/img/img_hstry.png';
import AbtBanner from '../../assets/img/img_abtbanr.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {taskActions} from '../../../store/actions'
import {config} from '../../../config'

import Art_Ambassador from '../../assets/img/Banner/Volunteer/Art_Ambassador.JPG';
import CA from '../../assets/img/Banner/Volunteer/CounsellingAssociate.png';
import HMS2 from '../../assets/img/Banner/Volunteer/HMS2.jpeg';
import RW_Ambassadors from '../../assets/img/Banner/Volunteer/fDesk.jpg';
import SHL from '../../assets/img/Banner/Volunteer/SHL.png';
import Specialist_Volunteers from '../../assets/img/Banner/Volunteer/Specialist_Volunteers.jpeg';

// import SH from '../../assets/img/VolOppo/SeniorHelpNew.jpg';
import SV  from '../../assets/img/VolOppo/SplVolNew.jpg';
import RWA from '../../assets/img/VolOppo/RwaNew.jpg';
import AMA from '../../assets/img/VolOppo/ArtMusicAmbassNew.jpg';
import HMS from '../../assets/img/VolOppo/HmsNew.jpg';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Select from 'react-select';
import {toastr} from '../../../services'
import SH from '../../assets/img/Banner/OurWork/SHL.png';
import VOLUNTEER_BANNER from '../../assets/img/NewBanner/Volunteer.png';
import FDV from '../../assets/img/Banner/Volunteer/FDVoln.jpg';
import ADV from '../../assets/img/Banner/Volunteer/Adhoc.jpg';

import {history} from '../../../helpers'

 class VolunteerOpportunities extends React.Component { 
	constructor(props) { 
		super(props);
		this.state = {
            name:'',
            email:'',
            address:'',
            mobile:'',
            volunteerOptions:[]
        }
    }
   
    componentWillReceiveProps(newProps){
        if(newProps && newProps.taskList && newProps.taskList.data){
            let data = newProps.taskList && newProps.taskList.data && newProps.taskList.data.rows
            let request = newProps.taskList && newProps.taskList.request ? newProps.taskList.request : []
            let totalCount = newProps.taskList && newProps.taskList.data && newProps.taskList.data.count ? newProps.taskList.data.count : []

            this.setState({taskList:data,totalCount,pageCount:totalCount/request.limit})
            this.props.resetGetTask()
        }
        let volunteerOptions = [];
    
        if(sessionStorage.getItem("translation") == "Chinees"){
            this.state.listCategory && this.state.listCategory.map(item=>{
                let obj = {"label":item.chineseTitle,"value":item.id}
                volunteerOptions.push(obj)
            })
            this.setState({volunteerOptions:volunteerOptions})
    
        }else{
            this.state.listCategory && this.state.listCategory.map(item=>{
                let obj = {"label":item.title,"value":item.id}
                volunteerOptions.push(obj)
            }) 
            this.setState({volunteerOptions:volunteerOptions})
        }
        // if(newProps && newProps.deleteStatus && newProps.deleteStatus == true){
        //     this.props.resetDelete()
        //     this.getList()
        // }
    }
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
        if (e.target.name == "email") {
       this.validate(this.state.email)
    }
   }
         
      passCheck = (value) => {
          const passCheck = /^(?=.*[A-Z])/;
          if(passCheck.test(value) && ((value && value.length) == 8)){
              return true
          }else{
              return false
          }
      }
     
      handleRole = (e) => {
        let roles = this.state.volunteerOptions
        roles && roles.map(role => {
            if(role.value == e.target.value){
              role.isChecked = e.target.checked
              
            }
        })
       
        this.setState({volunteerOptions:roles})
    
       }
      async componentDidMount() {
          console.log("ENTERS");
          // GET request using fetch with async/await `${config.apiUrl}/category/create`
          const response = await fetch(`${config.apiUrl}/category/list?formType=1`);
          const data = await response.json();
          this.setState({listCategory:data.data.rows})
          console.log(data.data.rows,"DATAAAAAAAAAAAA");
          let volunteerOptions = [];
          if(sessionStorage.getItem("translation") == "Chinees"){
              this.state.listCategory && this.state.listCategory.map(item=>{
                  let obj = {"label":item.chineseTitle,"value":item.id}
                  volunteerOptions.push(obj)
              })
              this.setState({volunteerOptions:volunteerOptions})
      
          }else{
              this.state.listCategory && this.state.listCategory.map(item=>{
                  let obj = {"label":item.title,"value":item.id}
                  volunteerOptions.push(obj)
              }) 
              this.setState({volunteerOptions:volunteerOptions})
          }
          // this.setState({ totalReactPackages: data.total })
     }
      validate = (value) => {
        var emailRegex = /^([a-z])+([a-z0-9_\.\-])+\@(([a-z\-])+\.)+([a-z]{2,3})$/;
        if (emailRegex.test(value)) {
          return true
        } else {
          return false;
        }
        }
        handleVolunteerChange = (data) => {      
          this.setState({categoryObj:data})    
      }
    handleSubmit = (e) => {
        e.preventDefault()
        const {name,email,mobile,address,categoryObj,volunteerOptions} = this.state;
        let selected = []
        volunteerOptions && volunteerOptions.map(role => {
            if(role.isChecked){
                selected.push(role)
            }
        })
        this.setState({submitted:true})
        if(name && email && this.validate(email) && mobile && mobile.length == 8 && address && selected.length > 0){
            console.log('STATE',this.state);
            let obj ={
                'name':name,
                'email':email,
                'mobile':mobile,
                'address':address,
            }
            let categoryId = []
               selected && selected.map(item => {
                   categoryId.push(item.label.toString())
               });
           
            obj['category'] = categoryId.toString();;
             (async () => {               
                            const rawResponse = await fetch(`${config.apiUrl}/form/mailVolunteerDetails`, {
                                method: 'POST',
                                headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(obj)
                            });
                            const content = await rawResponse.json();
                            console.log(content);
                            if(content.status == true)
                            {
                              
                                toastr.success('Thank you')
                                this.setState({name:'',email:'',mobile:'',address:'',categoryObj:'',submitted:false,volunteerOptions:[] });
                                let volunteerOptions = [];
                                if(sessionStorage.getItem("translation") == "Chinees"){
                                    this.state.listCategory && this.state.listCategory.map(item=>{
                                        let obj = {"label":item.chineseTitle,"value":item.id}
                                        volunteerOptions.push(obj)
                                    })
                                    this.setState({volunteerOptions:volunteerOptions})
                            
                                }else{
                                    this.state.listCategory && this.state.listCategory.map(item=>{
                                        let obj = {"label":item.title,"value":item.id}
                                        volunteerOptions.push(obj)
                                    }) 
                                    this.setState({volunteerOptions:volunteerOptions})
                                }
                            }
              })();
          
        }
        else{

           toastr.error('Mandatory fields are missing')
        }
       
    }
    goToHome = () => {
        history.push('/')
    }
        render(){
            // let volunteerOptions = [];
            // if(sessionStorage.getItem("translation") == "Chinees"){
            //     this.state.listCategory && this.state.listCategory.map(item=>{
            //         let obj = {"label":item.chineseTitle,"value":item.id}
            //         volunteerOptions.push(obj)
            //     })
            // }else{
            //     this.state.listCategory && this.state.listCategory.map(item=>{
            //         let obj = {"label":item.title,"value":item.id}
            //         volunteerOptions.push(obj)
            //     })
            // }
           
            let taskList = this.state.taskList && this.state.taskList.map((item,index)=>{
                if(item){
                    return   <div className="col-lg-4">
                            <div className="card">
                                <div className="oppo-box">
                                    <img src={config.apiUrl + '/uploads/tasks/' + item.imageUrl} />
                                    <span>
                                        <h5>{item.title}</h5>
                                        <h6>{item.Category && item.Category.title}</h6>
                                        <p>{item.description && item.description}</p>
                                         <button className="btn info-btn">Volunteer Now</button>
                                    </span>
                               
                        </div>  
                   </div>
                   
                </div>
                }
            })
            const responsive = {
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 1,
                  slidesToSlide: 2 // optional, default to 1.
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                }
              };  
            return(
              <div className="oppo-wrap">
              <nav class="breadcrumb">
                <div className="container d-flex">
                    <a class="breadcrumb-item" onClick={this.goToHome}><i className="fas fa-home"></i></a>
                    {/* <a class="breadcrumb-item" href="#">Volunteer</a> */}
                    {/* <span class="breadcrumb-item active">Volunteer</span>
                    <span class="breadcrumb-item active">Volunteer Opportunities</span> */}

                    <span className="breadcrumb-item active">{window.strings.HEADER.VOLUNTEER}</span>
                        <span className="breadcrumb-item active">{window.strings.HEADER.VOLUNTEER_OPPORTUNITIES}</span>
                    
                </div>
              </nav>
              <Carousel className="oppo-slide"
                      swipeable={false}
                      draggable={false}
                      showDots={false}
                      responsive={responsive}
                      ssr={true} // means to render carousel on server-side.
                      infinite={true}                     
                      autoPlay={false}
                      arrows={false}
                    //   autoPlay={this.props.deviceType !== "mobile" ? true : false}
                      autoPlaySpeed={3000}
                      keyBoardControl={true}
                      customTransition="all 1.2s ease 0s"
                      transitionDuration={1000}
                      containerClass="carousel-container"
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                      deviceType={this.props.deviceType}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                  >
                      <div className="vol-banr">
                          <img src={VOLUNTEER_BANNER}/>
                      </div>

                     {/* <div className="vol-banr">
                          <img src={Art_Ambassador}/>
                      </div>
                      <div className="vol-banr">
                          <img src={CA}/>
                      </div>
                      <div className="vol-banr">
                          <img src={HMS2}/>
                      </div>

                      <div className="vol-banr">
                          <img src={RW_Ambassadors}/>
                      </div>
                      <div className="vol-banr">
                          <img src={SHL}/>
                      </div>
                      <div className="vol-banr">
                          <img src={Specialist_Volunteers}/>
                      </div> */}
                  </Carousel>
                  <div className="vol-oppo-sec">
                      <div className="spad">
                        <div className="container">
                            <div className="vol-sec">
                                <h3>{window.strings.VOLUNTEER.VOLUNTEER}</h3>
                                <p>{window.strings.VOLUNTEER.VOLUNTEER_CONTENT_ONE}</p>
                                <p>{window.strings.VOLUNTEER.VOLUNTEER_CONTENT_TWO}</p>
                            </div>
                        </div>
                        <div className="oppo-sec">
                            <div className="container">
                              <h4>{window.strings.VOLUNTEER.VOLUNTEER_OPPORTUNITIES}</h4>
                              <p>{window.strings.VOLUNTEER.VOLUNTEER_OPPORTUNITIES_CONTENT}</p>
                              {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                  when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                  when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                              </p> */}
                            </div>
                        </div>
                        <div className="vol-tab">
                                <Tabs>
                                <div className="container-fluid">
                                    <TabList  className="row react-tabs__tab-list">
                                        <Tab className="col-md-3 col-lg-3 react-tabs__tab"><h5>{window.strings.VOLUNTEER.SENIORS_HELPLINERS}</h5></Tab>
                                        <Tab className="col-md-3 col-lg-3 react-tabs__tab"><h5>{window.strings.VOLUNTEER.COUNSELLING_ASSOCIATES}</h5></Tab>
                                        <Tab className="col-md-3 col-lg-3 react-tabs__tab"><h5>{window.strings.VOLUNTEER.SPECIALIST_VOLUNTEERS}</h5></Tab>
                                        <Tab className="col-md-3 col-lg-3 react-tabs__tab"><h5>{window.strings.VOLUNTEER.REMINISCENCE_WALK_AMBASSADORS}</h5></Tab>
                                        <Tab className="col-md-3 col-lg-3 react-tabs__tab"><h5>{window.strings.VOLUNTEER.MUSIC_AMBASSADORS}</h5></Tab>
                                        <Tab className="col-md-3 col-lg-3 react-tabs__tab"><h5>{window.strings.VOLUNTEER.HMS_VOLUNTEERS}</h5></Tab>
                                        <Tab className="col-md-3 col-lg-3 react-tabs__tab"><h5>{window.strings.VOLUNTEER.FRONT_DESK_VOLUNTEERS}</h5></Tab>
                                        <Tab className="col-md-3 col-lg-3 react-tabs__tab"><h5>{window.strings.VOLUNTEER.AD_HOC_EVENTS_VOLUNTEERS}</h5></Tab>
                                    </TabList>
                                </div>
                                <div className="container">
                                    <TabPanel>
                                        <div className="shl-tab">
                                            <div className="row">
                                                <div className="col-lg-4 col-12">
                                                    <img src={SH} className="rounded" />
                                                </div>
                                                <div className="col-lg-8 col-12">
                                                    <h4>{window.strings.VOLUNTEER.SENIORS_HELPLINERS}</h4>
                                                    <p>{window.strings.VOLUNTEER.SENIORS_HELPLINERS_STORY_ONE}</p>
                                                    <p>{window.strings.VOLUNTEER.SENIORS_HELPLINERS_STORY_TWO}</p>
                                                    <p>{window.strings.VOLUNTEER.SENIORS_HELPLINERS_STORY_THREE}</p>
                                                    <p className="fnt-wt">{window.strings.VOLUNTEER.SENIORS_HELPLINERS_STORY_FOUR}</p>
                                                    <p>{window.strings.VOLUNTEER.SENIORS_HELPLINERS_STORY_FIVE}</p>
                                                    <p>{window.strings.VOLUNTEER.SENIORS_HELPLINERS_STORY_SIX}</p>
                                                    <p>{window.strings.VOLUNTEER.SENIORS_HELPLINERS_STORY_SEVEN}</p>
                                                    <p className="fnt-wt">{window.strings.VOLUNTEER.SENIORS_HELPLINERS_STORY_EIGHT}</p>
                                                    <p>{window.strings.VOLUNTEER.SENIORS_HELPLINERS_STORY_NINE}</p>
                                                    <p>{window.strings.VOLUNTEER.SENIORS_HELPLINERS_STORY_TEN}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="coun-tab">
                                            <div className="row">
                                                <div className="col-lg-4 col-12">
                                                    <img src={CA} className="rounded" />
                                                </div>
                                                <div className="col-lg-8 col-12">
                                                    <h4>{window.strings.VOLUNTEER.COUNSELLING_ASSOCIATES}</h4>
                                                    <p>{window.strings.VOLUNTEER.COUNSELLING_ASSOCIATES_STORY_ONE}</p>
                                                    <p>{window.strings.VOLUNTEER.COUNSELLING_ASSOCIATES_STORY_TWO}</p>
                                                    <p className="fnt-wt">{window.strings.VOLUNTEER.COUNSELLING_ASSOCIATES_STORY_THREE}</p>
                                                    <p>{window.strings.VOLUNTEER.COUNSELLING_ASSOCIATES_STORY_FOUR}</p>
                                                    <p>{window.strings.VOLUNTEER.COUNSELLING_ASSOCIATES_STORY_FOUR_HAF}</p>
                                                    <p>{window.strings.VOLUNTEER.COUNSELLING_ASSOCIATES_STORY_FIVE}</p>
                                                    <p className="fnt-wt">{window.strings.VOLUNTEER.COUNSELLING_ASSOCIATES_STORY_SIX}</p>
                                                    <p>{window.strings.VOLUNTEER.COUNSELLING_ASSOCIATES_STORY_SEVEN}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                    <div className="sv-tab">
                                            <div className="row">
                                                <div className="col-lg-4 col-12">
                                                    <img src={Specialist_Volunteers} className="rounded" />
                                                </div>
                                                <div className="col-lg-8 col-12">
                                                    <h4>{window.strings.VOLUNTEER.SPECIALIST_VOLUNTEERS}</h4>
                                                    <p className="wrd-wrp">{window.strings.VOLUNTEER.SPECIALIST_VOLUNTEERS_STORY}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                    <div className="rwa-tab">
                                            <div className="row">
                                                <div className="col-lg-4 col-12">
                                                    <img src={RW_Ambassadors} className="rounded" />
                                                </div>
                                                <div className="col-lg-8 col-12">
                                                    <h4>{window.strings.VOLUNTEER.REMINISCENCE_WALK_AMBASSADORS}</h4>
                                                    <p>{window.strings.VOLUNTEER.REMINISCENCE_WALK_AMBASSADORS_STORY}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                    <div className="art-tab">
                                            <div className="row">
                                                <div className="col-lg-4 col-12">
                                                    <img src={Art_Ambassador} className="rounded" />
                                                </div>
                                                <div className="col-lg-8 col-12">
                                                    <h4>{window.strings.VOLUNTEER.MUSIC_AMBASSADORS}</h4>
                                                    <p className="fnt-wt">{window.strings.VOLUNTEER.ART_AMBASSADORS}</p>
                                                    <p>{window.strings.VOLUNTEER.ART_AMBASSADORS_STORY}</p>
                                                    <p className="fnt-wt">{window.strings.VOLUNTEER.MUS_AMBASSADORS}</p>
                                                    <p>{window.strings.VOLUNTEER.MUSIC_AMBASSADORS_STORY}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                    <div className="hms-tab">
                                            <div className="row">
                                                <div className="col-lg-4 col-12">
                                                    <img src={HMS2} className="rounded" />
                                                </div>
                                                <div className="col-lg-8 col-12">
                                                    <h4>{window.strings.VOLUNTEER.HMS_VOLUNTEERS}</h4>
                                                    <p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY}</p>
                                                    <p className="fnt-wt">{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY_LIST}</p>
                                                    <ul>
                                                        <li><p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY_TRUST}</p></li>
                                                        <li><p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY_TRAVEL}</p></li>
                                                        <li><p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY_LIC}</p></li>
                                                        <li><p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY_TOOL}</p></li>
                                                        <li><p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY_PAY}</p></li>
                                                        <li><p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY_COMFORT}</p></li>
                                                        <li><p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY_CHALLENGE}</p></li>
                                                        <li><p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY_COMMUNI}</p></li>
                                                        <li><p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY_INFO}</p></li>
                                                        <li><p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY_DOC}</p></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                    <div className="desk-tab">
                                            <div className="row">
                                                <div className="col-lg-4 col-12">
                                                    <img src={FDV} className="rounded" />
                                                </div>
                                                <div className="col-lg-8 col-12">
                                                    <h4>{window.strings.VOLUNTEER.FRONT_DESK_VOLUNTEERS}</h4>
                                                    <p>{window.strings.VOLUNTEER.FRONT_DESK_VOLUNTEERS_STORY}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                    <div className="adhoc-tab">
                                            <div className="row">
                                                <div className="col-lg-4 col-12">
                                                    <img src={ADV} className="rounded" />
                                                </div>
                                                <div className="col-lg-8 col-12">
                                                    <h4>{window.strings.VOLUNTEER.AD_HOC_EVENTS_VOLUNTEERS}</h4>
                                                    <p>{window.strings.VOLUNTEER.AD_HOC_EVENTS_VOLUNTEERS_STORY}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </div>
                            </Tabs>
                        </div>

                        <div className="container">
                            <div className="stry-wrap">
                                <div className="card">
                                    <div className="card-header">
                                        <h5>{window.strings.VOLUNTEER.VOL_SIGNUP_FORM}</h5>
                                    </div>
                                    <div className="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>{window.strings.VOLUNTEER.VOL_NAME}</label>&nbsp;<span class="mandatory">*</span>
                                                    <input type="text" className="form-control" name="name" value={this.state.name} onChange={this.handleInputChange} placeholder={window.strings.VOLUNTEER.VOL_NAME} />
                                                    {this.state.submitted && !this.state.name && <div className="mandatory">{window.strings.VOLUNTEER.NAMEENTER}</div>}

                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>{window.strings.VOLUNTEER.VOL_ADDRESS}</label>&nbsp;<span class="mandatory">*</span>
                                                    <input type="text" className="form-control" name="address" value={this.state.address} onChange={this.handleInputChange}  placeholder={window.strings.VOLUNTEER.VOL_ADDRESS} />
                                                    {this.state.submitted && !this.state.address && <div className="mandatory">{window.strings.VOLUNTEER.ADDRESSENTER}</div>}

                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>{window.strings.VOLUNTEER.VOL_PHONE_NUMBER}</label>&nbsp;<span class="mandatory">*</span>
                                                    <input type="number" name="mobile" value={this.state.mobile} onChange={this.handleInputChange} className="form-control" placeholder={window.strings.VOLUNTEER.VOL_PHONE_NUMBER} />
                                                    {this.state.submitted && !this.state.mobile && <div className="mandatory">{window.strings.VOLUNTEER.MOBILEENTER}</div>}
                                                   {this.state.submitted && this.state.mobile && this.state.mobile.length != 8 && <div className="mandatory">{window.strings.VOLUNTEER.MOBILEERROR}</div>}
                                       
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>{window.strings.VOLUNTEER.VOL_EMAIL_ADDRESS}</label>&nbsp;<span class="mandatory">*</span>
                                                    <input type="email" name="email"  value={this.state.email} onChange={this.handleInputChange} className="form-control" placeholder={window.strings.VOLUNTEER.VOL_EMAIL_ADDRESS}  />
                                                    {this.state.submitted && !this.state.email && <div className="mandatory">{window.strings.VOLUNTEER.EMAILENTER}</div>}
                                                    {this.state.submitted && this.state.email && !this.validate(this.state.email) && <div className="mandatory">{window.strings.VOLUNTEER.EMAILERROR}</div>}
                                       
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                            <label >{window.strings.VOLUNTEER.VOL_CAT}&nbsp;<span class="mandatory">*</span></label>
                                                        <div className ="col-sm-8">
                                                             {this.state.volunteerOptions && this.state.volunteerOptions.map((role,index) => (
                                                                 <div>
                                                            <input type="checkbox"  onChange={this.handleRole} checked={role.isChecked == true} value={role.value}/><span className="ml-2">{role.label}</span>
                                                            </div>
                                                            ))}
                                                        </div>   
                                                {/* <div className="form-group">
                                                    <label >{window.strings.VOLUNTEER.VOL_CAT}&nbsp;<span class="mandatory">*</span></label>
                                                        <Select className="state-box"
                                                            styles={{
                                                                control: base => ({
                                                                ...base,
                                                                borderColor: '#ced4da',
                                                                boxShadow: '#FE988D',
                                                                border:'0',
                                                                borderBottom:'1px solid #ced4da',
                                                                borderRadius:'0',
                                                                '&:focus': {
                                                                borderColor: '#ced4da'
                                                                            }
                                                                        }),
                                                                        menu: base => ({
                                                                            ...base,
                                                                            zIndex:"5"
                                                                            }),
                                                                        menuList: base => ({
                                                                            ...base,
                                                                            maxHeight:"140px",
                                                                           })
                                                                    }}
                                                                    
                                                                    value={this.state.categoryObj}
                                                                    onChange={this.handleVolunteerChange}
                                                                    options={volunteerOptions}
                                                                    placeholder={window.strings.VOLUNTEER.VOL_CAT}
                                                                    isMulti
                                                                />   
                                                                {this.state.submitted && !this.state.categoryObj && <div className="mandatory">{window.strings.VOLUNTEER.CATEGORYENTER}</div>}           
                                                    </div> */}
                                                </div>
                                     

  
                                        </div>
                                        <div className="text-left">
                                            <button type="submit" className="gradient-btn">{window.strings.CONTACT.SEND}</button>
                                        </div>
                                    </form>
                                </div>
                                </div>
                            </div>
                        </div>


                    {/* <div className="row m-0">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header"><h5>{window.strings.VOLUNTEER.SENIORS_HELPLINERS}</h5></div>
                                <div className="card-body crd-ht-o">
                                    <img src={SH}/>
                                    <p>{window.strings.VOLUNTEER.SENIORS_HELPLINERS_STORY_ONE}</p>
                                    <p>{window.strings.VOLUNTEER.SENIORS_HELPLINERS_STORY_TWO}</p>
                                    <p>{window.strings.VOLUNTEER.SENIORS_HELPLINERS_STORY_THREE}</p>
                                    <p><u>{window.strings.VOLUNTEER.SENIORS_HELPLINERS_STORY_FOUR}</u></p>
                                    <ul>
                                        <li><p>{window.strings.VOLUNTEER.SENIORS_HELPLINERS_STORY_FIVE}</p></li>
                                        <li><p>{window.strings.VOLUNTEER.SENIORS_HELPLINERS_STORY_SIX}</p></li>
                                        <li><p>{window.strings.VOLUNTEER.SENIORS_HELPLINERS_STORY_SEVEN}</p></li>
                                        </ul>
                                    <p><u>{window.strings.VOLUNTEER.SENIORS_HELPLINERS_STORY_EIGHT}</u></p>
                                    <ul><li><p>{window.strings.VOLUNTEER.SENIORS_HELPLINERS_STORY_NINE}</p></li></ul>
                                    <p>{window.strings.VOLUNTEER.SENIORS_HELPLINERS_STORY_TEN}</p>
                                </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header"><h5>{window.strings.VOLUNTEER.COUNSELLING_ASSOCIATES}</h5></div>
                                <div className="card-body crd-ht-o">
                                    <img src={CA}/>
                                    <p>{window.strings.VOLUNTEER.COUNSELLING_ASSOCIATES_STORY_ONE}</p>
                                    <p>{window.strings.VOLUNTEER.COUNSELLING_ASSOCIATES_STORY_TWO}</p>
                                    <p>{window.strings.VOLUNTEER.COUNSELLING_ASSOCIATES_STORY_THREE}</p>
                                    <p>{window.strings.VOLUNTEER.COUNSELLING_ASSOCIATES_STORY_FOUR}</p>
                                    <p>{window.strings.VOLUNTEER.COUNSELLING_ASSOCIATES_STORY_FOUR_HAF}</p>
                                    <p>{window.strings.VOLUNTEER.COUNSELLING_ASSOCIATES_STORY_FIVE}</p>
                                    <p>{window.strings.VOLUNTEER.COUNSELLING_ASSOCIATES_STORY_SIX}</p>
                                    <p>{window.strings.VOLUNTEER.COUNSELLING_ASSOCIATES_STORY_SEVEN}</p>
                                </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header"><h5>{window.strings.VOLUNTEER.SPECIALIST_VOLUNTEERS}</h5></div>
                                <div className="card-body crd-ht-t">
                                    <img src={SV}/>
                                    <p className="wrd-wrp">{window.strings.VOLUNTEER.SPECIALIST_VOLUNTEERS_STORY}</p>
                                </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header"><h5>{window.strings.VOLUNTEER.REMINISCENCE_WALK_AMBASSADORS}</h5></div>
                                <div className="card-body crd-ht-t">
                                    <img src={RWA}/>
                                    <p>{window.strings.VOLUNTEER.REMINISCENCE_WALK_AMBASSADORS_STORY}</p>
                                </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header"><h5>{window.strings.VOLUNTEER.MUSIC_AMBASSADORS}</h5></div>
                                <div className="card-body crd-ht-m">
                                    <img src={AMA}/>
                                    <p className="font-wt">{window.strings.VOLUNTEER.ART_AMBASSADORS}</p>
                                    <p>{window.strings.VOLUNTEER.ART_AMBASSADORS_STORY}</p>
                                    <p className="font-wt">{window.strings.VOLUNTEER.MUS_AMBASSADORS}</p>
                                    <p>{window.strings.VOLUNTEER.MUSIC_AMBASSADORS_STORY}</p>
                                </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header"><h5>{window.strings.VOLUNTEER.HMS_VOLUNTEERS}</h5></div>
                                <div className="card-body crd-ht-m">
                                    <img src={HMS}/>
                                    <p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY}</p>
                                    <p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY_LIST}</p>
                                    <ul className="crd-ht-ul">
                                        <li><p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY_TRUST}</p></li>
                                        <li><p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY_TRAVEL}</p></li>
                                        <li><p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY_LIC}</p></li>
                                        <li><p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY_TOOL}</p></li>
                                        <li><p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY_PAY}</p></li>
                                        <li><p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY_COMFORT}</p></li>
                                        <li><p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY_CHALLENGE}</p></li>
                                        <li><p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY_COMMUNI}</p></li>
                                        <li><p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY_INFO}</p></li>
                                        <li><p>{window.strings.VOLUNTEER.HMS_VOLUNTEERS_STORY_DOC}</p></li>
                                    </ul>
                                </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header"><h5>{window.strings.VOLUNTEER.FRONT_DESK_VOLUNTEERS}</h5></div>
                                <div className="card-body  crd-ht-l"><p>{window.strings.VOLUNTEER.FRONT_DESK_VOLUNTEERS_STORY}</p></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header"><h5>{window.strings.VOLUNTEER.AD_HOC_EVENTS_VOLUNTEERS}</h5></div>
                                <div className="card-body crd-ht-l"><p>{window.strings.VOLUNTEER.AD_HOC_EVENTS_VOLUNTEERS_STORY}</p></div>
                            </div>
                        </div>
                    </div> */}

                   
                    

                   {/* <div className="img-gallery">
                       <div className="row">{taskList}</div>
                   </div> */}
                          {/* <div className="img-gallery">
                              <div className="row">
                                  <div className="col-lg-4">
                                      <div className="card">
                                          <div className="oppo-box">
                                              <img src={OppoImg1} />
                                              <span>
                                                  <h5>B.X.M. 2020 Sports X-Change Project</h5>
                                                  <h6>Weekly 4-6 pm | Oct 2020-Dec2021 417 Bukit Batok West Ave 4 Singapore 650417</h6>
                                                  <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                   scrambled it to make a type specimen book.</p>
                                                   <button className="btn info-btn">Volunteer Now</button>
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-4">
                                      <div className="card">
                                          <div className="oppo-box">
                                              <img src={OppoImg2} />
                                              <span>
                                                  <h5>B.X.M. 2020 Sports X-Change Project</h5>
                                                  <h6>Weekly 4-6 pm | Oct 2020-Dec2021 417 Bukit Batok West Ave 4 Singapore 650417</h6>
                                                  <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                   scrambled it to make a type specimen book.</p>
                                                   <button className="btn info-btn">Volunteer Now</button>
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-4">
                                      <div className="card">
                                          <div className="oppo-box">
                                              <img src={OppoImg3} />
                                              <span>
                                                  <h5>B.X.M. 2020 Sports X-Change Project</h5>
                                                  <h6>Weekly 4-6 pm | Oct 2020-Dec2021 417 Bukit Batok West Ave 4 Singapore 650417</h6>
                                                  <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                   scrambled it to make a type specimen book.</p>
                                                   <button className="btn info-btn">Volunteer Now</button>
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-4">
                                      <div className="card">
                                          <div className="oppo-box">
                                              <img src={OppoImg4} />
                                              <span>
                                                  <h5>B.X.M. 2020 Sports X-Change Project</h5>
                                                  <h6>Weekly 4-6 pm | Oct 2020-Dec2021 417 Bukit Batok West Ave 4 Singapore 650417</h6>
                                                  <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                   scrambled it to make a type specimen book.</p>
                                                   <button className="btn info-btn">Volunteer Now</button>
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-4">
                                      <div className="card">
                                          <div className="oppo-box">
                                              <img src={OppoImg5} />
                                              <span>
                                                  <h5>B.X.M. 2020 Sports X-Change Project</h5>
                                                  <h6>Weekly 4-6 pm | Oct 2020-Dec2021 417 Bukit Batok West Ave 4 Singapore 650417</h6>
                                                  <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                   scrambled it to make a type specimen book.</p>
                                                   <button className="btn info-btn">Volunteer Now</button>
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-4">
                                      <div className="card">
                                          <div className="oppo-box">
                                              <img src={OppoImg6} />
                                              <span>
                                                  <h5>B.X.M. 2020 Sports X-Change Project</h5>
                                                  <h6>Weekly 4-6 pm | Oct 2020-Dec2021 417 Bukit Batok West Ave 4 Singapore 650417</h6>
                                                  <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                   scrambled it to make a type specimen book.</p>
                                                   <button className="btn info-btn">Volunteer Now</button>
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div> */}
                          {/* <div className="text-center">
                            <button className="gradient-btn">View More</button>
                          </div> */}
                      </div>
                  </div>
          </div>
            )            
        }}

const mapStateToProps = state => {
    return {
        taskList:state.task.taskList
    }
}


const actionCreators = {
    getList:taskActions.getTask,
    resetGetTask : taskActions.resetGetTask,
 }

const connectedVolunteerOpportunities = connect(mapStateToProps, actionCreators)(VolunteerOpportunities);
export { connectedVolunteerOpportunities as VolunteerOpportunities };