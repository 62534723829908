import {history} from '../../helpers';
import {config} from '../../config'
import CryptoJS from 'crypto-js';
import {categoryConstants} from '../../constants'
import {categoryService} from '../../services'
import {toastr} from '../../services'
import PubSub from 'pubsub-js';
export const categoryActions = {
    save,
    getList,
    delete:_delete,
    resetDelete,
    resetSave,
    resetList,
}
function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
}
function save(obj){
    return dispatch => {
        showLoader()
        categoryService.save(obj)
        .then(resp => {
            if(resp && resp.status == true){
            toastr.success('Category Saved Successfully!')
            dispatch({type:categoryConstants.SAVE_CATEGORY_SUCCESS,saveStatus:resp.status})
            hideLoader()
            }else{
                hideLoader()
            }
        },error => {
            hideLoader()
           toastr.error(error)
        })
    }
}
function resetSave(){
    return dispatch => {
        dispatch({type:categoryConstants.SAVE_CATEGORY_SUCCESS,saveStatus:''})
    }
}
function getList(obj){
    return dispatch => {
        showLoader()
        categoryService.getList(obj)
        .then(resp => {
            resp.request = obj
            dispatch({type:categoryConstants.FETCH_CATEGORY_SUCCESS,categoryList:resp})
            hideLoader()
        },error=> {
            hideLoader()
            toastr.error(error)
        })
    }
}
function resetList(){
    return dispatch => {
        dispatch({type:categoryConstants.FETCH_CATEGORY_SUCCESS,categoryList:''})
    }
}
function _delete(obj){
    return dispatch=> {
        showLoader()
        categoryService.delete(obj)
        .then(resp=>{
            toastr.success(resp.message)
            dispatch({type:categoryConstants.CATEGORY_DELETE_SUCCESS,deleteStatus:resp.status})
            hideLoader()
        },error=>{
            hideLoader()
            toastr.error(error)
        })
    }
}
function resetDelete(){
    return dispatch => {
        dispatch({type:categoryConstants.CATEGORY_DELETE_SUCCESS,deleteStatus:''})
    }
}
