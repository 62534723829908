import React,{Component,Fragment} from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import {history,ReactPagination} from '../../../helpers'
import {chatActions} from '../../../store/actions'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {toastr} from '../../../services'
import {config} from '../../../config'
class FetchDonate extends Component{
    constructor(props){
        super(props);
            this.state={
                categoryList:[],
                limit:10,
                page:1,
                searchTxt:'',
                totalCount:''
            }
        
    }
    componentDidMount(){
       this.list()
    }
    componentWillReceiveProps(newProps){
       
        if(newProps && newProps.getList && newProps.getList.data){
            let data = newProps.getList && newProps.getList.data && newProps.getList.data.rows
            let request = newProps.getList && newProps.getList.request ? newProps.getList.request : []
            let totalCount = newProps.getList && newProps.getList.data && newProps.getList.data.count ? newProps.getList.data.count : []
            if(data.length == 0){
                toastr.error("Result Not Found")
            }
            this.setState({getList:data,totalCount,pageCount:totalCount/request.limit})
            this.props.resetList()
        }
        // if(newProps && newProps.deleteStatus && newProps.deleteStatus === true){
    
        //     this.props.resetDelete()
        //     this.list()
        // }
    }
    list = () => {
      
        if(this.props && this.props.getList && this.props.getList.status == true){
            let request = this.props.getList && this.props.getList.request ? this.props.getList.request : "";
            if(request.limit || request.page || request.searchTxt){
                this.setState({
                    limit:request.limit,
                    page:request.page,
                    searchTxt:request.searchTxt
                },()=>{
                    this.getList()
                })
            }else{
                this.getList()
            }
        }
        else{
            this.getList()
        }
    }
    getList = () => {
        let obj = {}
        if(this.state.searchTxt){
            obj['page'] = this.state.page
            obj['limit'] = this.state.limit
            obj['searchTxt'] = this.state.searchTxt
            // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
            // obj['roleId'] = this.state.authorizedData ? this.state.authorizedData.roleId : ""
        }
        else{
            obj['page'] = this.state.page
            obj['limit'] = this.state.limit
            // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
            // obj['roleId'] = this.state.authorizedData ? this.state.authorizedData.roleId : ""
        }
        this.props.getStory(obj)
    }
    onChange = (data)=> {
        if(this.state.page !== (data.selected+1)){
            this.setState({
                page:data.selected +1
            }, ()=> {
                this.getList()
            })
        }
    }
    formPath = () => {
        history.push('/admin/backStory/add')
    }
    resetSearch = () => {
        
        this.setState({ searchTxt : '',page : 1 },() => {
            this.getList()
        })      
}
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }
    itemEdit = (id) => {
        history.push('/admin/backStory/edit/'+id,{obj:{id}})
    }
    itemDelete = (categoryId) => {
        let obj = {
            categoryId
       }
       this.props.delete(obj)
   }
   handleDelete = (categoryId) => {
       let message = '';
       const toastrConfirmOptions = {
           onOk: () => { this.itemDelete(categoryId) },
           onCancel: () => console.log('CANCEL: clicked')
       };
       toastr.customConfirm(message, toastrConfirmOptions,'Do you want to delete ?');

   }
    getNewResult = (e) => {
        e.preventDefault();
        if(this.state.searchTxt){
            this.setState({ page : 1 },() => {
            this.getList()
            })
        }else{
            toastr.error("Please type to search")
        }        
    }
    render(){
        const {limit,page} = this.state
        let list = this.state.getList && this.state.getList.map((items,index)=>{
            return <tr>
                <td>{(page - 1) * limit + index  + 1}</td>
                <td>{items.title}</td>
                {/* <td>{items.content && items.content.replace(/<[^>]*>/g, '')}</td> */}
                <td>{items.pageName}</td>
                <td>{items.pageUrl}</td>
                <td>
                    <a className="tooltip-wrap" onClick={()=>this.itemEdit(items.id)}>
                        <EditIcon className="edit-icon"/>
                        <div className="tooltip">
                            <div className="arrow"></div>
                            <div className="tooltip-inner">Edit</div>
                        </div>
                    </a>
                    {/* <a className="tooltip-wrap" onClick={()=>this.handleDelete(items.id)}>
                        <DeleteIcon className="delete-icon"/>
                        <div className="tooltip">
                            <div className="arrow"></div>
                            <div className="tooltip-inner">Delete</div>
                        </div>
                    </a> */}
                </td>
            </tr>
        })
        return(
        <Fragment>
            <div className="row tit-sec">
                <div className="col-6 col-md-5">
                    <h4>Content Management</h4>
                </div>
                <div className="col-6 col-md-7 text-right"> 
                    <button className="btn add-btn" onClick={this.formPath}><i className="fas fa-plus"></i>Add</button>
                </div>
                </div>
               <div className="card">
               <div className="card-body">
               <div className="pt-2 mb-3">
                                {/* <div className="d-flex justify-content-end">
                                    <form onSubmit={this.getNewResult} class="align-items-center" >
                                        <div class="input-group">
                                            <input type="text" placeholder="Search.." name="searchTxt" value={this.state.searchTxt} onChange={this.handleInputChange} aria-label="Recipient's username" formcontrolname="customSearch" class="form-control ng-pristine ng-valid ng-touched" />
                                            <div class="input-group-append">
                                                <button type="submit" class="btn btn-sm btn-dark" ><i className="fas fa-search"></i></button>
                                                <div class="pl-3">
                                                    <button _ngcontent-wdd-c125="" type="button" onClick={this.resetSearch} class="btn btn-outline-secondary btn-rounded btn-icon">
                                                    <i class="fas fa-redo-alt"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div> */}
                            </div>
                <div className="table-wrap">
                    <div className="table-responsive">
                        <table className="table ">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Title</th>
                                    <th>Page</th>
                                    <th>PageURL</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list}
                            </tbody>
                        </table>
                    </div>
                </div>
               </div>
               {this.state.getList && this.state.getList.length == 0 && <div className="table-responsive">
                            <h6 className="text-center">No record found</h6>
                            </div>}
               </div>
               {this.state.getList && this.state.getList.length > 0 && <ReactPagination PageDetails={{pageCount: this.state.pageCount, onPageChange: this.onChange, activePage: (this.state.page - 1), perPage: this.state.limit, totalCount: this.state.totalCount}}/>}

            </Fragment>
        )
    }
}
function mapStateToProps(state){
    return {
        getList : state.chat.backStories
    //    categoryList:state.category.categoryList,
    //    deleteStatus : state.category.deleteStatus
    }
}
const actionCreators = {
    getStory : chatActions.getStory,
    // delete:categoryActions.delete,
    // resetDelete : categoryActions.resetDelete,
    resetList: chatActions.resetGetStory
}
const connectedUser = connect(mapStateToProps, actionCreators)(FetchDonate);
export { connectedUser as FetchDonate };