import React , {Component, Fragment} from 'react';
import {CreateUser, Header,Sidebar} from '../admin/components'
import { withStyles } from "@material-ui/core/styles";
import {config} from '../config'
import 'bootstrap/dist/css/bootstrap.css'; 

// import './App.css';
// import './style.css'; 
let hash = ''
if(config.hashName){
     hash = window.location.hash
}else{
     hash = ''
}
let location = window.location.pathname+hash;
location.includes('admin') && require("../admin/assets/theme-styles/sass/adminStyle.scss");
// location.includes('admin') && require("../App.css");


const drawerWidth = 240;
const styles = theme => ({
    root: {
		display: "flex"
	},
    content :{
        padding: '24px',
        flexGrow: '1',
        marginTop: '56px',
        overflowX: 'hidden',
        marginLeft: '72px',
       },
    contentShift :{
        width: 'calc(100% - 240px)',
        transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        marginLeft: '240px',
        padding: '24px',
        flexGrow: '1',
        marginTop: '56px',
        overflowX: 'hidden',
      }
})
class MainLayout extends Component{
    state = {
		open: true
	};
    handleDrawer = () => {
        this.setState(prevState => {return {open: !prevState.open}})
    }
    render(){
        const {children,classes} = this.props
        return(
            <Fragment>
                <div className={classes.root}>
                    <Header handleDrawer={this.handleDrawer} open={this.state.open}/>
                    {this.state.open ?
						<main className="content-shift">
							{children}
						</main>
						:
						<main className="content">
							{children}
						</main>
					}
                   
                </div>
                <Sidebar open={this.state.open}/>
            </Fragment>
        )
    }
}
export default withStyles(styles)(MainLayout);
