import React, { Component, Fragment } from 'react'
import UploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import { chatActions } from '../../../store/actions'
import { connect } from 'react-redux';
import { history } from '../../../helpers'
import CryptoJS from 'crypto-js';
import { config } from '../../../config';
import { toastr } from '../../../services'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import 'react-quill/dist/quill.bubble.css';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Toggle from 'react-toggle';
class CreateDonate extends Component {
    constructor(props) {
        super(props);
        this.modules = {
            toolbar: [
                [{ 'font': [] }],
                [{ 'size': ['small', false, 'large', 'huge'] }],
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'align': [] }],
                [{ 'color': [] }, { 'background': [] }],
                ['clean']
            ]
        };

        this.formats = [
            'font',
            'size',
            'bold', 'italic', 'underline',
            'list', 'bullet',
            'align',
            'color', 'background'
        ];

        this.state = {
            id: '',
            title: '',
            content: '',
            status: 0,
            url: 'http://ec2-13-250-8-106.ap-southeast-1.compute.amazonaws.com/sagecc/#/',
            addURL: '',
            category: '',
            deleteSkuData: [],
            open: false,
            faqArray: [{ "question": '', "answer": '' }],
            submitted: false,
        }
    }
    componentDidMount() {
        if (this.props.location && this.props.location.state && this.props.location.state.obj) {
            let reqData = this.props.location.state.obj
            this.props.getStory(reqData)
        }
    }
    componentWillReceiveProps(newProps) {
        if (newProps && newProps.getList && newProps.getList.data && newProps.getList.data.rows && newProps.getList.data.rows[0]) {
            let editData = newProps.getList.data.rows[0]
            let open = false
            if (editData && editData.faq && editData.faq.length > 0 && (editData.faq[0].question || editData.faq[0].answer)) {
                open = true
            }
            this.setState({
                title: editData.title, status: editData.isEnabled, content: editData.content, id: editData.id, open,
                category: editData.pageName, addURL: editData.pageUrl && editData.pageUrl.slice(70), faqArray: editData && editData.faq
            })

        }
        if (newProps && newProps.saveStatus && newProps.saveStatus == true) {
            this.props.resetSave()
            history.goBack()
        }
    }
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }
    statusChange = (status) => {
        var status = this.state.status ? 0 : 1
        this.setState({ status })
    }
    rteChange = (html) => {
        this.setState({ content: html })
    }
    handleFaqChange = (index) => (eve) => {
        const faqArray = this.state.faqArray
        const value = (eve.target.value && eve.target.value < 0) ? '' : eve.target.value;
        const name = eve.target.name
        faqArray[index][name] = value
        this.setState({ faqArray })
    }
    handleAddfaq = () => {
        this.setState({
            faqArray: this.state.faqArray.concat(
                [{ "question": '', "answer": '' }]
            )
        })
    }
    handleDelete = (idx, removeId) => {
        let message = '';
        const toastrConfirmOptions = {
            onOk: () => { this.handleRemoveProductSku(idx, removeId) },
            onCancel: () => console.log('CANCEL: clicked')
        };
        toastr.customConfirm(message, toastrConfirmOptions, 'Do you want to delete ?');
    }

    handleRemoveProductSku = (idx, removeId) => {
        console.log("id", removeId)
        let deleteSkuData = this.state.deleteSkuData;
        if (removeId) {
            deleteSkuData.push(removeId);
        }

        this.setState({
            faqArray: this.state.faqArray && this.state.faqArray.filter((s, sidx) => idx !== sidx)
        }, () => {
            if (this.state.faqArray && this.state.faqArray.length == 0) {
                this.handleAddfaq();
            }
        });
    }
    faqValidate = (faqArray) => {
        let isValid = true
        faqArray && faqArray.length > 0 && faqArray.map(item => {
            if (item && ((item.question == "") || (item.answer == ""))) {
                isValid = false

            } else {
                isValid = true
            }
        })
        return isValid
    }
    listPath = () => {
        history.push('/admin/backStory')
    }
    openFaq = () => {
        const open = this.state.open ? false : true
        this.setState({
            open
        })
    }
    handleTypeChange = (e) => {
        this.setState({
            type: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault()
        // console.log(this.state.faqArray,"faq")
        const { title, id, status, addURL, category, url, faqArray } = this.state
        let isFaqValid = true
        // console.log( this.faqValidate(faqArray),"status")
        let content = this.state.content && this.state.content.replace(/<[^>]*>/g, '')
        this.setState({ submitted: true })
        let pageUrl = url.concat(addURL)
        if (title && content && addURL && category) {
            let obj = {
                title,
                content: this.state.content,
                pageUrl: pageUrl,
                pageName: category,
                isEnabled: status
            }

            if (id) {
                obj['id'] = id
            }
            if (this.state.open) {
                faqArray && faqArray.map((item, index) => {
                    if (!item.question || !item.answer) {
                        //    toastr.error("Mandatory fields are missing")
                        isFaqValid = false

                        // return console.log("qusetion")
                    }
                    //    if(item.question && item.answer){
                    //        return console.log("true")
                    //    }
                })
            }

            if (isFaqValid) {
                if (this.state.open) {
                    obj['faq'] = faqArray
                }
                this.props.save(JSON.stringify(obj))
            } else {
                toastr.error('Mandatory fields are missing')
                return
            }
        }
        else {
            toastr.error('Mandatory fields are missing')
        }

    }
    render() {
        return (
            <Fragment>
                <section>
                    <div className="tit-sec">{this.state.id ? <h4>Edit Content</h4> : <h4>Add Content</h4>}</div>
                    <div class="card">
                        <div class="card-body">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-tab">
                                    <div className="col-md-9">
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-xl-2 mb-md-1 col-form-label pr-0">Title&nbsp;<span class="mandatory">*</span></label>
                                            <div className="col-sm-9">
                                                <input type="text" name="title" value={this.state.title} onChange={this.handleInputChange} placeholder="Enter Title" className="form-control" disabled={this.state.id} />
                                                {this.state.submitted && !this.state.title && <div className="mandatory">Title is required</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-xl-2 mb-md-1 col-form-label pr-0">Path Name&nbsp;<span class="mandatory">*</span></label>
                                            <div className="col-sm-9">
                                                <div className="input-group">
                                                    <input type="text" name="url" value={this.state.url} onChange={this.handleInputChange} disabled className="form-control" />
                                                    <input type="text" name="addURL" value={this.state.addURL} onChange={this.handleInputChange} className="form-control" placeholder="Enter path name" />
                                                    {this.state.submitted && !this.state.addURL && <div className="mandatory">Page URL required</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Page URL&nbsp;<span class="mandatory">*</span></label>
                                                <div className="col-sm-7">
                                                <input type="text" name="addURL" value={this.state.addURL} onChange={this.handleInputChange}  className="form-control" placeholder="Enter path name"/>
                                                {this.state.submitted && !this.state.addURL && <div className="mandatory">Page URL required</div>}
                                                </div>
                                            </div>
                                        </div> */}
                                    <div className="col-md-9">
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-xl-2 mb-md-1 col-form-label pr-0">Footer Name&nbsp;<span class="mandatory">*</span></label>
                                            <div className="col-sm-9">
                                                <input type="text" name="category" value={this.state.category} onChange={this.handleInputChange} placeholder="Enter footer name" className="form-control" />
                                                {this.state.submitted && !this.state.category && <div className="mandatory">Category is required</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="form-group row">
                                            <label class="col-sm-3 col-xl-2 mb-md-1 col-form-label pr-0">Status</label>
                                            <div className="col-sm-9">
                                                <Toggle defaultChecked={this.state.status} checked={this.state.status} onClick={() => this.statusChange(this.state.status)} />

                                                {/* {this.state.submitted && !this.state.type && <div className="mandatory">Type is required</div>} */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-xl-2 mb-md-1 col-form-label pr-0">Content&nbsp;<span class="mandatory">*</span></label>
                                            <div className="col-sm-9">
                                                {/* <textarea type="text" name="content" value={this.state.content} onChange={this.handleInputChange} placeholder="Enter Content" className="form-control"/> */}
                                                <ReactQuill theme="snow" modules={this.modules}
                                                    formats={this.formats} onChange={this.rteChange}
                                                    value={this.state.content || ''} placeholder="Create Back Story " />
                                                {this.state.submitted && !this.state.content && <div className="mandatory">Please enter your content</div>}
                                            </div>
                                            <div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="faq-btn">
                                        <button type="button" className="btn add-btn" onClick={this.openFaq}><i className="fas fa-plus"></i>FAQ</button>
                                    </div>
                                    {this.state.open && this.state.faqArray && this.state.faqArray.length > 0 && this.state.faqArray.map((item, index) => (
                                        <div className="faq-wrap">
                                            <div className="col-md-9">
                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-xl-2 mb-md-1 col-form-label pr-0">Question&nbsp;<span class="mandatory">*</span></label>
                                                    <div className="col-sm-9">
                                                        <input type="text" name="question" value={item.question} onChange={this.handleFaqChange(index)} placeholder="Enter Question" className="form-control" />
                                                        {this.state.submitted && !item.question && <div className="mandatory">Required</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-xl-2 mb-md-1 col-form-label pr-0">Answer&nbsp;<span class="mandatory">*</span></label>
                                                    <div className="col-sm-9">
                                                        <input type="text" name="answer" value={item.answer} onChange={this.handleFaqChange(index)} placeholder="Enter Answer" className="form-control" />
                                                        {this.state.submitted && !item.answer && <div className="mandatory">Required</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="icon-btn">
                                                {index > 0 && <button type="button" onClick={() => this.handleDelete(index, item.id)} className="btn btn-minus"><i className="fas fa-minus-circle"></i></button>}
                                                {index == this.state.faqArray.length - 1 && <button type="button" onClick={this.handleAddfaq} className="btn btn-plus"><i className="fas fa-plus-circle"></i></button>}
                                            </div>
                                            {/* <div className="text-right">
												
												{index > 0 && <button variant="contained" type="button" onClick={() => this.handleDelete(index,item.id)} className="mr-2 btn btn-danger">DELETE</button>}
													{ index == this.state.faqArray.length - 1 && <Button color="primary" variant="contained" type="button" onClick={this.handleAddfaq} className="mr-2"> ADD</Button>}
													
					
												</div>	 */}
                                        </div>
                                    ))}
                                </div>
                                <div class="bottom-btn">
                                    <button type="button" className="btn cancel-btn" onClick={this.listPath}>Cancel</button>
                                    <button type="submit" className="btn add-btn">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}
function mapStateToProps(state) {
    return {
        saveStatus: state.chat.backStoryStatus,
        //   emailList : state.template.emailList
        getList: state.chat.backStories
    }
}
const actionCreators = {
    save: chatActions.save,
    resetSave: chatActions.resetSave,
    getStory: chatActions.getStory
}
const saveUser = connect(mapStateToProps, actionCreators)(CreateDonate);
export { saveUser as CreateDonate };
