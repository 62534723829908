export const EnglishTranslation = {
    "en" : {
        "HEADER" : {
            "HOME" : "Home",
            "EVENT" : "Event",
            "ABOUT" : "About",
            "OUR_WORK" : "Our Work",
            "GALLERY" : "Gallery",
            "VOLUNTEER" : "Volunteer",
            "DONATION" : "Donation",
            "OUR_HISTORY": "Our Centre",
            "OUR_BOARD_COMITTEE": "Our Board Comittee",
            "OUR_FINANCIAL_ANNUAL_REPORTS":"Annual Report & Financial Report",
            "EVENTS" : "Events",
            "VIDEOS_GALLERY":"Video Gallery",
            "PHOTOS_GALLERY":"Photo Gallery",
            "SUCCESS_STORIES":"Success Stories & Testimonial",
            "VOLUNTEER_OPPORTUNITIES":"Volunteer Opportunities",
            "FORTUNE_BAG":"Fortune Bag",
            "STORIES_OF_VOLUNTEERS":"Story of Volunteers",
            "ONLINE_SIGNUP_FORM":"Online Sign-up Form",
            "CONTACTUS" : "Contact Us",
            "DONATION_CHANNELS" : "Donation Channels",
            "SENIORS_HELPLINE" : "The Seniors Helpline",
            "LOGIN" : "Login",
        } ,
        "FOOTER" : {
            "SITE_MAP" : "Site Map",
            "ABOUT" : "About",
            "OUR_WORK" : "Our Work",
            "GALLERY" : "Gallery",
            "VOLUNTEER" : "Volunteer",
           "DONATION" : "Donation",
           "EVENT" : "Event",
           "ADDRESS" : "Address",
           "CONTACT_US" : "Contact Us",
           "TEL" : "Tel",
           "FAX" : "Fax",
           "EMAIL" : "Email",
           "FOLLOW":"Follow us on ",
           "ADDRESS_ONE":"SAGE Counselling Centre",
           "ADDRESS_TWO":"1 Jurong West Central 2",
           "ADDRESS_THR":"#06-04, Jurong Point Shopping Centre",
           "ADDRESS_FOU":"Singapore 648886",

        } ,
        "HOME" : {
            "EXPRESSIVE_THERAPEUTIC_ACTIVITY_FOR_SENIORS" : "Expressive Therapeutic Activity For Seniors",
            "FOR_ENQUIRY_ON_COUNSELLING_SERVICE_AND_PROGRAMMES_YOU_MAY_CALL_US" : "For enquiry on Counselling service and programmes, you may call us @ 6354-1191 during our office hours from Mondays to Fridays, 8.30am to 5.30pm",
            "MORE_INFO" : "More Info",
            "OUR_SERVICE_PGM" : "Our Services And Programmes",
            "WHO_ARE_WE":"About Us",
            "YOU_CAN_HELP" : "You Can Help, Any Time.",
            "YOU_CAN_HELP_CONTENT":"Our services and programmes are only made possible with kind donation and committed volunteers.",
            "BE_A_VOLUNTEER" : "Be a Volunteer",
            "BE_A_DONOR" : "Be a Donor",
            "SERVICE_PGM_CONTENT":"SAGE Counselling Centre is a non-profit and non-religious Social Service Agency. We aim to enhance the total well-being of the older persons, their family members and caregivers with a special focus on their psychological and social aspects of their health. We strive to address the changing needs of senior citizens in the community, not just for the needy and lonely to achieve better quality of life, but also for the able-bodied and healthy to lead fulfilling lives, engaging in lifelong learning and active ageing.",
            "VOLUNTEER_CONTENT":"If you have a passion in supporting seniors in their daily lives through providing a listening ear, befriending and activities engagement, join us as volunteer.",
            "DONOR_CONTENT":"Your donations are greatly appreciated and will continue to help us in our services and programmes for the Seniors and their family members or caregivers. ",
            "ABOUT_US":"About Us",
            "OUR_SERVICES":"Our Services",
            "LEARN_MORE":"Learn More",
            "COUNSELLING_SLIDE_CONTENT":"Our counselling service aims to enhance the total well-being of older persons, their caregivers and family members through face-to-face counselling.",
            "HELPLINE_SLIDE_CONTENT":"The Seniors Helpline is a toll-free National Helpline, for any person aged 50 years and above to call in or talk about or enquire on issues or services related to older persons.",
            "PEP_SLIDE_CONTENT":"We work with community partners to organise talks, heritage tours and training workshops to motivate life-long learning, encourage active ageing and enhance social skills, life skills and emotional stability of the seniors.",
            "ART_SLIDE_CONTENT":"Art and Music programmes encourage and empower seniors to lead meaningful and fulfilling lives. ",
            "HMS_SLIDE_CONTENT":"HMS provides free basic home maintenance services to eligible individuals and these services are provided by a group of skilled committed handymen, electricians and plumbers who are mostly seniors themselves.",

        },
        "ABOUT" : {
            "ABOUT" : "About",
            "OUR_HISTORY" : "Our History",
            "OUR_HOSTORY_ESTABLISHED_IN_1991" : "Established in 1991, the SAGE Hotline (1800-3538633) was officially launched to provide a listening ear to anyone who needs to talk about matters related to the older persons. Two years later, SAGE Counselling Centre was set up as a special programme under Singapore Action Group of Elders to provide free face-to-face counselling to the needy seniors & their families.",
            "OUR_HISTORY_IN_2005_SAGE_COUNSELLING_CENTRE" : "In 2005, SAGE Counselling Centre hosted the nation-wide The Seniors Helpline (1800-555-5555) supported by NCSS.",
            "OUR_HISTORY_SINCE_2008_THE_CENTRE_HAS_DEVELOPED" : "Since 2008, the Centre has developed into a Professional Counselling Centre as an independent entity. We have also obtained NCSS full membership & IPC Charity status.",

            "OUR_VISION" : "Our Vision",
            "OUR_VISION_STORY" : "Be a leading Social Service Agency specialising in gero-counselling for older persons, their family members, and caregivers.",

            "OUR_MISSION" : "Our Mission",
            "OUR_MISSION_STORY" : "To promote the well-being of older persons with a special focus on the social and psychological aspects of their health through engaging, enabling and empowering them in active ageing.",

            "OUR_VALUES" : "Our  Values",
            "OUR_VALUES_SELFLESS_IN_SERVICE_CLR" : "Selfless in service",
            "ACCEPTANCE_CLR" : "Acceptance",
            "GENUINENESS_CLR" : "Genuineness",
            "EXCELLENCE_CLR" : "Excellence",
            "COMMITMENT_CLR" : "Commitment",
            "COMPASSION_CLR" : "Compassion",
            
            "OUR_COMMITMENT" : "Our Commitments",
            "SAGE_COUNSELLING_CENTRE_IS_COMMITTED" : "SAGE Counselling Centre is committed to",
            "OUR_COMMITMENT_I" : "Enhance public awareness and understanding of the social and emotional needs of older persons.",
            "OUR_COMMITMENT_II" : "Promote professionalism in developing and implementing remedial, preventive and developmental social services and counselling programmes for older persons.",
            "OUR_COMMITMENT_III" : "Empower and maximize the potential of older persons through engaging them in life-long learning and volunteerism.",
            "OUR_COMMITMENT_IV" : "Strengthen families with older members through enabling caregivers and promoting inter-generational bonding.",
            "OUR_COMMITMENT_V" : "Collaborate with community partners to work towards an inclusive society where older persons are accepted, respected and empowered to keep active in the community and to age well at home.",

            "FINANCIAL_REPORT":"Financial Report",
            "ANN_REPORT":"Annual Report",

            "OUR_ADVISOR":"Our Advisor",
            "BOARDCHAIRMAN":"Chairman",
            "BOARDCICECHAIR":"Vice Chairman",
            "HONORARYSECRETARY":"Honorary Secretary",
            "HONORARYTREASURER":"Honorary Treasurer",
            "ASSISTANTTREASURER":"Assistant Treasurer",
            "BOARDMEMBER":"Board Member",
            "KOHENGSOONTIM":"Mr Koh Eng Soon Tim",
            "KANSEEKWAN":"Dr Kan See Mun",
            "LEESOOKWAN":"Ms Lee Soo Kwan",
            "ROBERTFUQIGUO":"Mr Robert Fu Qiguo",
            "SENGYUHMING":"Ms Seng Yuh Jen",
            "CHANKINMING":"Dr Chan Kin Ming",
            "ZULKIFLIMOHAMMED":"Mr Zulkifli Mohammed",
            "TANKIANCHOONPBM":"Mr Tan Kian Choon",
            "CHENGYUANSHAN":"Dr Cheng Yuanshan",
            "JOYCHEWOONAI":"Dr Joy Chew Oon Ai ",
            "LIMWEELIM":"Mr Lim Wee Lim",
            "HONORARYADVISIOR":"Honorary Advisor",
            "LUIHAHWAHELENAPBM":"Dr Lui Hah Wah Elena",
            "ESTHERTANNEEYIUKUMYUPB":"Dr Esther Tan nee Yiu Kum Yu",
            "LEGALADVISIOR":"Legal Advisor",
            "CHARLESLEONG":"Mr Charles Leong",

            "OUR_BRD_COMITT": "Our Board Comittee",
            "ABOUT_HED":"About",

            "MUL_PBM":"PB, PBM, BBM",
            "PBM":"PBM",
        } ,
        "VOLUNTEER" : {
            "VOLUNTEER" : "Our Volunteer",
            "VOLUNTEER_CONTENT_ONE":"SAGECC run a host of services and programmes to enable, encourage and empower physically fit and mentally alert seniors to engage in active ageing and contribute to society through volunteerism. We are very grateful to our team of dedicated volunteers who serve in various roles. ",
            "VOLUNTEER_CONTENT_TWO":"Our active volunteers are from all walks of life, with most of them being seniors themselves. They are continually learning and improving themselves to serve the senior community.",
            "VOLUNTEER_OPPORTUNITIES" : "Volunteer Opportunities",
            "VOLUNTEER_OPPORTUNITIES_CONTENT":"All volunteers are required to attend an orientation before they can formally join us. ",
            "SENIORS_HELPLINERS" : "The Seniors Helpline",
            "SENIORS_HELPLINERS_STORY_ONE" : "Volunteers attend to calls from the helpline by lending a listening ear to anyone 50 years and above or anyone who has a concern or question relating to the seniors.",
            "SENIORS_HELPLINERS_STORY_TWO":"Pre-service training is required.",
            "SENIORS_HELPLINERS_STORY_THREE":"Duty slots (choose one based on your availability):",
            "SENIORS_HELPLINERS_STORY_FOUR":"Monday to Friday ",
            "SENIORS_HELPLINERS_STORY_FIVE":"S1:    9.00am – 12.00pm",
            "SENIORS_HELPLINERS_STORY_SIX":"S2: 12.00pm – 4.00pm",
            "SENIORS_HELPLINERS_STORY_SEVEN":"S3:   4.00pm – 7.00pm",
            "SENIORS_HELPLINERS_STORY_EIGHT":"Saturday",
            "SENIORS_HELPLINERS_STORY_NINE":"S4:    9.00am – 1.00pm",
            "SENIORS_HELPLINERS_STORY_TEN":"Minimum commitment: One duty slot (S1/S2/S3/S4) per week for at least one year upon completion of training.",
            "FRONT_DESK_VOLUNTEERS" : "Front Desk Volunteers",
           "FRONT_DESK_VOLUNTEERS_STORY" : "Answer in-coming calls and greet visitors at the front desk.",
           "COUNSELLING_ASSOCIATES" : "Counselling Associates",
           "COUNSELLING_ASSOCIATES_STORY_ONE" : "Counselling Associates (CAs), volunteers, provide support to improve the overall psycho-socio-emotional well-being of seniors living in the community who have little or no social support, through home visitations or phone calls.",
           "COUNSELLING_ASSOCIATES_STORY_TWO" : "Pre-service training is required.",
           "COUNSELLING_ASSOCIATES_STORY_THREE" : "Minimum commitment:",
           "COUNSELLING_ASSOCIATES_STORY_FOUR" : "Four contacts (home visitation or phone call) with seniors per month for at least one year upon completion of training.",
           "COUNSELLING_ASSOCIATES_STORY_FOUR_HAF":"",
           "COUNSELLING_ASSOCIATES_STORY_FIVE" : "Submission of report is required after every home visit / phone call.",
           "COUNSELLING_ASSOCIATES_STORY_SIX" : "Timing:",
           "COUNSELLING_ASSOCIATES_STORY_SEVEN" : "As per availability of the seniors and CAs,",
           "REMINISCENCE_WALK_AMBASSADORS" : "Reminiscence Walk Ambassadors",
           "REMINISCENCE_WALK_AMBASSADORS_STORY" : "Reminiscence Walk (RW) Ambassadors learn about the various Heritage Trails in Singapore and serve as RW ambassadors while accompanying seniors during Heritage Tours. They will present on the history of the heritage sites as well as engage participants in reminiscing and sharing their stories. Volunteers are required to undergo training with NHB and receive CPR+AED training.",
           "MUSIC_AMBASSADORS" : "Art and Music Ambassadors",
           "MUS_AMBASSADORS":"Music Ambassadors :",
           "MUSIC_AMBASSADORS_STORY" : "Seniors pick up skills of playing a new instrument (Ukulele) with singing accompaniment; and develop the confidence and camaraderie to perform on stage, bringing joy to charity events, community hospital, nursing homes & seniors’ centres.",
           "ART_AMBASSADORS":"Art Ambassadors :",
           "ART_AMBASSADORS_STORY":"Seniors learn to communicate and express their feelings and thoughts through arts. The artworks created by the seniors will be showcase in exhibitions and proceeds from the sale of these artworks will be donated back to SAGECC.",
           "SPECIALIST_VOLUNTEERS" : "Specialist Volunteers",
           "SPECIALIST_VOLUNTEERS_STORY" : "Specialist volunteers share their knowledge and skills through conducting training, workshops or talks to our volunteers and seniors.",
           "HMS_VOLUNTEERS" : "Home Maintenance Service (HMS) Volunteers",
           "HMS_VOLUNTEERS_STORY" : "HMS volunteers perform simple home maintenance tasks islandwide to improve the living conditions of seniors with no/low income.",
           "HMS_VOLUNTEERS_STORY_LIST" : "We need you if you are / have:",
           "HMS_VOLUNTEERS_STORY_TRUST" : "Independent and trustworthy (as you will be conducting the home visit individually)",
           "HMS_VOLUNTEERS_STORY_TRAVEL" : "Able to travel (as we serve islandwide)",
           "HMS_VOLUNTEERS_STORY_LIC" : "An electrical or plumber license",
           "HMS_VOLUNTEERS_STORY_TOOL" : "Your own set of toolbox for their areas of skills",
           "HMS_VOLUNTEERS_STORY_PAY" : "Able to pay for materials needed for the job first (SAGECC will reimburse volunteers the material cost, together with the allowance within a month)",
           "HMS_VOLUNTEERS_STORY_CHALLENGE" : "Patience for challenging clients",
           "HMS_VOLUNTEERS_STORY_COMFORT" : "Comfortable to do jobs at unmaintained houses",
           "HMS_VOLUNTEERS_STORY_COMMUNI" : "Able to use WhatsApp as a form of communication ",
           "HMS_VOLUNTEERS_STORY_INFO" : "Able to keep clients’ information and job details confidential",
           "HMS_VOLUNTEERS_STORY_DOC" : "Able to submit the necessary document and photos via WhatsApp for the job done",

           "AD_HOC_EVENTS_VOLUNTEERS" : "Ad-Hoc Events Volunteers",
           "AD_HOC_EVENTS_VOLUNTEERS_STORY" : "Help in various areas such as photography, registration, flyers distribution in ad-hoc events organised by the Centre.",
           
           "STORIES_OF_VOLUNTEERS":"Story of Volunteers",
           "EXPERIENCE_SENIOR_HELPLINE":"My Experience in The Seniors Helpline",
           "EXPERIENCE_CONTENT_ONE":"My name is Mary Chang. I first joined as a The Seniors Helpline (SHL) volunteer in 1995. In the beginning, the group was small. Through the 3 months of weekly training, I learnt how to reach out to the senior person in an informal way and providing them psycho-emotional support over the telephone.",
           "EXPERIENCE_YEARS":"Over the years, I have come across a variety of callers: -",
           "EXPERIENCE_YEARS_ONE":"1)   The inquirers who ask for information regarding some eldercare services.",
           "EXPERIENCE_YEARS_TWO":"2)   The regular callers who share in detail about their problems and daily lives.",
           "EXPERIENCE_YEARS_THREE":"3) Callers who needed tele-counselling to work through their issues in relationship, work, etc.",
           "EXPERIENCE_CONTENT_TWO":"Through my 26 years of Helpline experience, I have learned to appreciate the meaning of real listening and not merely giving answers. The bonus rewards are when callers shown their gratitude for our listening ears and encouragements. It has been an enriching and meaningful time of service at SHL with fellow Helpliners and the staff. I hope to continue volunteering in SHL as long as I am able.",
        
            "VOL_SIGNUP_FORM":"ONLINE SIGN-UP FORM",
            "VOL_NAME":"Name",
            "VOL_ADDRESS":"Address",
            "VOL_EMAIL_ADDRESS":"Email address",
            "VOL_PHONE_NUMBER":"Telephone number",
            "VOL_CAT":"Select Category",
            "NAMEENTER":"Please enter your name",
            "EMAILENTER":"Please enter your email",
            "EMAILERROR":"Please enter Valid Email",
            "MOBILEENTER":"Please enter your mobile number",
            "MOBILEERROR":"Mobile number invalid(must be 8 digit)",
            "ADDRESSENTER":"Please enter your address",
            "CATEGORYENTER":"Category field is required"
        } ,
        "CONTACT" : {
            "CONTACT_US" : "Contact Us",
            "ADDRESS" : "Address",
            "TEL" : "Tel",
            "FAX" : "Fax",
            "EMAIL" : "Email",
           "VIEW_LOCATION" : "View location on google map",
           "CLICK_THE_BELOW_IMAGE" : "Direction (click on the map to expand)",

            "OP_HRS_HED":"Operation Hours:",    
            "OP_HRS_TIME":"Monday – Friday  8.30am – 5.30pm",


           "ENQUIRY_FORM" : "Enquiry Form (online)",

           "FIRST_NAME" : "First Name",
           "ENTER_YOUR_FIRST_NAME" : "Enter your first name",

           "LAST_NAME" : "Last name",
           "ENTER_YOUR_LAST_NAME" : "Enter your last name",

           "YOUR_EMAIL_ADDRESS" : "Your email address",
           "ENTER_YOUR_EMAIL_ADDRESS" : "Enter your email address",

           "YOUR_PHONE_NUMBER" : "Your phone number",
           "ENTER_YOUR_VALID_PHONE_NUMBER" : "Enter your valid phone number",

           
           "WRITE_SOMETHING_ABOUT_YOU" : "Your Message",
           "ABOUT_YOU" : "Enter Your Message",

           "SEND" : "Send",

           "NAME_IS_REQUIRED" : "Name is Required",
           "EMAIL_IS_REQUIRED" : "Email is Required",
           "MOBILE_IS_REQUIRED" : "Mobile is Required",

           "PLEASE_ENTER_VALID_EMAIL" : "Please Enter Valid Email",
           "MOBILE_MUST_BE_8_DIGIT" : "Mobile must be 8 digit",
        } ,
        "OURWORK" : {
            "OUR_WORK" : "Our Work",
            "OUR_WORK_CONTENT_ONE":"We provide direct services to older persons, their family members and caregivers who are facing psycho-social-emotional issues.",
            "OUR_WORK_CONTENT_TWO":"In recent years, we have also introduced “upstream”, developmental programmes and services to facilitate active ageing among able-bodied, physically fit and emotionally stable seniors.",
            "OUR_WORK_CONTENT_THREE":"As an advocate for seniors in our society and to act as their “voice”, the Centre also conducts outreach and public education programmes such as workshops, talks, educational seminars and forums. ",
            "COUNSELLING_SERVICE" : "Counselling Service",
            "THE_SENIORS_HELPLINE" : "The Seniors Helpline",
            "PUBLIC_EDUCATION_PROGRAMME":"Public Education Programme",    
            "CREST_BUANGKOK":"CREST @ Anchorvale & Buangkok",    
            "CREST_SAGE":"CREST SAGE @ Anchorvale & Buangkok",    
            "CREST_CRITERIA":"CREST Criteria",
            "CREST_RESIDENTS":"Residents living in Anchorvale & Buangkok",
            "CREST_AGE":"18 years old and above, can be caregiver",
            "CREST_FOR_WHO":"Individuals at risk of / diagnosed with mental health conditions",
            "CREST_OPERATING_HOURS_HEAD":"Operating Hours",
            "CREST_OPERATING_HOURS1":"Monday – Friday",
            "CREST_OPERATING_HOURS2":"8.30 am – 5.30 pm",
            "CREST_REFERRAL1":"CREST Referral",
            "CREST_REFERRAL2":"For referral, please fill in CREST Referral Form and email it to us at",
            "CREST_REFERRAL3":"Download Form",
            "CREST_DESCRIPTION":"The Community Outreach Team (CREST) focuses on raising public awareness of mental health conditions and dementia, promotes early recognition of at-risk individuals, provides emotional support to individuals and their caregivers and links individuals to relevant health and social care services when necessary. ",
            "ART_PROGRAMME":"Art and Music Programme",
            "HOME_MAINTENANCE_SERVICES":"Home Maintenance Services",
            "COUNSELLING":"Counselling",
            "COUNSELLING_CONTENT":"Our counselling service aims to enhance the total well-being of older persons, their caregivers and family members through face-to-face counselling. We provide an avenue for the clients to discuss their issues and concerns during the difficult transition of their lives and help to address their personal, social, or psychological challenges.",
            "COUNSELLING_OUR_CRITERIA_1":"Our",
            "COUNSELLING_OUR_CRITERIA_2":"C",
            "COUNSELLING_OUR_CRITERIA_3":"riteria",
            "COUNSELLING_CRITERIA_ONE":"Singaporean Or PR",
            "COUNSELLING_CRITERIA_TWO":"50 Years Old & Above",
            "COUNSELLING_CRITERIA_THREE":"No Minimum Age For Caregivers Or Family Members",
            "OUR_COUNSELLING_FEES":"Counselling Fees",
            "COUNSELLING_FEES_PR":"Singaporeans or PR",
            "COUNSELLING_FEES_ONE":"Centre-based: Free-of-charge",
            "COUNSELLING_FEES_ONE1":"Home counselling: Chargeable (please call for more information)",
            "COUNSELLING_FEES_TWO":"Non-Singaporean",
            "COUNSELLING_FEES_THREE":"Centre-based: $50/hr",
            "COUNSELLING_FEES_FOUR":"Home counselling: $100/hr",
            "COUNSELLING_OUR_OPERATION_HOURS":"Our",
            "COUNSELLING_OUR_OPERATION_HOURS_O":"O",
            "COUNSELLING_OUR_OPERATION_HOURS_PER":"peration",
            "COUNSELLING_OUR_OPERATION_HOURS_H":"H",
            "COUNSELLING_OUR_OPERATION_HOURS_URS":"ours",

            "COUNSELLING_OPERATION_WORK_HOURS_ENG":"• Monday – Friday:    8.30 am – 5.30 pm",
            "COUNSELLING_OPERATION_LUNCH_HOURS":"• Lunch (closed):    12.30pm – 1.30pm",

            "COUNSELLING_OPERATING_HOURS":"Operating Hours",
            "COUNSELLING_OPERATING_HOURS_MF":"Monday – Friday",
            "COUNSELLING_OPERATING_HOURS_MF_TIME":"8.30 am – 5.30 pm",
            "COUNSELLING_OPERATING_HOURS_LUNCH":"Lunch (closed)",
            "COUNSELLING_OPERATING_HOURS_LUNCH_TIME":"12.30pm – 1.30pm",
            "COUNSELLING_REFERRAL_HEAD":"Referral",
            "DOWNLOAD_FORM":"Download Form",
            "COUNSELLING_OUR_CRITERIA":"Our Criterial",
            "DOWNLOAD_HMS_FORM":"Download HMS Referral Form",
            "SENIORS_HELPLINE_HOURS_MF":"Monday – Friday",
            "SENIORS_HELPLINE_HOURS_MF_TIME":"9.00am - 7.00pm",
            "SENIORS_HELPLINE_HOURS_SAT":"Saturday",
            "SENIORS_HELPLINE_HOURS_SAT_TIME":"9.00am - 1.00pm",
            "SENIORS_HELPLINE_HOURS_CLOSE":"Closed on Sunday & Public Holidays.",
            "SENIORS_HELPLINE_NUMBER_LABEL":"The Seniors Helpline",
            "SENIORS_HELPLINE_NUMBER":"1800 555 5555",

            "COUNSELLING_MORE_INFO":"For more information, kindly contact SAGECC at 6354 1191 or write us to",
            "COUNSELLING_REFERRAL":"For referral, please fill up the Client Referral Form and email it back to us at",
            "COUNSELLING_INFO":"For referral or personal request, please fill up the Client Referral Form and email it back to us at",
            "COUNSELLING_FORM":"Counselling Form",
            "GROUP_WORK":"Group Work",
            "GROUP_WORK_ONE":"Expressive Therapeutic Activities is a Group Work programme specially tailored for seniors, in a group of 8 – 12 participants, over a period of six weeks. It is a platform for the participants to express their emotions in a healthy way, through identifying and strengthening their inner resources to overcome obstacles and develop their potential.",
            "GROUP_WORK_TWO":"Guided Autobiography for Seniors* (GAB) is a programme designed to help seniors write and share their life stories.  It is a meaningful journey of self-discovery, reflection and celebration as they connect with their past, entering the future with a sense of purpose and appreciation of life. It is conducted in a group of 8 – 12 participants, over a period of eight weeks",
            "GROUP_WORK_AIM":"The group works aim to increase interaction and socialization among the seniors, and to improve their psycho-emotional status.",
            "GROUP_WORK_THREE":"For enquiry on counselling or group work, kindly contact SAGECC at 6354 1191 or click",
            "GROUP_WORK_HERE":"here",
            "SENIORS_HELPLINE_0NE":"The Seniors Helpline is a toll-free National Helpline, with the support of National Council of Social Service (NCSS), for any person aged 50 years and above to call in or anyone who wants to talk about or enquire on issues or services related to older persons. It has been a channel of support for seniors, their family members and caregivers since 2005 by providing listening ears to them and addressing their concerns and needs.",
            "SENIORS_HELPLINE_HOURS":"Helpline operation hours: ",
            "SENIORS_HELPLINE_HOURS_ONE":"Monday – Friday:  9.00am - 7.00pm ",
            "SENIORS_HELPLINE_HOURS_TWO":"Saturday:     9.00am - 1.00pm",
            "SENIORS_HELPLINE_HOURS_THREE":"Closed on Sunday and Public Holidays.",
            "PUBLIC_EDUCATION_PROGRAMME_CONTENT":"We work with community partners to organise talks, heritage tours and training workshops under our Public Education programme to motivate life-long learning, encourage active ageing and enhance social skills, life skills and emotional stability of the seniors.", 
            "CREST_CONTENT": "CREST SAGE, funded under the Community Health Masterplan developed by the Agency for Integrated Care (AIC), together with the Ministry of Health (MOH), supports residents living in Anchorvale and Buangkok.",
            "PUBLIC_TALKS":"Talks And Workshops",
            "PUBLIC_TALKS_CONTENT":"These public education activities focus on sharing, educating and imparting knowledge on common gerontology topics to the seniors as well as the community partners.",
            "PUBLIC_TALKS_CONTENT_HOPE":"We hope to enhance public awareness on issues pertaining to ageing and caring for the elderly, and to encourage and empower the seniors to lead meaningful, fulfilling lives - not just for personal growth and life-satisfaction, but also to contribute to the community through the talks and workshops.",
            "PUBLIC_REMINISCENCE":"Reminiscence Walks (RWs)",
            "PUBLIC_REMINISCENCE_CONTENT":"Since December 2018, our Centre has been partnering National Heritage Board in RWs with its aims to empower senior volunteers as reminiscence walk ambassadors and to address the issue of social isolation amongst older persons in Singapore through heritage and culture participation and engagement. We bring lonely, isolated seniors to heritage trails and museums to view Singapore’s history. The trained RW ambassadors will share the history of the heritage sites with the seniors and engaging them in reminiscence activities. All the ambassadors are volunteers and mainly from the Pioneer Generation with the oldest member being 78 years old. Despite their diverse background, they share a common passion to serve their peers by “doing their part” as long they are physically able. We have been making an effort to recruit volunteers who speak different languages or dialects to present the walks in a language or manner that is familiar to the participants.",
            "ART_PROGRAMME_CONTENT":"Art and Music programmes encourage and empower seniors to lead meaningful and fulfilling lives. To find out more or join the programmes, do visit our microsite: ",
            "HOME_CONTENT":"HMS provides free basic home maintenance services to anyone who is:",
            "HOME_LIST_ONE":"Singaporean or PR",
            "HOME_LIST_TWO":"50 years old and above",
            "HOME_LIST_THREE":"holding a blue CHAS card or on ComCare Assistance Scheme. (The eligibility document must be valid at time of application.)",
            "TABLE_HEAD_ONE":"Subsidy Criteria",
            "TABLE_CATEGORY_A":"Category A",
            "TABLE_ROW_ONE":"ComCare Assistance Scheme",
            "TABLE_ROW_ONE_CONTENT":"The eligibility document must be valid at time of application.",
            "TABLE_ROW_TWO_REPLACE":"We replace faulty item, NOT install new item, and only provides basic materials. ",
            // "TABLE_ROW_TWO_NEW":"NOT install new item.",
            "TABLE_CATEGORY_B":"Category B",
            "TABLE_ROW_THREE":"Blue CHAS Assistance Scheme",
            "TABLE_ROW_THREE_CONTENT":"Maximum 3 issues per request.",
            "TABLE_ROW_THREE_CONTENT_EXAMPLE":"E.g. 1 faulty light in living room, 1 faulty cistern flushing system, 1 leaking kitchen sink.",
            "SERVICES":"We Offer Basic Services:",
            "SERVICES_CONTENT_ONE":"These services are provided by a group of skilled committed handymen, electricians and plumbers who are mostly seniors themselves.",
            "SERVICES_CONTENT_TWO":"For organizations who wish to make a referral, please fill up the HMS Request Form and email it back to us at ",
            "SERVICES_CONTENT_TWO_MAIL":"hms@sagecc.org.sg,",
            "SERVICES_CONTENT_TWO_COPY":" together with a copy of the senior’s:",
            "SERVICES_CONTENT_THREE":"NRIC (Front and Back). Can be omitted if Request Form is duly filled by Referral agency.",
            "SERVICES_CONTENT_FOUR":"Proof of subsidy",
            "SERVICES_CONTENT_FIVE":"Areas to be repaired",
            "SERVICES_CONTENT_SIX":"You may block out the first 4 digits of the NRIC number. Our Staff will contact you within 5 working days. ",
            "SERVICES_CONTENT_SEVEN":"For individuals who seek to engage our services, please approach your nearest Cluster Support, Family Service Centre, Seniors Activities Centre or any equivalent Social Service Agencies, to ask for a referral to us.",
            "SERVICES_CONTENT_Ei":"For enquiry, kindly contact SAGECC at 6354 1191 or click",
            "HMS":"HMS Testimonial",
            "HMS_CONTENT_ONE":"Thank you for your prompt action to call of action for my sick elderly mother living alone that you have dispatch your volunteer handy man to assist her in installing the water piping attachment to the tap and drainage.",
            "HMS_CONTENT_TWO":"With our aging society, with the helping from yourself and volunteers, our Pioneer and Merdeka generation can have a peaceful and stable retirement.",
            "HMS_CONTENT_THREE":"Please allow me to show you our appreciation and gratitude to you, your volunteer handyman (pls help me play the message to him/her), and your organisation.",
            "HMS_CONTENT_FOUR":"Yours respectfully,",
            "HMS_CONTENT_FIVE":"Yong CK",
            "HMS_CONTENT_T1":"Mrs T  aged 56 years married with two children was referred by the Polyclinic pertaining to caregiver stress. Mrs T is the youngest child in her family and she is the main caregiver of her 92 years old mother who is suffered from a stroke and has dementia.",
            "HMS_CONTENT_T2":"Mrs T enjoys a close relationship with her mother and is trying to come to terms with her mother’s deteriorating health condition and mortality. Although she has own children and her siblings, they were not supportive towards client and the care of the mother. Mrs T has been expressing caregiver stress as well as the difficulties she will face coping with the passing of the mother.",
            "HMS_CONTENT_T3":"During the initial counselling sessions, client could not contain her tears and would express her difficulties towards the caregiving of the mother. She would verbalize the lack of emotional support from her other family members and occasionally felt she was closed to the verge of attempting suicide. Through the six counselling sessions, client is calmer and made efforts to improve her situation. She was more open to try new ways to alleviate her stresses and it worked. Her deep sense of feeling guilty towards her mother is lowered as she affirmed herself that she has given her best to care for her mother. She accepted her mother’s frailty in health and that one day her mother will pass on and meet her maker.",
            "HMS_CONTENT_T4":"The amplifying and highlighting of client’s strengths and resilience have helped her in increasing her self-awareness, providing her with confidence to face future adversities.",
            "HMS_CONTENT_V1":"Mr V, 76 years old, was referred to SAGECC for depression. During the initial few sessions, Mr V’s mood was low citing recent loss of job, with difficulty in securing an alternative employment. His wife and all his friends have passed on over the years leaving him with no one that he can confide in. Mr V had a distant relationship with children and grandchildren. He was suffering from social isolation even though he was living with his daughter and family. He felt that the family members do not respect and care for him. The presence of a counsellor has been a solace and outlet for Mr V to confide on his feelings and frustration.",
            "HMS_CONTENT_V2":"On one occasion Mr V had strong suicide ideation.  The counsellor immediately attended to his emotional distress with empathetic listening and rendered care and support, hence lowering his suicide ideation at the end of the session. With the consent from Mr V, he was referred to a day care centre.",
            "HMS_CONTENT_V3":"Mr V was noticeably happier now and was very thankful to the counsellor who had walked with him during his darkest moments. Mr V shared that he does not have any suicide thoughts anymore and was ready to face any challenges ahead. ",
            "HMS_CONTENT_LIM1":"Mr Lim, 70 was referred by a hospice. The couple with no children, live in a three-room HDB flat. They worked in the same company as cleaners till 2016. Mr Lim stopped working when multiple health issues surfaced, bladder problem, stroke as well as throat issue. In addition, he was also suffering from years of depression and was under long-term medication. Mr Lim was receiving counselling from SAGECC for his eventual grief and loss of wife who was battling with stage 4 cancer with few months to live, as well as adjustment issue. Unfortunately, Mr Lim’s wife passed away not long later.",
            "HMS_CONTENT_LIM2":"During his darkness period, regular counselling was provided to him in view of his psycho-emotional well-being. He received 16 sessions of counselling till his emotions were stabilized and was able to cope independently. The support given to Mr Lim aided him in his grief and loss process and at the same time helped him coped with the adversities and the changes in roles and functions. After a year, Mr Lim reported a drastic improvement in his mood. In addition, Centre’s Counselling Associate was introduced to Mr Lim as a form of follow-up to check in on his overall well-being.",
            "HMS_CONTENT_LIM3":"During the initial home visit, the counsellor noticed that Mr Lim ‘s house has been without lights which further aggravated his depressive symptoms.  Client’s wish of bringing wife home from hospice during her last journey was fulfilled when the Home Maintenance Service was able to provide the couple with an installation of water heater as client suffers from chronic conditions and staggering between kitchen and toilet with boiling kettle was posed as danger. Home switch board was replaced with new one to better support the new water heater system. With the necessary home improvement, not only does it enhance the overall living condition and it also improves one’s mental outlook.",
            "VOLUNTEERING":"Volunteering",
            "DONATION":"Donation",
            "OTHERS":"Others",
            "PUBLIC_CONTACT":"For enquiry, kindly contact SAGECC at 6354 1191 or click",
            "TABLE_REMARKS":"Remarks",
            "TABLE_REQUEST":"We do not accept urgent request.",
            "GROUP_WORK_ONE_HEAD":"Expressive Therapeutic Activities",
            "GROUP_WORK_TWO_HEAD":"Guided Autobiography for Seniors",
            "STORY_CHHUA":"Story Of Mr.Chhua",
            "THATCHED_GRASS":"Thatched Grass-Mr.Soh Peng Hui v3",
        },
        "DONATION":{
            "DONATION_CONTENT_ONE" :"All donations to SAGE Counselling Centre are tax deductible. Please provide us the information for us to submit for tax deduction and to issue you a tax exemption receipt to be mailed/emailed to you.",
            // "DONATION_TAX":"We require the following details to be submitted for tax deduction:",
            "DONATION_NRIC":"NRIC/FIN Number",
            "DONATION_NAME":"Name in NRIC/FIN",
            "DONATION_ADDR":"Contact Number(s)",
            "DONATION_TEL":"Address",
            "DONATION_EMAIL":"Email",
            // "DONATION_CONTENT_TWO":"Your contribution will help us to make a difference to the lives of our clients.",
            "DONATION_MODE":"Donation Mode",
            "DONATION_PAYNOW":"PAYNOW",
            "PAYNOW_ACC_HED":"Account Name :",
            "PAYNOW_NAME":"SAGE Counselling Centre",
            "PAYNOW_UEN_HED":"UEN :",
            "PAYNOW_UEN":"T08SS0025E",
            "BANK_TRANSFER":"BANK TRANSFER",
            "BANK_DBS_HED":"DBS :",
            "BANK_DBS":"005-901741-0 (Current Accounts)",
            "BANK_CODE_HED":"Bank Code :",
            "BANK_CODE":"7171, Branch Code : 005",
            "PAYNOW_NUMBER_HED":"Account Number :",
            "PAYNOW_NUMBER":"005-901741-0",
            "DONATION_CHEQUE":"BY CHEQUE",
            "CHEQUE_CONTENT_ONE":"Cheque payable “SAGE Counselling Centre” and mail to",
            // "CHEQUE_CONTENT_TWO":"SAGE Counselling Centre",
            "CHEQUE_CONTENT_TWO":"1 Jurong West Central 2, #06-04 Jurong Point Shopping Centre, Singapore 648886.",
            // "CHEQUE_CONTENT_FOUR":" Singapore 648886.",
            "DONATION_CASH":"CASH",
            "CASH_CONTENT":"You can visit our office during office hours 8.30am to 5.30pm on weekdays.",
        },
        "EVENT":{
            "EVENT_SAGECC":"SAGE Counselling Centre",
            "EVENT_HEAD":"Events",
            "EVENT_CONTENT_ONE":"Besides our core services and programmes, we also organise events / activities such as forums, seminars, talks, and training workshops to motivate life-long learning and enhance social skills, life skills and emotional stability of the seniors.",
            "EVENT_CONTENT_TWO":"To keep pace with the increase in our ageing population and to provide more holistic programmes to the seniors, we also need to raise fund to run more enriching and meaningful activities for the seniors.",
            "EVENT_UPCOMING":"Upcoming Events",
            "EVENT_PAST":"Past Events",
            "EVENT_POSTPONE":"'Due to Covid-19, our physical workshops have been postponed until a later date.'",
        },
        "COUNSELLINGFORM":{
            "languageList" : ["English","Chinese","Malay","Tamil"],
            "raceList": [
                {value:'chinese',name: "Chinese"},
                {value: 'malay',name: "Malay"},
                {value: 'indian',name: 'Indian'},
                {value: 'eurasian', name: 'Eurasian'},
                {value: 'others',name: 'Others'}
            ],
            "serviceList": [
                {value:'audio',name: 'Phone Call'},
                {value: 'video',name: 'Video Call'},
              ],

            "COUNSELLINGSERVICEFORM":"Counselling Service Request Form",
            "FIRSTNAME":"First Name",
            "ISSNAME":"First Name is Required",
            "NAMEERRROR":"Enter your first name",
            "LASTNAME":"Last Name",
            "LASTERROR":"Enter your last name",
            "AGE":"Age",
            "GENDER":"Gender",
            "AGEERROR":"Enter your age",
            "MALE":"Male",
            "FEMALE":"Female",
            "RACE":"Race",
            "SELECTRACE":"Select your race",
            "LANGUAGE":"Language",
            "SELECTLANG":"Select Language",
            "NATIONALITY":"Nationality",
            "ENTERNATION":"Enter your nationality",
            "CONTACTNO":"Contact Number",
            "ENTERCONTACT":"enter your contact number",
            "EMAILADDRESS":"Your Email Address",
            "ENTEREMAIL":"enter your email address",
            "COUNSELINGSER":"Counselling Service",
            "SELECTCOUNS":"select your counselling service",
            "CONTACTADD":"Your Contact Address",
            "ENTERCONTACTADD":"enter your  valid contact address",
            "TELLAS":"Tells us about your issues/concern",
            "ISSUE":"issues/concern",
            "CONTACTREQ":"Contact Number is Required",
            "CONREQ":"Contact Number Invalid(must be 8 digit)",
            "EMAILENTER":"Please enter your email",
            "EMAILERROR":"Please enter Valid Email",
            "COUNSREQ":"Counselling Service is Required",
            "CANCEL":"Cancel",
            "SUBMIT":"Submit",
            "THANKYOU":"Thank You!",
            "AAAA01":"Your Counselling Service request form has been successfully submitted!",
            "AAAA02":"Do you wish to download your form?",
            "AAAA03":"Download Form",
            "AAAA04":"Our Work",
            "AAAA05":"Counselling Service",
            "AAAA06":"Online request form",
            
        },
        "SUCCESSSTORIES":{
            "SUCCESS_SAGECC":"SAGE Counselling Centre",
            "TESTI_MONIAL":"Success Story",
        },
        "GALLERY":{
            "SUICIDE_PREVEN":"SAGECC Suicide Prevention Forum 2018",
            "SINGAPORE_NIGHT":"Expressive Therapeutic Activity for Seniors (Singapore Night Festival)",
            "VIDEOS_GALLERY_1":"Join SAGE Counselling Centre as a Volunteer!",
            "VIDEOS_GALLERY_2":"The Seniors Helpline - Tele-Counselling (Mandarin)",
            "VIDEOS_GALLERY_3":"The Seniors Helpline - Tele-befriending (English)",
            "HEAR_VOL":"Hear from our volunteers why they volunteer",
            "WE_CARE":"We Listen We Care",
            "WE_CARE_VIDO3":"We Listen We Care",

        },

        "SIGNUPEVENT":{
            "SIGNUP_FOR_EVENT":"Signup For Event",
            "FULL_NAME":"Full Name",
            "CON_NO":"Contact Number",
            "CON_WHAPP":"Contactable by Whatsapp?",
            "YES":"Yes",
            "NO":"No",
            "EMAIL":"Your Email Address",
            "DOB":"DOB",
            "GEN":"Gender",
            "MALE":"Male",
            "FEMALE":"Female",
            "Other":"Other",
            "LANG":"Spoken Language(s)",
            "HOW":"How do you know about this event?",
            "BACK":"Back",
            "SUBMIT":"Submit",
            "EN_NAME":"Enter your Name",
            "NAME_RE":"Name is Required",
            "EN_CON":"Enter your contact number",
            "CON_RE":"Contact Number is Required",
            "CON_INVALID":"Contact Number Invalid(must be 8 digit)",
            "EN_EMAIL":"Enter your email address",
            "EMAIL_RE":"Email is Required",
            "EMAIL_INVALID":"Email is Invalid",
            "EN_DOB":"Enter your DOB",
            "SEL_LANG":"--Select Language--",
            "SEL_OPT":"Select your option",
            "RE_REQ":"Reason is Required",
            
        },
        "DONATEFORM":{
            "DONARPAT":"Donor Particulars",
            "CALLME":"Donor Name",
            "NRIC":"NRIC / FIN / UEN ",
            "PAYMENT":"Payment Method",
            "PAYMENTSELECT":"Select Payment Method",
            "PAYMENTPAYNOW":"Paynow",
            "PAYMENTCASH":"Cash",
            "PAYMENTBANK":"Bank Transfer",
            "PAYMENTREQURIED":"Payment Method is required",
            "FULLNAME":"Full Name",
            "NAMEERR":"Name is required",
            "NRREQ":"NRIC required",
            "PLSREQ":"Please enter the Valid NRIC and the letter should be capital",
            "EMAILENTER":"Email is required",
            "EMAILADDRESS":"Email",
            "EMAILERROR":"Enter Valid Email",
            "CONNO":"Contact No",
            "CONREQ":"Contact is required",
            "CONREQSS":"Contact must be 8 digit",
            "TAXREQ":"Tax Deduction",
            "ADDRESS":"Address",
            "ENADD":"Address is required",
            "NEXT":"Next",
            "DONGIVE":"Donate via Giving.sg",
            "DONGPAY":"Donate via PayNow",
            "CANCEL":"Cancel",
            "PROCEED_QRCODE":"Proceed to Qr code",
            "QR_CODE":"Scan the QR Code",
            "CHEQUE":"Cheque",
        },
        "CHAT":{
            "SEND":"Send Request",
            "ASK":"Ask SAGE CC",
        },
        "MAINTENANCE":{
            "UNDER_MAINTENANCE":"Under Maintenance",
        }
    }
}