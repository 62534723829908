import React from 'react';
import { connect } from 'react-redux';
import HistImg from '../../assets/img/img_hstry.png';
import AbtBanner from '../../assets/img/img_abtbanr.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { isWebLogin } from "../../../helpers";
import { toastr } from '../../../services';
// import {userService} from '../../../services/user.service'
import {userActions} from '../../../store/actions'
import {history} from '../../../helpers'
import UploadIcon from '@material-ui/icons/CloudUpload';


 class VolunteerSignUp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
      userName:'',
      gender:'',
      dob:'',
      email:'',
      password:'',
      no:'+65',
      mobile:'',
        }
    }


    componentWillReceiveProps(newProps){      
      if(newProps && newProps.saveStatus && newProps.saveStatus == true){
          this.props.resetSave()
          this.props.triggerFunc(false)
          history.goBack()
      }
  }

    handleInputChange = (e) => {
      e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
      if (e.target.name == "email") {
    this.validate(this.state.email)
  }
 }
handleLogin = () => {
  this.props.handleLogin(true)
}
   handleImgChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                fileData: file,
                imageName: file.name,
                imagePreviewUrl: reader.result
            })
        }
        if (e.target.files && e.target.files[0]) {
            reader.readAsDataURL(file)
        }
    }
 
passCheck = (value) => {
  const passCheck = /^(?=.*[A-Z])/;
  if(passCheck.test(value) && ((value && value.length) >=8)){
    return true
  }else{
    return false
  }
}
    validate = (value) => {
      var emailRegex = /^([a-z])+([a-z0-9_\.\-])+\@(([a-z\-])+\.)+([a-z]{2,3})$/;
      if (emailRegex.test(value)) {
        return true
      } else {
        return false;
      }
      }

      handleSubmit = (e) => {
        e.preventDefault()
        const {userName,dob,gender,email,password,fileData,imageName,user,id,mobile,days,time,no} = this.state
      
        this.setState({submitted:true})
        if(userName && email && this.validate(email) && password && this.passCheck(password) && mobile && mobile.length == 8){
            const formData = new FormData()
            
            const mobileConcat = no.concat(mobile)
            let obj = {
                userName:userName,
                email:email,
                password:password,
                role:"5",
                // mobile:mobileConcat,
                mobile:mobile,
                // dob:dob,
            }          

            if(gender){
              obj['gender'] = gender
            }
          
            formData.append("userData",JSON.stringify(obj))
            fileData && formData.append("profileImage",fileData)
            this.props.save(formData)
        }
        else{
            toastr.error('Mandatory fields are missing')
        }
       
    }

        render(){
            const responsive = {
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 2,
                  slidesToSlide: 2 // optional, default to 1.
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                }
              };  
            return(
            <div className="sign-wrap">
                {/* <nav class="breadcrumb">
                    <a class="breadcrumb-item" href="#"><i className="fas fa-home"></i></a>
                    <a class="breadcrumb-item" href="#">Volunteer</a>
                    <span class="breadcrumb-item active">Sign-up as a Volunteer</span>
                </nav>
                <Carousel className="sign-slide"
                        swipeable={false}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        customTransition="all 1.2s ease 0s"
                        transitionDuration={1000}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        deviceType={this.props.deviceType}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                    >
                       <div className="abt-banr">
                            <img src={AbtBanner}/>
                        </div>
                        <div className="abt-banr">
                            <img src={AbtBanner}/>
                        </div>
                        <div className="abt-banr">
                            <img src={AbtBanner}/>
                        </div>
                    </Carousel> */}

                        {/* <div className="text-center">
                          <h3>Volunteer</h3>
                          <h4>Sign-up as a Volunteer</h4>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                             Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                              when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                              when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                          </p>
                        </div> */}


                            <div className="card-body">
                              <h5>SIGN UP</h5>
                                <form onSubmit={this.handleSubmit}>
                                  <div className="row">
                                      <div className="col-lg-6">
                                        <div className="form-group">
                                          <label> Name &nbsp;<span class="mandatory">*</span></label>
                                          <input type="name" name="userName"  value={this.state.userName} onChange={this.handleInputChange} className="form-control" placeholder="Enter Name" />
                                          {this.state.submitted && !this.state.userName && <div className="mandatory">Please enter your name</div>}
                                        </div>
                                      </div>
                                    

                                      <div className="col-lg-6">
                                        <div className="form-group">
                                          <label>Email Address</label>&nbsp;<span class="mandatory">*</span>
                                          <input type="name" name="email"  value={this.state.email} onChange={this.handleInputChange} className="form-control" placeholder="Enter Email Address" />
                                          {this.state.submitted && !this.state.email && <div className="mandatory">Please enter your email</div>}
                                          {this.state.submitted && this.state.email && !this.validate(this.state.email) && <div className="mandatory">Please enter Valid Email</div>}
                                        </div>
                                      </div>

                                      <div className="col-lg-6">
                                        <div className="form-group">
                                          <label>Password</label>&nbsp;<span class="mandatory">*</span>
                                          <input type="password" name="password"  value={this.state.password} onChange={this.handleInputChange} className="form-control" placeholder=" Enter Password" />
                                          {this.state.submitted && !this.state.password && <div className="mandatory">Please enter the password</div>}
                                          {this.state.submitted && this.state.password && !this.passCheck(this.state.password) && <div className="mandatory">Password must be 8 digit with minimum one capital</div>}
                                        </div>
                                      </div>

                                      <div className="col-lg-6">
                                        <div className="form-group">
                                          <label>Contact Number(Home/Mobile)</label>&nbsp;<span class="mandatory">*</span>
                                          <div className="input-group">
                                              <input type="text" value={this.state.no}  className="form-control ph-input" disabled/>
                                              <input type="name" name="mobile"  value={this.state.mobile} onChange={this.handleInputChange} className="form-control pl-2" placeholder="Enter Contact Number" />
                                              {this.state.submitted && !this.state.mobile && <div className="mandatory">Please enter your mobile number</div>}
                                              {this.state.submitted && this.state.mobile && this.state.mobile.length != 8 && <div className="mandatory">Mobile number invalid(must be 8 digit)</div>}
                                          </div>
                                        </div>
                                      </div>   
                                     

                                            <div className="col-lg-6">
                                            <div className="form-group">
                                                <label >Gender</label>
                                                <div className="col-sm-12 px-0">
                                                    <div className="radio-box">
                                                        <label>
                                                            <input type="radio" value="male" onChange={this.handleInputChange} name="gender" checked={this.state.gender == "male"}/>
                                                            <span>Male</span>
                                                        </label>
                                                        <label>
                                                            <input type="radio" value="female" onChange={this.handleInputChange} name="gender" checked={this.state.gender == "female"}/>
                                                            <span>Female</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>


                                            <div className="col-lg-6">
                                            <div className="form-group">
                                            <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Image</label>

                                                <div className="col-sm-12">
                                                <div className="chos-wrap">
                                                    <label className="chos-btn" for="file"> 
                                                        <UploadIcon className="mr-2"/>Choose File
                                                        <input type="file" name="image" id="file" single="" accept="image/*" onChange={this.handleImgChange} />
                                                    </label>
                                                </div>
                                                <div>
                                                    <img src={this.state.imagePreviewUrl} height="80px" width="80px" />
                                                </div>
                                                </div>
                                            </div>
                                            {/* <div className="btn-wrap">
                                            <button onClick={this.handleLogin} className="line-btn">Back to Login</button>

                                            </div> */}
                                        </div>
                                        </div>
                                     
                                  <div className="btn-wrap">
                                     <button className="gradient-btn" type="submit">Sign Up</button>
                                     <button onClick={this.handleLogin} className="line-btn">Back to Login</button>
                                  </div>
                                </form>
                            </div>

            </div>
            )            
        }}

const mapStateToProps = state => {
  return {
    saveStatus : state.users.saveStatus,
    userList : state.users.userList,
}
}


const actionCreators = {
  save:userActions.save,
  getList : userActions.getList,
  resetSave:userActions.resetSave,
  resetList : userActions.resetList
}

const connectedVolunteerSignUp = connect(mapStateToProps, actionCreators)(VolunteerSignUp);
export { connectedVolunteerSignUp as VolunteerSignUp };