import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { userActions } from '../../../store/actions'
import { history } from '../../../helpers'
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            submitted: false
        }
    }
    componentWillReceiveProps(newProps) {
        if (newProps && newProps.loggedIn.loggedIn) {
            history.push('/admin/dashboard')
        }
    }
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });

    }
    forgetpasswordChange = (e) => {
        e.preventDefault()
        history.push('/admin/forgetpassword')
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ submitted: true })
        const { username, password } = this.state
        if (username && password) {
            let obj = {
                email: username,
                password,
            }
            if (username == "superadmin@gmail.com") {
                obj['role'] = "1"
            } else {
                obj['role'] = "2"
            }
            this.props.login(JSON.stringify(obj))
        }
    }
    render() {
        return (
            <Fragment>
                <div className="login-block">
                    <div className="row m-0">
                        <div className="col-md-6">
                            <div className="login-logo"></div>
                            <div className="login-box">
                                <h5>SIGN IN</h5>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <input type="text" name="username" value={this.state.username} onChange={this.handleInputChange} className="form-control" placeholder="Enter Username*" />
                                        {this.state.submitted && !this.state.username && <div className="mandatory">Required</div>}
                                    </div>
                                    <div className="form-group">
                                        <input type="password" name="password" value={this.state.password} onChange={this.handleInputChange} className="form-control" placeholder="Enter Password*" />
                                        {this.state.submitted && !this.state.password && <div className="mandatory">Required</div>}
                                    </div>
                                    <button className="btn primary-btn" type="submit">SIGN IN</button>
                                    {/* <div className="my-3 d-flex justify-content-center align-items-center">
                                        <a href="/forgetpassword" className="auth-link text-black" onClick={this.forgetpasswordChange}>Forgot password?</a>
                                    </div>                            */}
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="login-bg"></div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        loggedIn: state.authentication
    };
}

const actionCreators = {
    login: userActions.login
};



const connectedLogin = connect(mapStateToProps, actionCreators)(Login);
export { connectedLogin as Login };
