import React,{Component,Fragment} from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import {history,ReactPagination} from '../../../helpers'
import {ratingActions} from '../../../store/actions'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {toastr} from '../../../services'
import {config} from '../../../config'
import VisibilityIcon from '@material-ui/icons/Visibility';

class FetchSupport extends Component{
    constructor(props){
        super(props);
            this.state={
                supportList:[],
                limit:10,
                page:1,
                searchTxt:'',
                totalCount:''
            }
        
    }
    componentDidMount(){
       this.list()
    }
    componentWillReceiveProps(newProps){
       
        if(newProps && newProps.supportList && newProps.supportList.data){
            let data = newProps.supportList && newProps.supportList.data && newProps.supportList.data.rows
            let request = newProps.supportList && newProps.supportList.request ? newProps.supportList.request : []
            let totalCount = newProps.supportList && newProps.supportList.data && newProps.supportList.data.count ? newProps.supportList.data.count : []
            if(data.length == 0){
                toastr.error("Result Not Found")
            }
            this.setState({supportList:data,totalCount,pageCount:totalCount/request.limit})
            this.props.resetList()
        }
        // if(newProps && newProps.deleteStatus && newProps.deleteStatus === true){
    
        //     this.props.resetDelete()
        //     this.list()
        // }
    }
    list = () => {
      
        if(this.props && this.props.supportList && this.props.supportList.status == true){
            let request = this.props.supportList && this.props.supportList.request ? this.props.supportList.request : "";
            if(request.limit || request.page || request.searchTxt){
                this.setState({
                    limit:request.limit,
                    page:request.page,
                    searchTxt:request.searchTxt
                },()=>{
                    this.getList()
                })
            }else{
                this.getList()
            }
        }
        else{
            this.getList()
        }
    }
    getList = () => {
        let obj = {}
        if(this.state.searchTxt){
            obj['page'] = this.state.page
            obj['limit'] = this.state.limit
            obj['searchTxt'] = this.state.searchTxt
            obj['type'] = "support"
            // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
            // obj['roleId'] = this.state.authorizedData ? this.state.authorizedData.roleId : ""
        }
        else{
            obj['page'] = this.state.page
            obj['limit'] = this.state.limit
            obj['type'] = "support"
            // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
            // obj['roleId'] = this.state.authorizedData ? this.state.authorizedData.roleId : ""
        }
        this.props.getSupportList(obj)
    }
    onChange = (data)=> {
        // window.scrollTo({top : 10, left:100,behavior: 'smooth'});
        if(this.state.page !== (data.selected+1)){
            this.setState({
                page:data.selected +1
            }, ()=> {
                this.getList()
            })
        }
    }
    // formPath = () => {
    //     history.push('/admin/category/add')
    // }
    resetSearch = () => {
        
        this.setState({ searchTxt : '',page : 1 },() => {
            this.getList()
        })      
}
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }
    // itemEdit = (id) => {
    //     history.push('/admin/category/edit/'+id,{obj:{categoryId:id}})
    // }
//     itemDelete = (categoryId) => {
//         let obj = {
//             categoryId
//        }
//        this.props.delete(obj)
//    }
//    handleDelete = (categoryId) => {
//        let message = '';
//        const toastrConfirmOptions = {
//            onOk: () => { this.itemDelete(categoryId) },
//            onCancel: () => console.log('CANCEL: clicked')
//        };
//        toastr.customConfirm(message, toastrConfirmOptions,'Do you want to delete ?');

//    }
    getNewResult = (e) => {
        e.preventDefault();
        if(this.state.searchTxt){
            this.setState({ page : 1 },() => {
            this.getList()
            })
        }else{
            toastr.error("Please type to search")
        }        
    }
    itemView = (id) => {
        history.push('/admin/support/view/'+id,{obj:{supportId:id,type:'support'}})
    }
    render(){
        const {limit,page} = this.state
        let supportList = this.state.supportList && this.state.supportList.map((items,index)=>{
            if(items){
            return <tr>
                <td>{(page - 1) * limit + index  + 1}</td>
                <td>{items.User && items.User.userName}</td>
                <td>{items.status}</td>
                <td>{items.User && items.User.email}</td>
                <td>{items.User && items.User.mobile ? items.User.mobile : '-'}</td>
                {/* <td>{items.description ? items.description : '-'}</td> */}
                <td>{items.type}</td>
                {/* <td><img src={config.apiUrl +`/uploads/categories/`+ items.imageName}  height="50px" width="50px" ></img></td> */}
                {/* <td>
                    <a className="tooltip-wrap" onClick={()=>this.itemEdit(items.id)}>
                        <EditIcon className="edit-icon"/>
                        <div className="tooltip">
                            <div className="arrow"></div>
                            <div className="tooltip-inner">Edit</div>
                        </div>
                    </a>
                    <a className="tooltip-wrap" onClick={()=>this.handleDelete(items.id)}>
                        <DeleteIcon className="delete-icon"/>
                        <div className="tooltip">
                            <div className="arrow"></div>
                            <div className="tooltip-inner">Delete</div>
                        </div>
                    </a>
                </td> */}
                <td>
                <a className="tooltip-wrap" onClick={() => this.itemView(items.id)} >< VisibilityIcon className="visibility-icon" />
                     <div className="tooltip"><div className="arrow"></div><div className="tooltip-inner">View</div></div>
                     </a>
                </td>
            </tr>
            }
        })
        return(
        <Fragment>
            <div className="row tit-sec">
                <div className="col-6 col-md-5">
                  <h4>Support Management</h4>
                </div>
                <div className="col-6 col-md-7 text-right"> 
                    {/* <button className="btn add-btn" onClick={this.formPath}><i className="fas fa-plus"></i>Add Category</button> */}
                    {/* <Button color="primary" variant="contained" onClick={this.formPath}>Add Category
                    </Button> */}
                </div>
            </div>
               <div className="card">
               <div className="card-body">
               <div className="pt-2 mb-3">
                                    <form onSubmit={this.getNewResult} class="align-items-center" >
                                       <div className="d-flex justify-content-end">
                                        <div className="col-md-3">
                                            <div class="input-group">
                                                <input type="text" placeholder="Search.." name="searchTxt" value={this.state.searchTxt} onChange={this.handleInputChange} aria-label="Recipient's username" formcontrolname="customSearch" class="form-control ng-pristine ng-valid ng-touched" />
                                                <div class="input-group-append">
                                                    <button type="submit" class="btn btn-sm btn-dark" ><i className="fas fa-search"></i></button>
                                                    <div class="pl-3">
                                                        <button _ngcontent-wdd-c125="" type="button" onClick={this.resetSearch} class="btn btn-outline-secondary btn-rounded btn-icon">
                                                        <i class="fas fa-redo-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </form>
                            </div>
                <div className="table-wrap">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>User Name</th>
                                    <th>Status</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    {/* <th>Content</th> */}
                                    <th>Type</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {supportList}
                            </tbody>
                        </table>
                    </div>
                    {this.state.supportList && this.state.supportList.length == 0 && <div className="table-responsive">
                            <h6 className="text-center py-4">No Record Found</h6>
                            </div>}
                </div>
               </div>
               </div>
               {this.state.supportList && this.state.supportList.length > 0 && <ReactPagination PageDetails={{pageCount: this.state.pageCount, onPageChange: this.onChange, activePage: (this.state.page - 1), perPage: this.state.limit, totalCount: this.state.totalCount}}/>}

            </Fragment>
        )
    }
}
function mapStateToProps(state){
    return {
    supportList : state.rating.supportList
    }
}
const actionCreators = {
    getSupportList : ratingActions.getSupportList,
    resetList : ratingActions.resetSupportList
   
}
const connectedUser = connect(mapStateToProps, actionCreators)(FetchSupport);
export { connectedUser as FetchSupport };