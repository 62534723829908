import {userConstants} from '../../constants'
let user = localStorage.getItem('user')
function isJson(user) {
    try {
        JSON.parse(user)
    } catch (e) {
        return false;
    }
    return true;
}
const initialState = isJson(user) ? { loggedIn: true, user } : {};
export function authentication (state=initialState,action){
    switch(action.type){
        case userConstants.USER_LOGIN_SUCCESS:
            return {
                ...state,
                user:action.users,
                loggedIn: true
            }
            case userConstants.FORGOTPASSWORD_SUCCESS:
                return {
                    ...state,
                    user:action.user,
                    nextedIn: true
                }
                case userConstants.OTP_SUCCESS:
                    return {
                        user:action.user,
                        otpIn:true
                    }
                    case userConstants.PASSWORD_SUCCESS:
                        return {
                            user:action.user
                        }
                        case userConstants.LOGOUT_SUCCESS:
                            return {}
            default:
                return state;
    }
}