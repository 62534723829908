import React,{Component,Fragment} from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import {history,ReactPagination} from '../../../helpers'
import {userActions} from '../../../store/actions'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {toastr} from '../../../services'
import {config} from '../../../config'
class FetchAdmin extends Component{
    constructor(props){
        super(props);
            this.state={
                userList:[],
                itemsPerPage:10,
                page:1,
                searchTxt:'',
                totalCount:''
            }
        
    }
    componentDidMount(){
       this.list()
    }
    componentWillReceiveProps(newProps){
       
        if(newProps && newProps.userList && newProps.userList.data){
            let data = newProps.userList && newProps.userList.data && newProps.userList.data.rows
            let request = newProps.userList && newProps.userList.request ? newProps.userList.request : []
            let totalCount = newProps.userList && newProps.userList.data && newProps.userList.data.count ? newProps.userList.data.count : []
            if(data.length == 0){
                toastr.error("Result Not Found")
            }
            this.setState({userList:data,totalCount,pageCount:totalCount/request.itemsPerPage})
            this.props.resetList()
        }
        if(newProps && newProps.deleteStatus && newProps.deleteStatus == true){
    
            this.props.resetDelete()
            this.list()
        }
    }
    list = () => {
      
        if(this.props && this.props.userList && this.props.userList.status == true){
            let request = this.props.userList && this.props.userList.request ? this.props.userList.request : "";
            if(request.itemsPerPage || request.page || request.searchTxt){
                this.setState({
                    itemsPerPage:request.itemsPerPage,
                    page:request.page,
                    searchTxt:request.searchTxt
                },()=>{
                    this.getList()
                })
            }else{
                this.getList()
            }
        }
        else{
            this.getList()
        }
    }
    getList = () => {
        let obj = {}
        if(this.state.searchTxt){
            obj['page'] = this.state.page
            obj['itemsPerPage'] = this.state.itemsPerPage
            obj['searchTxt'] = this.state.searchTxt
            obj['role'] = 2
            // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
        }
        else{
            obj['page'] = this.state.page
            obj['itemsPerPage'] = this.state.itemsPerPage
            obj['role'] = 2
            // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
        }
        this.props.getList(obj)
    }
    onChange = (data)=> {
        // window.scrollTo({top : 10, left:100,behavior: 'smooth'});
        if(this.state.page !== (data.selected+1)){
            this.setState({
                page:data.selected +1
            }, ()=> {
                this.getList()
            })
        }
    }
    formPath = () => {
        history.push('/admin/manageAdmin/add')
    }
    resetSearch = () => {
        
        this.setState({ searchTxt : '',page : 1 },() => {
            this.getList()
        })      
}
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }
    itemEdit = (userId) => {
        history.push('/admin/manageAdmin/edit/'+userId,{obj:{userId,role:2}})
    }
    itemDelete = (userId) => {
        let obj = {
            userId
       }
       this.props.delete(obj)
   }
   handleDelete = (userId) => {
       let message = '';
       const toastrConfirmOptions = {
           onOk: () => { this.itemDelete(userId) },
           onCancel: () => console.log('CANCEL: clicked')
       };
       toastr.customConfirm(message, toastrConfirmOptions,'Do you want to delete ?');

   }
    getNewResult = (e) => {
        e.preventDefault();
        if(this.state.searchTxt){
            this.setState({ page : 1 },() => {
            this.getList()
            })
        }else{
            toastr.error("Please type to search")
        }        
    }
    render(){
        const {itemsPerPage,page} = this.state
        let userList = this.state.userList && this.state.userList.map((items,index)=>{
            if(items){
            return <tr>
                <td>{(page - 1) * itemsPerPage + index  + 1}</td>
                <td>{items.userName}</td>
                <td>{items.email}</td>
                <td>{items.adminRoleJson && items.adminRoleJson.map(role => {
                    if(role){
                        return <div>{role.roleName}</div>   
                    }
                })}</td>
                {/* <td><img src={config.apiUrl +`/uploads/categories/`+ items.imageName}  height="50px" width="50px" ></img></td> */}
                <td>
                    <a className="tooltip-wrap" onClick={()=>this.itemEdit(items.id)}>
                        <EditIcon className="edit-icon"/>
                        <div className="tooltip">
                            <div className="arrow"></div>
                            <div className="tooltip-inner">Edit</div>
                        </div>
                    </a>
                    <a className="tooltip-wrap" onClick={()=>this.handleDelete(items.id)}>
                        <DeleteIcon className="delete-icon"/>
                        <div className="tooltip">
                            <div className="arrow"></div>
                            <div className="tooltip-inner">Delete</div>
                        </div>
                    </a>
                </td>
            </tr>
            }
        })
        return(
        <Fragment>
                <div className="row tit-sec">
                    <div className="col-6 col-md-5">
                        <h4>Admins</h4>
                    </div>
                    <div className="col-6 col-md-7 text-right"> 
                        <button className="btn add-btn" onClick={this.formPath}><i className="fas fa-plus"></i>Add Admin</button>
                    </div>
                </div>
               <div className="card">
               <div className="card-body">
               <div className="pt-2 mb-3">
                                <div className="d-flex justify-content-end">
                                    <form onSubmit={this.getNewResult} class="align-items-center" >
                                        <div class="input-group">
                                            <input type="text" placeholder="Search.." name="searchTxt" value={this.state.searchTxt} onChange={this.handleInputChange} aria-label="Recipient's username" formcontrolname="customSearch" class="form-control ng-pristine ng-valid ng-touched" />
                                            <div class="input-group-append">
                                                <button type="submit" class="btn btn-sm btn-dark" ><i className="fas fa-search"></i></button>
                                                <div class="pl-3">
                                                    <button type="button" onClick={this.resetSearch} className="btn btn-outline-secondary btn-rounded btn-icon">
                                                    <i class="fas fa-redo-alt"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                <div className="table-wrap">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Role Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userList}
                            </tbody>
                        </table>
                    </div>
                    {this.state.userList && this.state.userList.length == 0 && <div>
                            <h6 className="text-center py-4">No Record Found</h6>
                            </div>}
                </div>
               </div>
               </div>
               {this.state.userList && this.state.userList.length > 0 && <ReactPagination PageDetails={{pageCount: this.state.pageCount, onPageChange: this.onChange, activePage: (this.state.page - 1), perPage: this.state.itemsPerPage, totalCount: this.state.totalCount}}/>}

            </Fragment>
        )
    }
}
function mapStateToProps(state){
    return {
        userList : state.users.userList,
       deleteStatus : state.users.deleteStatus
    }
}
const actionCreators = {
    getList : userActions.getList,
    resetList : userActions.resetList,
    delete:userActions.delete,
    resetDelete : userActions.resetDelete,
   
}
const connectedUser = connect(mapStateToProps, actionCreators)(FetchAdmin);
export { connectedUser as FetchAdmin };