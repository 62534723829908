import React ,{Component} from 'react'
import UploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import {history} from '../../../helpers';
import {appActions} from '../../../store/actions';
import {config} from '../../../config'
import {toastr} from '../../../services'
import Toggle from 'react-toggle';
import {categoryActions} from '../../../store/actions'
import Select from 'react-select';

class Create_AssignForm extends Component{
    constructor(props){
        super(props);
        this.state={
            title:'',
            id:'',
            description:'',
            status:false,
            submitted:false,
            formJSON : this.getFormsJson()
        }
    }
    componentDidMount(){
        if(this.props.location && this.props.location.state && this.props.location.state.obj){
            let reqData = this.props.location.state.obj
            this.props.getList(reqData)
        }
        this.getCategoryList()
    }
    componentWillReceiveProps(newProps){
        if(newProps && newProps.appList && newProps.appList.data && newProps.appList.data.rows && newProps.appList.data.rows[0]){
            let editData = newProps.appList.data.rows[0]
            this.setState({
               id:editData.id && editData.id,title:editData.name && editData.name,
               description:editData.description && editData.description,
               status:editData.status
            })
        }
        if(newProps && newProps.saveStatus && newProps.saveStatus === true){
            this.props.resetSave_AssignForm()
            history.goBack()
        }

        if(newProps && newProps.categoryList && newProps.categoryList.data){
            let data = newProps.categoryList && newProps.categoryList.data && newProps.categoryList.data.rows
           
            this.setState({categoryList:data})
            this.props.resetCategoryList()
        }
    }


    getFormsJson = () => {
        return  [

            {
                label : "Call record form",
                value : "callform"
            },

            {
                label : "Information and referral form",
                value : "infoform"
            },
            {
                label : "Tele-counselling form",
                value : "teleform"
            },
            {
                label : "Progress form",
                value : "progressform"
            },
            {
                label : "Life Satisfaction Form",
                value : "lifeSatisficationForm"
            },
            {
                label : "Client feedback form",
                value : "feedbackform"
            },
            {
                label : "Satisfaction form",
                value : "satisisficationForm"
            },
            {
                label : "Job completion form",
                value : "completionform"
            },  
            {
                label : "Walk Ambassador",
                value : "walkfeedback"
            },                      
                                          
                ]

// this.setState({ formJSON })
    }

    getCategoryList = () => {
        this.props.getCategoryList({})
    }


    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }
    listPath = () => {
        history.push('/admin/assignform')
        // history.goBack()
    }
    statusChange = (status) => {
        var status = this.state.status ? false : true
        this.setState({status})
    }

    handleCategoryChange = (data) => {
        this.setState({categoryObj:data})
    }

    handleFormChange = (data) => {      
        this.setState({formObj:data})
    }

    handleSubmit = (e) => {
        e.preventDefault()
        console.log("categoryId====>",this.state.categoryObj)
        const {formObj,categoryObj} = this.state
        this.setState({submitted:true})
        if(formObj && formObj.value && categoryObj && categoryObj.value ){
            let obj = {
                name:formObj && formObj.label,
                code : formObj && formObj.value,
                categoryId : categoryObj && categoryObj.value
            }
       
            // if(description){
            //     obj['description'] = description
            // }
            // if(id){
            //     obj['settingsId'] = id
            // }
            this.props.save_AssignForm(JSON.stringify(obj))
        }else{
            toastr.error('Mandatory fields are missing')

        }
    }
    render(){
        let categoryOptions = []
        this.state.categoryList && this.state.categoryList.map(item => {
            if(item && item.id != 0){
            let obj = {"label":item.title,"value":item.id}
            categoryOptions.push(obj)
            }
        })
        return(
            <div>
                 <section>
                    <div className="tit-sec">{this.state.id ? <h4>Edit Form</h4> : <h4>Add Form</h4>}</div>
                            <div class="card">
                                <div class="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-tab">
                                        {/* <div className="col-md-6">
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Title&nbsp;<span class="mandatory">*</span></label>
                                                <div className="col-sm-8">
                                                <input type="text" name="title" value={this.state.title} onChange={this.handleInputChange} placeholder="Enter Title" className="form-control"/>
                                                {this.state.submitted && !this.state.title && <div className="mandatory">Title field is required</div>}
                                                </div>
                                            </div>
                                        </div> */}

<div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Select Category&nbsp;<span class="mandatory">*</span></label>
                                                        <div className="col-sm-8">
                                                            <Select className="state-box"
                                                            
                                                                styles={{
                                                                        control: base => ({
                                                                        ...base,
                                                                        borderColor: 'hsl(0,0%,80%)',
                                                                        boxShadow: '#FE988D',
                                                                        '&:focus': {
                                                                        borderColor: '#80bdff'
                                                                                    }
                                                                                }),
                                                                        menu: base => ({
                                                                        ...base,
                                                                        zIndex:"5"
                                                                        }),
                                                                        menuList: base => ({
                                                                            ...base,
                                                                            maxHeight:"230px",
                                                                            })
                                                                    }}
                                                                   
                                                                        value={this.state.categoryObj}
                                                                        onChange={this.handleCategoryChange}
                                                                        options={categoryOptions}
                                                                        placeholder="Select Category"
                                                                    />  
                                                                    {this.state.submitted && !this.state.categoryObj && <div className="mandatory">Category field is required</div>}            
                                                            </div>                        
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Select Form&nbsp;<span class="mandatory">*</span></label>
                                                        <div className="col-sm-8">
                                                            <Select className="state-box"
                                                                styles={{
                                                                        control: base => ({
                                                                        ...base,
                                                                        borderColor: 'hsl(0,0%,80%)',
                                                                        boxShadow: '#FE988D',
                                                                        '&:focus': {
                                                                        borderColor: '#80bdff'
                                                                                    }
                                                                                }),
                                                                        menu: base => ({
                                                                        ...base,
                                                                        zIndex:"5"
                                                                        }),
                                                                        menuList: base => ({
                                                                            ...base,
                                                                            maxHeight:"120px"
                                                                            }),
                                                                            MaxMenuHeight:"320px"
                                                                    }}
                                                                    // defaultMenuIsOpen
                                                                        value={this.state.formObj}
                                                                        onChange={this.handleFormChange}
                                                                        options={this.state.formJSON}
                                                                        placeholder="Select Form"
                                                                    />  
                                                                    {this.state.submitted && !this.state.formObj && <div className="mandatory">Form field is required</div>}            
                                                            </div>                        
                                                        </div>
                                                    </div>




                                        {/* <div className="col-md-6">
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Description</label>
                                                <div className="col-sm-8">
                                                <input type="text" name="description" value={this.state.description} onChange={this.handleInputChange} placeholder="Enter Description" className="form-control"/>

                                                </div>
                                            </div>
                                        </div>                                      */}
                                   
                                        </div>
                                        <div class="bottom-btn">
                                                <button type="button" className="btn cancel-btn" onClick={this.listPath}>Cancel</button>
                                                <button type="submit" className="btn add-btn"> Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                </section>
            </div>
        )
    }
}
function mapStateToProps(state){
    return {
       saveStatus : state.app.assignedFormSaveStatus,
       appList:state.app.appList,
       categoryList : state.category.categoryList
    }
}
const actionCreators = {
    save_AssignForm : appActions.save_AssignForm,
    getList : appActions.getList,
    resetSave_AssignForm:appActions.resetSave_AssignForm,

    getCategoryList : categoryActions.getList,
    resetCategoryList: categoryActions.resetList

}
const appUser = connect(mapStateToProps, actionCreators)(Create_AssignForm);
export { appUser as Create_AssignForm };