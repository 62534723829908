import React,{Component} from 'react';
import { connect } from 'react-redux';
import { BarChart, Bar, XAxis, YAxis, Cell, Tooltip, Legend, CartesianGrid, Label, LabelList } from 'recharts';
import { PieChart, Pie } from 'recharts';
import {appActions,userActions} from '../../../store/actions'
import UserImg from '../../../assets/img/Users.png';
import CounsellorImg from '../../../assets/img/Counsellor.png';
import VolunteersImg from '../../../assets/img/Volunteers.png';
import TodayUsrImg from '../../assets/img/User.png'
import line from '../../../assets/img/line.png';
import pie from '../../../assets/img/piechart.png';
import {toastr} from '../../../services'
class Dashboard extends Component{
	constructor(props){
		super(props);
		this.state={
			dashboardList:[]
		}
	}
	componentDidMount(){
		this.props.getList()
		this.props.getUserList()
	}
	componentWillReceiveProps(newProps){
       
        if(newProps && newProps.dashboardList && newProps.dashboardList.data){
			let data = newProps.dashboardList && newProps.dashboardList.data && newProps.dashboardList.data
	
				
				let totalUsers = data.totalUsers
				let	todayUsers = data.todayUsers
				let	totalVolunteers = data.totalVolunteers
				let	totalCounsellors = data.totalCounsellors
				
			
			this.setState({dashboardList:data,totalCounsellors,totalVolunteers,totalUsers,todayUsers})
            this.props.resetList()
		}
		if(newProps && newProps.userList && newProps.userList.data){
			let data = newProps.userList && newProps.userList.data && newProps.userList.data.rows
			 if(data){
            let counsellorData = []
            let volunteerData = []
            data && data.map(users => {
                if((users && users.role && users.role) == 3){
                    counsellorData.push(users)
                }else if((users && users.role && users.role) == 4){
                    volunteerData.push(users)
                }
            })
            this.setState({counsellorData,volunteerData})
        }
		}
		const volunteer = this.state.volunteerData && this.state.volunteerData.slice(0,5)
		const counsellor = this.state.counsellorData && this.state.counsellorData.slice(0,5)
		this.setState({counsellor,volunteer})

	}
    
   
    render(){
		let counsellorList = this.state.counsellor && this.state.counsellor.map((user,index) => {
            return <tr>
            <td>{index  + 1}</td>
            <td>{user.firstName ? user.firstName : '-'}</td>
            <td>{user.userName}</td>
			<td>{user.email}</td>
            <td>{user.mobile}</td>
            <td>{user.gender ? user.gender : '-'}</td>
           
        </tr>
		})
		let volunteerList = this.state.volunteer && this.state.volunteer.map((user,index) => {
            return <tr>
            <td>{index  + 1}</td>
            <td>{user.firstName ? user.firstName : '-'}</td>
            <td>{user.userName}</td>
			<td>{user.email}</td>
            <td>{user.mobile}</td>
            <td>{user.gender ? user.gender : '-'}</td>
           
        </tr>
        })
              return(
            < div className="container-fluid">
              <div className="dash-sec">
              <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="card">
							<div className="card-body">
								<div className="row">
									<div className="col-lg-5 col-12">
										<div className="dash-icon text-center">
											<img src={UserImg}/>
										</div>
									</div>
									<div className="col-lg-7 col-12">
										<div className="dash-text">
											<p>Total Users</p>
											<h4>{this.state.totalUsers ? this.state.totalUsers : '0'}</h4>
										</div>
									</div>
								</div>
							</div>
                        </div>
                    </div>
					<div className="col-12 col-lg-6">
                        <div className="card">
							<div className="card-body">
								<div className="row">
									<div className="col-lg-5 col-12">
										<div className="dash-icon text-center">
											<img src={TodayUsrImg} />
										</div>
									</div>
									<div className="col-lg-7 col-12">
										<div className="dash-text">
											<p>Today Users</p>
											<h4>{this.state.todayUsers ? this.state.todayUsers : '0'}</h4>
										</div>
									</div>
								</div>
							</div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="card">
							<div className="card-body">
								<div className="row">
									<div className="col-lg-5 col-12">
										<div className="dash-icon text-center">
											<img src={CounsellorImg} />
										</div>
									</div>
									<div className="col-lg-7 col-12">
										<div className="dash-text">
											<p>Counsellors</p>
											<h4>{this.state.totalCounsellors ? this.state.totalCounsellors : '0'}</h4>
										</div>
									</div>
								</div>
							</div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="card">
							<div className="card-body">
								<div className="row">
									<div className="col-lg-5 col-12">
										<div className="dash-icon text-center">
											<img src={VolunteersImg} />
										</div>
									</div>
									<div className="col-lg-7 col-12">
										<div className="dash-text">
											<p>Volunteers</p>
											<h4>{this.state.totalVolunteers ? this.state.totalVolunteers : '0'}</h4>
										</div>
									</div>
								</div>
							</div>
                        </div>
                    </div>
               </div>
              </div>
			  {/* <div className="dash-graph d-flex justify-content-between">
				  <img src={line}/>
				  <img src={pie} />
			  </div> */}
			  	{/* Top 5 Volunteers */}
					{/* <div className="dash-vol tit-sec"> 
						<h4>Top 5 Volunteers</h4>
							<div className="card">
										<div className="card-body pt-3">
											<div className="table-wrap">
												<div className="table-responsive">
													<table className="table dash-table">
														<thead>
															<tr>
															<th className="xs-sec">S.NO</th>
																<th>First Name</th>
																<th>Volunteer Name</th>
																<th>Email</th>	
																<th>Mobile</th>
																<th>Gender</th>	
															</tr>
														</thead>
														<tbody>
														{volunteerList}
														</tbody>													
														
													</table>
												</div>
												{this.state.volunteerData && this.state.volunteerData.length == 0 && <div>
                            <h6 className="text-center py-4">No Record Found</h6>
                            </div>}
											</div>
										</div>
									</div>
					</div> */}
			 	
					{/* <div className="dash-coun tit-sec">
						<h4>Top 5 Counsellors</h4>									
							<div className="card">
										<div className="card-body pt-3">
											<div className="table-wrap">
												<div className="table-responsive">
													<table className="table dash-table">
														<thead>
															<tr>
																<th className="xs-sec">S.NO</th>
																<th>First Name</th>
																<th>Counsellor Name</th>
																<th>Email</th>	
																<th>Mobile</th>
																<th>Gender</th>
															</tr>
														</thead>
														<tbody>
															{counsellorList}
															
														</tbody>													
													
													</table>
												</div>
												{this.state.counsellorData && this.state.counsellorData.length == 0 && <div>
                            <h6 className="text-center py-4">No Record Found</h6>
                            </div>}
											</div>
										</div>
									</div>
					</div>	 */}
            </div>
        )
    }
}
function mapStateToProps(state){
    return {
		dashboardList:state.app.dashboardList,
	   deleteStatus : state.app.deleteStatus,
	   userList : state.users.userList,
    }
}
const actionCreators = {
    getList : appActions.getdashboardList,
	delete:appActions.delete,
	getUserList : userActions.getList,
    resetUserList : userActions.resetList,
    resetDelete : appActions.resetDelete,
    resetList: appActions.resetdashboardList
}
const connectedApp = connect(mapStateToProps, actionCreators)(Dashboard);
export { connectedApp as Dashboard };
