import {userConstants} from '../../constants'

const initialState = {
    userList:[],
    saveStatus:'',
    deleteStatus:'',
    userTypes:[],
    userRole:[],
    adminRoles:[]
}

export function users(state=initialState,action){
    switch(action.type){
        case userConstants.FETCH_USER_SUCCESS:
            return {
                ...state,
                userList:action.list
            }
            case userConstants.FETCH_VOLUNTEER_TASK_SUCCESS:
            return {
                ...state,
                volunteerTask:action.volunteerTask
            }
            case userConstants.SAVE_USER_SUCCESS:
                return {
                    ...state,
                    saveStatus:action.saveStatus
                }
                case userConstants.FETCH_ADMIN_ROLES:
                    return {
                        ...state,
                        adminRoles:action.adminRoles
                    }
                case userConstants.DELETE_USER_SUCCESS:
                    return {
                        ...state,
                        deleteStatus:action.deleteStatus
                    }
                    case userConstants.FETCH_USERTYPES_SUCCESS:
                        return {
                            ...state,
                            userTypes:action.userTypes
                        }
                        case userConstants.FETCH_USERROLE_SUCCESS:
                            return {
                                ...state,
                                userRole:action.userRole
                            }
            default:
                return state
    }
}