import React from "react";
import { connect } from "react-redux";
import HistImg from "../../assets/img/img_hstry.png";
import AbtBanner from "../../assets/img/img_abtbanr.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { history } from "../../../helpers";
import ElectImg from "../../assets/img/icn_electrical.png";
import HandyImg from "../../assets/img/icn_handyman.png";
import PlumbImg from "../../assets/img/icn_plumbing.png";
import ART_MUSIC from "../../assets/img/HomeSlider/ArtMusicPro.jpg";
import COUN_SERVICE from "../../assets/img/HomeSlider/Service.jpg";
import HMS from "../../assets/form/HMSForm.doc";
import CRF from "../../assets/form/Updated_CRF.docx";
import crestform from "../../assets/form/crestform.docx";
import SHLBanner from "../../assets/img/SHL_Banner.jpg";
import crest1 from "../../assets/img/crest1.png";
import crestimg from "../../assets/img/crestimg.jpg";
import crest2 from "../../assets/img/crest2.png";
import crest3 from "../../assets/img/crest3.png";
import PublicEduBanner from "../../assets/img/PublicEdu_Banner.jpg";
import CounsellingImg from "../../assets/img/Banner/OurWork/Counselling.jpg";
import GrpWrk from "../../assets/img/Banner/OurWork/Groupwork.jpg";
import SHLImg from "../../assets/img/Banner/OurWork/SHL.png";
import PEPImg from "../../assets/img/Banner/OurWork/PEP.JPG";
import RemWalk from "../../assets/img/Banner/OurWork/ReminiscenceWalk.jpg";
import AMPImg from "../../assets/img/Banner/OurWork/ArtandMusicProgramme.jpg";
import HMSImg from "../../assets/img/Banner/OurWork/HMS.jpg";
import SLIDE_COUNSELLING from "../../assets/img/Banner/OurWork/Counselling.jpg";
import SLIDE_HELPLINE from "../../assets/img/Banner/OurWork/SHL.png";
import SLIDE_PEP from "../../assets/img/Banner/OurWork/PEP.JPG";
import SLIDE_ART from "../../assets/img/Banner/Volunteer/Art_Ambassador.JPG";
import SLIDE_HMS from "../../assets/img/Banner/OurWork/HMSPhotoNew.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import SingaporeanImg from "../../assets/img/Singaporean.png";
import YearsOldImg from "../../assets/img/OldYears.png";
import MinAgeImg from "../../assets/img/MinAge.png";
import COUNSELLING from "../../assets/img/Banner/HomepageBanners/1 Counselling_ed.png";
import SHL from "../../assets/img/Banner/HomepageBanners/2 SHL.png";
import PEP from "../../assets/img/Banner/HomepageBanners/3 Public Education.png";
import CHHUASTORY from "../../assets/MrChhuaStory.pdf";
import MDMEE from "../../assets/MdmEe.pdf";
import ThatchedGrass from "../../assets/ThatchedGrassMrSohPengHuiv3.pdf";
import { Document, Page, pdfjs } from "react-pdf";
import WORK_BANNER from "../../assets/img/NewBanner/OurWork.png";

class OurWork extends React.Component {
  constructor(props) {
    super(props);
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.state = {
      selectedTab: 0,
      pageNumber: 1,
      pageNumber1: 1,
      pageNumber2: 1,
      numPages: null,
      numPages1: null,
    };
  }

  async setTabLink(val) {
    // sessionStorage.removeItem("selectedTabInWork");
    // window.scrollTo(0, 100);
    // var myElement = document.getElementById('tabScroll'); var topPos = myElement.offsetTop;
    // document.getElementById('scrolling_div').scrollTop = topPos;
    // document.getElementById('tabScroll').scrollTop -= 10;
    // var elmnt = document.getElementById("tabScroll");
    // elmnt.scrollIntoView();
    window.scrollBy(0, 550);
    await this.setState({ selectedTab: val });
    // if(val == 2)
    // {
    //     sessionStorage.setItem("selectedTabInWork",'educationProgramRoute')
    //     // await setTimeout(wrap(this.setState({selectedTab: 2})), 0);

    //     await this.setState({selectedTab: 2});
    //     // this.forceUpdate();
    // }
    // if(val == 1)
    // {
    //     sessionStorage.setItem("selectedTabInWork",'seniorHelpRoute')
    //     await this.setState({selectedTab: 1});
    //     this.forceUpdate();
    // }

    console.log("VALUESC", val);
  }
  goToHome = () => {
    history.push("/");
  };

  componentWillMount() {
    if (
      sessionStorage.getItem("selectedTabInWork") == "educationProgramRoute"
    ) {
      this.setState({ selectedTab: 2 });
      window.scrollBy(0, 450);
    } else if (
      sessionStorage.getItem("selectedTabInWork") == "artProgramRoute"
    ) {
      this.setState({ selectedTab: 3 });
      window.scrollBy(0, 450);
    } else if (
      sessionStorage.getItem("selectedTabInWork") == "homeMaintenanceRoute"
    ) {
      this.setState({ selectedTab: 4 });
      window.scrollBy(0, 450);
    } else if (
      sessionStorage.getItem("selectedTabInWork") == "seniorHelpRoute"
    ) {
      this.setState({ selectedTab: 1 });
      window.scrollBy(0, 450);
    } else if (
      sessionStorage.getItem("selectedTabInWork") == "counsellingSerRoute"
    ) {
      this.setState({ selectedTab: 0 });
      window.scrollBy(0, 450);
    } else {
      this.setState({ selectedTab: 0 });
    }
  }
  navigateCounsellingForm = () => {
    history.push("/counsellingServiceForm");
  };

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages });
  };
  onDocumentLoad1 = ({ numPages1 }) => {
    this.setState({ numPages1 });
  };
  onDocumentLoad1 = ({ numPages2 }) => {
    this.setState({ numPages2 });
  };

  goToNextPage = () =>
    this.setState((state) => ({ pageNumber: state.pageNumber + 1 }));
  goToPrevPage = () =>
    this.setState((state) => ({ pageNumber: state.pageNumber - 1 }));

  goToPrevPage1 = () =>
    this.setState((state) => ({ pageNumber1: state.pageNumber1 - 1 }));
  goToNextPage1 = () =>
    this.setState((state) => ({ pageNumber1: state.pageNumber1 + 1 }));

  goToPrevPage2 = () =>
    this.setState((state) => ({ pageNumber2: state.pageNumber2 - 1 }));
  goToNextPage2 = () =>
    this.setState((state) => ({ pageNumber2: state.pageNumber2 + 1 }));

  render() {
    // const {pageNumber} = this.state;
    // const {pageNumber1} = this.state;
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 2, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };

    const ServiceResponsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };

    return (
      <div className="wrk-wrap">
        <nav class="breadcrumb">
          <div className="container d-flex">
            <a class="breadcrumb-item" onClick={this.goToHome}>
              <i className="fas fa-home"></i>
            </a>
            <span class="breadcrumb-item active">
              {window.strings.HEADER.OUR_WORK}
            </span>
          </div>
        </nav>
        <Carousel
          className="sign-slide"
          swipeable={false}
          draggable={false}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          // autoPlay={this.props.deviceType !== "mobile" ? true : false}
          autoPlay={false}
          arrows={false}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="all 1.2s ease 0s"
          transitionDuration={1000}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          deviceType={this.props.deviceType}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div className="wrk-banr">
            <img src={WORK_BANNER} />
          </div>

          {/* <div className="wrk-banr">
                            <img src={COUNSELLING} />
                        </div>
                        <div className="wrk-banr">
                            <img src={SHL} />
                        </div>
                        <div className="wrk-banr">
                            <img src={PEP} />
                        </div> */}

          {/* <div className="wrk-banr"><img src={CounsellingImg} /></div>
                        <div className="wrk-banr"><img src={GrpWrk} /></div>
                        <div className="wrk-banr"><img src={SHLImg} /></div>
                        <div className="wrk-banr"><img src={PEPImg} /></div>
                        <div className="wrk-banr"><img src={RemWalk} /></div>
                        <div className="wrk-banr"><img src={AMPImg} /></div>
                        <div className="wrk-banr"><img src={HMSImg} /></div> */}

          {/* <div className="abt-banr">
                            <img src={AbtBanner}/>
                        </div>
                        <div className="abt-banr">
                            <img src={SHLBanner}/>
                        </div>
                        <div className="abt-banr">
                            <img src={PublicEduBanner}/>
                        </div> */}
        </Carousel>
        <div className="container">
          <div className="spad">
            <div className="wrk-box">
              <div className="row">
                {/* <div className="col-lg-4"><img src={HistImg} /></div> */}
                <div className="col-lg-12">
                  <h4>{window.strings.OURWORK.OUR_WORK}</h4>
                  <p>{window.strings.OURWORK.OUR_WORK_CONTENT_ONE}</p>
                  <p>{window.strings.OURWORK.OUR_WORK_CONTENT_TWO}</p>
                  <p>{window.strings.OURWORK.OUR_WORK_CONTENT_THREE}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="service-slide">
          <div className="container">
            <h3>{window.strings.HOME.OUR_SERVICES}</h3>
            <Carousel
              swipeable={false}
              draggable={false}
              responsive={ServiceResponsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={this.props.deviceType !== "mobile" ? true : false}
              // autoPlay={false}
              autoPlaySpeed={5000}
              keyBoardControl={true}
              customTransition="all 1.2s ease 0s"
              transitionDuration={1000}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              deviceType={this.props.deviceType}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              <div className="service-slide-box">
                <img src={SLIDE_COUNSELLING} />
                <h2>{window.strings.OURWORK.COUNSELLING_SERVICE}</h2>
                <p>{window.strings.HOME.COUNSELLING_SLIDE_CONTENT}</p>
                <button
                  className="btn more-btn"
                  onClick={() => {
                    this.setTabLink(0);
                  }}
                >
                  {window.strings.HOME.LEARN_MORE}
                </button>
              </div>
              <div className="service-slide-box">
                <img src={SLIDE_HELPLINE} />
                <h2>{window.strings.OURWORK.THE_SENIORS_HELPLINE}</h2>
                <p>{window.strings.HOME.HELPLINE_SLIDE_CONTENT}</p>
                <button
                  className="btn more-btn"
                  onClick={() => {
                    this.setTabLink(1);
                  }}
                >
                  {" "}
                  {window.strings.HOME.LEARN_MORE}
                </button>
              </div>
              <div className="service-slide-box">
                <img src={crestimg} />
                <h2>{window.strings.OURWORK.CREST_SAGE}</h2>
                <p>{window.strings.OURWORK.CREST_CONTENT}</p>
                <button
                  className="btn more-btn"
                  onClick={() => {
                    this.setTabLink(2);
                  }}
                >
                  {window.strings.HOME.LEARN_MORE}
                </button>
              </div>
              <div className="service-slide-box">
                <img src={SLIDE_PEP} />
                <h2>{window.strings.OURWORK.PUBLIC_EDUCATION_PROGRAMME}</h2>
                <p>{window.strings.HOME.PEP_SLIDE_CONTENT}</p>
                <button
                  className="btn more-btn"
                  onClick={() => {
                    this.setTabLink(3);
                  }}
                >
                  {" "}
                  {window.strings.HOME.LEARN_MORE}
                </button>
              </div>
              <div className="service-slide-box">
                <img src={SLIDE_ART} />
                <h2>{window.strings.OURWORK.ART_PROGRAMME}</h2>
                <p>{window.strings.HOME.ART_SLIDE_CONTENT}</p>
                <button
                  className="btn more-btn"
                  onClick={() => {
                    this.setTabLink(4);
                  }}
                >
                  {" "}
                  {window.strings.HOME.LEARN_MORE}
                </button>
              </div>
              <div className="service-slide-box hms-slide">
                <img src={SLIDE_HMS} />
                <h2>{window.strings.OURWORK.HOME_MAINTENANCE_SERVICES}</h2>
                <p>
                  <span>{window.strings.OURWORK.HOME_CONTENT}</span>
                  <ul>
                    <li>{window.strings.OURWORK.HOME_LIST_TWO}</li>
                    <li>{window.strings.OURWORK.HOME_LIST_THREE}</li>
                  </ul>
                </p>

                {/* <p>{window.strings.HOME.HMS_SLIDE_CONTENT}</p> */}
                <button
                  className="btn more-btn"
                  onClick={() => {
                    this.setTabLink(4);
                  }}
                >
                  {" "}
                  {window.strings.HOME.LEARN_MORE}
                </button>
              </div>
            </Carousel>
          </div>
        </div>

        <div className="work-tab">
          <Tabs
            selectedIndex={this.state.selectedTab}
            onSelect={(index) => this.setTabLink(index)}
          >
            <div className="container-fluid">
              <TabList>
                <Tab>
                  <h5>{window.strings.OURWORK.COUNSELLING_SERVICE}</h5>
                </Tab>
                <Tab>
                  <h5>{window.strings.OURWORK.THE_SENIORS_HELPLINE}</h5>
                </Tab>
                <Tab>
                  <h5>{window.strings.OURWORK.CREST_BUANGKOK}</h5>
                </Tab>
                <Tab>
                  <h5>{window.strings.OURWORK.PUBLIC_EDUCATION_PROGRAMME}</h5>
                </Tab>
                <Tab>
                  <h5>{window.strings.OURWORK.ART_PROGRAMME}</h5>
                </Tab>
                <Tab>
                  <h5>{window.strings.OURWORK.HOME_MAINTENANCE_SERVICES}</h5>
                </Tab>
                {/* <Tab className="col-md-4 col-lg-4 react-tabs__tab"><h5>{window.strings.OURWORK.STORY_CHHUA}</h5></Tab> */}
              </TabList>
            </div>

            <TabPanel>
              <div className="coun-tab">
                <div className="container">
                  <div class="row">
                    <div className="col-lg-5 col-12">
                      <img src={SLIDE_COUNSELLING} className="rounded" />
                    </div>
                    <div className="col-lg-7 col-12">
                      <h4 className="left-border">
                        {window.strings.OURWORK.COUNSELLING_SERVICE}
                      </h4>
                      <p>{window.strings.OURWORK.COUNSELLING_CONTENT}</p>
                    </div>
                  </div>
                </div>
                <div className="coun-banner">
                  <div className="banr-img"></div>
                  <div className="banr-content">
                    <h3>{window.strings.OURWORK.COUNSELLING_OUR_CRITERIA}</h3>
                    <div className="banr-wrap">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-4 col-6">
                            <img src={SingaporeanImg} />
                            <p>
                              {window.strings.OURWORK.COUNSELLING_CRITERIA_ONE}
                            </p>
                          </div>
                          <div className="col-md-4 col-6">
                            <img src={YearsOldImg} />
                            <p>
                              {window.strings.OURWORK.COUNSELLING_CRITERIA_TWO}
                            </p>
                          </div>
                          <div className="col-md-4 col-12">
                            <img src={MinAgeImg} />
                            <p>
                              {
                                window.strings.OURWORK
                                  .COUNSELLING_CRITERIA_THREE
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="coun-refer">
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-12">
                        <h4>{window.strings.OURWORK.OUR_COUNSELLING_FEES}</h4>
                        <div className="space-box">
                          <p className="fnt-wt">
                            {window.strings.OURWORK.COUNSELLING_FEES_PR}
                          </p>
                          <p>{window.strings.OURWORK.COUNSELLING_FEES_ONE}</p>
                          <p>{window.strings.OURWORK.COUNSELLING_FEES_ONE1}</p>
                        </div>
                        <div className="space-box">
                          <p className="fnt-wt">
                            {window.strings.OURWORK.COUNSELLING_FEES_TWO}
                          </p>
                          <p>{window.strings.OURWORK.COUNSELLING_FEES_THREE}</p>
                          <p>{window.strings.OURWORK.COUNSELLING_FEES_FOUR}</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <h4>
                          {window.strings.OURWORK.COUNSELLING_OPERATING_HOURS}
                        </h4>
                        <div className="space-box">
                          <p className="fnt-wt">
                            {
                              window.strings.OURWORK
                                .COUNSELLING_OPERATING_HOURS_MF
                            }
                          </p>
                          <p>
                            {
                              window.strings.OURWORK
                                .COUNSELLING_OPERATING_HOURS_MF_TIME
                            }
                          </p>
                          <p>
                            {
                              window.strings.OURWORK
                                .COUNSELLING_OPERATING_HOURS_MF1_TIME
                            }
                          </p>
                        </div>
                        <div className="space-box">
                          <p className="fnt-wt">
                            {
                              window.strings.OURWORK
                                .COUNSELLING_OPERATING_HOURS_LUNCH
                            }
                          </p>
                          <p>
                            {
                              window.strings.OURWORK
                                .COUNSELLING_OPERATING_HOURS_LUNCH_TIME
                            }
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12 col-12">
                        <h4>
                          {window.strings.OURWORK.COUNSELLING_REFERRAL_HEAD}
                        </h4>
                        <p className="space-box">
                          {window.strings.OURWORK.COUNSELLING_INFO}&nbsp;
                          <a
                            className="line-text"
                            href="mailto:counselling@sagecc.org.sg"
                          >
                            counselling@sagecc.org.sg
                          </a>
                          {/* <a href="http://counselling@sagecc.org.sg" target={"_blank"} className="wrk-a">counselling@sagecc.org.sg</a> */}
                        </p>
                        <p className="dwnlod-form">
                          <a
                            href={CRF}
                            target={"_blank"}
                            className="btn more-btn"
                            download="Updated_CRF.doc"
                          >
                            {window.strings.OURWORK.DOWNLOAD_FORM}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="grp-wrk">
                    <h4>{window.strings.OURWORK.GROUP_WORK}</h4>
                    <div className="grp-tab">
                      <Tabs>
                        <TabList>
                          <Tab>
                            <h5>
                              {window.strings.OURWORK.GROUP_WORK_ONE_HEAD}
                            </h5>
                          </Tab>
                          <Tab>
                            <h5>
                              {window.strings.OURWORK.GROUP_WORK_TWO_HEAD}
                            </h5>
                          </Tab>
                        </TabList>
                        <TabPanel>
                          <p>{window.strings.OURWORK.GROUP_WORK_ONE}</p>
                        </TabPanel>
                        <TabPanel>
                          <div className="row">
                            <div className="col-md-6">
                              <p>{window.strings.OURWORK.GROUP_WORK_TWO}</p>
                            </div>
                            <div className="col-md-6">
                              <div className="doc-box">
                                <div className="col-sm-12">
                                  <div className="rep-load">
                                    <a href={ThatchedGrass} target="_blank">
                                      <i className="fas fa-cloud-download-alt"></i>
                                    </a>
                                  </div>
                                  <div className="rep-wrap">
                                    <Document
                                      width="100px"
                                      height="100px"
                                      file={ThatchedGrass}
                                      onLoadSuccess={this.onDocumentLoad}
                                    >
                                      <Page
                                        pageNumber={this.state.pageNumber}
                                      />
                                    </Document>
                                    <div className="page-box">
                                      <button
                                        className="btn-lef"
                                        onClick={this.goToPrevPage}
                                      >
                                        <i className="fas fa-arrow-left"></i>
                                      </button>
                                      <span>
                                        {window.strings.OURWORK.THATCHED_GRASS}
                                      </span>
                                      <button
                                        className="btn-rit"
                                        onClick={this.goToNextPage}
                                      >
                                        <i className="fas fa-arrow-right"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="rep-load">
                                    <a href={CHHUASTORY} target="_blank">
                                      <i className="fas fa-cloud-download-alt"></i>
                                    </a>
                                  </div>
                                  <div className="rep-wrap">
                                    <Document
                                      width="300px"
                                      height="500px"
                                      file={CHHUASTORY}
                                      onLoadSuccess={this.onDocumentLoad1}
                                    >
                                      <Page
                                        pageNumber={this.state.pageNumber1}
                                      />
                                    </Document>
                                    <div className="page-box">
                                      <button
                                        className="btn-lef"
                                        onClick={this.goToPrevPage1}
                                      >
                                        <i className="fas fa-arrow-left"></i>
                                      </button>
                                      <span>
                                        {window.strings.OURWORK.STORY_CHHUA}
                                      </span>
                                      <button
                                        className="btn-rit"
                                        onClick={this.goToNextPage1}
                                      >
                                        <i className="fas fa-arrow-right"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="rep-load">
                                    <a href={MDMEE} target="_blank">
                                      <i className="fas fa-cloud-download-alt"></i>
                                    </a>
                                  </div>
                                  <div className="rep-wrap">
                                    <Document
                                      width="300px"
                                      height="500px"
                                      file={MDMEE}
                                      onLoadSuccess={this.onDocumentLoad2}
                                    >
                                      <Page
                                        pageNumber={this.state.pageNumber2}
                                      />
                                    </Document>
                                    <div className="page-box">
                                      <button
                                        className="btn-lef"
                                        onClick={this.goToPrevPage2}
                                      >
                                        <i className="fas fa-arrow-left"></i>
                                      </button>
                                      <span>GAB Mdm Ee Kwai Wing</span>
                                      <button
                                        className="btn-rit"
                                        onClick={this.goToNextPage2}
                                      >
                                        <i className="fas fa-arrow-right"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPanel>
                      </Tabs>
                    </div>
                    {/* <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <div className="prim-border">
                                                        <h5>{window.strings.OURWORK.GROUP_WORK_ONE_HEAD}</h5>
                                                        <p>{window.strings.OURWORK.GROUP_WORK_ONE}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="prim-border">
                                                        <h5>{window.strings.OURWORK.GROUP_WORK_TWO_HEAD}</h5>
                                                        <p>{window.strings.OURWORK.GROUP_WORK_TWO}</p> 
                                                        <div className="doc-box">
                                                            <div className="row">
                                                                <div className="col-sm-12 col-xl-6">
                                                                    <div className="rep-load"><a href={ThatchedGrass} target="_blank"><i className="fas fa-cloud-download-alt"></i></a></div>
                                                                    <div className="rep-wrap">
                                                                        <Document 
                                                                                width='100px'
                                                                                height='100px'
                                                                                file = {ThatchedGrass}
                                                                                onLoadSuccess = {this.onDocumentLoad} 
                                                                                >
                                                                                <Page pageNumber = {this.state.pageNumber} />
                                                                        </Document>
                                                                        <div className="page-box">
                                                                                <button className="btn-lef" onClick={this.goToPrevPage}><i className="fas fa-arrow-left"></i></button>
                                                                                <span>{window.strings.OURWORK.THATCHED_GRASS}</span>
                                                                                <button className="btn-rit" onClick={this.goToNextPage}><i className="fas fa-arrow-right"></i></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12 col-xl-6">
                                                                <div className="rep-load"><a href={CHHUASTORY} target="_blank"><i className="fas fa-cloud-download-alt"></i></a></div>
                                                                <div className="rep-wrap">
                                                                    <Document 
                                                                            width='300px'
                                                                            height='500px'
                                                                            file = {CHHUASTORY}
                                                                            onLoadSuccess = {this.onDocumentLoad1} 
                                                                            >
                                                                            <Page pageNumber = {this.state.pageNumber1} />
                                                                    </Document>
                                                                    <div className="page-box">
                                                                            <button className="btn-lef" onClick={this.goToPrevPage1}><i className="fas fa-arrow-left"></i></button>
                                                                            <span>{window.strings.OURWORK.STORY_CHHUA}</span>
                                                                            <button className="btn-rit" onClick={this.goToNextPage1}><i className="fas fa-arrow-right"></i></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                    <div className="space-m">
                      <p>{window.strings.OURWORK.GROUP_WORK_AIM}</p>
                      <p>
                        {window.strings.OURWORK.GROUP_WORK_THREE}&nbsp;
                        <a
                          onClick={() => history.push("/contact")}
                          className="wrk-a"
                        >
                          {window.strings.OURWORK.GROUP_WORK_HERE}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="shl-tab">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-5 col-12">
                      <img src={SLIDE_HELPLINE} className="rounded" />
                    </div>
                    <div className="col-lg-7 col-12">
                      <h4>{window.strings.OURWORK.THE_SENIORS_HELPLINE}</h4>
                      <p>{window.strings.OURWORK.SENIORS_HELPLINE_0NE}</p>
                      <div className="shl-time">
                        <h4>
                          {window.strings.OURWORK.COUNSELLING_OPERATING_HOURS}
                        </h4>
                        <div className="space-box">
                          <p className="fnt-wt">
                            {window.strings.OURWORK.SENIORS_HELPLINE_HOURS_MF}
                          </p>
                          <p>
                            {
                              window.strings.OURWORK
                                .SENIORS_HELPLINE_HOURS_MF_TIME
                            }
                          </p>
                        </div>
                        <div className="space-box">
                          <p className="fnt-wt">
                            {window.strings.OURWORK.SENIORS_HELPLINE_HOURS_SAT}
                          </p>
                          <p>
                            {
                              window.strings.OURWORK
                                .SENIORS_HELPLINE_HOURS_SAT_TIME
                            }
                          </p>
                          <p>
                            {
                              window.strings.OURWORK
                                .SENIORS_HELPLINE_HOURS_CLOSE
                            }
                          </p>
                        </div>
                        <div className="shl-num">
                          <h5>
                            {
                              window.strings.OURWORK
                                .SENIORS_HELPLINE_NUMBER_LABEL
                            }
                          </h5>
                          <p>
                            {window.strings.OURWORK.SENIORS_HELPLINE_NUMBER}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div className="coun-tab">
                <div className="container">
                  <div class="row">
                    <div className="col-lg-5 col-12">
                      <img src={crestimg} className="rounded" />
                    </div>
                    <div className="col-lg-7 col-12">
                      <h4 className="left-border">
                        {window.strings.OURWORK.CREST_BUANGKOK}
                      </h4>
                      <p>{window.strings.OURWORK.CREST_DESCRIPTION}</p>
                    </div>
                  </div>
                </div>
                <div className="coun-banner">
                  <div className="banr-img2"></div>
                  <div className="banr-content">
                    <h3>{window.strings.OURWORK.CREST_CRITERIA}</h3>
                    <div className="banr-wrap">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-4 col-6">
                            <img src={crest1} />
                            <p> {window.strings.OURWORK.CREST_RESIDENTS}</p>
                          </div>
                          <div className="col-md-4 col-6">
                            <img src={crest2} />
                            <p> {window.strings.OURWORK.CREST_AGE}</p>
                          </div>
                          <div className="col-md-4 col-12">
                            <img src={crest3} />
                            <p> {window.strings.OURWORK.CREST_FOR_WHO}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="coun-refer">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-12">
                        <h4>
                          {window.strings.OURWORK.CREST_OPERATING_HOURS_HEAD}
                        </h4>
                        <div className="space-box">
                          <p className="fnt-wt">
                            {window.strings.OURWORK.CREST_OPERATING_HOURS1}
                          </p>
                          <p>{window.strings.OURWORK.CREST_OPERATING_HOURS2}</p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <h4>{window.strings.OURWORK.CREST_REFERRAL1}</h4>
                        <p className="space-box">
                          {window.strings.OURWORK.CREST_REFERRAL2}&nbsp;
                          <a
                            className="line-text"
                            href="mailto:crest_bk@sagecc.org.sg"
                          >
                            crest_bk@sagecc.org.sg
                          </a>
                          {/* <a href="http://counselling@sagecc.org.sg" target={"_blank"} className="wrk-a">counselling@sagecc.org.sg</a> */}
                        </p>
                        <p className="dwnlod-form">
                          <a
                            href={crestform}
                            target={"_blank"}
                            className="btn more-btn"
                            download="crestform.doc"
                          >
                            {window.strings.OURWORK.CREST_REFERRAL3}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="pep-tab">
                <div className="container">
                  <div class="row">
                    <div className="col-lg-5 col-12">
                      <img src={SLIDE_PEP} className="rounded" />
                    </div>
                    <div className="col-lg-7 col-12">
                      <h4 className="left-border">
                        {window.strings.OURWORK.PUBLIC_EDUCATION_PROGRAMME}
                      </h4>
                      <p>
                        {
                          window.strings.OURWORK
                            .PUBLIC_EDUCATION_PROGRAMME_CONTENT
                        }
                      </p>
                    </div>
                  </div>
                  <div className="pep-box">
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <div className="prim-border">
                          <h3>{window.strings.OURWORK.PUBLIC_TALKS}</h3>
                          <p>{window.strings.OURWORK.PUBLIC_TALKS_CONTENT}</p>
                          <p>
                            {window.strings.OURWORK.PUBLIC_TALKS_CONTENT_HOPE}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="prim-border">
                          <h3>{window.strings.OURWORK.PUBLIC_REMINISCENCE}</h3>
                          <p>
                            {window.strings.OURWORK.PUBLIC_REMINISCENCE_CONTENT}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <p>
                      {window.strings.OURWORK.PUBLIC_CONTACT}&nbsp;
                      <a
                        onClick={() => history.push("/contact")}
                        className="wrk-a"
                      >
                        {window.strings.OURWORK.GROUP_WORK_HERE}
                      </a>
                    </p>
                    {/* <p className="dwnlod-form mt-4"><a href={HMS} target={"_blank"} className="btn more-btn" download="HMS.doc" >{window.strings.OURWORK.DOWNLOAD_HMS_FORM}</a></p>                                            */}
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="art-tab">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-5 col-12">
                      <img src={SLIDE_ART} className="rounded" />
                    </div>
                    <div className="col-lg-7 col-12">
                      <h4 className="left-border">
                        {window.strings.OURWORK.ART_PROGRAMME}
                      </h4>
                      <p>{window.strings.OURWORK.ART_PROGRAMME_CONTENT}</p>
                      <a
                        className="wrk-a"
                        href="http://arts.sagecc.org.sg"
                        target={"_blank"}
                      >
                        http://arts.sagecc.org.sg
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="hms-tab">
                <div className="container">
                  <div class="row">
                    <div className="col-lg-4 col-12">
                      <img src={SLIDE_HMS} className="rounded" />
                    </div>
                    <div className="col-lg-7 col-12">
                      <h4 className="left-border">
                        {window.strings.OURWORK.HOME_MAINTENANCE_SERVICES}
                      </h4>
                      <p>{window.strings.OURWORK.HOME_CONTENT}</p>
                      <ul>
                        <li>{window.strings.OURWORK.HOME_LIST_TWO}</li>
                        <li>{window.strings.OURWORK.HOME_LIST_THREE}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="criteria-box">
                    {/* <h4 className="center-border">{window.strings.OURWORK.TABLE_HEAD_ONE}</h4> */}
                    <div className="card">
                      <h6 className="card-header">
                        {window.strings.OURWORK.TABLE_REMARKS}
                      </h6>
                      <p>{window.strings.OURWORK.TABLE_REQUEST}</p>
                      <p>{window.strings.OURWORK.TABLE_ROW_ONE_CONTENT}</p>
                      <p>{window.strings.OURWORK.TABLE_ROW_TWO_REPLACE}</p>
                      <p className="mb-1">
                        {window.strings.OURWORK.TABLE_ROW_THREE_CONTENT}
                      </p>
                      <p>
                        {window.strings.OURWORK.TABLE_ROW_THREE_CONTENT_EXAMPLE}
                      </p>
                    </div>
                    {/* <div className="row">
                                                <div className="col-md-6 col-12">
                                                    <div className="prim-border">
                                                        <h6>{window.strings.OURWORK.TABLE_CATEGORY_A}</h6>
                                                        <p className="fnt-wt">{window.strings.OURWORK.TABLE_ROW_ONE}</p>
                                                        <p>{window.strings.OURWORK.TABLE_ROW_ONE_CONTENT}</p>
                                                        <p>{window.strings.OURWORK.TABLE_ROW_TWO_REPLACE}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="prim-border">
                                                        <h6>{window.strings.OURWORK.TABLE_CATEGORY_B}</h6>
                                                        <p className="fnt-wt">{window.strings.OURWORK.TABLE_ROW_THREE}</p>
                                                        <p>{window.strings.OURWORK.TABLE_ROW_THREE_CONTENT}</p>
                                                    </div>
                                                </div>
                                            </div> */}
                  </div>
                  <div className="offer-box">
                    <h4 className="center-border">
                      {window.strings.OURWORK.SERVICES}
                    </h4>
                    <div>
                      <div className="img-box">
                        <img src={ElectImg} />
                        <img src={HandyImg} />
                        <img src={PlumbImg} />
                      </div>
                      <div>
                        <p>{window.strings.OURWORK.SERVICES_CONTENT_ONE}</p>
                        <p>
                          {window.strings.OURWORK.SERVICES_CONTENT_TWO}
                          {/* <a href="http://hms@sagecc.org.sg" target={"_blank"} className="wrk-a">{window.strings.OURWORK.SERVICES_CONTENT_TWO_MAIL}</a> */}
                          <a
                            href="mailto:hms@sagecc.org.sg"
                            className="line-text"
                          >
                            hms@sagecc.org.sg
                          </a>
                          {/* {window.strings.OURWORK.SERVICES_CONTENT_TWO_MAIL} */}
                          {window.strings.OURWORK.SERVICES_CONTENT_TWO_COPY}
                        </p>
                        <ul>
                          <li>
                            <p className="fnt-wt">
                              {window.strings.OURWORK.SERVICES_CONTENT_THREE}
                            </p>
                          </li>
                          <li>
                            <p className="fnt-wt">
                              {window.strings.OURWORK.SERVICES_CONTENT_FOUR}
                            </p>
                          </li>
                          <li>
                            <p className="fnt-wt">
                              {window.strings.OURWORK.SERVICES_CONTENT_FIVE}
                            </p>
                          </li>
                        </ul>
                        <p>{window.strings.OURWORK.SERVICES_CONTENT_SIX}</p>
                        <p>{window.strings.OURWORK.SERVICES_CONTENT_SEVEN}</p>
                        <p>
                          {window.strings.OURWORK.SERVICES_CONTENT_Ei}&nbsp;
                          <a
                            onClick={() => history.push("/contact")}
                            className="wrk-a"
                          >
                            {window.strings.OURWORK.GROUP_WORK_HERE}
                          </a>
                        </p>
                        <p className="dwnlod-form">
                          <a
                            href={HMS}
                            target={"_blank"}
                            className="btn more-btn"
                            download="HMSForm.doc"
                          >
                            {window.strings.OURWORK.DOWNLOAD_HMS_FORM}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            {/* <TabPanel>
                                <div className="doc-box">
                                    <div className="col-sm-6 col-xl-4">
                                        <div className="rep-load"><a href={CHHUASTORY}><i className="fas fa-cloud-download-alt"></i></a></div>
                                        <div className="rep-wrap">
                                            <Document 
                                                    width='300px'
                                                    height='500px'
                                                    file = {CHHUASTORY}
                                                    onLoadSuccess = {this.onDocumentLoad1} 
                                                    >
                                                    <Page pageNumber = {this.state.pageNumber1} />
                                            </Document>
                                            <div className="page-box">
                                                    <button className="btn-lef" onClick={this.goToPrevPage1}>&#8592;</button>
                                                    <button className="btn-rit" onClick={this.goToNextPage1}>&#8594;</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel> */}
          </Tabs>
        </div>

        {/* <div id="accordion">
                                    <div class="card">
                                        <div class="card-header" id="headingOne">
                                            <button className="btn collapsed  collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <h5>{window.strings.OURWORK.COUNSELLING_SERVICE}</h5><i className="fas fa-chevron-right"></i>
                                                <i className="fas fa-chevron-down"></i>
                                            </button>
                                        </div>
                                        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div class="card-body">
                                            <div className="wrk-img"><img src={COUN_SERVICE} /></div>
                                                <ul>
                                                    <li className="fnt-wt">{window.strings.OURWORK.COUNSELLING}
                                                        <p>{window.strings.OURWORK.COUNSELLING_CONTENT}</p>
                                                        <h5 className="fnt-wt">{window.strings.OURWORK.COUNSELLING_OUR_CRITERIA}
                                                            {window.strings.OURWORK.COUNSELLING_OUR_CRITERIA_1}<span className="color-letter">{window.strings.OURWORK.COUNSELLING_OUR_CRITERIA_2}</span>
                                                            {window.strings.OURWORK.COUNSELLING_OUR_CRITERIA_3}:
                                                        </h5>
                                                        <ul className="li-text">
                                                            <li><p>{window.strings.OURWORK.COUNSELLING_CRITERIA_ONE}</p></li>
                                                            <li><p>{window.strings.OURWORK.COUNSELLING_CRITERIA_TWO}</p></li>
                                                            <li><p>{window.strings.OURWORK.COUNSELLING_CRITERIA_THREE}</p></li>
                                                        </ul>
                                                        <h5 className="fnt-wt">{window.strings.OURWORK.OUR_COUNSELLING_FEES}:</h5>
                                                        <ul className="li-text">
                                                            <li><p>{window.strings.OURWORK.COUNSELLING_FEES_ONE}</p></li>
                                                            <li><p>{window.strings.OURWORK.COUNSELLING_FEES_TWO}</p></li>
                                                            <ul className="li-cir">
                                                                <li><p>{window.strings.OURWORK.COUNSELLING_FEES_THREE}</p></li>
                                                                <li><p>{window.strings.OURWORK.COUNSELLING_FEES_FOUR}</p></li>
                                                            </ul>
                                                        </ul>
                                                        <div className="wrk-time">
                                                            <h5 className="fnt-wt">{window.strings.OURWORK.COUNSELLING_OUR_OPERATION_HOURS}
                                                            <span className="color-letter">{window.strings.OURWORK.COUNSELLING_OUR_OPERATION_HOURS_O}</span>{window.strings.OURWORK.COUNSELLING_OUR_OPERATION_HOURS_PER}
                                                            <span className="color-letter">{window.strings.OURWORK.COUNSELLING_OUR_OPERATION_HOURS_H}</span>{window.strings.OURWORK.COUNSELLING_OUR_OPERATION_HOURS_URS}:</h5>
                                                            <div className="chin-content">
                                                                <p>{window.strings.OURWORK.COUNSELLING_OPERATION_WORK_HOURS}</p>
                                                                <p className="chin-hrs">{window.strings.OURWORK.COUNSELLING_OPERATION_LUNCH_CHIN_HOURS}</p>
                                                            </div>
                                                            <ul className="li-text">
                                                                <li><p>{window.strings.OURWORK.COUNSELLING_OPERATION_WORK_HOURS_ENG}</p></li>
                                                                <li><p>{window.strings.OURWORK.COUNSELLING_OPERATION_LUNCH_HOURS}</p></li>
                                                            </ul>
                                                            
                                                        </div>
                                                        <p>{window.strings.OURWORK.COUNSELLING_MORE_INFO}&nbsp;<a onClick={() => history.push('/contact')} className="wrk-a">here</a></p>
                                                       
                                                        <p>{window.strings.OURWORK.COUNSELLING_INFO}&nbsp;<a href="http://counselling@sagecc.org.sg" target={"_blank"} className="wrk-a">counselling@sagecc.org.sg</a></p>

                                                        <p>{window.strings.OURWORK.COUNSELLING_REFERRAL}&nbsp;<a href="http://counselling@sagecc.org.sg" target={"_blank"} className="wrk-a">counselling@sagecc.org.sg</a></p>

                                                        <button onClick={this.navigateCounsellingForm} className="gradient-btn">{window.strings.OURWORK.COUNSELLING_FORM}</button>
                                                        <p className="dwnlod-form"><a href={CRF} target={"_blank"} className="gradient-btn" download="CRF.doc" >Download Client Referral Form </a></p>   
                                                    </li>
                                                    <li className="fnt-wt">{window.strings.OURWORK.GROUP_WORK}
                                                        <p>1.&nbsp; {window.strings.OURWORK.GROUP_WORK_ONE}</p>
                                                        <p>2.&nbsp; {window.strings.OURWORK.GROUP_WORK_TWO}</p> 
                                                        <p>{window.strings.OURWORK.GROUP_WORK_AIM}</p> 
                                                        <p>{window.strings.OURWORK.GROUP_WORK_THREE}&nbsp;<a onClick={() => history.push('/contact')} className="wrk-a">{window.strings.OURWORK.GROUP_WORK_HERE}</a></p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingTwo">
                                            <button class="btn collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                <h5>{window.strings.OURWORK.THE_SENIORS_HELPLINE}</h5><i className="fas fa-chevron-right"></i>
                                                <i className="fas fa-chevron-down"></i>
                                            </button>
                                        </div>
                                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                            <div class="card-body">
                                                <ul>
                                                    <li>
                                                        <p>{window.strings.OURWORK.SENIORS_HELPLINE_0NE}</p>
                                                    </li>
                                                    <li>
                                                        <p>{window.strings.OURWORK.SENIORS_HELPLINE_HOURS}</p>
                                                        <p>{window.strings.OURWORK.SENIORS_HELPLINE_HOURS_ONE}</p>
                                                        <p>{window.strings.OURWORK.SENIORS_HELPLINE_HOURS_TWO}</p>
                                                        <p>{window.strings.OURWORK.SENIORS_HELPLINE_HOURS_THREE}</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingThree">
                                            <button class="btn collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                <h5>{window.strings.OURWORK.PUBLIC_EDUCATION_PROGRAMME}</h5><i className="fas fa-chevron-right"></i>
                                                <i className="fas fa-chevron-down"></i>
                                            </button>
                                        </div>
                                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                            <div class="card-body">
                                                <p>{window.strings.OURWORK.PUBLIC_EDUCATION_PROGRAMME_CONTENT}</p>
                                                <ul>
                                                    <li  className="fnt-wt">{window.strings.OURWORK.PUBLIC_TALKS}
                                                        <p>{window.strings.OURWORK.PUBLIC_TALKS_CONTENT}</p>
                                                        <p>{window.strings.OURWORK.PUBLIC_TALKS_CONTENT_HOPE}</p>
                                                    </li>
                                                    <li className="fnt-wt">{window.strings.OURWORK.PUBLIC_REMINISCENCE}
                                                        <p>{window.strings.OURWORK.PUBLIC_REMINISCENCE_CONTENT}</p>
                                                        <p>{window.strings.OURWORK.PUBLIC_CONTACT}&nbsp;<a onClick={() => history.push('/contact')} className="wrk-a">{window.strings.OURWORK.GROUP_WORK_HERE}</a></p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingFive">
                                            <button class="btn collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                <h5>{window.strings.OURWORK.ART_PROGRAMME}</h5><i className="fas fa-chevron-right"></i>
                                                <i className="fas fa-chevron-down"></i>
                                            </button>
                                        </div>
                                        <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                            <div class="card-body">
                                            <div className="wrk-img"><img src={ART_MUSIC} /></div>
                                                <ul>
                                                    <li>
                                                        <p>{window.strings.OURWORK.ART_PROGRAMME_CONTENT}</p>
                                                        <a className="wrk-a" href="http://arts.sagecc.org.sg" target={"_blank"}>http://arts.sagecc.org.sg</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingFour">
                                            <button class="btn collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                <h5>{window.strings.OURWORK.HOME_MAINTENANCE_SERVICES}</h5><i className="fas fa-chevron-right"></i>
                                                
                                                <i className="fas fa-chevron-down"></i>
                                            
                                            </button>
                                        </div>
                                        <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                            <div class="card-body">
                                                <p>{window.strings.OURWORK.HOME_CONTENT}</p>
                                                <ul>

                                                    <li>{window.strings.OURWORK.HOME_LIST_ONE}</li>

                                                    <li>{window.strings.OURWORK.HOME_LIST_TWO}</li>
                                                    <li>{window.strings.OURWORK.HOME_LIST_THREE}</li>
                                                </ul>
                                                <div className="wrk-table">
                                                <table className="table table-responsive">
                                                    <thead>
                                                        <tr>
                                                            <td>{window.strings.OURWORK.TABLE_HEAD_ONE}</td>
                                                            <td>Remarks</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="wrk-td"><h6>{window.strings.OURWORK.TABLE_CATEGORY_A}</h6><p>{window.strings.OURWORK.TABLE_ROW_ONE}</p></td>
                                                            <td><p>{window.strings.OURWORK.TABLE_ROW_ONE_CONTENT}</p></td>
                                                        </tr>
                                                        <tr className="wrk-col">
                                                            <td></td>
                                                            <td><p>{window.strings.OURWORK.TABLE_ROW_TWO_REPLACE}</p><p>{window.strings.OURWORK.TABLE_ROW_TWO_NEW}</p></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="wrk-td"><h6>{window.strings.OURWORK.TABLE_CATEGORY_B}</h6><p>{window.strings.OURWORK.TABLE_ROW_THREE}</p></td>
                                                            <td><p>{window.strings.OURWORK.TABLE_ROW_THREE_CONTENT}</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                                <div className="wrk-service">
                                                    <h4>{window.strings.OURWORK.SERVICES}</h4>
                                                    <div className="pb-2">
                                                        <img src={ElectImg}/>
                                                        <img src={HandyImg}/>
                                                        <img src={PlumbImg}/>
                                                    </div>
                                                    <p>{window.strings.OURWORK.SERVICES_CONTENT_ONE}</p>
                                                    <p>{window.strings.OURWORK.SERVICES_CONTENT_TWO}<a href="http://hms@sagecc.org.sg" target={"_blank"} className="wrk-a">{window.strings.OURWORK.SERVICES_CONTENT_TWO_MAIL}</a>{window.strings.OURWORK.SERVICES_CONTENT_TWO_COPY}</p>
                                                    <p>{window.strings.OURWORK.SERVICES_CONTENT_THREE}</p>
                                                    <p>{window.strings.OURWORK.SERVICES_CONTENT_FOUR}</p>
                                                    <p>{window.strings.OURWORK.SERVICES_CONTENT_FIVE}</p>
                                                    <p>{window.strings.OURWORK.SERVICES_CONTENT_SIX}</p>
                                                    <p>{window.strings.OURWORK.SERVICES_CONTENT_SEVEN}</p>
                                                    <p>{window.strings.OURWORK.SERVICES_CONTENT_Ei}&nbsp;<a onClick={() => history.push('/contact')} className="wrk-a">{window.strings.OURWORK.GROUP_WORK_HERE}</a></p>
                                                    <p className="dwnlod-form"><a href={HMS} target={"_blank"} className="gradient-btn" download="HMS.doc" >Download HMS Referral Form</a></p>                                           
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="card">
                                        <div class="card-header" id="headingSix">
                                            <button className="btn collapsed  collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                                <h5>{window.strings.OURWORK.VOLUNTEERING}</h5><i className="fas fa-chevron-right"></i>
                                            </button>
                                        </div>
                                        <div id="collapseSix" class="collapse show" aria-labelledby="headingSix" data-parent="#accordion">
                                            <div class="card-body">
                                            Volunteering
                                        </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingSeven">
                                            <button className="btn collapsed  collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                                                <h5>{window.strings.OURWORK.DONATION}</h5><i className="fas fa-chevron-right"></i>
                                            </button>
                                        </div>
                                        <div id="collapseSeven" class="collapse show" aria-labelledby="headingSeven" data-parent="#accordion">
                                            <div class="card-body">
                                            Donation
                                        </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingEight">
                                            <button className="btn collapsed  collapsed" data-toggle="collapse" data-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                                                <h5>{window.strings.OURWORK.OTHERS}</h5><i className="fas fa-chevron-right"></i>
                                            </button>
                                        </div>
                                        <div id="collapseEight" class="collapse show" aria-labelledby="headingEight" data-parent="#accordion">
                                            <div class="card-body">
                                            Others
                                        </div>
                                        </div>
                                    </div>
                                </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const actionCreators = {};

const connectedOurWork = connect(mapStateToProps, actionCreators)(OurWork);
export { connectedOurWork as OurWork };
