import React from "react";
import { Link,NavLink } from 'react-router-dom';
import { history } from "../../../../helpers";
let selectedParentPath =  sessionStorage.getItem("selectedParentPath") ? sessionStorage.getItem("selectedParentPath") :  ""
let selectedChildPath = sessionStorage.getItem("selectedChildPath") ? sessionStorage.getItem("selectedChildPath") :  ""

// var previousPage = ""

function getChildCategory(item,childItem,props){
	props.resetSearch()
	;
	selectedChildPath = childItem.path
	selectedParentPath = item.path
	sessionStorage.setItem("selectedParentPath",item.path)
	sessionStorage.setItem("selectedChildPath",childItem.path)
	props.menuClose()
	history.push(childItem.path)
}

function getParentMenu(item,props){
	;
	props.resetSearch()
	selectedParentPath = item.path
	selectedChildPath = item.child && item.child[0] && item.child[0].path ? item.child[0].path : ""
	
	sessionStorage.setItem("selectedParentPath",item.path)
	sessionStorage.setItem("selectedChildPath",selectedChildPath)
	props.menuClose()
	history.push(item.path)
}

	// sessionStorage.setItem("selectedParentPath",parentPath)
	// sessionStorage.setItem("selectedChildPath",parentPath)
const Menu = props => {
	const { item, showChild,isMobile,menuClose } = props;	
	var currentPage = document.location.href;
	// sessionStorage.removeItem("selectedTabInWork");
	function highlightParent(parentPath){
		if(window.location.href.split('/')[5] == 'photosGallery'){
			if(parentPath=='videosGallery'){
				return " active";
			}
		}
		else if((selectedParentPath == parentPath)){
			return " active";
		}
		return " ";
	}

	function highlightChild(childPath){	
		if(window.location.href.split('/')[5] == 'photosGallery'){
			highlightParent('videosGallery')
			if(childPath == 'photosGallery'){
				return " active"
			}
		}
		if(selectedChildPath == childPath){
			return " active"
		}
		return " "
	}

	let childCateLists = item && item.child && item.child.map((childItem, index) => {
		return <li key={index} onClick={() => getChildCategory(item,childItem,props)}><button className={highlightChild(childItem.path)} >{childItem.childName}</button> </li>
	});
	
	// debugger;
	return (
		<li className={"nav-list" + highlightParent(item.path)}>
			<button className="menu-btn" onClick={() => getParentMenu(item,props)}>{item.parentName}</button>
			{showChild && <ul className={isMobile ? "mob-nav-menu" : "sub-nav-menu"}>				
				{childCateLists}	
			</ul>}
		</li>
	);
};

export default Menu;