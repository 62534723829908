import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from "../../../helpers";
import { taskActions } from "../../../store/actions";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { toastr } from "../../../services";
import { config } from "../../../config";

class ViewTask extends Component {
  constructor(props) {
    super(props);

    this.state = {
      taskData: [],
    };
  }

  componentDidMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.obj
    ) {
      let reqData = this.props.location.state.obj;
      this.props.getTask(reqData);
    }
  }

  componentWillReceiveProps(newProps) {
	console.log('asdad')
    if (
      newProps &&
      newProps.taskList &&
      newProps.taskList.data &&
      newProps.taskList.data &&
      newProps.taskList.data.rows &&
      newProps.taskList.data.rows[0]
    ) {
      let data = newProps.taskList.data.rows[0];
      this.setState({ taskData: data });
      this.props.resetGetTask();
    }
    console.log("task", this.state.taskData);
  }

  listPath = () => {
    history.goBack();
  };

  render() {
    const { taskData, id } = this.state;
    return (
      <section>
        <div className="tit-sec">
          <h4>View Task</h4>
        </div>
        <div class="card">
          <div class="card-body">
            <div className="form-tab">
              <div className="row">
                <div className="col-md-8">
                  <div class="form-group row">
                    <label className="col-4 col-sm-3 px-0">
                      <b>Title</b>{" "}
                    </label>
                    <div class="col-8 col-sm-8  pr-0">
                      {taskData && taskData.title ? taskData.title : "-"}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label className="col-4 col-sm-3 px-0">
                      <b>Category Name</b>{" "}
                    </label>
                    <div class="col-8 col-sm-8  pr-0">
                      {taskData && taskData.Category && taskData.Category.title
                        ? taskData.Category.title
                        : "-"}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label className="col-4 col-sm-3 px-0">
                      <b>Volunteers</b>{" "}
                    </label>
                    <div class="col-8 col-sm-8  pr-0">
                      {taskData &&
                        taskData.User &&
                        taskData.User.map((item) => {
                          if (item) {
                            return (
                              item &&
                              item.data &&
                              item.data.userName &&
                              item.data.userName + ","
                            );
                          }
                        })}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label className="col-4 col-sm-3 px-0">
                      <b>Task Status</b>{" "}
                    </label>
                    <div class="col-8 col-sm-8  pr-0">
                      {taskData && taskData.taskStatus
                        ? taskData.taskStatus
                        : "-"}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label className="col-4 col-sm-3 px-0">
                      <b>Working Time</b>{" "}
                    </label>
                    <div class="col-8 col-sm-8  pr-0">
                      Start Time :{" "}
                      {taskData &&
                      taskData.workingTime &&
                      taskData.workingTime.startTime
                        ? taskData.workingTime.startTime
                        : "-"}
                      <br />
                      End Time :{" "}
                      {taskData &&
                      taskData.workingTime &&
                      taskData.workingTime.endTime
                        ? taskData.workingTime.endTime
                        : "-"}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label className="col-4 col-sm-3 px-0">
                      <b>Image</b>{" "}
                    </label>
                    <div class="col-8 col-sm-8  pr-0">
                      <img
                        src={
                          config.apiUrl + "/uploads/tasks/" + taskData.imageUrl
                        }
                        height="80px"
                        width="80px"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-right">
                <button
                  type="button"
                  className="btn cancel-btn"
                  onClick={this.listPath}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* </div> */}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    taskList: state.task.taskList,
  };
}

const actionCreators = {
  getTask: taskActions.getTask,
  resetGetTask: taskActions.resetGetTask,
};

const connectedTask = connect(mapStateToProps, actionCreators)(ViewTask);
export { connectedTask as ViewTask };
