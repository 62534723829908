import React from 'react';
import { connect } from 'react-redux';
import HistImg from '../../assets/img/img_hstry.png';
import DonationBanner from '../../assets/img/Banner/Donation/Donation.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import OppoImg1 from '../../assets/img/img_vlnter_1.png';
import OppoImg2 from '../../assets/img/img_vlnter_2.png';
import OppoImg3 from '../../assets/img/img_vlnter_3.png';
import FortuneImg1 from '../../assets/img/fortunebag1.png';
import FortuneImg2 from '../../assets/img/fortunebag2.png';
import FortuneImg3 from '../../assets/img/fortunebag3.png';
import donarImg from '../../assets/img/img_donr.png'
import { toastr } from '../../../services';
import { chatActions } from '../../../store/actions'
import Toggle from 'react-toggle';
import { history } from '../../../helpers/history'
import { isWebLogin } from '../../../helpers/authorizedData'
import QRCode from 'qrcode.react';
import { Redirect } from "react-router-dom";
import DONATION_BANNER from '../../assets/img/NewBanner/Donation.png';
import { Link } from 'react-router-dom'
import FortunePDF from '../../assets/FortuneBag.pdf';



class FortuneBag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            nric: '',
            contact: '',
            email: '',
            add1: '',
            add2: '',
            paymentBy: '',
            no: '+65',
            status: false,
            user: isWebLogin(),
            submitted: false,
            qrcode: false
        }
    }
    componentWillReceiveProps(newProps) {
        if (newProps && newProps.saveStatus && newProps.saveStatus == true) {
            this.props.resetSave()
            this.setState({
                name: '',
                nric: '',
                contact: '',
                email: '',
                add1: '',
                add2: '',
                paymentBy: '',
                no: '+65',
                pay: false,
                status: false, submitted: false
            })
            // history.push('/qrcode')
        }
    }
    statusChange = (status) => {
        var status = this.state.status ? false : true
        this.setState({ status })
    }
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
        if (e.target.name == "email") {
            this.validate(this.state.email)
        }
    }

    validate = (value) => {
        var emailRegex = /^([a-z])+([a-z0-9_\.\-])+\@(([a-z\-])+\.)+([a-z]{2,3})$/;
        if (emailRegex.test(value)) {
            return true
        } else {
            return false;
        }
    }
    nricValidate = (value) => {
        if (!value.match(/^[STFG]\d{7}[A-Z]$/)) {

            return false
        }
        else {
            return true
        }
    }
    goToForm = () => {
        this.setState({ pay: true })
    }
    goToQRcode = () => {
        history.push('/qrcode')
    }
    goToHome = () => {
        history.push('/')
    }
    goToFront = () => {
        this.setState({
            name: '',
            nric: '',
            contact: '',
            email: '',
            add1: '',
            add2: '',
            paymentBy: '',
            pay: false,
            status: false, submitted: false,
        })
    }
    handleSubmit = (e) => {
        e.preventDefault()
        const { name, contact, no, email, nric, add1, add2, status, user, paymentBy } = this.state
        let webLoginData = isWebLogin()

        // if(webLoginData && webLoginData.id){
        this.setState({ submitted: true })
        if (name && email && this.validate(email) && nric && contact && contact.length == 8 && this.nricValidate(nric) && paymentBy) {

            let obj = {
                email: email,
                callerName: name,
                NRICNumber: nric,
                mobile: contact,
                taxDeduction: status,
                paymentBy: paymentBy
                // userId:this.state.user && this.state.user.id
            }
            if (add1) {
                obj['address1'] = add1
            }
            if (add2) {
                obj['address2'] = add2
            }
            this.props.save(JSON.stringify(obj))
        }
        else {
            toastr.error('Mandatory fields are missing')
        }

        // }
        // else{
        //   toastr.error('Please Login')
        // }

    }
    render() {
        const responsive = {
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 1,
                slidesToSlide: 1 // optional, default to 1.
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 1,
                slidesToSlide: 1 // optional, default to 1.
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                slidesToSlide: 1 // optional, default to 1.
            }
        };
        return (
            <div className="donate-wrap">
                <nav class="breadcrumb">
                    <div className="container d-flex">
                        <a class="breadcrumb-item" onClick={this.goToHome}><i className="fas fa-home"></i></a>
                        <span class="breadcrumb-item active">{window.strings.HEADER.DONATION}</span>
                        <span class="breadcrumb-item active">{window.strings.HEADER.DONATION_CHANNELS}</span>
                    </div>
                </nav>
                <Carousel className="donate-slide"
                    swipeable={false}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                    autoPlay={false}
                    arrows={false}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    customTransition="all 1.2s ease 0s"
                    transitionDuration={1000}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    deviceType={this.props.deviceType}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                >
                    <div className="abt-banr">
                        <img src={DONATION_BANNER} />
                    </div>

                    {/* <div className="abt-banr">
                          <img src={DonationBanner}/>
                      </div> */}
                    {/* <div className="abt-banr">
                          <img src={AbtBanner}/>
                      </div>
                      <div className="abt-banr">
                          <img src={AbtBanner}/>
                      </div> */}
                </Carousel>
                <div className="container">
                    <div className="spad">
                        {/* <div className="text-center">
                            <h3>{window.strings.HEADER.DONATION}</h3>
                            <h4>{window.strings.HEADER.DONATION_CHANNELS}</h4>
                        </div> */}
                        <div>
                            <img src={FortuneImg1}></img>
                            <img src={FortuneImg2}></img>
                            <img src={FortuneImg3}></img>
                        </div>
                        <div className="text-center">
                            <a className="prim-btn" href={FortunePDF} target="_blank" download>Download PDF</a>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        saveStatus: state.chat.donarStatus
    }
}


const actionCreators = {
    save: chatActions.saveDonar,
    resetSave: chatActions.resetDonar,
}

const connectedFortuneBag = connect(mapStateToProps, actionCreators)(FortuneBag);
export { connectedFortuneBag as FortuneBag };