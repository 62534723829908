import {appoinmentConstants} from '../../constants'
import {appoinmentService} from '../../services'
import {history} from '../../helpers';
import {config} from '../../config'
import CryptoJS from 'crypto-js';
import {toastr} from '../../services'
import PubSub from 'pubsub-js';
export const appoinmentActions = {
    getList,
    resetList,
    updateStatus,
    resetStatus,
    createAppointment,
    resetAppointment
}
function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
}
function getList(obj){
    return dispatch => {
        showLoader()
        appoinmentService.getList(obj)
        .then(lists=>{
            lists.request = obj
            dispatch({type:appoinmentConstants.FETCH_APPOINMENT_SUCCESS,appoinmentList:lists})
            hideLoader()
        },error => {
            toastr.error(error)
            hideLoader()
        })
    }
}
function resetList(){
    return dispatch => {
        dispatch({type:appoinmentConstants.FETCH_APPOINMENT_SUCCESS,appoinmentList:''})
    }
}
function updateStatus(obj){
    return dispatch => {
        showLoader()
        appoinmentService.updateStatus(obj)
        .then(list => {
            if(list && list.status == true){
            toastr.success(list.message)
            dispatch({type:appoinmentConstants.UPDATE_APPOINMENT_SUCCESS,saveStatus : list.status})
            hideLoader()
            }else{
                hideLoader()
            }
        },error => {
            toastr.error(error)
            hideLoader()
        })
    }
}

function resetStatus(){
    return dispatch => {
        dispatch({type:appoinmentConstants.UPDATE_APPOINMENT_SUCCESS,saveStatus : ''})
    }
}

function createAppointment(obj){
    return dispatch => {
        showLoader()
        appoinmentService.createAppointment(obj)
        .then(list => {
            if(list && list.status == true){
            toastr.success("Request Send Successfully...!")
            dispatch({type:appoinmentConstants.CREATE_APPOINMENT_SUCCESS,createStatus : list.status})
            hideLoader()
            }else{
                hideLoader()
            }
        },error => {
            toastr.error(error)
            hideLoader()
        })
    }
}

function resetAppointment(){
    return dispatch => {
        dispatch({type:appoinmentConstants.CREATE_APPOINMENT_SUCCESS,createStatus : ''})
    }
}



