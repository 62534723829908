import { userConstants } from '../../constants/user.constants';
import { userService } from '../../services/user.service';
import { history, handleError } from '../../helpers';
import { config } from '../../config';
import CryptoJS from 'crypto-js';
import { toastr } from '../../services';
import PubSub from 'pubsub-js';
export const userActions = {
  getList,
  getVolunteerTask,
  resetVolunteerTask,
  save,
  login,
  logout,
  webLogout,
  getAdmins,
  delete: _delete,
  resetList,
  resetDelete,
  resetSave,
  getUserTypes,
  getUser,
  resetUser,
  forgotPassword,
  otp,
  pwd,
};
function showLoader() {
  PubSub.publish('msg', true);
}

function hideLoader() {
  PubSub.publish('msg', false);
}

function getList(obj) {
  return (dispatch) => {
    showLoader();
    userService.getList(obj).then(
      (users) => {
        users.request = obj;
        dispatch({ type: userConstants.FETCH_USER_SUCCESS, list: users });
        hideLoader();
      },
      (error) => {
        hideLoader();
        console.log('user', error);
        toastr.error(error);
      }
    );
  };
}
function getAdmins() {
  return (dispatch) => {
    showLoader();
    userService.getAdmins().then(
      (users) => {
        // users.request = obj;
        dispatch({ type: userConstants.FETCH_ADMIN_ROLES, adminRoles: users });
        hideLoader();
      },
      (error) => {
        hideLoader();
        // toastr.error(error)
      }
    );
  };
}
function getUser(obj) {
  //get particular Role
  return (dispatch) => {
    showLoader();
    userService.getUser(obj).then(
      (user) => {
        user.request = obj;
        dispatch({ type: userConstants.FETCH_USERROLE_SUCCESS, userRole: user });
        hideLoader();
      },
      (error) => {
        hideLoader();
        toastr.error(error);
      }
    );
  };
}

function resetUser() {
  return (dispatch) => {
    dispatch({ type: userConstants.FETCH_USERROLE_SUCCESS, userRole: '' });
  };
}

function getVolunteerTask(obj) {
  //get particular Role
  return (dispatch) => {
    showLoader();
    userService.getVolunteerTask(obj).then(
      (user) => {
        if (user) {
          user.request = obj;
          dispatch({ type: userConstants.FETCH_VOLUNTEER_TASK_SUCCESS, volunteerTask: user });
          hideLoader();
        }
      },
      (error) => {
        hideLoader();
        // toastr.error(error)
      }
    );
  };
}

export async function getVolunteerTaskForCSV(obj) {
  try {
    const user = await userService.getVolunteerTask(obj);
    if (user) {
      user.request = obj;
    }
    return user;
  } catch (error) {
    return null;
  }
}

function resetVolunteerTask() {
  return (dispatch) => {
    dispatch({ type: userConstants.FETCH_VOLUNTEER_TASK_SUCCESS, volunteerTask: '' });
  };
}

function getUserTypes() {
  return (dispatch) => {
    showLoader();
    userService.getUserTypes().then(
      (types) => {
        dispatch({ type: userConstants.FETCH_USERTYPES_SUCCESS, userTypes: types });
        hideLoader();
      },
      (error) => {
        hideLoader();
        console.log(error, 'error');
      }
    );
  };
}
function resetList() {
  return (dispatch) => {
    dispatch({ type: userConstants.FETCH_USER_SUCCESS, list: '' });
  };
}
function save(obj) {
  return (dispatch) => {
    showLoader();
    userService.save(obj).then(
      (users) => {
        if (users && users.status == true) {
          toastr.success(users && users.message);
          dispatch({ type: userConstants.SAVE_USER_SUCCESS, saveStatus: users.status });
          hideLoader();
        } else {
          hideLoader();
        }
      },
      (error) => {
        hideLoader();
        toastr.error(error);
      }
    );
  };
}
function resetSave() {
  return (dispatch) => {
    dispatch({ type: userConstants.SAVE_USER_SUCCESS, saveStatus: '' });
  };
}
function login(obj) {
  return (dispatch) => {
    showLoader();
    userService.login(obj).then(
      (users) => {
        if (users && users.status == true) {
          toastr.success('Login successfully');
          var user = CryptoJS.AES.encrypt(JSON.stringify(users), config.phaseKey);
          localStorage.setItem('user', user);
          dispatch({ type: userConstants.USER_LOGIN_SUCCESS, users, loggedIn: true });
          hideLoader();
        } else {
          hideLoader();
        }
      },
      (error) => {
        hideLoader();
        toastr.error(error);
      }
    );
  };
}
function logout() {
  userService.logout();
  history.push('/admin/login');
  return { type: userConstants.LOGOUT_SUCCESS };
}

function webLogout() {
  userService.logout();
  history.push('/');
  return { type: userConstants.LOGOUT_SUCCESS };
}

function forgotPassword(obj) {
  return (dispatch) => {
    showLoader();
    userService.forgotPassword(obj).then(
      (user) => {
        toastr.success('OTP send successfully...!');
        dispatch({ type: userConstants.FORGOTPASSWORD_SUCCESS, user });
        hideLoader();
      },
      (error) => {
        hideLoader();
        console.log(error, 'forgeterror');
      }
    );
  };
}
function otp(obj) {
  return (dispatch) => {
    showLoader();
    userService.otp(obj).then(
      (user) => {
        dispatch({ type: userConstants.OTP_SUCCESS, user });
        hideLoader();
      },
      (error) => {
        hideLoader();
      }
    );
  };
}
function pwd(obj) {
  return (dispatch) => {
    showLoader();
    userService.pwd(obj).then(
      (user) => {
        dispatch({ type: userConstants.PASSWORD_SUCCESS, user });
        hideLoader();
      },
      (error) => {
        hideLoader();
      }
    );
  };
}
function _delete(obj) {
  return (dispatch) => {
    showLoader();
    userService.delete(obj).then(
      (user) => {
        if (user && user.status == true) {
          toastr.success(user.message);
          dispatch({ type: userConstants.DELETE_USER_SUCCESS, deleteStatus: user.status });
          hideLoader();
        } else {
          hideLoader();
        }
      },
      (error) => {
        hideLoader();
        toastr.error(error);
      }
    );
  };
}
function resetDelete() {
  return (dispatch) => {
    dispatch({ type: userConstants.DELETE_USER_SUCCESS, deleteStatus: '' });
  };
}
