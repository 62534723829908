import React from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import PersonIcon from "@material-ui/icons/Person";
import { withStyles } from "@material-ui/core/styles";
import SettingIcon from '@material-ui/icons/Settings';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import {userActions} from '../../../store/actions';
import cx from "classnames";

const styles = theme => ({
    root: {
        backgroundColor:"#ffffff",
    },
    menuButton: {
		marginLeft: 12,
        marginRight: 36,
        color:"#90C226",
        '&:hover': {
            backgroundColor:"#90C226",
            color:"#ffffff",
            transition:".5s ease",
        }
	},

})
function logoutFunc() {
	userActions.logout()
}
const Header = props => {
    const {classes , handleDrawer, open} = props;
    console.log("--------handleDrawer--------",open);
    return (
        <AppBar position = "fixed" className={cx({["header"]: open == true}, {["header-expand"]: open == false})}>
            <Toolbar disableGutters={true}>
               <div className="navbar-header">
               <IconButton color="inherit"
					aria-label="Open drawer" onClick={handleDrawer} className={classes.menuButton}>
                    <MenuIcon/>
                </IconButton>
                {/* <IconButton color="inherit" className="adminIcon"><PersonIcon/></IconButton> */}
               </div>
               <div className="drop-cust">
                    <div className="dropdown">
                        <div type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Admin<i className="fas fa-chevron-down"></i>
                        </div>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {/* <button class="dropdown-item" type="button" onClick={settingsPage}><SettingIcon className="header-icon"/>Settings</button> */}
                            <button class="dropdown-item " type="button" onClick={logoutFunc}> <PowerIcon className="mr-2"/>Logout</button>
                        </div>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    )
}
const connectedHeader = withStyles(styles)(Header);
export { connectedHeader as Header };