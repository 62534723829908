import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { history, ReactPagination } from "../../../helpers";
import { toastr } from "../../../services";
import { taskActions } from "../../../store/actions";
import { taskService } from "../../../services";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Toggle from "react-toggle";
import { config } from "../../../config";
import VisibilityIcon from "@material-ui/icons/Visibility";
class FetchTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      searchTxt: "",
      taskList: [],
    };
  }
  componentDidMount() {
    this.list();
  }
  componentWillReceiveProps(newProps) {
    if (newProps && newProps.taskList && newProps.taskList.data) {
      let data =
        newProps.taskList &&
        newProps.taskList.data &&
        newProps.taskList.data.rows;
      let request =
        newProps.taskList && newProps.taskList.request
          ? newProps.taskList.request
          : [];
      let totalCount =
        newProps.taskList &&
        newProps.taskList.data &&
        newProps.taskList.data.count
          ? newProps.taskList.data.count
          : [];
      if (data.length == 0) {
        toastr.error("Result Not Found");
      }
      this.setState({
        taskList: data,
        totalCount,
        pageCount: totalCount / request.limit,
      });
      this.props.resetGetTask();
    }
    if (newProps && newProps.deleteStatus && newProps.deleteStatus == true) {
      this.props.resetDelete();
      this.getList();
    }
  }
  list = () => {
    if (
      this.props &&
      this.props.taskList &&
      this.props.taskList.status == true
    ) {
      let request =
        this.props.taskList && this.props.taskList.request
          ? this.props.taskList.request
          : "";
      if (request.size || request.page || request.searchTxt) {
        this.setState(
          {
            limit: request.limit,
            page: request.page,
            searchTxt: request.searchTxt,
          },
          () => {
            this.getList();
          }
        );
      } else {
        this.getList();
      }
    } else {
      this.getList();
    }
  };
  getList = () => {
    const { taskStatus } = this.state;
    let obj = {};
    if (this.state.searchTxt) {
      obj["page"] = this.state.page;
      obj["limit"] = this.state.limit;
      obj["searchTxt"] = this.state.searchTxt;
      // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
      // obj['roleId'] = this.state.authorizedData ? this.state.authorizedData.roleId : ""
    } else {
      obj["page"] = this.state.page;
      obj["limit"] = this.state.limit;
      // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
      // obj['roleId'] = this.state.authorizedData ? this.state.authorizedData.roleId : ""
    }
    if (taskStatus) {
      obj["taskStatus"] = taskStatus;
    }
    this.props.getTask(obj);
  };
  formPath = () => {
    history.push("/admin/task/add");
  };
  statusChange = (task) => {
    // const formData = new FormData()
    let status = task.status ? false : true;
    let obj = {
      userId: task.userId,
      categoryId: task.categoryId,
      taskId: task.id,
      status: status.toString(),
    };
    // formData.append("taskObject",JSON.stringify(obj))
    // this.props.saveTask(formData)
    taskService.updateStatus(JSON.stringify(obj)).then(
      (res) => {
        if (res && res.status == true) {
          toastr.success(res.message);
          this.getList();
        }
        // console.log(res)
      },
      (error) => {}
    );
  };
  onChange = (data) => {
    if (this.state.page !== data.selected + 1) {
      this.setState(
        {
          page: data.selected + 1,
        },
        () => {
          this.getList();
        }
      );
    }
  };
  resetSearch = () => {
    this.setState({ searchTxt: "", page: 1, taskStatus: "" }, () => {
      this.getList();
    });
  };
  handleInputChange = (e) => {
    e.target.value && e.target.value[0].includes(" ")
      ? (e.target.value = "")
      : this.setState({ [e.target.name]: e.target.value });
  };
  handleStatusChange = (e) => {
    this.setState(
      {
        taskStatus: e.target.value,
      },
      () => {
        this.getList();
      }
    );
  };
  getNewResult = (e) => {
    e.preventDefault();
    if (this.state.searchTxt) {
      this.setState({ page: 1 }, () => {
        this.getList();
      });
    } else {
      toastr.error("Please type to search");
    }
  };
  itemEdit = (taskId) => {
    history.push("/admin/task/edit/" + taskId, { obj: { taskId } });
  };
  navigateToVolunteer = (taskId) => {
    history.push("/admin/task/view/" + taskId, { obj: { taskId } });
  };
  itemDelete = (taskId) => {
    let obj = {
      taskId,
    };
    this.props.delete(obj);
  };
  handleDelete = (taskId) => {
    let message = "";
    const toastrConfirmOptions = {
      onOk: () => {
        this.itemDelete(taskId);
      },
      onCancel: () => console.log("CANCEL: clicked"),
    };
    toastr.customConfirm(
      message,
      toastrConfirmOptions,
      "Do you want to delete ?"
    );
  };
  render() {
    const { limit, page } = this.state;
    let taskList =
      this.state.taskList &&
      this.state.taskList.map((task, index) => {
        return (
          <tr>
            <td>{(page - 1) * limit + index + 1}</td>
            {/* <td>{task.title}</td> */}
            {/* <td>{task.categoryName}</td> */}
            <td>{task.Category && task.Category.title}</td>
            {/* <td>{task.User && task.User.map(item => {
                if(item){
                    return item && item.data && item.data.userName && item.data.userName + ','
                }
            })}</td> */}
            {/* <td>{task.taskStatus}</td> */}
            {/* <td><Toggle onClick={()=>this.statusChange(task)}  defaultChecked={task.status}
                    Checked={task.status}/></td> */}
            <td>
              <img
                src={
                  config.apiUrl +
                  "/uploads/categories/" +
                  (task.Category && task.Category.imageName)
                }
                height="50px"
                width="50px"
              ></img>
            </td>
            <td>
              <a
                className="tooltip-wrap"
                onClick={() => this.itemEdit(task.id)}
              >
                <EditIcon className="edit-icon" />
                <div className="tooltip">
                  <div className="arrow"></div>
                  <div className="tooltip-inner">Edit</div>
                </div>
              </a>
              <a
                className="tooltip-wrap"
                onClick={() => this.handleDelete(task.id)}
              >
                <DeleteIcon className="delete-icon" />
                <div className="tooltip">
                  <div className="arrow"></div>
                  <div className="tooltip-inner">Delete</div>
                </div>
              </a>
              <a
                className="tooltip-wrap"
                onClick={() => this.navigateToVolunteer(task.id)}
              >
                <VisibilityIcon className="visibility-icon" />
                <div className="tooltip">
                  <div className="arrow"></div>
                  <div className="tooltip-inner">View</div>
                </div>
              </a>
            </td>
          </tr>
        );
      });
    return (
      <Fragment>
        <div className="row tit-sec">
          <div className="col-6 col-md-5 ">
            <h4>Tasks List</h4>
          </div>
          <div className="col-6 col-md-7 text-right">
            <button className="btn add-btn" onClick={this.formPath}>
              <i className="fas fa-plus"></i>Add Task
            </button>
            {/* <Button color="primary" variant="contained" onClick={this.formPath}>Create Task
                    </Button> */}
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="pt-2 mb-3">
              <form onSubmit={this.getNewResult}>
                <div class="input-group justify-content-end">
                  {/* <div className="col-md-5 col-lg-3 input-fields">
                                            <div className="form-group">
                                                <label>Select Task Status</label>
                                                    <select value={this.state.taskStatus} onChange={this.handleStatusChange} className="form-control">
                                                    <option value=''>Select Status</option>
                                                    <option value="todo">Todo</option>       
                                                    <option value="inprogress">In Progress</option>    
                                                    <option value="done">Done</option>
                                                    </select>
                                            </div>
                                        </div>                                      */}
                  <div className="col-md-5 col-lg-3 m-top">
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Search.."
                        name="searchTxt"
                        value={this.state.searchTxt}
                        onChange={this.handleInputChange}
                        aria-label="Recipient's username"
                        formcontrolname="customSearch"
                        class="form-control ng-pristine ng-valid ng-touched"
                      />
                      <div class="input-group-append">
                        <button type="submit" class="btn btn-sm btn-dark">
                          <i className="fas fa-search"></i>
                        </button>
                        <div class="pl-3">
                          <button
                            _ngcontent-wdd-c125=""
                            type="button"
                            onClick={this.resetSearch}
                            class="btn btn-outline-secondary btn-rounded btn-icon"
                          >
                            <i class="fas fa-redo-alt"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="table-wrap">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      {/* <th>Title</th> */}
                      <th>Category Name</th>
                      {/* <th>Volunteer Name</th> */}
                      {/* <th>Task Status</th> */}
                      {/* <th>Status</th> */}
                      <th>Image</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{taskList}</tbody>
                </table>
              </div>
              {this.state.taskList && this.state.taskList.length == 0 && (
                <div>
                  <h6 className="text-center py-4">No Record Found</h6>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.taskList && this.state.taskList.length > 0 && (
          <ReactPagination
            PageDetails={{
              pageCount: this.state.pageCount,
              onPageChange: this.onChange,
              activePage: this.state.page - 1,
              perPage: this.state.limit,
              totalCount: this.state.totalCount,
            }}
          />
        )}
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    taskList: state.task.taskList,
    deleteStatus: state.task.deleteStatus,
  };
}
const actionCreators = {
  getTask: taskActions.getTask,
  resetGetTask: taskActions.resetGetTask,
  saveTask: taskActions.saveTask,
  delete: taskActions.delete,
  resetDelete: taskActions.resetDelete,
};
const Task = connect(mapStateToProps, actionCreators)(FetchTask);
export { Task as FetchTask };
