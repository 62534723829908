import React from "react";
import { connect } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ServiceImg from "../../assets/img/img_srvc.png";
import PlsImg from "../../assets/img/icn_pls.png";
import MinusImg from "../../assets/img/icn_minus.png";
import BannerImg from "../../assets/img/img_banr.png";
import {
  authorizedData,
  isWebLogin,
  history,
  isPincodeValid,
} from "../../../helpers";
import UserFemale from "../../assets/img/urs-f.png";
import UserMale from "../../assets/img/urs-m.png";
// import { Widget,addResponseMessage, addLinkSnippet, addUserMessage  } from 'react-chat-widget';
// import 'react-chat-widget/lib/styles.css';
import { WebChat } from "./WebChat";
import AGE from "../../assets/img/HomeSlider/Age.jpg";
import COUN_SERVICE from "../../assets/img/Banner/AboutUs/abtus.jpg";

import ART_MUSIC from "../../assets/img/HomeSlider/ArtMusicPro.jpg";
import Menu from "../Ui/WebHeader/Menu";
import { isMobile } from "react-device-detect";
import { config } from "../../../config";
import COUNSELLING from "../../assets/img/Banner/HomepageBanners/1 Counselling_ed.png";
import SHL from "../../assets/img/Banner/HomepageBanners/2 SHL.png";
import PEP from "../../assets/img/Banner/HomepageBanners/3 Public Education.png";
import SLIDE_COUNSELLING from "../../assets/img/Banner/OurWork/Counselling.jpg";
import SLIDE_HELPLINE from "../../assets/img/Banner/OurWork/SHL.png";
import SLIDE_PEP from "../../assets/img/Banner/OurWork/PEP.JPG";
import SLIDE_ART from "../../assets/img/Banner/Volunteer/Art_Ambassador.JPG";
import SLIDE_HMS from "../../assets/img/Banner/OurWork/hmsNew.png";
import HOME_BANNER from "../../assets/img/NewBanner/Home.png";

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventImage: "",
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.loggedIn && newProps.loggedIn.loggedIn) {
      // this.triggerFunc(false)
    }
    if (isWebLogin()) {
      this.setState({ isLogin: true });
    } else {
      this.setState({ isLogin: false });
    }
  }
  async componentDidMount() {
    console.log("ENTERS");
    // GET request using fetch with async/await `${config.apiUrl}/category/create`
    const response = await fetch(
      `${config.apiUrl}/events/listWithCount?eventType=upcoming&type=web`
    );
    // const response = await fetch('http://192.168.1.24:5006/events/listWithCount?eventType=upcoming&type=web');
    const data = await response.json();
    console.log(data.data.rows, "DATAAAAAAAAAAAA");
    if (data.data.rows && data.data.rows.length != 0) {
      let imageUrls = config.imgPath + "/events/" + data.data.rows[0].imageUrl;
      let d = data.data.rows[0].imageUrl
        ? this.setState({ eventImage: imageUrls })
        : "";
    } else {
      this.setState({ eventImage: AGE });
    }

    // this.setState({ totalReactPackages: data.total })
  }
  OpenClose = () => {
    sessionStorage.setItem("selectedParentPath", "videosGallery");
    sessionStorage.setItem("selectedChildPath", "photosGallery");
    history.push("/photosGallery");
  };
  openClose1 = () => {
    sessionStorage.setItem("selectedTabInWork", "counsellingSerRoute");
    history.push("/ourWork");
  };
  openClose2 = () => {
    sessionStorage.setItem("selectedTabInWork", "seniorHelpRoute");
    history.push("/ourWork");
  };
  openClose3 = () => {
    sessionStorage.setItem("selectedTabInWork", "educationProgramRoute");
    history.push("/ourWork");
  };

  handleNewUserMessage = (newMessage) => {
    console.log(`New message incoming! ${newMessage}`);
  };
  counsellingSerRoute = () => {
    sessionStorage.setItem("selectedTabInWork", "counsellingSerRoute");
    history.push("/ourWork");
  };
  seniorHelpRoute = () => {
    sessionStorage.setItem("selectedTabInWork", "seniorHelpRoute");
    history.push("/ourWork");
  };
  educationProgramRoute = () => {
    sessionStorage.setItem("selectedTabInWork", "educationProgramRoute");
    history.push("/ourWork");
  };
  artProgramRoute = () => {
    sessionStorage.setItem("selectedTabInWork", "artProgramRoute");
    history.push("/ourWork");
  };
  homeMaintenanceRoute = () => {
    sessionStorage.setItem("selectedTabInWork", "homeMaintenanceRoute");
    history.push("/ourWork");
  };
  navigateOurHistory = () => {
    sessionStorage.setItem("selectedParentPath", "ourHistory");
    sessionStorage.setItem("selectedChildPath", "");
    history.push("/ourHistory");
  };
  navigateVolunteer = () => {
    sessionStorage.setItem("selectedParentPath", "volunteersOpportunities");
    sessionStorage.setItem("selectedChildPath", "");
    history.push("/volunteersOpportunities");
  };
  navigateDonation = () => {
    sessionStorage.setItem("selectedParentPath", "donation");
    sessionStorage.setItem("selectedChildPath", "");
    history.push("/donation");
  };
  navigateFortuneBag = () => {
    sessionStorage.setItem("selectedParentPath", "fortuneBag");
    sessionStorage.setItem("selectedChildPath", "");
    history.push("/fortuneBag");
  };

  render() {
    const { eventImage } = this.state;
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };

    const ServiceResponsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };

    return (
      <div className="w-home">
        <div className="service-wrap">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/* <h3>{window.strings.HOME.OUR_SERVICE_PGM}</h3> */}
                <h3>Privacy Policy</h3>
                <p>
                  SAGE Counselling Centre (SAGECC) respects and values the
                  privacy of our prospective service users, service users,
                  service users’ authorised representative, partners, donors,
                  volunteers and potential employees and we are committed to
                  accord the information the due level of care as presented in
                  this Policy and consistent with the Personal Data Protection
                  Act 2012 (“the PDPA”).
                  <br></br>
                  <br></br>
                  The policy outlines our obligations and practices in the
                  handling of the Personal Data we collect and holds about our
                  service users, volunteers, partners, donors and all others who
                  come in contact with us across all touchpoints of our
                  operations.
                  <br></br>
                  <br></br>
                  <h3>Collection, Use and Disclosure</h3>
                  We receive or collect Personal Data for the purpose of
                  administering social services to our clients and
                  beneficiaries, engaging volunteers and donors, working with
                  partners, employment matters, reporting to proper Authorities
                  and other relevant and reasonable work that are necessary to
                  facilitate and enhance SAGE Counselling Centre’s provision of
                  social services. “Personal Data” in the PDPA means data,
                  whether true or not, about an individual who can be identified
                  from that data or from that data and other information to
                  which the organisation has or likely to have access.
                  <br></br>
                  <br></br>We may for these purposes, contact you via mail,
                  email, telephone, SMS or other communication means. In
                  providing you with a service, we may sometimes need to
                  disclose your Personal Data to others. It is generally not our
                  policy to disclose &/or are required to disclose your Personal
                  Data as required in the normal course and scope of our service
                  provision to you, &/or for contractual, legal and regulatory
                  requirements.
                  <br></br>
                  <br></br>
                  <h3>Rights of Access & Correction </h3>
                  You may request for access and/or amendment to your personal
                  information by informing the service staff whom you know about
                  your request. Or you may contact our Data Protection Officer
                  at dpo@sagecc.org.sg if you wish to seek clarifications on
                  such requests.
                  <br></br>
                  <br></br>
                  <h3>Withdrawal of Consent</h3>
                  Withdrawing of consent should be made formally in writing with
                  document proof. Whilst we respect your decision to withdraw
                  your consent, this may restrict our ability to provide you
                  with our continued service. Therefore, SAGE Counselling Centre
                  has the right to decide whether to continue or to terminate
                  the service. Please note that withdrawing consent does not
                  affect our right to continue to collect, use and disclose
                  Personal Data where such collection, use and disclosure
                  without consent is permitted or required by law.
                  <br></br>
                  <br></br>
                  <h3>Protection and Retention </h3>
                  Security of Personal Data is important to us and we take all
                  reasonable precautions to protect Personal Data from misuse,
                  loss, unauthorised access, modification or disclosure. You
                  should be aware, however, that no method of transmission over
                  the Internet or method of electronic storage is completely
                  secure. While security cannot be guaranteed, we strive to
                  protect the security of your information and are constantly
                  reviewing and enhancing our information security measures. We
                  will retain your Personal Data for a reasonable period for the
                  purposes as cited or until you request for us to delete the
                  Personal Data, or as required by law.
                  <br></br>
                  <br></br>
                  <h3>Changes to Policy</h3>
                  SAGE Counselling Centre reserves the right to modify or change
                  this Policy at any time. The amended Policy will be uploaded
                  on our website accordingly. Please refer to our website from
                  time to time for any changes and/or updates to our Policy.
                  <br></br>
                  <br></br>
                  <h3>Contact Us</h3>
                  If you have further questions or wish to contact us regarding
                  this Policy, please do not hesitate to contact us
                  at dpo@sagecc.org.sg or contact number 6354 1191.
                </p>
              </div>
            </div>
          </div>
        </div>

        <WebChat isLogin={this.state.isLogin} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const actionCreators = {};

const connectedHome = connect(mapStateToProps, actionCreators)(PrivacyPolicy);
export { connectedHome as PrivacyPolicy };
