import {eventConstants} from '../../constants'

const initialState = {
    saveStatus:'',
    eventList:[],
    deleteStatus:'',
    updateStatus:'',
    eventMembers:[]
}

export function events(state=initialState,action){
    switch(action.type){
        case eventConstants.SAVE_EVENT_SUCCESS:
            return {
                ...state,
                saveStatus:action.saveStatus
            }
            case eventConstants.FETCH_EVENT_SUCCESS:
                return {
                    ...state,
                    eventList:action.eventList
                }
                case eventConstants.EVENT_DELETE_SUCCESS:
                    return {
                        ...state,
                        deleteStatus:action.deleteStatus
                    }
                    case eventConstants.EVENT_UPDATE_STATUS:
                        return {
                            ...state,
                            updateStatus:action.updateStatus
                        }
                        case eventConstants.FETCH_EVENT_MEMBER:
                            return {
                                ...state,
                                eventMembers:action.eventMembers
                            }
            default:
                return state
    }
}