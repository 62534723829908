import React , {Component} from 'react'
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleIcon from '@material-ui/icons/People';
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import {config} from '../../../config'
import { Link } from 'react-router-dom';
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AssignmentSharpIcon from '@material-ui/icons/AssignmentSharp';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import GradeIcon from '@material-ui/icons/Grade';
import ChatIcon from '@material-ui/icons/Chat';
import CategoryIcon from '@material-ui/icons/Category';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import EmailIcon from '@material-ui/icons/Email';
import GavelIcon from '@material-ui/icons/Gavel';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import SettingsSystemDaydreamIcon from '@material-ui/icons/SettingsSystemDaydream';
import {isAdminLogin} from '../../../helpers/authorizedData'
import {history} from '../../../helpers/history'

import ViewListIcon from '@material-ui/icons/ViewList';
const drawerWidth = 240;
const styles = theme => ({
    activeBtn: {
        backgroundColor: "#4F8A26",
        marginRight:"10px",
        '& .menu-icon': {
         color: '#fff'
        },
         '& span.MuiTypography-root': {
           color: '#fff'
         }
     },
     inactiveBtn: {
         color:"#ffffff",
         '&:hover':{
             backgroundColor:"rgba(0, 0, 0, 0.09)"
         }
     },
    SidebarDrawerPaper:{
        top:"0 !important",
        backgroundColor: "#90C226 !important",
        color: "#fff",
        textDecoration: "none",
        listStyle: "none",
        boxShadow: "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
        transitionDuration: ".2s, .2s, .35s",
        transitionProperty: "top, bottom, width",
        transitionTimingFunction: "linear, linear, ease",
    },
    drawerPaper: {
        position: "fixed",
        top:0,
		top: theme.spacing(8),
		whiteSpace: "nowrap",
		backgroundColor: "#fff",
		width: drawerWidth,
		// transition: theme.transitions.create("width", {
		// 	easing: theme.transitions.easing.sharp,
		// 	duration: theme.transitions.duration.enteringScreen
        // }),
        '&:hover':{
            width: drawerWidth,
            '& .login-logo':{
                backgroundSize: "145px"
            }
        }
    },
    drawerPaperClose: {
		overflowX: "hidden",
		// transition: theme.transitions.create("width", {
		// 	easing: theme.transitions.easing.sharp,
		// 	duration: theme.transitions.duration.leavingScreen
		// }),
		width: theme.spacing(8),
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(9)
        },
        '& .login-logo':{
            backgroundSize: "55px"
        }
    }
    
})
const webNavigate = () => {
    history.push('/')
}
const Sidebar = props => {
    const {  classes,open } = props;
    let hash = ''
let userData = isAdminLogin()
userData && userData.adminRoleJson && userData.adminRoleJson.map(admin =>(
    console.log("admin",admin)
))
    if(config.hashName){
         hash = window.location.hash
    }else{
         hash = ''
    }
        let location = window.location.pathname+window.location.hash
        
    return (
        
        <div className="sidebar-layout">
            <Drawer
            variant="permanent"
            classes={{
                paper: classNames(
                    classes.SidebarDrawerPaper,
                    classes.drawerPaper,
                    !open && classes.drawerPaperClose
                )
            }}
            >
            <PerfectScrollbar>
                <li>
                    <div className="login-logo" onClick={webNavigate}></div>
                </li>
                <li>
                    <Link to="/admin/dashboard">
                        <div>
                        <ListItem button className={location && location.includes('dashboard') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon>
                                {/* <DashboardIcon className="menu-icon"/> */}
                                <i class="fas fa-tachometer-alt menu-icon" style={{fontSize: `15px`}}></i>
                            </ListItemIcon>
                            <ListItemText primary="Dashboard"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li>
               {userData && userData.role == "1" && <li>
                    <Link to="/admin/manageAdmin">
                    <div>
                        <ListItem button className={location && location.includes('manageAdmin') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon>
                                {/* < AcUnitIcon  className="menu-icon"/> */}
                                <i className="fas fa-user-shield menu-icon" style={{fontSize: `14px`}}></i>
                            </ListItemIcon>
                            <ListItemText primary="Admin Management"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li>}
                { userData.adminRoleJson && userData.adminRoleJson.map(admin => (
                    // if(admin){
                    // return 
                    <div>


                        
              
                     
                {/* <li>
                    <Link to="/admin/user">
                    <div>
                        <ListItem button className={location && location.includes('user') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon >
                                <PeopleIcon className="menu-icon"/>
                            </ListItemIcon>
                            <ListItemText primary="User"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li> */}
            
                { ((admin && admin.roleName == "Counsellor Management") || (userData.role == "1")) && <li>
                    <Link to="/admin/counsellor">
                    <div>
                        <ListItem button className={location && location.includes('counsellor') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon>
                               {/* <NaturePeopleIcon className="menu-icon"/> */}
                               <i class="fas fa-user-md menu-icon"></i>
                            </ListItemIcon>
                            <ListItemText primary="Counsellor"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li>}
                {((admin && admin.roleName == "Volunteer Management") || (userData.role == "1")) && <li>
                    <Link to="/admin/volunteer">
                    <div>
                        <ListItem button className={location && location.includes('volunteer') && !location.includes('volunteertask') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon>
                               {/* <EmojiPeopleIcon className="menu-icon"/> */}
                               <i className="fas fa-hand-holding-heart  menu-icon"></i>
                            </ListItemIcon>
                            <ListItemText primary="Volunteer"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li>}

                {((admin && admin.roleName == "Volunteer Management") || (userData.role == "1")) && <li>
                    <Link to="/admin/volunteertask">
                    <div>
                        <ListItem button className={location && location.includes('volunteertask') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon>
                               {/* <EmojiPeopleIcon className="menu-icon"/> */}
                               <i className="fas fa-hand-holding-heart  menu-icon"></i>
                            </ListItemIcon>
                            <ListItemText primary="Volunteer Task"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li>}

                {((admin && admin.roleName == "User Management") || (userData.role == "1")) && <li>
                    <Link to="/admin/normal">
                    <div>
                        <ListItem button className={location && location.includes('normal') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon>
                               {/* <AssignmentIndIcon className="menu-icon"/> */}
                               <i className="fas fa-user menu-icon"  style={{fontSize: `18px`}}></i>
                            </ListItemIcon>
                            <ListItemText primary="User"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li>}
                {((admin && admin.roleName == "Category Management") || (userData.role == "1")) && <li>
                    <Link to="/admin/category">
                    <div>
                        <ListItem button className={location && location.includes('category') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon >
                                {/* <CategoryIcon className="menu-icon"/> */}
                                <i className="fas fa-layer-group menu-icon"></i>
                            </ListItemIcon>
                            <ListItemText primary="Category"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li>}
               
                {((admin && admin.roleName == "Task Management") || (userData.role == "1")) && <li>
                    <Link to="/admin/task">
                    <div>
                        <ListItem button className={location && location.includes('admin/task') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon>
                                {/* <AssignmentSharpIcon className="menu-icon"/> */}
                                <i className="fas fa-tasks menu-icon "></i>
                            </ListItemIcon>
                            <ListItemText primary="Task"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li>}




                {((admin && admin.roleName == "Event Management") || (userData.role == "1")) && <li>
                    <Link to="/admin/event">
                    <div>
                        <ListItem button className={location && location.includes('event') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon>
                                {/* <EventSeatIcon className="menu-icon"/> */}
                                <i className="fas fa-calendar-check menu-icon"></i>
                            </ListItemIcon>
                            <ListItemText primary="Events"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li>}



                {((admin && admin.roleName == "Event Members") || (userData.role == "1")) && <li>
                    <Link to="/admin/members">
                    <div>
                        <ListItem button className={location && location.includes('admin/members') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon>
                                {/* < GroupAddIcon  className="menu-icon"/> */}
                                <i className="fas fa-id-card menu-icon"></i>
                            </ListItemIcon>
                            <ListItemText primary="Event Members"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li>}
                
                {(userData.role == "1") && <li>
                    <Link to="/admin/booking">
                    <div>
                        <ListItem button className={location && location.includes('booking') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon>
                               {/* <AssignmentIndIcon className="menu-icon"/> */}
                               <i className="fas fa-id-badge menu-icon"  style={{fontSize: `18px`}}></i>
                            </ListItemIcon>
                            <ListItemText primary="Appointments"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li>}
                {((admin && admin.roleName == "Email Template Management") || (userData.role == "1")) && <li>
                    <Link to="/admin/email">
                    <div>
                        <ListItem button className={location && location.includes('email') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon>
                               {/* <EmailIcon className="menu-icon"/> */}
                               <i className="fas fa-envelope menu-icon"></i>
                            </ListItemIcon>
                            <ListItemText primary="Email Template"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li>}
                {/* <li>
                    <Link to="/admin/rating">
                    <div>
                        <ListItem button className={location && location.includes('rating') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon>
                               <GradeIcon className="menu-icon"/>
                            </ListItemIcon>
                            <ListItemText primary="Ratings"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li>
                <li>
                    <Link to="/admin/chat">
                    <div>
                        <ListItem button className={location && location.includes('chat') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon>
                               <ChatIcon className="menu-icon"/>
                            </ListItemIcon>
                            <ListItemText primary="Chats"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li>
                <li>
                    <Link to="/admin/official">
                    <div>
                        <ListItem button className={location && location.includes('official') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon>
                               <ChatIcon className="menu-icon"/>
                            </ListItemIcon>
                            <ListItemText primary="Official Form"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li> */}
                {((admin && admin.roleName == "Notification Management") || (userData.role == "1")) && <li>
                    <Link to="/admin/notification">
                    <div>
                        <ListItem button className={location && location.includes('notification') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon>
                               <i className="fas fa-bell menu-icon"></i>
                            </ListItemIcon>
                            <ListItemText primary="Notification"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li>}
                {((admin && admin.roleName == "Content Management") || (userData.role == "1")) && <li>
                    <Link to="/admin/backStory">
                    <div>
                        <ListItem button className={location && location.includes('backStory') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon >
                                {/* <ImportContactsIcon className="menu-icon"/> */}
                                <i className="fas fa-file-signature menu-icon"></i>
                            </ListItemIcon>
                            <ListItemText primary="Content Management"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li>}
                { (userData.role == "1") && <li>
                    <Link to="/admin/app">
                    <div>
                        <ListItem button className={location && location.includes('app') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon>
                               <i className="fa fa-cogs menu-icon"></i>
                            </ListItemIcon>
                            <ListItemText primary="App Master Setting"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li>}
                {((admin && admin.roleName == "Support Management") || (userData.role == "1")) &&<li>
                    <Link to="/admin/support">
                    <div>
                        <ListItem button className={location && location.includes('support') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon>
                               {/* <SettingsSystemDaydreamIcon className="menu-icon"/> */}
                               <i className="fas fa-user-cog menu-icon"></i>
                            </ListItemIcon>
                            <ListItemText primary="Support Management"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li>}
                { (userData.role == "1") && <li>
                    <Link to="/admin/donar">
                    <div>
                        <ListItem button className={location && location.includes('donar') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon>
                            <i className="fas fa-yen-sign menu-icon"></i>
                            </ListItemIcon>
                            <ListItemText primary="Donation Management"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li>}
                { (userData.role == "1") && <li>
                    <Link to="/admin/form">
                    <div>
                        <ListItem button className={location && location.includes('admin/form') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon>
                            <i className="fab fa-wpforms menu-icon" style={{fontSize: `20px`}}></i>
                            </ListItemIcon>
                            <ListItemText primary="Form Management"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li>}

                { (userData.role == "1") && <li>
                    <Link to="/admin/assignform">
                    <div>
                        <ListItem button className={location && location.includes('assignform') ? classes.activeBtn : classes.inactiveBtn}>
                            <ListItemIcon>
                            <i className="fab fa-wpforms menu-icon" style={{fontSize: `20px`}}></i>
                            </ListItemIcon>
                            <ListItemText primary="Assign Form"></ListItemText>
                        </ListItem>
                    </div>
                    </Link>
                </li>}
                </div>
                    // }
    ))} 
                </PerfectScrollbar>
            </Drawer>
        </div>
        
    )
            
}
const connectedSidebar = withStyles(styles)(Sidebar);
export { connectedSidebar as Sidebar };
