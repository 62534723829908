import React from 'react';
import { connect } from 'react-redux';
import HistImg from '../../assets/img/img_hstry.png';
import AbtBanner from '../../assets/img/img_abtbanr.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Modal } from 'react-responsive-modal';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {userService} from '../../../services/user.service'
import PubSub from 'pubsub-js';
import { isWebLogin } from "../../../helpers";
import { toastr } from '../../../services';
import {config} from '../../../config'
import {history} from '../../../helpers'

const animatedComponents = makeAnimated();
class CounsellingServiceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // languageList : ["English","Chinese","Malay","Tamil"]
    }
  }
// componentDidUpdate(prevProps,nextProps){
//   if (nextProps.raceList != nextProps.raceList) {
//     this.setState({ raceList: this.state.raceList, serviceList: this.state.serviceList })
//   }
// }
  handleLanguage = (Data) => {
		this.setState({ languageObj: Data })
	}

  handleInputChange = (e) => {
    e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    if (e.target.name == "email") {
      this.validate(this.state.email)
    }
  }

  validate = (value) => {
    var emailRegex = /^([a-z])+([a-z0-9_\.\-])+\@(([a-z\-])+\.)+([a-z]{2,3})$/;
    if (emailRegex.test(value)) {
      return true
    } else {
      return false;
    }
  }

  showLoader() {
    PubSub.publish('msg', true);
}

 hideLoader() {
    PubSub.publish('msg', false);
}
handleCancel = () => {
  history.goBack()
  // this.setState({submitted:false,firstName:'',lastName:'',email:'',mobile:'',age:'',
  // gender:'',address1:'',counsellingService:'',concern:'',languageObj:'',nationality:'',race:''})
}

  handleSubmit = (e) => {
    e.preventDefault()
    const {firstName,lastName,email,mobile,age,gender,address1,counsellingService,concern,
      languageObj,nationality,race} = this.state     
    let webLoginData = isWebLogin()   
    if(webLoginData && webLoginData.id){    
        ; 
        this.setState({submitted : true})  
    if(firstName && email && this.validate(email) && mobile && mobile.length == 8 && counsellingService
    ){
        let obj = {
          "firstName": firstName,
          "lastName": lastName,
          "age": age,
          "email": email,
          "mobile": mobile,
          "address1": address1,          
          "appointmentStatus": "yettostart",
          "concern": concern,
          "counsellingService": counsellingService,        
          "language": languageObj,                  
          "nationality": nationality,
          "race": race,
          "status": true,
          "type": counsellingService,
          "userId": webLoginData.id
         }

         if(gender){
          obj['gender'] = gender
         }
           this.showLoader();
           userService.counsellingServiceForm(obj).then(resp => {
            this.hideLoader()
             if(resp && resp.status == true){
           
            this.setState({ open : true })
             }
        }).catch(error => {
          console.error(error)
          this.hideLoader()
        })
    }else{
        toastr.error("Mandatory fields are invalid or missing")
    }
}else{
    toastr.error("Please login")
}
}
downloadForm = () => {
  let webLoginData = isWebLogin() 
  var link = document.createElement('a');
  link.href = config.apiUrl + '/form/downloadForm?'+`userId=${webLoginData && webLoginData.id}`;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

}
  resetForm = () => {
    // this.setState({ open: false })
    this.setState({submitted:false,open:false,firstName:'',lastName:'',email:'',mobile:'',age:'',
  gender:'',address1:'',counsellingService:'',concern:'',languageObj:'',nationality:'',race:''})
  }

  render() {

    let languageOptions = [];

    window.strings.COUNSELLINGFORM.languageList && window.strings.COUNSELLINGFORM.languageList.map(item => {
			let obj = { "label": item, "value": item }
			languageOptions.push(obj)
    }) 
    
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
      }
    };
    return (
      <div className="sign-wrap">
        <Modal open={this.state.open} onClose={() => this.resetForm()} center
          classNames={{
            modal: 'form-mod',
          }}>
          <div class="con-modal">
            <div class="mod-header">
              <h5>{window.strings.COUNSELLINGFORM.THANKYOU}</h5>
            </div>
            <div class="mod-body">
              <h6>{window.strings.COUNSELLINGFORM.AAAA01}</h6>
              <p>{window.strings.COUNSELLINGFORM.AAAA02}</p>
            </div>
            <div class="mod-footer">
              <button type="button" class="gradient-btn" onClick={this.downloadForm}>{window.strings.COUNSELLINGFORM.AAAA03}</button>
              <button type="button" class="link-btn" data-dismiss="modal" onClick={this.resetForm}>{window.strings.COUNSELLINGFORM.CANCEL}</button>
            </div>
          </div>
        </Modal>
        <nav class="breadcrumb">
          <div className="container d-flex">
            <a class="breadcrumb-item" href="#"><i className="fas fa-home"></i></a>
            <a class="breadcrumb-item" href="#">{window.strings.COUNSELLINGFORM.AAAA04}</a>
            <a class="breadcrumb-item" href="#">{window.strings.COUNSELLINGFORM.AAAA05}</a>
            <span class="breadcrumb-item active">{window.strings.COUNSELLINGFORM.AAAA06}</span>
          </div>
        </nav>
        <Carousel className="sign-slide"
          swipeable={false}
          draggable={false}
          showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={this.props.deviceType !== "mobile" ? true : false}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="all 1.2s ease 0s"
          transitionDuration={1000}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          deviceType={this.props.deviceType}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div className="abt-banr">
            <img src={AbtBanner} />
          </div>
          <div className="abt-banr">
            <img src={AbtBanner} />
          </div>
          <div className="abt-banr">
            <img src={AbtBanner} />
          </div>
        </Carousel>
        <div className="container">
          <div className="spad">
            <div className="col-lg-8 m-auto">
              <div className="card">
                <div className="card-header">
                  <h5>{window.strings.COUNSELLINGFORM.COUNSELLINGSERVICEFORM}</h5>
                </div>
                <div className="card-body">
                  <form onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>{window.strings.COUNSELLINGFORM.FIRSTNAME}</label>&nbsp;<span class="mandatory">*</span>
                          <input type="text" name="firstName" value={this.state.firstName} onChange={this.handleInputChange} className="form-control" placeholder={window.strings.COUNSELLINGFORM.NAMEERRROR} />
                          {this.state.submitted && !this.state.firstName && <div className="mandatory">{window.strings.COUNSELLINGFORM.ISSNAME}</div>}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>{window.strings.COUNSELLINGFORM.LASTNAME}</label>
                          <input type="text" name="lastName" value={this.state.lastName} onChange={this.handleInputChange} className="form-control" placeholder={window.strings.COUNSELLINGFORM.LASTERROR} />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>{window.strings.COUNSELLINGFORM.AGE}</label>
                          <input type="number" name="age" value={this.state.age} onChange={this.handleInputChange} className="form-control" placeholder={window.strings.COUNSELLINGFORM.AGEERROR} />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>{window.strings.COUNSELLINGFORM.GENDER}</label>
                          <div className="col-sm-12 px-0">
                            <div className="radio-box">
                              <label>
                                <input type="radio" value="male" onChange={this.handleInputChange} name="gender" checked={this.state.gender == "male"} />
                                <span>{window.strings.COUNSELLINGFORM.MALE}</span>
                              </label>
                              <label>
                                <input type="radio" value="female" onChange={this.handleInputChange} name="gender" checked={this.state.gender == "female"} />
                                <span>{window.strings.COUNSELLINGFORM.FEMALE}</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>{window.strings.COUNSELLINGFORM.RACE}</label>
                          <select name="race" value={this.state.race} onChange={this.handleInputChange} className="form-control mt-1">
                            <option value="">{window.strings.COUNSELLINGFORM.SELECTRACE}</option>
                            {window.strings.COUNSELLINGFORM.raceList.map(({value,name}, index) => <option value={value} key={value}>{name}</option>)}
                            {/* <option value="Chinese">chinese</option>
                            <option value="malay">Malay</option>
                            <option value="indian">Indian</option>
                            <option value="eurasian">Eurasian</option>
                            <option value="others">Others</option> */}
                          </select>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>{window.strings.COUNSELLINGFORM.LANGUAGE}</label>
                          <div>
                            <Select
                              styles={{
                                  control: base => ({ 
                                    ...base,
                                    borderColor: '#ced4da',
                                    boxShadow: '#FE988D',
                                    border:'0',
                                    borderBottom:'1px solid #ced4da',
                                    borderRadius:'0',
                                    minHeight:'calc(1.5em + .75rem + 2px)',
                                    '&:focus': { 
                                              borderColor: '#80bdff'
                                            } 
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    cursor: "pointer", 
                                    height: "30px",
                                    minHeight: "30px"
                                  })
                              }}
                              value={this.state.languageObj}
                              options={languageOptions}
                              onChange={this.handleLanguage}
                              placeholder={window.strings.COUNSELLINGFORM.SELECTLANG}
                              components={animatedComponents}
                              isMulti
                            />
                            {/* {this.state.submitted && ((this.state.languageObj && this.state.languageObj.length < 1 || !this.state.languageObj)) && <div className="mandatory">{"Language is Required"}</div>} */}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>{window.strings.COUNSELLINGFORM.NATIONALITY}</label>
                          <input type="text" name="nationality" value={this.state.nationality} onChange={this.handleInputChange} className="form-control" placeholder={window.strings.COUNSELLINGFORM.ENTERNATION} />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>{window.strings.COUNSELLINGFORM.CONTACTNO}</label>&nbsp;<span class="mandatory">*</span>
                          <input type="number" name="mobile" value={this.state.mobile} onChange={this.handleInputChange} className="form-control" placeholder={window.strings.COUNSELLINGFORM.ENTERCONTACT} />
                          {this.state.submitted && !this.state.mobile && <div className="mandatory">{window.strings.COUNSELLINGFORM.CONTACTREQ}</div>}
                          {this.state.submitted && this.state.mobile && this.state.mobile.length !=8 && <div className="mandatory">{window.strings.COUNSELLINGFORM.CONREQ}</div>}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>{window.strings.COUNSELLINGFORM.EMAILADDRESS}</label>&nbsp;<span class="mandatory">*</span>
                          <input type="email" name="email" value={this.state.email} onChange={this.handleInputChange} className="form-control" placeholder={window.strings.COUNSELLINGFORM.ENTEREMAIL} />
                          {this.state.submitted && !this.state.email && <div className="mandatory">{window.strings.COUNSELLINGFORM.EMAILENTER}</div>}
                          {this.state.submitted && this.state.email && !this.validate(this.state.email) && <div className="mandatory">{window.strings.COUNSELLINGFORM.EMAILERROR}</div>}                        
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>{window.strings.COUNSELLINGFORM.COUNSELINGSER}</label>&nbsp;<span class="mandatory">*</span>
                          <select name="counsellingService" value={this.state.counsellingService} onChange={this.handleInputChange} className="form-control">
                            <option value="">{window.strings.COUNSELLINGFORM.SELECTCOUNS}</option>
                            {window.strings.COUNSELLINGFORM.serviceList.map(({value,name}, index) => <option value={value} key={value}>{name}</option>)}
                            {/* <option value="Phone Call">Phone Call</option>
                            <option value="Email">Email</option> */}
                            {/*  <option value="audio">Phone Call</option>
                             <option value="video">Video Call</option> */}
                          </select>
                          {this.state.submitted && !this.state.counsellingService && <div className="mandatory">{window.strings.COUNSELLINGFORM.COUNSREQ}</div>}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>{window.strings.COUNSELLINGFORM.CONTACTADD}</label>
                          <input type="textArea" name="address1" value={this.state.address1} onChange={this.handleInputChange} className="form-control" placeholder={window.strings.COUNSELLINGFORM.ENTERCONTACTADD} />
                        </div>
                      </div>                     
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>{window.strings.COUNSELLINGFORM.TELLAS}</label>
                          <textarea type="textArea" name="concern" value={this.state.concern} onChange={this.handleInputChange} className="form-control" placeholder={window.strings.COUNSELLINGFORM.ISSUE} />
                        </div>
                      </div>
                    </div>
                    <div className="text-left">
                      <button type="button" onClick={this.handleCancel} className="cncl-btn mr-4">{window.strings.COUNSELLINGFORM.CANCEL}</button>
                      <button type="submit" className="gradient-btn" >{window.strings.COUNSELLINGFORM.SUBMIT}</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 
                  <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                    Launch demo modal
                  </button>

                  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">Thank You!</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                              <h6>Your Counselling Service request form has been successfully submitted!</h6>
                              <p>Do you wish to download your form?</p>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="gradient-btn">Download Form</button>
                          <button type="button" class="link-btn" data-dismiss="modal">Cancel</button>
                        </div>
                      </div>
                    </div>
                  </div> */}


      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
}


const actionCreators = {}

const connectedCounsellingServiceForm = connect(mapStateToProps, actionCreators)(CounsellingServiceForm);
export { connectedCounsellingServiceForm as CounsellingServiceForm };