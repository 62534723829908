import {categoryConstants} from '../../constants'

const initialState = {
    saveStatus:'',
    categoryList:[],
    deleteStatus : ''
}
export function category(state=initialState,action){
    switch(action.type){
        case categoryConstants.SAVE_CATEGORY_SUCCESS:
            return {
                ...state,
                saveStatus:action.saveStatus
            }
            case categoryConstants.FETCH_CATEGORY_SUCCESS:
                return {
                    ...state,
                    categoryList:action.categoryList
                }
                case categoryConstants.CATEGORY_DELETE_SUCCESS:
                    return {
                        ...state,
                        deleteStatus:action.deleteStatus
                    }
            default:
                return state
    }
}