import React from "react";
import { connect } from "react-redux";
import AbtBanner from "../../assets/img/img_abtbanr.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Modal } from "react-responsive-modal";
import Select from "react-select";
import moment from "moment";
import makeAnimated from "react-select/animated";
import { eventService } from "../../../services/event.service";
import PubSub from "pubsub-js";
import { isWebLogin, history } from "../../../helpers";
import { toastr } from "../../../services";
import { config } from "../../../config";
const animatedComponents = makeAnimated();
class SignupEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageList: ["English", "Chinese", "Malay", "Tamil"],
      whtapp: "",
      loginData: isWebLogin(),
    };
  }
  componentDidMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.obj
    ) {
      let reqData = this.props.location.state.obj;
      this.setState({ eventId: reqData && reqData.eventId });
    }

    if (this.state.loginData) {
      const { userName, email, mobile, gender } = this.state.loginData;
      let mobileAlter = mobile ? mobile.replace("+65", "") : "";
      this.setState({
        userName: userName ? userName : "",
        gender: gender ? gender : "",
        mobile: mobileAlter,
        email: email ? email : "",
      });
    }
  }

  handleLanguage = (Data) => {
    this.setState({ languageObj: Data });
  };

  handleInputChange = (e) => {
    e.target.value && e.target.value[0].includes(" ")
      ? (e.target.value = "")
      : this.setState({ [e.target.name]: e.target.value });
    if (e.target.name == "email") {
      this.validate(this.state.email);
    }
  };
  goToHome = () => {
    history.push("/");
  };
  validate = (value) => {
    var emailRegex = /^([a-z])+([a-z0-9_\.\-])+\@(([a-z\-])+\.)+([a-z]{2,3})$/;
    if (emailRegex.test(value)) {
      return true;
    } else {
      return false;
    }
  };

  showLoader() {
    PubSub.publish("msg", true);
  }

  hideLoader() {
    PubSub.publish("msg", false);
  }
  handleCancel = () => {
    // this.setState({submitted:false,userName:'',email:'',mobile:'',gender:'',dob:'',whtapp:'',
    //                   languageObj:'',toKnow:''})
    history.goBack();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log("state", this.state);
    const {
      userName,
      email,
      mobile,
      age,
      gender,
      address1,
      counsellingService,
      dob,
      whtapp,
      languageObj,
      toKnow,
      eventId,
    } = this.state;
    this.setState({ submitted: true });
    if (
      userName &&
      email &&
      this.validate(email) &&
      mobile &&
      mobile.length == 8 &&
      toKnow
    ) {
      let obj = {
        // "type":"events",
        name: userName,
        email: email,
        mobile: mobile,
        reason: toKnow,
        eventId: eventId,
      };
      if (gender) {
        obj["gender"] = gender;
      }
      if (dob) {
        obj["dob"] = dob;
      }
      if (languageObj) {
        obj["languages"] = languageObj.toString();
      }
      if (whtapp) {
        obj["whatsapp"] = whtapp;
      }
      this.showLoader();
      eventService
        .webEventEnquiry(obj)
        .then((resp) => {
          this.hideLoader();
          if (resp && resp.status == true) {
            toastr.success("You've signed up!");
            this.setState({
              userName: "",
              email: "",
              mobile: "",
              gender: "",
              submitted: false,
              dob: "",
              whtapp: "",
              languageObj: "",
              toKnow: "",
            });
            history.push("/");
          }
        })
        .catch((error) => {
          console.error(error);
          this.hideLoader();
        });
    } else {
      toastr.error("Mandatory fields are invalid or missing");
    }
  };

  // resetForm = () => {
  //   // this.setState({ open: false })
  //   this.setState({submitted:false,open:false,userName:'',lastName:'',email:'',mobile:'',age:'',
  // gender:'',address1:'',counsellingService:'',concern:'',languageObj:'',nationality:'',race:''})
  // }

  render() {
    let languageOptions = [];

    this.state.languageList &&
      this.state.languageList.map((item) => {
        let obj = { label: item, value: item };
        languageOptions.push(obj);
      });

    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };
    return (
      <div className="sign-wrap">
        <nav class="breadcrumb">
          <div className="container d-flex">
            <a class="breadcrumb-item" onClick={this.goToHome}>
              <i className="fas fa-home"></i>
            </a>
            {/* <a class="breadcrumb-item" href="#">Events</a> */}
            <span class="breadcrumb-item active">Events</span>
            <span class="breadcrumb-item active">Event Signup Form</span>
          </div>
        </nav>
        <Carousel
          className="sign-slide"
          swipeable={false}
          draggable={false}
          showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={this.props.deviceType !== "mobile" ? true : false}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="all 1.2s ease 0s"
          transitionDuration={1000}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          deviceType={this.props.deviceType}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div className="abt-banr">
            <img src={AbtBanner} />
          </div>
          <div className="abt-banr">
            <img src={AbtBanner} />
          </div>
          <div className="abt-banr">
            <img src={AbtBanner} />
          </div>
        </Carousel>
        <div className="container">
          <div className="spad">
            <div className="col-lg-10 m-auto">
              <div className="card">
                <div className="card-header">
                  <h5>{window.strings.SIGNUPEVENT.SIGNUP_FOR_EVENT}</h5>
                </div>
                <div className="card-body">
                  <form onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>{window.strings.SIGNUPEVENT.FULL_NAME}</label>
                          &nbsp;<span class="mandatory">*</span>
                          <input
                            type="text"
                            name="userName"
                            value={this.state.userName}
                            onChange={this.handleInputChange}
                            className="form-control"
                            placeholder={window.strings.SIGNUPEVENT.EN_NAME}
                          />
                          {this.state.submitted && !this.state.userName && (
                            <div className="mandatory">
                              {window.strings.SIGNUPEVENT.NAME_RE}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>{window.strings.SIGNUPEVENT.CON_NO}</label>
                          &nbsp;<span class="mandatory">*</span>
                          <input
                            type="number"
                            name="mobile"
                            value={this.state.mobile}
                            onChange={this.handleInputChange}
                            className="form-control"
                            placeholder={window.strings.SIGNUPEVENT.EN_CON}
                          />
                          {this.state.submitted && !this.state.mobile && (
                            <div className="mandatory">
                              {window.strings.SIGNUPEVENT.CON_RE}
                            </div>
                          )}
                          {this.state.submitted &&
                            this.state.mobile &&
                            this.state.mobile.length != 8 && (
                              <div className="mandatory">
                                {window.strings.SIGNUPEVENT.CON_INVALID}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>{window.strings.SIGNUPEVENT.CON_WHAPP}</label>
                          <div className="col-sm-12 px-0">
                            <div className="radio-box">
                              <label>
                                <input
                                  type="radio"
                                  value="true"
                                  onChange={this.handleInputChange}
                                  name="whtapp"
                                  checked={this.state.whtapp == "true"}
                                />
                                <span>{window.strings.SIGNUPEVENT.YES}</span>
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  value="false"
                                  onChange={this.handleInputChange}
                                  name="whtapp"
                                  checked={this.state.whtapp == "false"}
                                />
                                <span>{window.strings.SIGNUPEVENT.NO}</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>{window.strings.SIGNUPEVENT.EMAIL}</label>
                          &nbsp;<span class="mandatory">*</span>
                          <input
                            type="email"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                            className="form-control"
                            placeholder={window.strings.SIGNUPEVENT.EN_EMAIL}
                          />
                          {this.state.submitted && !this.state.email && (
                            <div className="mandatory">
                              {window.strings.SIGNUPEVENT.EMAIL_RE}
                            </div>
                          )}
                          {this.state.submitted &&
                            this.state.email &&
                            !this.validate(this.state.email) && (
                              <div className="mandatory">
                                {window.strings.SIGNUPEVENT.EMAIL_INVALID}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>{window.strings.SIGNUPEVENT.DOB}</label>
                          <input
                            type="number"
                            min="1900"
                            max="2022"
                            name="dob"
                            value={this.state.dob}
                            onChange={this.handleInputChange}
                            className="form-control"
                            placeholder="Enter your Year of Birth"
                          />
                          {/* {this.state.submitted && !this.state.userName && <div className="mandatory">{'First Name is Required'}</div>} */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>{window.strings.SIGNUPEVENT.GEN}</label>
                          <div className="col-sm-12 px-0">
                            <div className="radio-box">
                              <label>
                                <input
                                  type="radio"
                                  value="male"
                                  onChange={this.handleInputChange}
                                  name="gender"
                                  checked={this.state.gender == "male"}
                                />
                                <span>{window.strings.SIGNUPEVENT.MALE}</span>
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  value="female"
                                  onChange={this.handleInputChange}
                                  name="gender"
                                  checked={this.state.gender == "female"}
                                />
                                <span>{window.strings.SIGNUPEVENT.FEMALE}</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>{window.strings.SIGNUPEVENT.LANG}</label>
                          <div>
                            <Select
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  borderColor: "#ced4da",
                                  boxShadow: "#FE988D",
                                  border: "0",
                                  borderBottom: "1px solid #ced4da",
                                  borderRadius: "0",
                                  minHeight: "calc(1.5em + .75rem + 2px)",
                                  "&:focus": {
                                    borderColor: "#80bdff",
                                  },
                                }),
                                option: (provided) => ({
                                  ...provided,
                                  cursor: "pointer",
                                  height: "30px",
                                  minHeight: "30px",
                                }),
                              }}
                              value={this.state.languageObj}
                              options={languageOptions}
                              onChange={this.handleLanguage}
                              placeholder={window.strings.SIGNUPEVENT.SEL_LANG}
                              components={animatedComponents}
                              isMulti
                            />
                            {/* {this.state.submitted && ((this.state.languageObj && this.state.languageObj.length < 1 || !this.state.languageObj)) && <div className="mandatory">{"Language is Required"}</div>} */}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>{window.strings.SIGNUPEVENT.HOW}</label>&nbsp;
                          <span class="mandatory">*</span>
                          <select
                            name="toKnow"
                            value={this.state.toKnow}
                            onChange={this.handleInputChange}
                            className="form-control"
                          >
                            <option value="">
                              {window.strings.SIGNUPEVENT.SEL_OPT}
                            </option>
                            <option value="Existing Volunteer/Service User">
                              Existing Volunteer/Service User
                            </option>
                            <option value="SAGECC Web/App/Social Media">
                              SAGECC Web/App/Social Media
                            </option>
                            <option value="SAGECC Email">SAGECC Email</option>
                            <option value="Friend/Family">Friend/Family</option>
                            <option value="Others">Others</option>
                          </select>
                          {this.state.submitted && !this.state.toKnow && (
                            <div className="mandatory">
                              {window.strings.SIGNUPEVENT.RE_REQ}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="text-left mt-2">
                      <button
                        type="button"
                        onClick={this.handleCancel}
                        className="cncl-btn mr-4"
                      >
                        {window.strings.SIGNUPEVENT.BACK}
                      </button>
                      <button type="submit" className="gradient-btn">
                        {window.strings.SIGNUPEVENT.SUBMIT}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 
                  <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                    Launch demo modal
                  </button>

                  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">Thank You!</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                              <h6>Your Counselling Service request form has been successfully submitted!</h6>
                              <p>Do you wish to download your form?</p>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="gradient-btn">Download Form</button>
                          <button type="button" class="link-btn" data-dismiss="modal">Cancel</button>
                        </div>
                      </div>
                    </div>
                  </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const actionCreators = {};

const connectedEventForm = connect(
  mapStateToProps,
  actionCreators
)(SignupEvent);
export { connectedEventForm as SignupEvent };
