import CryptoJS from 'crypto-js';
import { config } from '../config';
import io from 'socket.io-client';
import { chatActions } from '../store/actions'
// import {toastr} from '../services/toastr.service.js'


export function isAdminLogin() {
	let user = '';
	if (localStorage.getItem('user')) {
		user = localStorage.getItem('user');
		var bytes = CryptoJS.AES.decrypt(user.toString(), config.phaseKey);
		user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

	}

	if (user && user.data && user.data.sessionData && user.data.sessionData.token && user.data && ((user.data.role == 2) || (user.data.role == 1))) {

		return user.data
	} else {
		return false
	}
}

export function isWebLogin() {
	let user = '';
	if (localStorage.getItem('user')) {
		user = localStorage.getItem('user');
		var bytes = CryptoJS.AES.decrypt(user.toString(), config.phaseKey);
		user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

	}


	if (user && user.data && user.data.sessionData && user.data.sessionData.token && user.data && ((user.data.role == 5))) {

		return user.data
	} else {
		return false
	}
}


export function getAcceptedChannel_Listen() {
	let isWebLoginData = isWebLogin()
	// const socket = io.connect(config.socket_url);

	const socket = io(config.socket_url, {
		transports: ["websocket"],
		jsonp: false,
	});

	socket.on('connect', function () {
		console.log("Connected to WS server");
		console.log(socket.connected);

		socket.on('GET_USER_CHATS', function (data) {
			if (((data && data.toUserId) == (isWebLoginData && isWebLoginData.id))) {
				chatActions.volunteer_Accept_Channel(data)
			}
		}
		);

	});
	
}

export function senduserMsg_Emit(obj) {
	let isWebLoginData = isWebLogin()
	if (isWebLoginData && isWebLoginData.id) {

		const socket = io(config.socket_url, {
			transports: ["websocket"],
			jsonp: false,
		});

		// const socket = io.connect(config.socket_url);
		socket.on('connect', function () {
			socket.emit('CHANNEL_CHAT', obj)
			// socket.on(`NOTIFICATIONS_COUNT_${isWebLoginData.id}`, function(data){
			// 	if(data && (data.unReadCount && (data.userId == isWebLoginData.id))){
			// 	  store.dispatch({ type: orderPaymentConstants.UNREAD_NOTIFICATION,unreadCount : data.unReadCount })
			// 	}
			// 	}
			// );
		});
	}
}


export function getUserMsg_Listen(channelId) {
	let isWebLoginData = isWebLogin()
	// const socket = io.connect(config.socket_url);

	const socket = io(config.socket_url, {
		transports: ["websocket"],
		jsonp: false,
	});

	socket.on('connect', function () {
		console.log("Connected to WS server");
		console.log(socket.connected);

		if (channelId) {
			socket.on(`CHATS_${channelId}`, function (data) {
				chatActions.getMessage_Listen(data)
			}
			);
		}
	});

}




