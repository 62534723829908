import React, { Fragment } from "react";
import { connect } from "react-redux";
import { history } from "../../../../helpers/history";
import Menu from "../WebHeader/Menu";
import logo from "../../../assets/img/logo-dark.png";
import NCSSLogo from "../../../assets/img/FooterLogo/NCSSNew.png";
import MHSLogo from "../../../assets/img/FooterLogo/NewMOH_Logo.png";
import CCLogo from "../../../assets/img/FooterLogo/ComChestNew.png";
import { WebHeader } from "../WebHeader/WebHeader";
import PubSub from "pubsub-js";

class WebFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuData: [],
      translation: sessionStorage.getItem("translation")
        ? sessionStorage.getItem("translation")
        : "English",
    };
  }
  componentDidMount() {}
  navigateAbout = () => {
    sessionStorage.setItem("selectedParentPath", "ourHistory");
    sessionStorage.setItem("selectedChildPath", "ourHistory");
    history.push("/ourHistory");
  };
  navigateOurWork = () => {
    sessionStorage.setItem("selectedParentPath", "ourWork");
    sessionStorage.setItem("selectedChildPath", "");
    history.push("/ourWork");
  };
  navigateGallery = () => {
    sessionStorage.setItem("selectedParentPath", "videosGallery");
    sessionStorage.setItem("selectedChildPath", "videosGallery");
    history.push("/videosGallery");
  };
  navigateVolunteer = () => {
    sessionStorage.setItem("selectedParentPath", "volunteersOpportunities");
    sessionStorage.setItem("selectedChildPath", "volunteersOpportunities");
    history.push("/volunteersOpportunities");
  };
  navigateDonation = () => {
    sessionStorage.setItem("selectedParentPath", "donation");
    sessionStorage.setItem("selectedChildPath", "");
    history.push("/donation");
  };
  navigateEvent = () => {
    sessionStorage.setItem("selectedParentPath", "events");
    sessionStorage.setItem("selectedChildPath", "");
    history.push("/events");
  };
  navigatePrivacy = () => {
    sessionStorage.setItem("selectedParentPath", "");
    sessionStorage.setItem("selectedChildPath", "");
    history.push("/privacy-policy");
  };
  render() {
    const { menuData } = this.state;
    let menusLists =
      menuData &&
      menuData.map((item, key) => {
        return (
          <Menu
            key={key}
            item={item}
            showChild={true}
            resetSearch={() => this.resetSearch()}
          />
        );
      });
    return (
      <Fragment>
        {/* <!-- Footer Section Begin --> */}
        <footer className="footer-section">
          <div className="container px-sm-0">
            <div className="row">
              <div className="col-xl-3 col-md-6 col-12">
                <a className="foot-logo" href="#">
                  <img src={logo} alt={"logo"} />
                </a>
              </div>
              <div className="col-xl-2 col-md-6 col-12">
                <h5>{window.strings.FOOTER.SITE_MAP}</h5>
                <ul>
                  <li>
                    <button className="foot-btn" onClick={this.navigateAbout}>
                      {window.strings.FOOTER.ABOUT}
                    </button>
                  </li>
                  <li>
                    <button className="foot-btn" onClick={this.navigateOurWork}>
                      {window.strings.FOOTER.OUR_WORK}
                    </button>
                  </li>
                  <li>
                    <button className="foot-btn" onClick={this.navigateGallery}>
                      {window.strings.FOOTER.GALLERY}
                    </button>
                  </li>
                  <li>
                    <button
                      className="foot-btn"
                      onClick={this.navigateVolunteer}
                    >
                      {window.strings.FOOTER.VOLUNTEER}
                    </button>
                  </li>
                  <li>
                    <button
                      className="foot-btn"
                      onClick={this.navigateDonation}
                    >
                      {window.strings.FOOTER.DONATION}
                    </button>
                  </li>
                  <li>
                    <button className="foot-btn" onClick={this.navigateEvent}>
                      {window.strings.FOOTER.EVENT}
                    </button>
                  </li>
                  <li>
                    <button className="foot-btn" onClick={this.navigatePrivacy}>
                      Privacy Policy
                    </button>
                  </li>
                </ul>
              </div>
              <div className="col-xl-4 col-md-6 col-12">
                <h5>{window.strings.FOOTER.ADDRESS}</h5>
                <ul>
                  <li>{window.strings.FOOTER.ADDRESS_ONE}</li>
                  <li>{window.strings.FOOTER.ADDRESS_TWO}</li>
                  <li>{window.strings.FOOTER.ADDRESS_THR}</li>
                  <li>{window.strings.FOOTER.ADDRESS_FOU}</li>
                </ul>
              </div>
              <div className="col-xl-3 col-md-6 col-12">
                <h5>{window.strings.FOOTER.CONTACT_US}</h5>
                <ul>
                  <li>{window.strings.FOOTER.TEL}: (65) 6354 1191</li>
                  <li>{window.strings.FOOTER.FAX}: (65) 6354 1905</li>
                  <li>
                    {window.strings.FOOTER.EMAIL}:{" "}
                    <a
                      href="mailto:reception@sagecc.org.sg"
                      className="line-text"
                    >
                      reception@sagecc.org.sg
                    </a>
                  </li>
                </ul>
                <div className="ft-social">
                  <div className="ht-social">
                    <h5>
                      {window.strings.FOOTER.FOLLOW}
                      <a
                        href="https://www.facebook.com/sagecc/"
                        className="icon-box f-icon"
                      >
                        <i className="fab fa-facebook-square"></i>
                      </a>
                    </h5>
                  </div>
                  {/* <a href="#" className="icon-box"><i className="fab fa-facebook-square"></i></a> */}
                  {/* <a href="#" className="icon-box"><i className="fab fa-twitter-square"></i></a> */}
                  {/* <a href="#" className="icon-box"><i className="fab fa-linkedin-square"></i></a> */}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="copyright-text">
            <h5>Copyright © SAGECC. All Rights Reserved</h5>
        </div> */}
        </footer>
        <div className="logo-wrap">
          <div className="container">
            <div className="logo-box">
              <div>
                <p>Member of:</p>
                <img className="footer-img" src={NCSSLogo} />
              </div>
              <div>
                <p>Funded by:</p>
                <img className="footer-img" src={MHSLogo} />
              </div>
              <div>
                <p className="space-box"></p>
                <img className="footer-img" src={CCLogo} />
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-text">
          <h5>Copyright © SAGECC. All Rights Reserved</h5>
        </div>
        {/* <!-- Footer Section End --> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const actionCreators = {};

const connectedWebFooter = connect(mapStateToProps, actionCreators)(WebFooter);
export { connectedWebFooter as WebFooter };
