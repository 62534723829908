import React from 'react';
import { connect } from 'react-redux';
import AbtBanner from '../../assets/img/img_abtbanr.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import EvntImg1 from '../../assets/img/img_evnt_1.png';
import EvntImg2 from '../../assets/img/img_evnt_2.png';
import EvntImg3 from '../../assets/img/img_evnt_3.png';
import EvntImg4 from '../../assets/img/img_evnt_4.png';
import EvntImg5 from '../../assets/img/img_evnt_5.png';
import EvntImg6 from '../../assets/img/img_evnt_6.png';
import { eventActions } from '../../../store/actions';
import { toastr } from '../../../services'
import { config } from '../../../config'
import moment from 'moment'
import { history, isWebLogin } from '../../../helpers'

class EventDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // loginData : isWebLogin()
        }
    }


    componentWillReceiveProps(newProps) {
        if (newProps && newProps.eventList && newProps.eventList.data) {
            let data = newProps.eventList && newProps.eventList.data && newProps.eventList.data.rows
            this.setState({ eventList: data })
            this.props.resetList()
        }
    }

    componentDidMount() {
        if (this.props.location && this.props.location.state && this.props.location.state.obj) {
            let reqData = this.props.location.state.obj
            this.setState({ eventId: reqData && reqData.eventId })
            this.props.getList(reqData)
        }

        this.getEventList()
    }
    goToSignup = () => {
        history.push('/signUpEvent', { obj: { eventId: this.state.eventId } })
    }
    getEventList = () => {
        let obj = {
            type : 'web',
            eventType : "upcoming"
        }
        this.props.getList(obj)
    }



    render() {
        const { eventList } = this.state
        console.log("event", eventList)
        const responsive = {
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 1,
                slidesToSlide: 1 // optional, default to 1.
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2,
                slidesToSlide: 2 // optional, default to 1.
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                slidesToSlide: 1 // optional, default to 1.
            }
        };
        return (
            <div className="evnt-detail">
                {/* <nav class="breadcrumb">
                    <div className="container d-flex">
                        <a class="breadcrumb-item" href="#"><i className="fas fa-home"></i></a>
                        <span class="breadcrumb-item active">Events</span>
                        <span class="breadcrumb-item active">Event Detail</span>
                    </div>
                </nav> */}
                {/* <Carousel className="evnt-slide"
                        swipeable={false}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        customTransition="all 1.2s ease 0s"
                        transitionDuration={1000}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        deviceType={this.props.deviceType}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                    >
                       <div className="abt-banr">
                            <img src={AbtBanner}/>
                        </div>
                        <div className="abt-banr">
                            <img src={AbtBanner}/>
                        </div>
                        <div className="abt-banr">
                            <img src={AbtBanner}/>
                        </div>
                    </Carousel> */}
                <div className="container">
                    <div className="spad">
                        {/* <div className="text-center">
                                <h3>SAGE Counselling Centre</h3>
                                <h4>Events</h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </p>
                            </div> */}
                        <div className="img-gallery">

                            {
                                this.state.eventList && this.state.eventList.map(item => (
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body row">
                                                <div className="col-lg-4"><img src={config.imgPath + '/events/' + item.imageUrl} /></div>
                                                <div className="col-lg-8">
                                                    <span>
                                                        <h6> Title: {item.title && item.title}</h6>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <span className="evnt-detail-box"><i className="far fa-calendar-alt"></i>
                                                                    <p>{item.startDate && item.startDate && moment(item.startDate).format('YYYY-MM-DD')}</p>
                                                                    <p>{item.endDate && item.endDate && moment(item.endDate).format('YYYY-MM-DD')}</p></span>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <span className="evnt-detail-box"><i className="far fa-clock"></i>
                                                                    <p>{item.workingTime && item.workingTime.startTime}</p>
                                                                    <p>{item.workingTime && item.workingTime.endTime}</p></span>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <span className="evnt-detail-box"><i className="fas fa-map-marker-alt"></i>
                                                                    <p>{item.address && item.address}</p>
                                                                    <p>{item.location && item.location}</p></span>
                                                            </div>
                                                        </div>
                                                        <div className="evnt-desc">
                                                            <p className="text-truncate">{item.description && item.description}</p>
                                                        </div>
                                                        <button className="gradient-btn" onClick={this.goToSignup}>Signup Event</button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }


                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        eventList: state.events.eventList,
    }
}


const actionCreators = {
    getList: eventActions.getList,
    resetList: eventActions.resetList,
}

const connectedEvents = connect(mapStateToProps, actionCreators)(EventDetail);
export { connectedEvents as EventDetail };