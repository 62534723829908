import CryptoJS from 'crypto-js';
import {config} from '../config';

export function authHeader(){
    let user = ""
    if(localStorage.getItem('user')){
        user = localStorage.getItem('user')
        var bytes = CryptoJS.AES.decrypt(user.toString(), config.phaseKey);
		user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));     
    }
    
    let obj = {}
    if (user && user.data && user.data.role == "5") { 
      obj['type'] = "user" 
    }


    if (user && user.data &&  user.data.sessionData && user.data.sessionData.token) {
      obj['Authorization'] = user.data.sessionData.token  
      return obj
	} else {
		return {};
	}
}