import React, { Component, Fragment } from "react";
import UploadIcon from "@material-ui/icons/CloudUpload";
import Button from "@material-ui/core/Button";
import { userActions } from "../../../store/actions";
import { connect } from "react-redux";
import { history } from "../../../helpers";
import CryptoJS from "crypto-js";
import { config } from "../../../config";
import { toastr } from "../../../services";
import moment from "moment";
var tz = require("moment-timezone");
class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      id: "",
      lname: "",
      uname: "",
      gender: "",
      dob: "",
      email: "",
      password: "",
      user: "",
      no: "+65",
      mobile: "",
      description: "",
      days: [
        { day: "Sunday", value: "Sun", isChecked: false },
        { day: "Monday", value: "Mon", isChecked: false },
        { day: "Tuesday", value: "Tue", isChecked: false },
        { day: "Wednesday", value: "Wed", isChecked: false },
        { day: "Thursday", value: "Thrs", isChecked: false },
        { day: "Friday", value: "Fri", isChecked: false },
        { day: "Saturday", value: "Sat", isChecked: false },
      ],
      startTime: "",
      endTime: "",
      workingDays: [],
      submitted: false,
    };
  }
  componentDidMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.obj
    ) {
      let reqData = this.props.location.state.obj;
      this.setState({ roleId: this.props.location.state.obj.roleId });
      this.props.getList(reqData);
    }
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.roleObj
    ) {
      this.setState({ roleId: this.props.location.state.roleObj.roleId });
    }
  }
  componentWillReceiveProps(newProps) {
    if (
      newProps &&
      newProps.userList &&
      newProps.userList.data &&
      newProps.userList.data.rows &&
      newProps.userList.data.rows[0]
    ) {
      let editData = newProps.userList.data.rows[0];
      let workingDays = [];
      this.state.days &&
        this.state.days.map((item) => {
          editData.workingDays &&
            editData.workingDays.map((day) => {
              if (day == item.value) {
                item.isChecked = true;
                workingDays.push(day);
              }
            });
        });
      console.log();
      this.setState({
        fname: editData.firstName && editData.firstName,
        lname: editData.lastName && editData.lastName,
        email: editData.email && editData.email,
        uname: editData.userName && editData.userName,
        dob: editData.dob,
        gender: editData.gender && editData.gender,
        user: editData.role && editData.role,
        id: editData.id,
        startTime: editData.availableTime && editData.availableTime.startTime,
        endTime: editData.availableTime && editData.availableTime.endTime,
        description: editData.description && editData.description,
        password: editData.password,
        mobile: editData.mobile && editData.mobile,
        imagePreviewUrl:
          config.apiUrl + "/uploads/users/" + editData.profileImg,
        workingDays,
      });
      this.props.resetList();
    }
    if (newProps && newProps.saveStatus && newProps.saveStatus == true) {
      this.props.resetSave();
      history.goBack();
    }
  }
  handleInputChange = (e) => {
    e.target.value && e.target.value[0].includes(" ")
      ? (e.target.value = "")
      : this.setState({ [e.target.name]: e.target.value });
  };
  handleDays = (eve) => {
    const days = this.state.days;
    days &&
      days.map((day, index) => {
        if ((day && day.value) == eve.target.value) {
          day.isChecked = eve.target.checked;
        }
      });
    this.setState({ days }, () => {
      this.handleWorkingDays();
    });
  };
  handleWorkingDays = () => {
    const dayArray = [];
    const days = this.state.days;
    days &&
      days.map((item) => {
        if (item && item.isChecked) {
          dayArray.push(item.value);
        }
      });
    this.setState({ workingDays: dayArray });
  };
  handleImgChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        fileData: file,
        imageName: file.name,
        imagePreviewUrl: reader.result,
      });
    };
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);
    }
  };
  handleUserChange = (e) => {
    this.setState({ roleId: e.target.value });
  };
  validate = (value) => {
    var emailRegex = /^([a-z])+([a-z0-9_\.\-])+\@(([a-z\-])+\.)+([a-z]{2,3})$/;
    if (emailRegex.test(value)) {
      return true;
    } else {
      return false;
    }
  };
  validPass = (value) => {
    if (value && value.length == 6) {
      return true;
    } else {
      return false;
    }
  };
  isMobileValid = (value) => {
    if (value && value.length == 8) {
      return true;
    } else {
      return false;
    }
  };
  listPath = () => {
    history.goBack();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      fname,
      lname,
      uname,
      dob,
      gender,
      email,
      password,
      fileData,
      imageName,
      user,
      id,
      mobile,
      days,
      startTime,
      endTime,
      no,
      roleId,
      workingDays,
      description,
    } = this.state;
    console.log(fileData);
    const availableTime = {};
    if (startTime && endTime) {
      availableTime["startTime"] = startTime;
      availableTime["endTime"] = endTime;
      // availableTime['startTime'] = moment(startTime,"hh:mm A").tz('Asia/Singapore').format('HH:mm')
      // availableTime['endTime'] = moment(endTime,"hh:mm A").tz('Asia/Singapore').format('HH:mm')
    }
    console.log("tine", availableTime);
    this.setState({ submitted: true });
    if (
      fname &&
      uname &&
      email &&
      password &&
      mobile &&
      this.validate(email) &&
      this.isMobileValid(mobile) &&
      dob &&
      (roleId == 3 ? startTime && endTime && workingDays.length > 0 : true)
    ) {
      const formData = new FormData();
      const mobile_no = no.concat(mobile);
      let obj = {
        userName: uname,
        firstName: fname,
        email: email,
        // password:password,
        role: this.state.roleId,
        mobile: mobile,
        dob: dob,
      };
      // if(fname){
      //     obj['firstName'] = fname
      // }
      if (lname) {
        obj["lastName"] = lname;
      }
      if (workingDays.length > 0) {
        obj["workingDays"] = workingDays;
      }
      if (availableTime) {
        obj["availableTime"] = availableTime;
      }
      if (gender) {
        obj["gender"] = gender;
      }
      if (description) {
        obj["description"] = description;
      }
      // if(dob){
      //     obj['dob'] = dob
      // }
      if (id) {
        obj["userId"] = id;
      } else {
        obj["password"] = password;
      }
      formData.append("userData", JSON.stringify(obj));
      fileData && formData.append("profileImage", fileData);
      console.log(fileData)
      console.log('below is formdata')
      for (var pair of formData.entries()) {
          console.log(pair[0]+ ', ' + pair[1]); 
      }
      this.props.save(formData);
    } else {
      toastr.error("Mandatory fields are missing");
    }
  };
  render() {
    const { roleId, id } = this.state;
    console.log(roleId);
    return (
      <Fragment>
        <section>
          {/* {this.state.id ? <h4>Edit User</h4> : <h4>Add User</h4>} */}
          <div className="tit-sec">
            {roleId == 4 && !id && <h4>Add Volunteer</h4>}
            {roleId == 4 && id && <h4>Edit Volunteer</h4>}
            {roleId == 3 && !id && <h4>Add Counsellor</h4>}
            {roleId == 3 && id && <h4>Edit Counsellor</h4>}
            {roleId == 5 && id && <h4>Edit User</h4>}
          </div>
          <div class="card">
            <div class="card-body">
              <form onSubmit={this.handleSubmit}>
                <div className="form-tab row">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">
                        First name&nbsp;<span class="mandatory">*</span>
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          name="fname"
                          value={this.state.fname}
                          onChange={this.handleInputChange}
                          placeholder="Enter First name"
                          className="form-control"
                        />
                        {this.state.submitted && !this.state.fname && (
                          <div className="mandatory">
                            Please enter the first name
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label class="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">
                        Last name
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          name="lname"
                          value={this.state.lname}
                          onChange={this.handleInputChange}
                          placeholder="Enter Last name"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">
                        Username&nbsp;<span class="mandatory">*</span>
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          name="uname"
                          value={this.state.uname}
                          onChange={this.handleInputChange}
                          placeholder="Enter Username"
                          className="form-control"
                        />
                        {this.state.submitted && !this.state.uname && (
                          <div className="mandatory">
                            Please enter the username
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">
                        Gender
                      </label>
                      <div className="col-sm-8">
                        <div className="radio-btn">
                          <label>
                            <input
                              type="radio"
                              value="male"
                              onChange={this.handleInputChange}
                              name="gender"
                              checked={this.state.gender == "male"}
                            />
                            <span>Male</span>
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="female"
                              onChange={this.handleInputChange}
                              name="gender"
                              checked={this.state.gender == "female"}
                            />
                            <span>Female</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-sm-3 col-xl-3 mb-md-1  col-form-label pr-0">
                        Date of Birth&nbsp;<span class="mandatory">*</span>
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="date"
                          name="dob"
                          value={this.state.dob}
                          onChange={this.handleInputChange}
                          placeholder="Enter Date of Birth"
                          className="form-control"
                          max={moment().format("YYYY-MM-DD")}
                        />
                        {this.state.submitted && !this.state.dob && (
                          <div className="mandatory">
                            Please Select your dob
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">
                        Mobile&nbsp;<span class="mandatory">*</span>
                      </label>
                      <div className="col-sm-8">
                        <div className="input-group">
                          <input
                            type="text"
                            value={this.state.no}
                            className="form-control ph-input"
                            disabled
                          />
                          <input
                            type="number"
                            name="mobile"
                            value={this.state.mobile}
                            onChange={this.handleInputChange}
                            placeholder="Enter Mobile.No"
                            className="form-control"
                          />
                          {this.state.submitted && !this.state.mobile && (
                            <div className="mandatory">
                              Please enter your mobile number
                            </div>
                          )}
                          {this.state.submitted &&
                            this.state.mobile &&
                            !this.isMobileValid(this.state.mobile) && (
                              <div className="mandatory">
                                Please enter valid Mobile Number
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">
                        Email&nbsp;<span class="mandatory">*</span>
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          name="email"
                          value={this.state.email}
                          onChange={this.handleInputChange}
                          placeholder="Enter Email"
                          className="form-control"
                        />
                        {this.state.submitted && !this.state.email && (
                          <div className="mandatory">
                            Please enter the email
                          </div>
                        )}
                        {this.state.submitted &&
                          this.state.email &&
                          !this.validate(this.state.email) && (
                            <div className="mandatory">
                              Please enter valid email
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">
                        Password&nbsp;<span class="mandatory">*</span>
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="password"
                          name="password"
                          value={this.state.password}
                          onChange={this.handleInputChange}
                          placeholder="Enter Password"
                          className="form-control"
                          disabled={this.state.id}
                        />
                        {this.state.submitted && !this.state.password && (
                          <div className="mandatory">
                            Please enter the password
                          </div>
                        )}
                        {/* {this.state.submitted && this.state.password && !this.validPass(this.state.password) && <div className="mandatory">Password must be 6 characters</div>} */}
                      </div>
                    </div>
                  </div>

                  {/* { this.state.roleId != 5 && */}
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">
                        User Type&nbsp;<span class="mandatory">*</span>
                      </label>
                      <div className="col-sm-8">
                        <select
                          value={this.state.user}
                          onChange={this.handleUserChange}
                          className="form-control"
                        >
                          <option value="">Select User</option>
                          <option value="3">Counsellor</option>
                          <option value="4">Volunteers</option>
                          <option value="5">Normal User</option>
                        </select>
                        {/* <input type="text" value={this.state.roleId == 4 ? "Volunteer" : 'Counsellor'}  className="form-control"/> */}
                        {this.state.submitted && !this.state.user && (
                          <div className="mandatory">
                            Please select usertype
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* } */}

                  {this.state.roleId == 3 && (
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">
                          Start Time&nbsp;<span class="mandatory">*</span>
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="time"
                            name="startTime"
                            value={this.state.startTime}
                            onChange={this.handleInputChange}
                            className="form-control"
                          />
                          {this.state.submitted && !this.state.startTime && (
                            <div className="mandatory">
                              Please Select Start Time
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.roleId == 3 && (
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">
                          End Time&nbsp;<span class="mandatory">*</span>
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="time"
                            name="endTime"
                            value={this.state.endTime}
                            onChange={this.handleInputChange}
                            className="form-control"
                          />
                          {this.state.submitted && !this.state.endTime && (
                            <div className="mandatory">
                              Please Select End Time
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.roleId == 3 && (
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-xl-3 mb-md-1  col-form-label pr-0">
                          Working Days&nbsp;<span class="mandatory">*</span>
                        </label>
                        <div className="col-sm-8">
                          {this.state.days &&
                            this.state.days.map((day, index) => (
                              <div>
                                <input
                                  type="checkbox"
                                  value={day && day.value}
                                  checked={day.isChecked == true}
                                  onChange={this.handleDays}
                                />
                                <span className="ml-2">{day.day}</span>
                              </div>
                            ))}
                          {this.state.submitted &&
                            !(
                              this.state.workingDays &&
                              this.state.workingDays.length > 0
                            ) && (
                              <div className="mandatory">
                                Please Select Working days
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.roleId == 3 && (
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">
                          Description
                        </label>
                        <div className="col-sm-8">
                          <textarea
                            type="text"
                            name="description"
                            value={this.state.description}
                            onChange={this.handleInputChange}
                            placeholder="Enter Description"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">
                        Image
                      </label>

                      <div className="col-sm-8">
                        <div className="choose-wrap">
                          <label className="choo-btn" for="file">
                            <UploadIcon className="mr-2" />
                            Choose File
                            <input
                              type="file"
                              name="image"
                              id="file"
                              single=""
                              accept="image/*"
                              onChange={this.handleImgChange}
                            />
                          </label>
                        </div>
                        <div>
                          <img
                            src={this.state.imagePreviewUrl}
                            height="80px"
                            width="80px"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bottom-btn">
                  <button
                    type="button"
                    className="btn cancel-btn"
                    onClick={this.listPath}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn add-btn">
                    Submit
                  </button>
                  {/* <Button type="button" variant="contained" onClick={this.listPath}>Cancel</Button>
                                                <Button color="primary" variant="contained" type="submit" className="ml-2">
                                                    Submit
                                                </Button> */}
                </div>
              </form>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    saveStatus: state.users.saveStatus,
    userList: state.users.userList,
  };
}
const actionCreators = {
  save: userActions.save,
  getList: userActions.getList,
  resetSave: userActions.resetSave,
  resetList: userActions.resetList,
};
const saveUser = connect(mapStateToProps, actionCreators)(CreateUser);
export { saveUser as CreateUser };
