import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { history, ReactPagination } from '../../../helpers';
import { appActions } from '../../../store/actions';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { toastr } from '../../../services';
import { config } from '../../../config';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { List } from '@material-ui/core';
import { CSVLink } from 'react-csv';

class FetchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formList: [],
      limit: 10,
      page: 1,
      searchTxt: '',
      totalCount: '',
      categoryId: '',
      category: '',
      tabIndex: 0,
      childTabIndex: 0,
      volunteerForm: false,
    };
  }
  componentDidMount() {
    //    if(this.props.location && this.props.location.state && this.props.location.state.obj){
    //     let reqData = this.props.location.state.obj

    //     this.props.getList(reqData)
    //     this.setState({volunteerForm:true})
    // }else{
    // this.list()
    // }
    this.initialList();
  }
  initialList = () => {
    if (this.props.location && this.props.location.state && this.props.location.state.obj) {
      let reqData = this.props.location.state.obj;
      console.log('request data is ', reqData);
      this.props.getList(reqData);
      this.setState({ volunteerForm: true });
    } else {
      this.setState({ volunteerForm: false }, () => {
        this.list();
      });
    }
  };

  componentDidUpdate(prevProps) {
    let hash = '';
    if (config.hashName) {
      hash = window.location.hash;
    } else {
      hash = '';
    }

    if (window.location.pathname + hash != config.hashName + prevProps.location.pathname) {
      this.initialList();
    }
  }

  exportCSV = () => {
    if (this.state.formList) {
      console.log('this is the formlist', this.state.formList);
    }
  };
  componentWillReceiveProps(newProps) {
    if (newProps && newProps.formList && newProps.formList.data) {
      let data = newProps.formList && newProps.formList.data && newProps.formList.data.rows;
      let request = newProps.formList && newProps.formList.request ? newProps.formList.request : [];
      let totalCount =
        newProps.formList && newProps.formList.data && newProps.formList.data.count ? newProps.formList.data.count : [];
      if (data.length == 0) {
        toastr.error('Result Not Found');
      }
      this.setState({
        formList: data,
        totalCount,
        pageCount: totalCount / request.limit,
      });
      this.props.resetList();
    }
  }
  list = () => {
    if (this.props && this.props.formList && this.props.formList.status == true) {
      let request = this.props.formList && this.props.formList.request ? this.props.formList.request : '';
      if (request.limit || request.page || request.searchTxt) {
        this.setState(
          {
            limit: request.limit,
            page: request.page,
            searchTxt: request.searchTxt,
          },
          () => {
            this.getList();
          }
        );
      } else {
        this.getList();
      }
    } else {
      this.getList();
    }
  };
  getList = () => {
    let obj = {};
    if (this.state.userId) {
      obj['userId'] = this.state.userId;
    }
    // if(this.state.searchTxt){
    //     obj['page'] = this.state.page
    //     obj['limit'] = this.state.limit
    //     obj['searchTxt'] = this.state.searchTxt
    // }
    // else{
    //     obj['page'] = this.state.page
    //     obj['limit'] = this.state.limit
    // }
    this.props.getList(obj);
  };
  tabChange = (tabIndex) => {
    this.setState({ tabIndex });
  };
  childTabChange = (childTab) => {
    this.setState({ childTabIndex: childTab });
    console.log('index', childTab);
  };
  onChange = (data) => {
    // window.scrollTo({top : 10, left:100,behavior: 'smooth'});
    if (this.state.page !== data.selected + 1) {
      this.setState(
        {
          page: data.selected + 1,
        },
        () => {
          this.getList();
        }
      );
    }
  };
  formPath = () => {
    history.push('/admin/category/add');
  };
  resetSearch = () => {
    this.setState({ searchTxt: '', page: 1 }, () => {
      this.getList();
    });
  };
  handleInputChange = (e) => {
    e.target.value && e.target.value[0].includes(' ')
      ? (e.target.value = '')
      : this.setState({ [e.target.name]: e.target.value });
  };
  itemEdit = (id) => {
    history.push('/admin/category/edit/' + id, { obj: { categoryId: id } });
  };
  itemDelete = (categoryId) => {
    let obj = {
      categoryId,
    };
    this.props.delete(obj);
  };
  handleCategory = (eve) => {
    this.setState({
      category: eve.target.value,
    });
    console.log('state', this.state.category);
  };
  handleDelete = (categoryId) => {
    let message = '';
    const toastrConfirmOptions = {
      onOk: () => {
        this.itemDelete(categoryId);
      },
      onCancel: () => console.log('CANCEL: clicked'),
    };
    toastr.customConfirm(message, toastrConfirmOptions, 'Do you want to delete ?');
  };
  getNewResult = (e) => {
    e.preventDefault();
    if (this.state.searchTxt) {
      this.setState({ page: 1 }, () => {
        this.getList();
      });
    } else {
      toastr.error('Please type to search');
    }
  };
  render() {
    console.log('====volunteer', this.state.formList);
    const { tabIndex } = this.state;
    const filteredList = [];
    var seniorTab = false;
    var caTab = false;
    var hmTab = false;
    var walkTab = false;
    const caList = [];
    const callRecordForm = [];
    const clientInformation = [];
    const teleCouns = [];
    const basicData = [];
    const clientfb = [];
    const lifeIdx = [];
    const hmsList = [];
    const walkList = [];
    const hmsSatisfaction = [];
    const jobCompleteList = [];
    const csvData = [];
    const headers = [
      { label: 'ID', key: 'id' },
      { label: 'Type', key: 'type' },
      { label: 'Submitted at', key: 'submitted_at' },
      { label: 'Label', key: 'label' },
      { label: 'Result', key: 'result' },
    ];
    this.state.formList.map((item) => {
      csvData.push({
        id: item.id,
        type: item.categoryCode,
        submitted_at: item.createdAt,
        data: '',
      });
      Object.keys(item).forEach((e) => {
        // console.log(`${e}: ${item[e]}`);
        if (e && item[e]) {
          if (e.toString() == 'formJson') {
            if (typeof item[e] === 'object') {
              Object.keys(item[e]).forEach((formJsonField) => {
                if (typeof item[e][formJsonField] === 'object') {
                  csvData.push({
                    label: formJsonField.toString(),
                  });
                  Object.keys(item[e][formJsonField]).forEach((nestedField) => {
                    if (typeof item[e][formJsonField][nestedField] === 'object') {
                      csvData.push({
                        label: nestedField.toString(),
                      });
                      Object.keys(item[e][formJsonField][nestedField]).forEach((nested2Field) => {
                        csvData.push({
                          label: nested2Field.toString(),
                          result: item[e][formJsonField][nestedField][nested2Field].toString(),
                        });
                      });
                    } else {
                      // else settled
                      csvData.push({
                        label: nestedField.toString(),
                        result: item[e][formJsonField][nestedField].toString(),
                      });
                    }
                  });
                } else {
                  csvData.push({
                    label: formJsonField.toString(),
                    result: item[e][formJsonField].toString(),
                  });
                }
              });
            } else {
              item[e].map((formJsonItem) => {
                Object.keys(formJsonItem).forEach((formJsonField) => {
                  if (typeof formJsonItem[formJsonField] === 'object') {
                    csvData.push({
                      label: formJsonField.toString(),
                    });
                    Object.keys(formJsonItem[formJsonField]).forEach((nestedField) => {
                      if (typeof formJsonItem[formJsonField][nestedField] === 'object') {
                        csvData.push({
                          label: nestedField.toString(),
                        });
                        Object.keys(formJsonItem[formJsonField][nestedField]).forEach((nested2Field) => {
                          csvData.push({
                            label: nested2Field.toString(),
                            result: formJsonItem[formJsonField][nestedField][nested2Field].toString(),
                          });
                        });
                      } else {
                        // else settled
                        csvData.push({
                          label: nestedField.toString(),
                          result: formJsonItem[formJsonField][nestedField].toString(),
                        });
                      }
                    });
                  } else {
                    csvData.push({
                      label: formJsonField.toString(),
                      result: formJsonItem[formJsonField].toString(),
                    });
                  }
                });
              });
            }
          } else {
            csvData.push({ label: e.toString(), result: item[e].toString() });
          }
        }
      });
      csvData.push({
        id: '---------------------',
        type: '---------------------',
        submitted_at: '---------------------',
        label: '---------------------',
        result: '---------------------',
      });

      // console.log("csvdata", csvData);
      var listData = Object.entries(item.formJson);
      // console.log("listdata", listData);
      // listData.map((item) => {
      //   csvData.push(item.toString());
      // });
      // item.formJson.map((formjsonData)=>{

      // const data = formjsonData;
      // console.log('data',data)

      // for (let x = 0; x < data.length; i++) {
      //   const data2 = data[x];
      //   console.log(data2)

      //   csvData.push({
      //     id: "",
      //     type: "",
      //     submitted_at: "",
      //     data: data2.toString(),
      //   });
      // }
      // })
    });
    // console.log("csvdata", csvData);
    this.state.formList &&
      this.state.formList.map((list, index) => {
        // if((tabIndex == 0) && (list && list.categoryId) == 5){
        //     filteredList.push(list)

        // }

        if (
          (tabIndex == 0 && (list && list.categoryCode) == 'callform') ||
          (this.state.volunteerForm && (list && list.categoryCode) == 'callform')
        ) {
          callRecordForm.push(list);
        }
        if (
          (tabIndex == 0 && (list && list.categoryCode) == 'infoform') ||
          (this.state.volunteerForm && (list && list.categoryCode) == 'infoform')
        ) {
          clientInformation.push(list);
        }
        if (
          (tabIndex == 0 && (list && list.categoryCode) == 'teleform') ||
          (this.state.volunteerForm && (list && list.categoryCode) == 'teleform')
        ) {
          teleCouns.push(list);
        }
        if (
          (tabIndex == 1 && (list && list.categoryCode) == 'feedbackform') ||
          (this.state.volunteerForm && (list && list.categoryCode) == 'feedbackform')
        ) {
          clientfb.push(list);
        }
        if (
          (tabIndex == 1 && (list && list.categoryCode) == 'lifeSatisficationForm') ||
          (this.state.volunteerForm && (list && list.categoryCode) == 'lifeSatisficationForm')
        ) {
          lifeIdx.push(list);
        }
        if (
          (tabIndex == 1 && (list && list.categoryCode) == 'progressform') ||
          (this.state.volunteerForm && (list && list.categoryCode) == 'progressform')
        ) {
          basicData.push(list);
        }
        // if((tabIndex == 1) && (list && list.categoryId) == 4){
        //     caList.push(list)

        // }
        // if((tabIndex == 2) && (list && list.categoryId) == 6){
        //     hmsList.push(list)

        // }
        if (
          (tabIndex == 2 && (list && list.categoryCode) == 'satisisficationForm') ||
          (this.state.volunteerForm && list && list.categoryCode == 'satisisficationForm')
        ) {
          hmsSatisfaction.push(list);
        }
        if (
          (tabIndex == 2 && (list && list.categoryCode) == 'completionform') ||
          (this.state.volunteerForm && list && list.categoryCode == 'completionform')
        ) {
          jobCompleteList.push(list);
        }

        // if((tabIndex == 3) && (list && list.categoryId) == 1){
        //     walkList.push(list)

        // }
        if (
          (tabIndex == 3 && (list && list.categoryCode) == 'walkfeedback') ||
          (this.state.volunteerForm && list && list.categoryCode == 'walkfeedback')
        ) {
          walkList.push(list);
        }
      });
    console.log('hmsSatisfaction=======', hmsSatisfaction);
    const walkJson = [];
    walkList &&
      walkList.map((list) => {
        walkJson.push(list && list.formJson);
      });

    const hmsJson = [];

    //    const hmsSatisfaction = []
    //    const satifactionList = []
    //    const jobCompleteList = []
    //    hmsList && hmsList.map(list => {
    //        if((list && list.formJson && list.formJson.title && list.formJson.title) == 'HMS Satisfaction Form'){
    //             hmsSatisfaction.push(list)
    //        }
    //        if((list && list.formJson && list.formJson.title && list.formJson.title) == "HMS Job Completion Form"){
    //         jobCompleteList.push(list)
    //    }
    //         hmsJson.push(list && list.formJson)
    //    })

    //    hmsJson && hmsJson.map((list,index) => {
    //     if(list && list.title == 'HMS Satisfaction Form'){
    //         satifactionList.push(list)
    //     }
    //     // if(list && list.title == "HMS Job Completion Form"){
    //     //     jobCompleteList.push(list)
    //     // }
    // })
    //     const totalList = (filteredList && filteredList.length) || (caList && caList.length)

    const seniorJson = [];
    const callRecord = [];
    // const teleCouns = []
    // const callRecordForm = [] // const callRecordForm = []
    // const clientInformation = []
    filteredList &&
      filteredList.map((list, index) => {
        // if((list && list.formJson && list.formJson.length && list.formJson.length) == 4){
        //     callRecordForm.push(list)
        // }
        // if((list && list.formJson && list.formJson.title && list.formJson.title) == 'Client Satisfaction Questionnaire (For tele-counselling)'){
        //     teleCouns.push(list)
        // }
        // if((list && list.formJson && list.formJson.title && list.formJson.title)  == 'Client Satisfaction Questionnaire (For Information & Referral)'){
        //     clientInformation.push(list)
        // }
        seniorJson.push(list && list.formJson);
      });

    // seniorJson && seniorJson.map((list,index) => {
    //     if(list && list.length == 4){
    //         callRecord.push(list)
    //     }
    // })
    // seniorJson && seniorJson.map((list,index) => {
    //     if(list && list.title == 'Client Satisfaction Questionnaire (For tele-counselling)'){
    //         teleCouns.push(list)
    //     }
    // })
    // seniorJson && seniorJson.map((list,index) => {
    //     if(list && list.title == 'Client Satisfaction Questionnaire (For Information & Referral)'){
    //         clientInformation.push(list)
    //     }
    // })

    // const basicData = []
    // const clientfb = []
    // const lifeIdx = []
    // caList && caList.map((list,index) => (
    //        basicData.push (list && list.formJson && list.formJson.basicData),
    //        clientfb.push(list && list.formJson && list.formJson.clientFeedbackQnsAns),
    //        lifeIdx.push(list && list.formJson && list.formJson.lifeSatisfyQnsAns)
    // )
    // )

    const { limit, page } = this.state;
    let formList =
      this.state.formList &&
      this.state.formList.map((items, index) => {
        if (items) {
          return (
            <tr>
              <td>{(page - 1) * limit + index + 1}</td>
              <td>{items.title}</td>
              <td>{items.description}</td>
              {/* <td><img src={config.apiUrl +`/uploads/categories/`+ items.imageName}  height="50px" width="50px" ></img></td>
                <td>
                    <a className="tooltip-wrap" onClick={()=>this.itemEdit(items.id)}>
                        <EditIcon className="edit-icon"/>
                        <div className="tooltip">
                            <div className="arrow"></div>
                            <div className="tooltip-inner">Edit</div>
                        </div>
                    </a>
                    <a className="tooltip-wrap" onClick={()=>this.handleDelete(items.id)}>
                        <DeleteIcon className="delete-icon"/>
                        <div className="tooltip">
                            <div className="arrow"></div>
                            <div className="tooltip-inner">Delete</div>
                        </div>
                    </a>
                </td> */}
            </tr>
          );
        }
      });
    return (
      <Fragment>
        <div className="row tit-sec">
          <div className="col-6 col-md-5">
            <h4>Forms</h4>
          </div>
        </div>
        <div className="card form-wrap">
          <div className="card-body">
            <div className="pt-2 mb-3">
              <CSVLink
                data={csvData}
                headers={headers}
                filename={`data_` + this.props.location?.state?.obj?.clockInOutId}
                className="btn add-btn"
                target="_blank"
              >
                Download Forms
              </CSVLink>
              <form onSubmit={this.getNewResult} class="align-items-center">
                <div className="d-flex justify-content-end">
                  <div className="col-md-5 col-lg-3 input-fields"></div>
                </div>
              </form>
            </div>
            {!this.state.volunteerForm && (
              <Tabs
                className="tab-wrap"
                selectedIndex={this.state.tabIndex}
                onSelect={(tabIndex) => this.tabChange(tabIndex)}
              >
                <TabList className="tab-change">
                  {/* {((callRecordForm && callRecordForm.length > 0 )|| (teleCouns && teleCouns.length > 0) || (clientInformation && clientInformation.length > 0 )) &&  <Tab className={this.state.tabIndex == "0" ? 'tab-select' : 'tab-unselect'}>Senior Helpline</Tab>} */}
                  <Tab className={this.state.tabIndex == '0' ? 'tab-select' : 'tab-unselect'}>Senior Helpline</Tab>
                  <Tab className={this.state.tabIndex == '1' ? 'tab-select' : 'tab-unselect'}>
                    Counselling Associate
                  </Tab>
                  <Tab className={this.state.tabIndex == '2' ? 'tab-select' : 'tab-unselect'}>
                    Home Maintenance Service(HMS)
                  </Tab>
                  <Tab className={this.state.tabIndex == '3' ? 'tab-select' : 'tab-unselect'}>Walk Ambassador</Tab>
                </TabList>
                <TabPanel className="r-pan"></TabPanel>
                <TabPanel className="w-pan"></TabPanel>
              </Tabs>
            )}

            {this.state.tabIndex == 0 && !this.state.volunteerForm && (
              <Tabs
                className="sen-tab-wrap"
                selectedIndex={this.state.childTabIndex}
                onSelect={(tabIndex) => this.childTabChange(tabIndex)}
              >
                <TabList className="sen-tab-change">
                  {callRecordForm && callRecordForm.length > 0 && (
                    <Tab className={this.state.childTabIndex == '0' ? 'tab-select' : 'tab-unselect'}>
                      Call Record Form
                    </Tab>
                  )}
                  {teleCouns && teleCouns.length > 0 && (
                    <Tab className={this.state.childTabIndex == '1' ? 'tab-select' : 'tab-unselect'}>
                      Client Satisfaction Questionnaire (Tele Counselling Forms)
                    </Tab>
                  )}
                  {clientInformation && clientInformation.length > 0 && (
                    <Tab className={this.state.childTabIndex == '2' ? 'tab-select' : 'tab-unselect'}>
                      Client Satisfaction Questionnaire (Information & Referral Forms)
                    </Tab>
                  )}
                </TabList>
                <TabPanel className="r-pan"></TabPanel>
                <TabPanel className="w-pan"></TabPanel>
              </Tabs>
            )}

            {(this.state.childTabIndex == '0' || this.state.volunteerForm) && (
              <div className="sen-form">
                <div className="tab-box">
                  {this.state.tabIndex == '0' &&
                    !this.state.volunteerForm &&
                    callRecordForm &&
                    callRecordForm.length == 0 && <h6>No Record Found</h6>}
                  {/* {callRecordForm && callRecordForm.length > 0 && <h5>Call Record Forms</h5>} */}
                  {callRecordForm &&
                    callRecordForm.map((list) => (
                      <div className="card">
                        <div className="card-body row">
                          <div className="tab-pan col-md-3">
                            {this.state.volunteerForm && (
                              <p>
                                <b>
                                  Category :&nbsp;
                                  {list && list.Category && list.Category.title}
                                </b>
                              </p>
                            )}
                            {this.state.volunteerForm && (
                              <p>
                                <b>
                                  Form Name :&nbsp;
                                  {list && list.formJson && list.formJson[0] && list.formJson[0].code === 'callRecForm'
                                    ? 'Call Record Form'
                                    : list.formJson[0].code}
                                </b>
                              </p>
                            )}
                            <p>
                              User Name :&nbsp;{list?.User?.firstName} {list?.User?.lastName}
                            </p>
                            <p>
                              Title:&nbsp;
                              {list && list.formJson && list.formJson[0] && list.formJson[0].title}
                            </p>
                            <p>
                              Start Time:&nbsp;
                              {list && list.formJson && list.formJson[0] && list.formJson[0].startTime}
                            </p>
                            <p>
                              End Time:&nbsp;
                              <span>{list && list.formJson && list.formJson[3] && list.formJson[3].endtime}</span>
                            </p>
                            <p>
                              Duration:&nbsp;
                              {list && list.formJson && list.formJson[3] && list.formJson[3].diffTime}
                            </p>
                            <p>
                              Date:&nbsp;
                              {list && list.formJson && list.formJson[0] && list.formJson[0].date}
                            </p>
                            <p>
                              Volunteer Code Name:&nbsp;
                              {list && list.formJson && list.formJson[0] && list.formJson[0].codeName}
                            </p>
                          </div>

                          <div className="tab-pan col-md-3">
                            {this.state.volunteerForm && (
                              <p>
                                <b>
                                  Form Name :&nbsp;
                                  {list && list.formJson && list.formJson[1] && list.formJson[1].code}
                                </b>
                              </p>
                            )}
                            <p>
                              Title:&nbsp;
                              <span>{list && list.formJson && list.formJson[1] && list.formJson[1].title}</span>
                            </p>
                            <p>
                              Caller Name:&nbsp;
                              <span>{list && list.formJson && list.formJson[1] && list.formJson[1].callerName}</span>
                            </p>
                            <p>
                              Caller Type:&nbsp;
                              <span>{list && list.formJson && list.formJson[1] && list.formJson[1].callerType}</span>
                            </p>
                            <p>
                              {' '}
                              Mobile No:&nbsp;
                              <span>{list && list.formJson && list.formJson[1] && list.formJson[1].mobileNo}</span>
                            </p>
                            <p>
                              Age:&nbsp;
                              <span>{list && list.formJson && list.formJson[1] && list.formJson[1].age}</span>
                            </p>
                            <p>
                              Gender:&nbsp;
                              <span>{list && list.formJson && list.formJson[1] && list.formJson[1].gender}</span>
                            </p>
                            <p>
                              How did they know about SHL:&nbsp;
                              <span>{list && list.formJson && list.formJson[1] && list.formJson[1].reason}</span>
                            </p>
                            <p>
                              Dwelling Type:&nbsp;
                              <span>{list && list.formJson && list.formJson[1] && list.formJson[1].dweelingType}</span>
                            </p>
                            <p>
                              Language:&nbsp;
                              <span>{list && list.formJson && list.formJson[1] && list.formJson[1].language}</span>
                            </p>
                            <p>
                              Race:&nbsp;
                              <span>{list && list.formJson && list.formJson[1] && list.formJson[1].race}</span>
                            </p>
                            <p>
                              Address:&nbsp;
                              <span>{list && list.formJson && list.formJson[1] && list.formJson[1].address1}</span>
                            </p>
                            <p>
                              Postal Code:&nbsp;
                              <span>{list && list.formJson && list.formJson[1] && list.formJson[1].address2}</span>
                            </p>
                          </div>

                          <div className="tab-pan col-md-3">
                            {this.state.volunteerForm && (
                              <p>
                                <b>
                                  Form Name :&nbsp;
                                  {list && list.formJson && list.formJson[2] && list.formJson[2].code}
                                </b>
                              </p>
                            )}
                            <p>
                              Title:&nbsp;
                              <span>{list && list.formJson && list.formJson[2] && list.formJson[2].title}</span>
                            </p>
                            <p>
                              Problem Category:&nbsp;
                              <span>
                                {list &&
                                  list.formJson &&
                                  list.formJson[2] &&
                                  list.formJson[2].pblmCategory &&
                                  list.formJson[2].pblmCategory.map((item) => item)}
                              </span>
                            </p>
                            <p>
                              Type Of Counselling:&nbsp;
                              <span>
                                {list && list.formJson && list.formJson[2] && list.formJson[2].typeOfCCounselling}
                              </span>
                            </p>

                            <p>
                              Call Content:&nbsp;
                              <span>{list && list.formJson && list.formJson[2] && list.formJson[2].description}</span>
                            </p>
                          </div>
                          <div className="tab-pan col-md-3">
                            {this.state.volunteerForm && (
                              <p>
                                <b>
                                  Form Name :&nbsp;
                                  {list && list.formJson && list.formJson[3] && list.formJson[3].code}
                                </b>
                              </p>
                            )}
                            <p>
                              Title:&nbsp;
                              <span>{list && list.formJson && list.formJson[3] && list.formJson[3].title}</span>
                            </p>
                            <p>
                              Caller Type:&nbsp;
                              <span>{list && list.formJson && list.formJson[3] && list.formJson[3].callerType}</span>
                            </p>
                            <p>
                              Issue Type:&nbsp;
                              <span>{list && list.formJson && list.formJson[3] && list.formJson[3].disorderType}</span>
                            </p>
                            <p>
                              Issues:&nbsp;
                              <span>{list && list.formJson && list.formJson[3] && list.formJson[3].issueType}</span>
                            </p>
                            <p>
                              Description:&nbsp;
                              <span>{list && list.formJson && list.formJson[3] && list.formJson[3].description}</span>
                            </p>
                            <p>
                              Q/A:&nbsp;
                              {list &&
                                list.formJson &&
                                list.formJson[3] &&
                                list.formJson[3].questions &&
                                list.formJson[3].questions.map((item) => (
                                  <div className="form-table table-responsive">
                                    <table className="table">
                                      <tbody>
                                        <tr>
                                          <td>Question</td>
                                          <td>{item.Qns}</td>
                                        </tr>
                                        <tr>
                                          <td>Answer</td>
                                          <td>{item.Ans}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                ))}
                            </p>
                          </div>
                        </div>
                        <br />
                      </div>
                    ))}
                </div>
              </div>
            )}
            {(this.state.childTabIndex == '1' || this.state.volunteerForm) && (
              <div className="sen-form">
                <div className="tab-box">
                  {this.state.tabIndex == '0' && !this.state.volunteerForm && teleCouns && teleCouns.length == 0 && (
                    <h6>No Record Found</h6>
                  )}
                  {/* {teleCouns && teleCouns.length > 0 && <h5>Tele Counselling Forms</h5>} */}
                  {teleCouns &&
                    teleCouns.map((list, index) => (
                      <div className="card">
                        <div className="card-body row">
                          <div className="tab-pan col-md-6">
                            {this.state.volunteerForm && (
                              <p>
                                <b>
                                  Category :&nbsp;
                                  {list && list.Category && list.Category.title}
                                </b>
                              </p>
                            )}
                            {this.state.volunteerForm && (
                              <p>
                                <b>
                                  Form Name :&nbsp;
                                  {list && list.formJson && list.formJson.basicData && list.formJson.basicData.code}
                                </b>
                              </p>
                            )}
                            <p>
                              User Name :&nbsp;{list?.User?.firstName} {list?.User?.lastName}
                            </p>
                            <p>
                              Title : &nbsp;
                              <span>{list && list.formJson && list.formJson.title}</span>
                            </p>
                            <p>
                              Caller Name:&nbsp;
                              <span>
                                {list && list.formJson && list.formJson.basicData && list.formJson.basicData.callerName}
                              </span>
                            </p>
                            <p>
                              Call Received Date:&nbsp;
                              <span>
                                {list &&
                                  list.formJson &&
                                  list.formJson.basicData &&
                                  list.formJson.basicData.callrecieverName}
                              </span>
                            </p>
                            <p>
                              Comments:&nbsp;
                              <span>{list && list.formJson && list.formJson.comments}</span>
                            </p>
                            <p>
                              Mobile No:&nbsp;
                              <span>
                                {list && list.formJson && list.formJson.basicData && list.formJson.basicData.mobileNo}
                              </span>
                            </p>
                            <p>
                              Surveyed By :&nbsp;
                              <span>{list && list.formJson && list.formJson.surveyedBy}</span>
                            </p>
                            <p>
                              Gender : &nbsp;
                              <span>
                                {list && list.formJson && list.formJson.basicData && list.formJson.basicData.gender}
                              </span>
                            </p>
                            <p>
                              Languages:&nbsp;
                              <span>
                                {list && list.formJson && list.formJson.basicData && list.formJson.basicData.languages}
                              </span>
                            </p>
                            <p>
                              Date:&nbsp;
                              <span>{list && list.formJson && list.formJson.date}</span>
                            </p>
                            <p>
                              Selected Age:&nbsp;
                              <span>
                                {list &&
                                  list.formJson &&
                                  list.formJson.basicData &&
                                  list.formJson.basicData.selectedAge}
                              </span>
                            </p>
                          </div>
                          <div className="tab-pan col-md-6">
                            <p>
                              Q/A:&nbsp;
                              {list &&
                                list.formJson &&
                                list.formJson.questions &&
                                list.formJson.questions.map((item) => (
                                  <div className="form-table table-responsive">
                                    <table className="table">
                                      <tbody>
                                        <tr>
                                          <td>Question</td>
                                          <td>{item.Qns}</td>
                                        </tr>
                                        <tr>
                                          <td>Answer</td>
                                          <td>{item.Ans}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                ))}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
            {(this.state.childTabIndex == '2' || this.state.volunteerForm) && (
              <div className="sen-form">
                <div className="tab-box">
                  {this.state.tabIndex == '0' &&
                    !this.state.volunteerForm &&
                    clientInformation &&
                    clientInformation.length == 0 && <h6>No Record Found</h6>}
                  {/* {clientInformation && clientInformation.length > 0 && <h5> Information & Referral Forms</h5>} */}
                  {clientInformation &&
                    clientInformation.map((list, index) => (
                      <div className="card">
                        <div className="card-body row">
                          <div className="tab-pan col-md-6">
                            {this.state.volunteerForm && (
                              <p>
                                <b>
                                  Category :&nbsp;
                                  {list && list.Category && list.Category.title}
                                </b>
                              </p>
                            )}
                            {this.state.volunteerForm && (
                              <p>
                                <b>
                                  Form Name :&nbsp;
                                  {list && list.formJson && list.formJson.basicData && list.formJson.basicData.code}
                                </b>
                              </p>
                            )}
                            <p>
                              User Name :&nbsp;{list?.User?.firstName} {list?.User?.lastName}
                            </p>
                            <p>
                              {' '}
                              Title :&nbsp;
                              <span>{list && list.formJson && list.formJson.title}</span>
                            </p>
                            {/* <p>caller Name:&nbsp;<span>{list.formJson}</span></p> */}
                            <p>
                              comments:&nbsp;
                              <span>{list.formJson.comments}</span>
                            </p>
                            <p>
                              Surveyed By :&nbsp;
                              <span>{list.formJson.surveyedBy}</span>
                            </p>
                            <p>
                              Surveyed Date:&nbsp;<span>{list.formJson.date}</span>
                            </p>
                            <p>
                              Caller's Name:&nbsp;
                              <span>{list.formJson.callersName}</span>
                            </p>
                            <p>
                              Tel/Mobile Number:&nbsp;
                              <span>{list.formJson.mobileNumber}</span>
                            </p>
                            <p>
                              Call Received Date:&nbsp;
                              <span>{list.formJson.callReceivedDate}</span>
                            </p>
                            <p>
                              questions :&nbsp;
                              {list &&
                                list.formJson &&
                                list.formJson.questions &&
                                list.formJson.questions.map((item) => (
                                  <div className="form-table table-responsive">
                                    <table className="table">
                                      <tbody>
                                        <tr>
                                          <td>Question</td>
                                          <td>{item.Qns}</td>
                                        </tr>
                                        <tr>
                                          <td>Answer</td>
                                          <td>{item.Ans}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                ))}
                            </p>
                          </div>
                          {/* 
                                            <div className="tab-pan col-md-6">
                                                {this.state.volunteerForm && <p><b>Category :&nbsp;{list && list.Category && list.Category.title}</b></p>}
                                                {this.state.volunteerForm && <p><b>Form Name :&nbsp;{list && list.formJson && list.formJson.basicQns && list.formJson.basicQns.code && list.formJson.basicQns.code}</b></p>}
                                                <p>Title:&nbsp;<span>{list && list.formJson && list.formJson.basicQns && list.formJson.basicQns.title}</span></p>
                                                <p>Surveyed By:&nbsp;<span>{list && list.formJson && list.formJson.basicQns && list.formJson.basicQns.surveyedBy}</span></p>
                                                <p>Staff Incharger:&nbsp;<span>{list && list.formJson && list.formJson.basicQns && list.formJson.basicQns.staffIncharger}</span></p>
                                                <p>Comments:&nbsp;<span>{list && list.formJson && list.formJson.basicQns && list.formJson.basicQns.comments}</span></p>
                                                <p>Date:&nbsp;<span>{list && list.formJson && list.formJson.basicQns && list.formJson.basicQns.date}</span></p>
                                                <p>Time:&nbsp;<span>{list && list.formJson && list.formJson.basicQns && list.formJson.basicQns.time}</span></p>
                                                <p>questions:&nbsp;{list && list.formJson && list.formJson.basicQns && list.formJson.basicQns.questions && list.formJson.basicQns.questions.map(item => (
                                                    <div className="form-table table-responsive">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Question</td>
                                                                    <td>{item.Qns}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Answer</td>
                                                                    <td>{item.Ans}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ))}</p>

                                            </div> */}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
            {/* <div className="row"> */}
            {(this.state.tabIndex == 1 || this.state.volunteerForm) && (
              <div className="tab-box">
                <div className="card">
                  <div className="card-body row">
                    <div className="tab-pan col-md-4">
                      <div>
                        {this.state.tabIndex == '1' && basicData && basicData.length == 0 ? (
                          <h6>No Record Found</h6>
                        ) : (
                          !this.state.volunteerForm && <h5>CA Progress Forms</h5>
                        )}
                      </div>
                      {basicData &&
                        basicData.map((list) => (
                          <div className="card">
                            {this.state.volunteerForm && (
                              <p>
                                <b>
                                  Category :&nbsp;
                                  {list && list.Category && list.Category.title}
                                </b>
                              </p>
                            )}
                            {this.state.volunteerForm && (
                              <p>
                                <b>
                                  Form Name :&nbsp;
                                  {list && list.formJson && list.formJson && list.formJson.code}
                                </b>
                              </p>
                            )}
                            <p>
                              User Name :&nbsp;{list?.User?.firstName} {list?.User?.lastName}
                            </p>
                            <p>
                              Title :&nbsp;
                              <span>{list && list.formJson && list.formJson && list.formJson.title}</span>
                            </p>
                            <p>
                              Client Name :&nbsp;
                              <span>{list && list.formJson && list.formJson && list.formJson.clientName}</span>
                            </p>
                            <p>
                              Mobile Number :&nbsp;
                              <span>{list && list.formJson && list.formJson && list.formJson.mobileNumber}</span>
                            </p>
                            <p>
                              Counselling Associate1 :&nbsp;
                              <span>{list && list.formJson && list.formJson && list.formJson.associateCA1}</span>
                            </p>
                            <p>
                              Counselling Associate2 :&nbsp;
                              <span>{list && list.formJson && list.formJson && list.formJson.associateCA2}</span>
                            </p>
                            <p>
                              Remarks1 :&nbsp;
                              <span>{list && list.formJson && list.formJson && list.formJson.remarks1}</span>
                            </p>
                            <p>
                              Remarks2 :&nbsp;
                              <span>{list && list.formJson && list.formJson && list.formJson.remarks2}</span>
                            </p>
                            <p>
                              Action or Interaction1 :&nbsp;
                              <span>{list && list.formJson && list.formJson && list.formJson.actionInteraction1}</span>
                            </p>
                            <p>
                              Action or Interaction2 :&nbsp;
                              <span>{list && list.formJson && list.formJson && list.formJson.actionInteraction}</span>
                            </p>
                            <p>
                              Visit/Call/VC :&nbsp;
                              <span>{list && list.formJson && list.formJson && list.formJson.visitCall1}</span>
                            </p>
                            <p>
                              Associate Date1 :&nbsp;
                              <span>{list && list.formJson && list.formJson && list.formJson.associateDate1}</span>
                            </p>
                            <p>
                              Associate Date2 : &nbsp;
                              <span>{list && list.formJson && list.formJson && list.formJson.associateDate2}</span>
                            </p>
                            <p>
                              Associate Duration1 :&nbsp;
                              <span>{list && list.formJson && list.formJson && list.formJson.associateDuration1}</span>
                            </p>
                            <p>
                              Associate Duration2 : &nbsp;
                              <span>{list && list.formJson && list.formJson && list.formJson.associateDuration2}</span>
                            </p>
                            <p>
                              Languages : &nbsp;
                              <span>{list && list.formJson && list.formJson && list.formJson.languages}</span>
                            </p>
                          </div>
                        ))}
                    </div>
                    <div className="tab-pan col-md-4">
                      {this.state.tabIndex == '1' && clientfb && clientfb.length == 0 ? (
                        <h6>No Record Found</h6>
                      ) : (
                        !this.state.volunteerForm && <h5>Feedback Forms</h5>
                      )}

                      {clientfb &&
                        clientfb.map((list) => (
                          <div className="card">
                            {this.state.volunteerForm && (
                              <p>
                                <b>
                                  Category :&nbsp;
                                  {list && list.Category && list.Category.title}
                                </b>
                              </p>
                            )}
                            {this.state.volunteerForm && (
                              <p>
                                <b>
                                  Form Name :&nbsp;
                                  {list && list.formJson && list.formJson && list.formJson.code}
                                </b>
                              </p>
                            )}
                            <p>
                              User Name :&nbsp;{list?.User?.firstName} {list?.User?.lastName}
                            </p>
                            <p>
                              Title :&nbsp;
                              <span>{list && list.formJson && list.formJson.title && list.formJson.title}</span>
                            </p>
                            <p>
                              Name of Client :&nbsp;
                              <span>{list && list.formJson && list.formJson.title && list.formJson.nameOfClient}</span>
                            </p>
                            <p>
                              Date of Survey :&nbsp;
                              <span>{list && list.formJson && list.formJson.title && list.formJson.dateOfSurvey}</span>
                            </p>
                            <p>
                              Surveyed By :&nbsp;
                              <span>{list && list.formJson && list.formJson.title && list.formJson.surveryedBy}</span>
                            </p>
                            <p>
                              {' '}
                              Q/A:{' '}
                              {list &&
                                list.formJson &&
                                list.formJson.clintFeedbackAns &&
                                list.formJson.clintFeedbackAns.map((item) => (
                                  <div className="form-table table-responsive">
                                    <table className="table">
                                      <tbody>
                                        <tr>
                                          <td>Question</td>
                                          <td>{item.Qns}</td>
                                        </tr>
                                        <tr>
                                          <td>Answer</td>
                                          <td>{item.Ans}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                ))}
                            </p>
                          </div>
                        ))}
                    </div>
                    <div className="tab-pan col-md-4">
                      {this.state.tabIndex == '1' && lifeIdx && lifeIdx.length == 0 ? (
                        <h6>No Record Found</h6>
                      ) : (
                        !this.state.volunteerForm && <h5>Life Satisfaction Forms</h5>
                      )}

                      {lifeIdx &&
                        lifeIdx.map((list) => (
                          <div className="card">
                            {this.state.volunteerForm && (
                              <p>
                                <b>
                                  Category :&nbsp;
                                  {list && list.Category && list.Category.title}
                                </b>
                              </p>
                            )}
                            {this.state.volunteerForm && (
                              <p>
                                <b>
                                  Form Name :&nbsp;
                                  {list && list.formJson && list.formJson && list.formJson.code}
                                </b>
                              </p>
                            )}
                            <p>
                              User Name :&nbsp;{list?.User?.firstName} {list?.User?.lastName}
                            </p>
                            <p>
                              Title :&nbsp;
                              <span>{list && list.formJson && list.formJson.title && list.formJson.title}</span>
                            </p>
                            <p>
                              Name of Client :&nbsp;
                              <span>{list && list.formJson && list.formJson.title && list.formJson.nameOfClient}</span>
                            </p>
                            <p>
                              Date of Survey :&nbsp;
                              <span>{list && list.formJson && list.formJson.title && list.formJson.dateOfSurvey}</span>
                            </p>
                            <p>
                              Surveyed By :&nbsp;
                              <span>{list && list.formJson && list.formJson.title && list.formJson.surveryedBy}</span>
                            </p>
                            <p>
                              Q/A
                              {list &&
                                list.formJson &&
                                list.formJson.lifeSatisfyQnsAns &&
                                list.formJson.lifeSatisfyQnsAns.map((item) => (
                                  <div className="form-table table-responsive">
                                    <table className="table">
                                      <tbody>
                                        <tr>
                                          <td>Question</td>
                                          <td>{item.Qns}</td>
                                        </tr>
                                        <tr>
                                          <td>Answer</td>
                                          <td>{item.Ans}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                ))}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(this.state.tabIndex == '2' || this.state.volunteerForm) && (
              <div className="row">
                <div className="col-6">
                  {/* <div className="tab-box">

                                        {!this.state.volunteerForm && hmsSatisfaction && hmsSatisfaction.length > 0 && <h5>Satisfaction Form List</h5>}
                                        {this.state.tabIndex == "2" && hmsSatisfaction && hmsSatisfaction.length == 0 && <div><h6>No Record Found</h6></div>}
                                        {hmsSatisfaction && hmsSatisfaction.map(user => (

                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="tab-pan">
                                                        {this.state.volunteerForm && <p><b>Category :&nbsp;{user && user.Category && user.Category.title}</b></p>}
                                                        {this.state.volunteerForm && <p><b>Form Name :&nbsp;{user && user.formJson && user.formJson.code && user.formJson.code}</b></p>}
                                                        <p>UserId:&nbsp;<span>{user.userId}</span></p>
                                                        <p>Title : &nbsp;<span>{user && user.formJson && user.formJson.title}</span></p>
                                                        <p>Client Name :&nbsp;<span>{user && user.formJson && user.formJson.clientName}</span></p>
                                                        <p>HandyMan : &nbsp;<span>{user && user.formJson && user.formJson.handymanName}</span></p>
                                                        <p>Date : &nbsp;<span>{user && user.formJson && user.formJson.date}</span></p>
                                                        <p>Order No:&nbsp;<span>{user && user.formJson && user.formJson.orderNo}</span></p>
                                                        <p>Result :&nbsp;<span>{user && user.formJson && user.formJson.result}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                            // ))
                                        ))}

                                    </div> */}
                  <div className="tab-box">
                    {!this.state.volunteerForm && jobCompleteList && jobCompleteList.length > 0 && (
                      <h5>Satisfaction Form List</h5>
                    )}
                    {this.state.tabIndex == '2' && jobCompleteList && jobCompleteList.length == 0 && (
                      <div>
                        <h6>No Record Found</h6>
                      </div>
                    )}

                    {jobCompleteList &&
                      jobCompleteList.map((list, index) => (
                        <div className="card">
                          <div className="card-body">
                            <div className="tab-pan">
                              {/* {this.state.volunteerForm && <p><b>Category :&nbsp;{list && list.Category && list.Category.title}</b></p>}
                                                        {this.state.volunteerForm && <p><b>Form Name :&nbsp;{list && list.formJson[0] && list.formJson[1] && list.formJson[0].code}</b></p>}
                                                        <p>UserId :&nbsp;<span>{list && list.formJson && list.userId}</span></p>
                                                        <p>Title :&nbsp;<span>{list && list.formJson[1] && list.formJson[1].title}</span></p>
                                                        <p>Category Name :&nbsp;<span>{list && list.formJson[1] && list.formJson[1].categoryName}</span></p>
                                                        <p>Work Type : &nbsp;<span>{list && list.formJson[1] && list.formJson[1].workType}</span></p>
                                                        <p>Purchase Amt :&nbsp;<span>{list && list.formJson[1] && list.formJson[1].purchaseAmt}</span></p>
                                                        <p>Remarks:&nbsp;<span>{list && list.formJson[1] && list.formJson[1].remarks}</span></p> */}

                              {this.state.volunteerForm && (
                                <p>
                                  <b>
                                    Category :&nbsp;
                                    {list && list.Category && list.Category.title}
                                  </b>
                                </p>
                              )}
                              {this.state.volunteerForm && (
                                <p>
                                  <b>
                                    Form Name :&nbsp;
                                    {list && list.formJson[0] && list.formJson[0].code && list.formJson[0].code}
                                  </b>
                                </p>
                              )}
                              <p>
                                User Name :&nbsp;{list?.User?.firstName} {list?.User?.lastName}
                              </p>
                              <p>
                                Title : &nbsp;
                                <span>{list && list.formJson[0] && list.formJson[0].title}</span>
                              </p>
                              <p>
                                Client Name :&nbsp;
                                <span>{list && list.formJson[0] && list.formJson[0].clientName}</span>
                              </p>
                              <p>
                                HandyMan : &nbsp;
                                <span>{list && list.formJson[0] && list.formJson[0].handymanName}</span>
                              </p>
                              <p>
                                Date of Completion : &nbsp;
                                <span>{list && list.formJson[0] && list.formJson[0].date}</span>
                              </p>
                              <p>
                                Order No:&nbsp;
                                <span>{list && list.formJson[0] && list.formJson[0].orderNo}</span>
                              </p>
                              <p>
                                Result :&nbsp;
                                <span>{list && list.formJson[0] && list.formJson[0].result}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="col-6">
                  <div className="tab-box">
                    {!this.state.volunteerForm && jobCompleteList && jobCompleteList.length > 0 && (
                      <h5>Job Completion Form List</h5>
                    )}
                    {this.state.tabIndex == '2' && jobCompleteList && jobCompleteList.length == 0 && (
                      <div>
                        <h6>No Record Found</h6>
                      </div>
                    )}

                    {jobCompleteList &&
                      jobCompleteList.map((list, index) => (
                        <div className="card">
                          <div className="card-body">
                            <div className="tab-pan">
                              {this.state.volunteerForm && (
                                <p>
                                  <b>
                                    Category :&nbsp;
                                    {list && list.Category && list.Category.title}
                                  </b>
                                </p>
                              )}
                              {this.state.volunteerForm && (
                                <p>
                                  <b>
                                    Form Name :&nbsp;
                                    {list && list.formJson[1] && list.formJson[1] && list.formJson[1].code}
                                  </b>
                                </p>
                              )}
                              <p>
                                User Name :&nbsp;<span>{list?.User?.firstName}</span>
                              </p>
                              <p>
                                Title :&nbsp;
                                <span>{list && list.formJson[1] && list.formJson[1].title}</span>
                              </p>
                              <p>
                                Category Name :&nbsp;
                                <span>{list && list.formJson[1] && list.formJson[1].categoryName}</span>
                              </p>
                              <p>
                                Work Type : &nbsp;
                                <span>{list && list.formJson[1] && list.formJson[1].workType}</span>
                              </p>
                              <p>
                                Purchase Amt :&nbsp;
                                <span>{list && list.formJson[1] && list.formJson[1].purchaseAmt}</span>
                              </p>
                              <p>
                                Remarks:&nbsp;
                                <span>{list && list.formJson[1] && list.formJson[1].remarks}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
            {(this.state.tabIndex == 3 || this.state.volunteerForm) && (
              <div className="tab-box">
                {this.state.tabIndex == '3' && walkList && walkList.length == 0 && <h6>No Record Found</h6>}
                {walkList &&
                  walkList.map((user) => (
                    <div className="card">
                      <div className="card-body">
                        <div className="tab-pan col-md-12">
                          {this.state.volunteerForm && (
                            <p>
                              <b>
                                Category :&nbsp;
                                {user && user.Category && user.Category.title}
                              </b>
                            </p>
                          )}
                          {this.state.volunteerForm && (
                            <p>
                              <b>
                                Form Name :&nbsp;
                                {user && user.formJson && user.formJson && user.formJson.code}
                              </b>
                            </p>
                          )}
                          <p>
                            User Name :&nbsp;
                            <span>
                              {user.firstName} {user.lastName}
                            </span>
                          </p>
                          <p>
                            Title :&nbsp;
                            <span>{user && user.formJson && user.formJson.title}</span>
                          </p>
                          <p>
                            Name of Participant :&nbsp;
                            <span>{user && user.formJson && user.formJson.nameOfParticipant}</span>
                          </p>
                          <p>
                            Date of Walk :&nbsp;
                            <span>{user && user.formJson && user.formJson.dateOfWalk}</span>
                          </p>
                          <p>
                            Heritage Site :&nbsp;
                            <span>{user && user.formJson && user.formJson.heritageSite}</span>
                          </p>
                          <p>
                            Sureryed By :&nbsp;
                            <span>{user && user.formJson && user.formJson.surveryedBy}</span>
                          </p>
                          {console.log('hello object', user.formJson.clintFeedbackAns)}
                          <p>
                            <b>Client Feedback Ans :&nbsp;</b>
                            {user &&
                              user.formJson &&
                              user.formJson.clintFeedbackAns &&
                              user.formJson.clintFeedbackAns.map((item) => (
                                <div className="row">
                                  <div className="col-md-6">{item.Qns}</div>
                                  <div className="col-md-6">
                                    <b> {item.Ans}</b>
                                  </div>
                                  <hr />
                                </div>
                              ))}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
            {/* <div className="table-wrap">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                            <th>S.No</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Image</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                    <tbody>
                            {formList}
                    </tbody>
                    </table>
                    </div>
                    {this.state.formList && this.state.formList.length == 0 && <div>
                            <h6 className="text-center py-4">No Record Found</h6>
                            </div>}
                </div> */}
          </div>
        </div>
        {/* {this.state.formList && this.state.formList.length > 0 && <ReactPagination PageDetails={{pageCount: this.state.pageCount, onPageChange: this.onChange, activePage: (this.state.page - 1), perPage: this.state.limit, totalCount: this.state.totalCount}}/>} */}
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    formList: state.app.formList,
  };
}
const actionCreators = {
  getList: appActions.getForm,
  resetList: appActions.resetFormList,
};
const connectedUser = connect(mapStateToProps, actionCreators)(FetchForm);
export { connectedUser as FetchForm };
