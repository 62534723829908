export const userConstants = {
    FETCH_USER_SUCCESS : "FETCH_USER_SUCCESS",
    SAVE_USER_SUCCESS : "SAVE_USER_SUCCESS",
    DELETE_USER_SUCCESS : "DELETE_USER_SUCCESS",

    USER_LOGIN_SUCCESS : "USER_LOGIN_SUCCESS",
    FETCH_ADMIN_ROLES : "FETCH_ADMIN_ROLES",
    FETCH_USERTYPES_SUCCESS : 'FETCH_USERTYPES_SUCCESS',
    FETCH_USERROLE_SUCCESS : 'FETCH_USERROLE_SUCCESS',
    FETCH_VOLUNTEER_TASK_SUCCESS : 'FETCH_VOLUNTEER_TASK_SUCCESS',


    FORGOTPASSWORD_SUCCESS : 'FORGOTPASSWORD_SUCCESS',
    OTP_SUCCESS : 'OTP_SUCCESS',
    PASSWORD_SUCCESS : 'PASSWORD_SUCCESS',
    LOGOUT_SUCCESS : 'LOGOUT_SUCCESS'

}