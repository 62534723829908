import { history } from "../../helpers";
import { config } from "../../config";
import CryptoJS from "crypto-js";
import { appConstants } from "../../constants";
import { appService } from "../../services";
import { toastr } from "../../services";
import PubSub from "pubsub-js";
export const appActions = {
  save,
  getList,
  delete: _delete,
  getForm,
  resetFormList,
  getReportList,
  getdashboardList,
  resetdashboardList,
  resetReportList,
  resetDelete,
  resetSave,
  resetList,
  getAssignedForm,
  delete_AssignForm,
  resetAssignedFormDelete,
  resetAssignedFormList,
  save_AssignForm,
  resetSave_AssignForm,
};
function showLoader() {
  PubSub.publish("msg", true);
}

function hideLoader() {
  PubSub.publish("msg", false);
}
function save(obj) {
  return (dispatch) => {
    showLoader();
    appService.save(obj).then(
      (resp) => {
        if (resp && resp.status == true) {
          toastr.success("Settings Created");
          dispatch({
            type: appConstants.SAVE_APP_SUCCESS,
            saveStatus: resp.status,
          });
          hideLoader();
        } else {
          hideLoader();
        }
      },
      (error) => {
        hideLoader();
        toastr.error(error);
      }
    );
  };
}
function resetSave() {
  return (dispatch) => {
    dispatch({ type: appConstants.SAVE_APP_SUCCESS, saveStatus: "" });
  };
}
function getList(obj) {
  return (dispatch) => {
    showLoader();
    appService.getList(obj).then(
      (resp) => {
        resp.request = obj;
        dispatch({ type: appConstants.FETCH_APP_SUCCESS, appList: resp });
        hideLoader();
      },
      (error) => {
        hideLoader();
        toastr.error(error);
      }
    );
  };
}
function getForm(obj) {
  return (dispatch) => {
    showLoader();
    appService.getForm(obj).then(
      (resp) => {
        // resp.request = obj
        if ((resp && resp.status) == true) {
          dispatch({ type: appConstants.FETCH_FORM_SUCCESS, formList: resp });
          hideLoader();
        } else {
          hideLoader();
        }
      },
      (error) => {
        hideLoader();
        console.log("erroform", error);
        // toastr.error(error)
      }
    );
  };
}

function resetFormList() {
  return (dispatch) => {
    dispatch({ type: appConstants.FETCH_FORM_SUCCESS, formList: "" });
  };
}

/////////////////////Assign Form ////////
function getAssignedForm(obj) {
  return (dispatch) => {
    showLoader();
    appService.getAssignedForm(obj).then(
      (resp) => {
        resp.request = obj;
        if ((resp && resp.status) == true) {
          dispatch({
            type: appConstants.FETCH_ASSIGNEDFORM_SUCCESS,
            assignedFormList: resp,
          });
          hideLoader();
        } else {
          hideLoader();
        }
      },
      (error) => {
        hideLoader();
        console.log("erroform", error);
        // toastr.error(error)
      }
    );
  };
}
function resetAssignedFormList() {
  return (dispatch) => {
    dispatch({
      type: appConstants.FETCH_ASSIGNEDFORM_SUCCESS,
      assignedFormList: "",
    });
  };
}

function save_AssignForm(obj) {
  return (dispatch) => {
    showLoader();
    appService.save_AssignForm(obj).then(
      (resp) => {
        if (resp && resp.status == true) {
          toastr.success("Form Created Successfully");
          dispatch({
            type: appConstants.SAVE_ASSIGNEDFORM_SUCCESS,
            assignedFormSaveStatus: resp.status,
          });
          hideLoader();
        } else {
          hideLoader();
        }
      },
      (error) => {
        hideLoader();
        toastr.error(error);
      }
    );
  };
}
function resetSave_AssignForm() {
  return (dispatch) => {
    dispatch({
      type: appConstants.SAVE_ASSIGNEDFORM_SUCCESS,
      assignedFormSaveStatus: "",
    });
  };
}

//////////////////////////

function getReportList(obj) {
  return (dispatch) => {
    showLoader();
    appService.getReportList(obj).then(
      (resp) => {
        resp.request = obj;
        dispatch({ type: appConstants.FETCH_REPORT_SUCCESS, reportList: resp });
        hideLoader();
      },
      (error) => {
        hideLoader();
        console.log("report", error);
      }
    );
  };
}
function resetReportList() {
  return (dispatch) => {
    dispatch({ type: appConstants.FETCH_REPORT_SUCCESS, reportList: "" });
  };
}

function getdashboardList(obj) {
  return (dispatch) => {
    showLoader();
    appService.getdashboardList(obj).then(
      (resp) => {
        resp.request = obj;
        dispatch({
          type: appConstants.FETCH_DASHBOARD_SUCCESS,
          dashboardList: resp,
        });
        hideLoader();
      },
      (error) => {
        hideLoader();
        toastr.error(error);
      }
    );
  };
}
function resetdashboardList() {
  return (dispatch) => {
    dispatch({ type: appConstants.FETCH_DASHBOARD_SUCCESS, dashboardList: "" });
  };
}
function resetList() {
  return (dispatch) => {
    dispatch({ type: appConstants.FETCH_APP_SUCCESS, appList: "" });
  };
}
function delete_AssignForm(obj) {
  return (dispatch) => {
    showLoader();
    appService.delete_AssignForm(obj).then(
      (resp) => {
        if (resp && resp.status == true) {
          toastr.success(resp.message);
          dispatch({
            type: appConstants.ASSIGNEDFORM_DELETE_SUCCESS,
            assignedDeleteStatus: resp.status,
          });
          hideLoader();
        } else {
          hideLoader();
        }
      },
      (error) => {
        hideLoader();
        toastr.error(error);
      }
    );
  };
}
function resetAssignedFormDelete() {
  return (dispatch) => {
    dispatch({
      type: appConstants.ASSIGNEDFORM_DELETE_SUCCESS,
      deleteStatus: "",
    });
  };
}
function _delete(obj) {
  return (dispatch) => {
    showLoader();
    appService.delete(obj).then(
      (resp) => {
        if (resp && resp.status == true) {
          toastr.success(resp.message);
          dispatch({
            type: appConstants.APP_DELETE_SUCCESS,
            deleteStatus: resp.status,
          });
          hideLoader();
        } else {
          hideLoader();
        }
      },
      (error) => {
        hideLoader();
        toastr.error(error);
      }
    );
  };
}
function resetDelete() {
  return (dispatch) => {
    dispatch({ type: appConstants.APP_DELETE_SUCCESS, deleteStatus: "" });
  };
}
