import React,{Component,Fragment} from 'react'
import UploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import {history,ReactPagination,isAdminLogin} from '../../../helpers';
import {appoinmentActions,emailActions} from '../../../store/actions'
import {toastr} from '../../../services'
import Toggle from 'react-toggle';
import Select from 'react-select';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
class FetchEmail extends Component{
    constructor(props){
        super(props);
        this.state={
            page:1,
            searchTxt:'',
            limit:10
           
        }
    }
    componentDidMount(){
        this.list()
    }
    componentWillReceiveProps(newProps){
        if(newProps && newProps.emailList && newProps.emailList.data){
            let data = newProps.emailList && newProps.emailList.data && newProps.emailList.data.rows
            let request = newProps.emailList && newProps.emailList.request ? newProps.emailList.request : []
            let totalCount = newProps.emailList && newProps.emailList.data && newProps.emailList.data.count ? newProps.emailList.data.count : []
            if(data.length == 0){
                toastr.error("Result Not Found")
            }
            this.setState({emailList:data,totalCount,pageCount:totalCount/request.limit})
        //    this.props.resetUser()
        }
        if(newProps && newProps.deleteStatus && newProps.deleteStatus == true){
            this.props.resetDelete()
            this.getList()
        }
       
      
    }
    list = () => {
        if(this.props && this.props.emailList && this.props.emailList.status == true){
            let request = this.props.emailList && this.props.emailList.request ? this.props.emailList.request : "";
            if(request.limit || request.page || request.searchTxt){
                this.setState({
                    limit:request.limit,
                    page:request.page,
                    searchTxt:request.searchTxt
                },()=>{
                    this.getList()
                })
            }else{
                this.getList()
            }
        }
        else{
            this.getList()
        }
    }
    getList = () => {
        let obj = {}
        if(this.state.searchTxt){
            obj['page'] = this.state.page
            obj['limit'] = this.state.limit
            obj['searchTxt'] = this.state.searchTxt
            // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
           
        }
        else{
            obj['page'] = this.state.page
            obj['limit'] = this.state.limit
            // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
            // obj['type'] = "report"
        }
        this.props.getList(obj)
    }
    
    formPath = () => {
       history.push('/admin/email/add')
    }
    resetSearch = () => {
        
        this.setState({searchTxt  : '',page : 1},() => {
            this.getList()
        })      
    }
    onChange = (data)=> {
        if(this.state.page !== (data.selected+1)){
        this.setState({
            page:data.selected +1
        }, ()=> {
            this.getList()
        })
    }
}
statusChange = (user) => {
    const formData = new FormData()
    const obj = {
        status : user.status ? false : true,
        userId:user.id
    }
    formData.append("userData",JSON.stringify(obj))
    this.props.save(formData)
}
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }
    itemEdit = (templateId) =>{
        history.push('/admin/email/edit/'+templateId,{obj:{templateId}})
    }
    itemDelete = (templateId,type) => {
         let obj = {
            templateId,
            type
        }
        this.props.delete(obj)
    }
    handleDelete = (templateId,type) => {
        let message = '';
        const toastrConfirmOptions = {
            onOk: () => { this.itemDelete(templateId,type) },
            onCancel: () => console.log('CANCEL: clicked')
        };
        toastr.customConfirm(message, toastrConfirmOptions,'Do you want to delete ?');

    }
    getNewResult = (e) => {
        e.preventDefault();
        if(this.state.searchTxt){
            this.setState({ page : 1 },() => {
            this.getList()
            })
        }else{
            toastr.error("Please type to search")
        }        
    }
    render(){     
        
        const {limit,page} = this.state
        let emailList = this.state.emailList && this.state.emailList.map((email,index) => {
            return <tr>
            <td>{(page - 1) * limit + index  + 1}</td>
            <td>{email.name}</td>
            <td>
                    <a className="tooltip-wrap" onClick={()=>this.itemEdit(email.id)}>
                        <EditIcon className="edit-icon"/>
                        <div className="tooltip">
                            <div className="arrow"></div>
                            <div className="tooltip-inner">Edit</div>
                        </div>
                    </a>
                    <a className="tooltip-wrap" onClick={()=>this.handleDelete(email.id,email.type)}>
                        <DeleteIcon className="delete-icon"/>
                        <div className="tooltip">
                            <div className="arrow"></div>
                            <div className="tooltip-inner">Delete</div>
                        </div>
                    </a>
                </td>
        </tr>
        })   
        return(
            <Fragment>
            <div className="row tit-sec">
                <div className="col-6 col-md-5">
                    <h4>Template Management</h4>
                </div>
                <div className="col-6 col-md-7 text-right"> 
                    <button className="btn add-btn" onClick={this.formPath}><i className="fas fa-plus"></i>Add Template </button>
                </div>
            </div>
           <div className="card">
           <div className="card-body">
                        <div className="pt-2 mb-3">
                            {/* <div className="d-flex justify-content-end">
                                <form onSubmit={this.getNewResult} class="align-items-center" >
                                    <div class="input-group">
                                      <input type="text" placeholder="Search.." name="searchTxt" value={this.state.searchTxt} onChange={this.handleInputChange} aria-label="Recipient's username" formcontrolname="customSearch" class="form-control ng-pristine ng-valid ng-touched" />
                                        <div class="input-group-append">
                                            <button type="submit" class="btn btn-sm btn-dark" ><i className="fas fa-search"></i></button>
                                            <div class="pl-3">
                                                <button _ngcontent-wdd-c125="" type="button" onClick={this.resetSearch} class="btn btn-outline-secondary btn-rounded btn-icon">
                                                <i class="fas fa-redo-alt"></i>
                                                </button>
                                            </div>
                                        </div>
                                      </div>
                                </form>
                            </div> */}
                        </div>
            <div className="table-wrap">
                <div class="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {emailList}
                        </tbody>
                    </table>
                </div>
            </div>
            {this.state.emailList && this.state.emailList.length == 0 && <div className="table-responsive">
                            <h6 className="text-center">No record found</h6>
                            </div>}
           </div>
           </div>
           {this.state.emailList && this.state.emailList.length > 0 && <ReactPagination PageDetails={{pageCount: this.state.pageCount, onPageChange: this.onChange, activePage: (this.state.page - 1), perPage: this.state.limit, totalCount: this.state.totalCount}}/>}
        </Fragment>

        )
    }
}
function mapStateToProps(state){
    return {
       emailList : state.template.emailList,
       deleteStatus : state.template.deleteStatus
    }
}
const actionCreators = {
    getList : emailActions.getList,
    delete : emailActions.delete,
    resetDelete : emailActions.resetDelete

}
const Template = connect(mapStateToProps, actionCreators)(FetchEmail);
export {Template as FetchEmail };