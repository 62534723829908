import React,{Component,Fragment} from 'react'
import UploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import {history,ReactPagination} from '../../../helpers';
import {chatActions} from '../../../store/actions'
import {toastr} from '../../../services'
import Toggle from 'react-toggle';
class FetchChat extends Component{
    constructor(props){
        super(props);
        this.state={
            page:1,
            limit:10,
            searchTxt:'',
            chatList:[]
        }
    }
    componentDidMount(){
        this.list()
     
    }
    componentWillReceiveProps(newProps){

        // if(newProps && newProps.chatList && newProps.chatList.data){
        //     let data = newProps.chatList && newProps.chatList.data && newProps.chatList.data.rows
        //     let request = newProps.chatList && newProps.chatList.request ? newProps.chatList.request : []
        //     let totalCount = newProps.chatList && newProps.chatList.data && newProps.chatList.data.count ? newProps.chatList.data.count : []
        //     if(data.length == 0){
        //         toastr.error("Result Not Found")
        //     }
        //     this.setState({chatList:data,totalCount,pageCount:totalCount/request.limit})
        //    this.props.resetList()
        // }
    }
    list = () => {
        if(this.props && this.props.chatList && this.props.chatList.status == true){
            let request = this.props.chatList && this.props.chatList.request ? this.props.chatList.request : "";
            if(request.size || request.page || request.searchTxt){
                this.setState({
                    limit:request.limit,
                    page:request.page,
                    searchTxt:request.searchTxt
                },()=>{
                    this.getList()
                })
            }else{
                this.getList()
            }
        }
        else{
            this.getList()
        }
    }
    getList = () => {
       
        let obj = {}
        if(this.state.searchTxt){
            obj['page'] = this.state.page
            obj['limit'] = this.state.limit
            obj['searchTxt'] = this.state.searchTxt
            // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
            // obj['roleId'] = this.state.authorizedData ? this.state.authorizedData.roleId : ""
        }
        else{
            obj['page'] = this.state.page
            obj['limit'] = this.state.limit
            // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
            // obj['roleId'] = this.state.authorizedData ? this.state.authorizedData.roleId : ""
        }
       
        this.props.getList(obj)
    }
    
    resetSearch = () => {
        
        this.setState({searchTxt  : '',page : 1},() => {
            this.getList()
        })      
    }
   
    onChange = (data)=> {
        if(this.state.page !== (data.selected+1)){
        this.setState({
            page:data.selected +1
        }, ()=> {
            this.getList()
        })
    }
}
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }
    getNewResult = (e) => {
        e.preventDefault();
        if(this.state.searchTxt){
            this.setState({ page : 1 },() => {
            this.getList()
            })
        }else{
            toastr.error("Please type to search")
        }        
    }
    render(){
       
        return(
            <Fragment>
            <div className="row ml-auto align-item-center">
            <div className="col-6 col-md-5 pl-0">
            <h4>Chats</h4>
            </div>
            </div>
           <div className="card">
           <div className="card-body">
                        <div className="pt-2 mb-3">
                            <div className="d-flex justify-content-end">
                                <form onSubmit={this.getNewResult} class="align-items-center" >
                                    <div class="input-group">
                                      <input type="text" placeholder="Search.." name="searchTxt" value={this.state.searchTxt} onChange={this.handleInputChange} aria-label="Recipient's username" formcontrolname="customSearch" class="form-control ng-pristine ng-valid ng-touched" />
                                        <div class="input-group-append">
                                            <button type="submit" class="btn btn-sm btn-dark" >Search</button>
                                            <div class="pl-3">
                                                <button _ngcontent-wdd-c125="" type="button" onClick={this.resetSearch} class="btn btn-outline-secondary btn-rounded btn-icon">
                                                <i class="fas fa-redo-alt"></i>
                                                </button>
                                            </div>
                                        </div>
                                      </div>
                                </form>
                            </div>
                        </div>
           <table className="table">
            <thead className="table-decor">
            
            </thead>
               <tbody>
                  
               </tbody>
            </table>
           </div>
           </div>
           {this.state.chatList && this.state.chatList.length > 0 && <ReactPagination PageDetails={{pageCount: this.state.pageCount, onPageChange: this.onChange, activePage: (this.state.page - 1), perPage: this.state.limit, totalCount: this.state.totalCount}}/>}
        </Fragment>

        )
    }
}
function mapStateToProps(state){
    return {
      chatList : state.chat.chatList
    }
}
const actionCreators = {
  getList:chatActions.getList,
  resetList: chatActions.resetList
}
const Chat = connect(mapStateToProps, actionCreators)(FetchChat);
export {Chat as FetchChat };