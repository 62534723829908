import React ,{Component} from 'react';
import UploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import {history} from '../../../helpers';
import {userActions} from '../../../store/actions';
import {config} from '../../../config'
import {toastr} from '../../../services'
//import {toastr} from '../../../services'

class CreateAdmin extends Component{
    constructor(props){
        super(props);
        this.state={
            email:'',
            id:'',
            password:'',
            userName:'',
            type:'',
            roleId:'',
            roles:[{value : "User"},{value : "Volunteer"},{value : "Category"},{value : "Counsellor"}],
            submitted:false
        }
    }
    componentDidMount(){
        if(this.props.location && this.props.location.state && this.props.location.state.obj){
            let reqData = this.props.location.state.obj
            this.props.getUserList(reqData)
        }
        this.props.getList()
    }
    componentWillReceiveProps(newProps){
       
        if(newProps && newProps.adminRoles && newProps.adminRoles.data && newProps.adminRoles.data){
            let data = newProps.adminRoles.data
            this.setState({adminRoles:data})
            // this.setState({
            //    id:editData.id && editData.id,title:editData.title && editData.title,
            //    description:editData.description && editData.description,
            //    imagePreviewUrl:config.imgPath+'/categories/' +editData.imageName
            // })
        }
      
        if(newProps && newProps.userList && newProps.userList.data){
            let editData = newProps.userList && newProps.userList.data && newProps.userList.data.rows[0]
            let arrayJson =[]
            editData.adminRoleJson && editData.adminRoleJson.map(role => {
                this.state.adminRoles && this.state.adminRoles.map(roles => {
                    if(roles.roleId == role.roleId){
                        roles.isChecked = role.isChecked
                        // arrayJson.push(roles)
                    }
                })
            })
            this.setState({userName : editData.userName,email:editData.email,password:editData.password,id:editData.id,})
        }
        if(newProps && newProps.saveStatus && newProps.saveStatus == true){
            this.props.resetSave()
            history.goBack()
        }
    }
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }
    listPath = () => {
        history.push('/admin/manageAdmin')
    }
    handleRole = (e) => {
        let roles = this.state.adminRoles
        roles && roles.map(role => {
            if(role.roleId == e.target.value){
              role.isChecked = e.target.checked
              
            }
        })
       
        this.setState({adminRoles:roles})

    }
    validate = (value) => {
		var emailRegex = /^([a-z])+([a-z0-9_\.\-])+\@(([a-z\-])+\.)+([a-z]{2,3})$/;
		if (emailRegex.test(value)) {
			return true
		} else {
			return false;
		}
    }
    handleSubmit = (e) => {
        e.preventDefault()
        const {email,password,id,adminRoles,userName} = this.state
        this.setState({submitted:true})
        let selected = []
        adminRoles && adminRoles.map(role => {
            if(role.isChecked){
                selected.push(role)
            }
        })
        console.log("role",selected)
        if(email && password && userName && this.validate(email)){
            const formData = new FormData()
            let obj = {
                email,
                // password,
                userName,
                role:'2',
                adminRoleJson:selected
            }
            if(id){
                obj['userId'] = id
            }else{
                obj['password'] = password
            }
            formData.append("userData",JSON.stringify(obj))
            this.props.save(formData)
        }else{
            toastr.error('Mandatory fields are missing')

        }
    }
    render(){
       
        return(
            <div>
                 <section>
                    <div className="tit-sec">{this.state.id ? <h4>Edit Admin</h4> : <h4>Add Admin</h4>}</div>
                            <div class="card">
                                <div class="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-tab">
                                        <div className="col-md-6">
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Username&nbsp;<span class="mandatory">*</span></label>
                                                <div className="col-sm-8">
                                                <input type="text" name="userName" value={this.state.userName} onChange={this.handleInputChange} placeholder="Enter username" className="form-control" maxLength={32}/>
                                                {this.state.submitted && !this.state.userName && <div className="mandatory">Required</div>}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Email&nbsp;<span class="mandatory">*</span></label>
                                                <div className="col-sm-8">
                                                <input type="text" name="email" value={this.state.email} onChange={this.handleInputChange} placeholder="Enter Email" className="form-control"/>
                                                {this.state.submitted && !this.state.email && <div className="mandatory">Required</div>}
                                                {this.state.submitted && this.state.email && !this.validate(this.state.email) && <div className="mandatory">Please enter valid email</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Password&nbsp;<span class="mandatory">*</span></label>
                                                <div className="col-sm-8">
                                                <input type="password" name="password" value={this.state.password} onChange={this.handleInputChange} placeholder="Enter Password" className="form-control" disabled={this.state.id}/>
                                                {this.state.submitted && !this.state.password && <div className="mandatory">Required</div>}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-xl-3 mb-md-1 col-form-label pr-0">Admin Roles</label>                                               
                                                        <div className ="col-sm-8">
                                                             {this.state.adminRoles && this.state.adminRoles.map((role,index) => (
                                                                 <div>
                                                            <input type="checkbox"  onChange={this.handleRole} checked={role.isChecked == true} value={role.roleId}/><span className="ml-2">{role.roleName}</span>
                                                            </div>
                                                            ))}
                                                        </div>                                                                                                                                       
                                            </div>
                                        </div>                               
                                        </div>
                                        <div className="bottom-btn">
                                            <button type="button" className="btn cancel-btn" onClick={this.listPath}>Cancel</button>
                                            <button type="submit" className="btn add-btn">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                </section>
            </div>
        )
    }
}
function mapStateToProps(state){
    return {
        saveStatus : state.users.saveStatus,
        userList : state.users.userList,
    //    saveStatus : state.category.saveStatus,
    //    categoryList:state.category.categoryList,
        adminRoles : state.users.adminRoles
    }
}
const actionCreators = {
    save:userActions.save,
    getList : userActions.getAdmins,
    getUserList : userActions.getList,
    resetSave:userActions.resetSave

}
const User = connect(mapStateToProps, actionCreators)(CreateAdmin);
export { User as CreateAdmin };