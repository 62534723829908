import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../../helpers';
import {ratingActions} from '../../../store/actions'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Button from '@material-ui/core/Button';
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { toastr } from '../../../services';
import { config } from '../../../config';

class ViewSupport extends Component {
	constructor(props) {
		super(props);
	
		this.state = {
			
			viewData: []
		}
	}

	componentDidMount() {
		if (this.props.location && this.props.location.state && this.props.location.state.obj) {
			let reqData = this.props.location.state.obj
			this.props.getSupportList(reqData);
		}
	}

	componentWillReceiveProps(newProps) {
		if (newProps && newProps.supportList && newProps.supportList.data && newProps.supportList.data && newProps.supportList.data.rows && newProps.supportList.data.rows[0]) {
			let viewData = newProps.supportList.data.rows[0]
			this.setState({ viewData })
			this.props.resetList()
		}
	}

	listPath = () => {
		history.goBack()
	}


	

	render() {
		const { viewData, id } = this.state;
		return (
			<section >
				<h4 className="tit-sec">View Feedback</h4>
								<div class="card">
									<div class="card-body">
										<div className="form-tab">
										<div className="row">
											<div className="col-md-8">
													<div class="form-group row">
															<label className="col-4 col-sm-3 px-0"><b>First Name</b> </label>
															<div class="col-8 col-sm-8  pr-0">
																{viewData && viewData.User && viewData.User.firstName ?  viewData.User.firstName : '-'}
															</div>
														</div>
														<div class="form-group row">
															<label className="col-4 col-sm-3 px-0"><b>User Name</b> </label>
															<div class="col-8 col-sm-8  pr-0">
																{viewData && viewData.User && viewData.User.userName ? viewData.User.userName : '-'}
															</div>
														</div>
														<div class="form-group row">
															<label className="col-4 col-sm-3 px-0"><b>Email</b> </label>
															<div class="col-8 col-sm-8  pr-0">
																{viewData && viewData.User && viewData.User.email ? viewData.User.email : '-'}
															</div>
														</div>
														<div class="form-group row">
															<label className="col-4 col-sm-3 px-0"><b>Mobile</b> </label>
															<div class="col-8 col-sm-8  pr-0">
																{viewData && viewData.User && viewData.User.mobile ? viewData.User.mobile : '-'}
															</div>
														</div>
														<div class="form-group row">
															<label className="col-4 col-sm-3 px-0"><b>Content</b> </label>
															<div class="col-8 col-sm-8  pr-0">
																{viewData && viewData.description ? viewData.description : '-'}
															</div>
														</div>
											</div>
										</div>
												
														
                                                                <div class="text-right">
											<button type="button" className="btn cancel-btn" onClick={this.listPath}>Cancel</button>  
										</div>
										</div>
														</div>	
																										
														</div>

															
										
										{/* </div> */}


										
									
                                    
							
				
			</section>
		);
	}
}


function mapStateToProps(state) {
	return {
        supportList : state.rating.supportList
	};
}

const actionCreators = {
	getSupportList : ratingActions.getSupportList,
    resetList : ratingActions.resetSupportList
};


const connectedLogin = connect(mapStateToProps, actionCreators)(ViewSupport);
export { connectedLogin as ViewSupport };
