import React,{Component} from 'react'
import QRCode from 'qrcode.react';
import {history} from '../../../helpers/history';
import QRImg from '../../assets/img/QRCode.png';

function goToFront(){
    history.push('/donation')
}
function QRcode(){
    return (
       <div className="spad text-center qr-wrap">
           <h3>{window.strings.DONATEFORM.QR_CODE}</h3>
            {/* <QRCode
// id="123456"
value="sELVAn"
size={290}
level={"H"}
includeMargin={true}
/> */}
    <img src={QRImg}/>
    <div className="text-center">
        <button type="button" className="gradient-btn mt-3" onClick={goToFront}>{window.strings.SIGNUPEVENT.BACK}</button>

    </div>
            </div>
    )
}
export default QRcode