import {taskConstants} from '../../constants'

const initialState = {
    taskList : [],
    saveStatus : '',
    deleteStatus : ''
}

export function task(state = initialState,action){
    switch(action.type){
        case taskConstants.FETCH_TASK_SUCCESS:
            return {
                ...state,
                taskList:action.taskList
            }
            case taskConstants.SAVE_TASK_SUCCESS:
                return {
                    ...state,
                    saveStatus:action.saveStatus
                }
                case taskConstants.TASK_DELETE_SUCCESS:
                    return {
                        ...state,
                        deleteStatus:action.deleteStatus
                    }
                default:
                    return state
    }
}