import React from 'react'
import { confirmAlert } from 'react-confirm-alert';

import 'react-confirm-alert/src/react-confirm-alert.css';
import Button from '@material-ui/core/Button';
import { isWebLogin} from '../helpers/authorizedData'

export const toastr = {
    notificationSystem: null,
    setNotificationSystem(n) {
        this.notificationSystem = n;
    },
    success(message = '', title = 'Information') {
        this.notificationSystem.addNotification({
            title: title,
            message: message,
            level: 'success'
        });
    },
    error(message = '', title = 'Information') {
        this.notificationSystem.addNotification({
            title: title,
            message: message,
            level: 'error'
        });
    },
    warning(message = '', title = 'Information') {
        this.notificationSystem.addNotification({
            title: title,
            message: message,
            level: 'info'
        });
    },
    confirm(message, props, title) {
        const options = {
            title: title,
            message: message,
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Cancel',
                    onClick: () => { props.onCancel() }
                },
                {
                    label: 'Ok',
                    onClick: () => { props.onOk() }
                }

            ]
        }
        confirmAlert(options)
    },
    confirmSubmit(message, props, title) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='delete-popup'>
                        <h4 className="delete-popup-header">{title}</h4>
                        <div className="delete-popup-message">
                            <p>{message}</p>
                            {/* <button className="btn btn-default pull-right mrr10" onClick={()=>{
                        props.onCancel() 
                        onClose()}}>{window.strings.NEXT}</button>
                        <button className="btn btn-primary pull-right mrr10" onClick={() => { props.onOk()
                            onClose()}}>{window.strings.SUBMITBUTTON}</button> */}

                            <button className="btn btn-default mr-2" onClick={() => {
                                props.onCancel()
                                onClose()
                            }}>{window.strings.NEXT}</button>
                            <button className="btn btn-primary" onClick={() => {
                                props.onOk()
                                onClose()
                            }}>{window.strings.SUBMITBUTTON}</button>

                        </div>
                    </div>
                )
            }
        })
    },
    customConfirm(message, props, title) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='delete-popup'>
                        <h4 className="delete-popup-header">{isWebLogin() ? '' : <i className="fas fa-trash shake"></i> }{title}</h4>
                        <div className="delete-popup-message">
                            <p>{message}</p>
                            {/* <button className="btn btn-default" onClick={() => {
                                props.onCancel()
                                onClose()
                            }}>{'Cancel'}</button>
                            <button className="btn btn-primary ml-2" onClick={() => {
                                props.onOk()
                                onClose()
                            }}>{'Confirm'}</button> */}
                             <button type="button" className="btn cancel-btn" onClick={() => {
                                props.onCancel()
                                onClose()
                            }}>{'No'}</button>
                            <button className="btn add-btn" type="submit"  onClick={() => {
                                props.onOk()
                                onClose()
                            }}>{'Yes'}</button>
                        </div>
                    </div>
                )
            }
        })
    },



    mapConfirm(message, props, title) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='delete-popup map-box'>
                        <h4 className="delete-popup-header">{title}</h4>
                        <div className="delete-popup-message p-2 mt-4">
                            <p>{message}</p>
                            <button className="btn btn-default popup-cancel mt-2 mx-3" onClick={() => {
                                props.onCancel()
                                onClose()
                            }}>{"Cancel"}</button>
                            <button className="btn btn-primary" onClick={() => {
                                props.nullPolygon()
                                onClose()
                            }}>{"Null"}</button>
                            <button className="btn btn-primary" onClick={() => {
                                props.newPolygon()
                                onClose()
                            }}>{"New Polygon"}</button>
                            <button className="btn btn-primary" onClick={() => {
                                props.splitUp()
                                onClose()
                            }}>{"Assign"}</button>
                        </div>
                    </div>
                )
            }
        })
    },
}


