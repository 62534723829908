export const appConstants = {
    FETCH_APP_SUCCESS : 'FETCH_APP_SUCCESS',
    SAVE_APP_SUCCESS  : 'SAVE_APP_SUCCESS',
    APP_DELETE_SUCCESS: 'APP_DELETE_SUCCESS',

    FETCH_REPORT_SUCCESS : 'FETCH_REPORT_SUCCESS',
    FETCH_DASHBOARD_SUCCESS : 'FETCH_DASHBOARD_SUCCESS',
    FETCH_FORM_SUCCESS : 'FETCH_FORM_SUCCESS',

    FETCH_ASSIGNEDFORM_SUCCESS : 'FETCH_ASSIGNEDFORM_SUCCESS',
    SAVE_ASSIGNEDFORM_SUCCESS : 'SAVE_ASSIGNEDFORM_SUCCESS',
    ASSIGNEDFORM_DELETE_SUCCESS : '    ASSIGNEDFORM_DELETE_SUCCESS'

}