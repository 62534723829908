import React,{Component, Fragment} from 'react'
import { connect } from 'react-redux';
import {userActions} from '../../../../store/actions'
import {history} from '../../../../helpers'
import { Link } from 'react-router-dom';
import { TrendingUpRounded } from '@material-ui/icons';
class WebLogin extends Component{
    constructor(props){
        super(props);
        this.state={
            username:'',
            password:'',
            submitted:false
        }
    }
    componentWillReceiveProps(newProps){
        if(!this.state.called && newProps && newProps.loggedIn && newProps.loggedIn.loggedIn){
            // history.push('/admin/dashboard')
            this.props.triggerFunc(false)
            this.setState({ called: true })
        }
    }
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });

    }
    handleSignup = () => {
        this.props.handleSignup(true)
    }
    forgetpasswordChange = (e) => {
        e.preventDefault()
        history.push('/admin/forgetpassword')
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({submitted:true})
        const {username,password} = this.state
        if(username && password){
            let obj = {
                email:username,
                password,
                type : "web",
                role : "5"
            }
            // if(username == "superadmin@gmail.com"){
            //     obj['role'] = "1"
            // }else{
            //     obj['role'] = "2"
            // }
            this.props.login(JSON.stringify(obj))
        }
    }
    render(){
        return (
            <Fragment>
                <div className="log-block">
                                <h5>SIGN IN</h5>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <input type="text" name="username" value={this.state.username} onChange={this.handleInputChange} className="form-control" placeholder="Enter Username*"/>
                                        {this.state.submitted && !this.state.username && <div className="mandatory">Required</div>}
                                    </div>
                                    <div className="form-group">
                                        <input type="password" name="password" value={this.state.password} onChange={this.handleInputChange} className="form-control" placeholder="Enter Password*"/>
                                        {this.state.submitted && !this.state.password && <div className="mandatory">Required</div>}
                                    </div>
                                    <div className="btn-wrap">
                                        <button className="gradient-btn" type="submit">SIGN IN</button>
                                        <button onClick={this.handleSignup} className="line-btn">SIGN UP</button>
                                    </div>
                                    {/* <div className="my-3 d-flex justify-content-center align-items-center">
                                        <a href="/forgetpassword" className="auth-link text-black" onClick={this.forgetpasswordChange}>Forgot password?</a>
                                    </div>                            */}
                                </form>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
	return {
		loggedIn : state.authentication
	};
}

const actionCreators = {
  login :userActions.login
};



const connectedLogin = connect(mapStateToProps, actionCreators)(WebLogin);
export { connectedLogin as WebLogin };
