import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history,ReactPagination } from '../../../helpers';
import {ratingActions,userActions} from '../../../store/actions';
import { config } from '../../../config';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Checkbox } from '@material-ui/core';
import {toastr} from '../../../services/toastr.service'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import {isAdminLogin} from '../../../helpers/authorizedData'

class PushNotification extends Component {
    csvLink = React.createRef()
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            itemsPerPage: 10,
            page: 1,
            totalCount: '',
            searchTxt: '',
            userId: '',
            content:'',
            title : '',
            description :'',
            userData : isAdminLogin(),
            // userData: authorizedData(),
            checkedList: [],
            open: false,
            submit: false,
            isSelectAll : false,
            unSelectList : []
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps && newProps.userList && newProps.userList.status == true) {
            let data = newProps.userList && newProps.userList.data && newProps.userList.data.rows
            console.log("data",data)
            if(data.length == 0){
                toastr.error('Result not found')  
            }
            let request = newProps.userList && newProps.userList.request ? newProps.userList.request : []
            let totalCount = newProps.userList && newProps.userList.data && newProps.userList.data.count ? newProps.userList.data.count : []
            let listData = []
            data && data.map((item, index) => {
                let checkedItem = {}
                this.state.checkedList && this.state.checkedList.map(checkedItems => {
                    if (item.id == checkedItems.id) {
                        checkedItem = checkedItems
                    }
                })
                if (Object.keys(checkedItem).length > 0) {
                    item.isChecked = true;
                    listData.push(item);
                } else {
                    if(this.state.isSelectAll){
                        if(this.state.unSelectList && this.state.unSelectList.length > 0){
                            item.isChecked = true
                            this.state.unSelectList && this.state.unSelectList.map(unSelectItem => {
                                if(unSelectItem.id == item.id){
                                    item.isChecked = false;
                                }
                            })
                        }else{
                            item.isChecked = true;
                        }                                               
                    }
                    listData.push(item);
                }
            })


            this.setState({
                listData: data, totalCount, itemsPerPage: request.itemsPerPage,
                page: request.page, searchTxt: request.searchTxt, pageCount: totalCount / request.itemsPerPage
            })
            this.props.resetList();
        }
        // if(newProps && newProps.deleteStatus == "200"){
        //     this.getList();
        //     this.props.resetDelete()
        // }

        if (newProps && newProps.notificationStatus == true) {
            this.props.resetPushNotification();
            this.triggerFunc(false);
            history.goBack()
        }
    }

    componentDidMount() {
        this.list();
        console.log("list",this.state.listData)
    }

    list = () => {
        if(this.props && this.props.userList && this.props.userList.status == true){
            let request = this.props.userList&& this.props.userList.request ? this.props.userList.request : "";
            if(request.itemsPerPage || request.page || request.searchTxt){
                this.setState({
                    itemsPerPage:request.itemsPerPage,
                    page:request.page,
                    searchTxt:request.searchTxt
                },()=>{
                    this.getList()
                })
            }else{
                this.getList()
            }
        }
        else{
            this.getList()
        }
    }

    getNewResult = (e) => {
        e.preventDefault();
        if (this.state.searchTxt) {
            this.setState({ page : 1 },() => {
            this.getList()
            })
        } else {
            toastr.error("Please type to search")
        }
    }

    resetSearch = () => {
        this.setState({ searchTxt: '',page : 1  }, () => {
            this.getList()
        })
    }

    getList = () => {
        let obj = {
        }
        // if(this.state.userId){
        //     obj['userId'] = this.state.userId
        // }
        if(this.state.searchTxt){
            obj['searchTxt'] = this.state.searchTxt
            obj['itemsPerPage'] = this.state.itemsPerPage
            obj['page'] = this.state.page
        }
        else{
            obj['itemsPerPage'] = this.state.itemsPerPage
            obj['page'] = this.state.page
        }
      

        this.props.getList(obj);
    }

    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }

    onChange = (data) => {
        if (this.state.page !== (data.selected + 1)) {
            this.setState({ page: data.selected + 1 }, () => {
                this.getList();
            });
        }
    }


    onCheck = (item) => {
        let checkedList = this.state.checkedList;
        let listData = this.state.listData;
        let unSelectList = this.state.unSelectList;
        if (item.isChecked) {
            let filterCheckedList = checkedList && checkedList.filter(chekedItem => {
                return item.id != chekedItem.id
            })
            listData && listData.map((listItem) => {
                if (item.id == listItem.id) {
                    listItem['isChecked'] = false
                    if(this.state.isSelectAll){ //unCheck particular column When Selectall true
                        unSelectList.push(listItem)
                        this.setState({ checkboxChange : true })
                    }                    
                }
            })
            this.setState({ checkedList: filterCheckedList ? filterCheckedList : [], listData })
        } else {
            checkedList.push(item);
            item['isChecked'] = true;
            if(this.state.isSelectAll){ 
                var filteredList = []
                filteredList = unSelectList.filter(unSelectItem => {
                    return unSelectItem.id != item.id
                })
            }
            this.setState({ checkedList,unSelectList : filteredList ? filteredList : [] })
        }
    }

    handleSelectAll = (SelectAll) => {
        let isSelectAll = SelectAll ? false : true;
        let listData = this.state.listData
        let checkedList = this.state.checkedList;
        if(isSelectAll){
            listData && listData.map(item => {
                item.isChecked = true
            })
            this.setState({ unSelectList : []})
        }else{
            listData && listData.map(item => {
                item.isChecked = false
            })
            this.setState({ unSelectList : [] })
        }                           
        this.setState({ isSelectAll,listData,checkedList : [],checkboxChange : false })
    }

    handleShowOrginal = () => {
        this.setState({ checkboxChange : false },() => {
            this.handleSelectAll(false)
        })
    }

    triggerFunc = (value) => {
        if (value == true) {
            if(this.state.isSelectAll){
                this.setState({ open: value }) 
            }else{
                this.state.checkedList && this.state.checkedList.length > 0 ? this.setState({ open: value }) : toastr.error("Please select any user");
            }
        } else {
            this.setState({ open: value })

            
        }
       
    }

    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }

    pageNavigation = () => {
        history.goBack()
    }

    onSubmit = () => {
        this.setState({ submit: true })
        if (this.state.title && this.state.description) {
            let userIds = [];
            let unSelectList = [];
            let obj = {
               title : this.state.title,
               description : this.state.description,
               userId : this.state.userData && this.state.userData.id,
               type:'admin'
            }
           
            if(this.state.isSelectAll){
                this.state.unSelectList && this.state.unSelectList.map(item => {
                    if (item.id) {
                        unSelectList.push(item.id)
                    }
                })
                
                if(unSelectList.length >= 1){
                   
                    obj['unSelectList'] = unSelectList
                    obj['isSelectAll'] = false
                }else{
                    obj['isSelectAll'] = true
                    obj['unSelectList'] = []

                }
                console.log(this.state.unSelectList,"unselect")
         }else{
                this.state.checkedList && this.state.checkedList.map(item => {
                    if (item.id) {
                        userIds.push(item.id)
                    }
                })
                console.log("ids",userIds)
                obj['toUserId'] = userIds
             }
            this.props.pushNotification(JSON.stringify(obj))
        }
    }

    render() {
        const { size, page } = this.state;
        const contentStyle = {
            maxWidth: "600px",
            width: "90%",
            height: "50px"
        };
        let listData = [];
        this.state.listData && this.state.listData.map((item, index) => {
            if (item) {
                let listObj =  <tr >
                    <td><input type="checkbox" checked={item.isChecked} onChange={() => this.onCheck(item)} /></td>
                    <td>{item.userName}</td>
                    <td>{item.email}</td>
                    <td>{item.mobile ? item.mobile : '-'}</td>
                </tr>
                listData.push(listObj)
            }
        })

        return (
            <section>
                <div class="row tit-sec">
                    <div class="col-4 col-md-4">
                        <h4>Users</h4>
                    </div>
                    <div class="col-11 col-md-8 text-right">
                        <button  className="btn add-btn mr-2" onClick={() => this.triggerFunc(true)}>Push Notification</button>
                        <button className="btn cancel-btn mr-0" onClick={() => this.pageNavigation()}>Back</button>
                    </div>
                    <Modal open={this.state.open} onClose={() => this.triggerFunc(false)} center blockScroll={false}
                    classNames={{
                        modal:'notify'
                    }}>
                        
                        <h5><i className="fas fa-paper-plane shake"></i>Send Notification</h5>
                        <div className="mb-3">
                            <input type="text" name="title" placeholder="Enter title" value={this.state.title} onChange={this.handleInputChange} className="form-control" />
                            {this.state.submit && !this.state.title && <div className="mandatory">{'Title Required'}</div>}
                        </div>
                        <div className="mb-3">
                            <input type="text" name="description" placeholder="Enter description" value={this.state.description} onChange={this.handleInputChange} className="form-control" />
                            {this.state.submit && !this.state.description && <div className="mandatory">{'Description Required'}</div>}
                        </div>
                        <div class="text-right mt-3">
                            <button className="btn cancel-btn" onClick={() => this.triggerFunc(false)}>Cancel</button>
                            <button className="btn add-btn" onClick={() => this.onSubmit(this.state.content)}>Submit</button>
                        </div>
                       
                    </Modal>
                  
                   
                </div>
                <div className="row">
                    <div className="col-lg-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body pt-3">
                                <div className="pt-2 mb-3">
                                    <div className="d-flex justify-content-end">
                                        <form onSubmit={this.getNewResult} class="align-items-center" >
                                            <div class="input-group">
                                                <input type="text" placeholder="Search.." name="searchTxt" value={this.state.searchTxt} onChange={this.handleInputChange} aria-label="Recipient's username" formcontrolname="customSearch" class="form-control ng-pristine ng-valid ng-touched" />
                                                <div class="input-group-append">
                                                    <button type="submit" class="btn btn-sm btn-dark" ><i className="fas fa-search"></i></button>
                                                    <div class="pl-3">
                                                        <button _ngcontent-wdd-c125="" type="button" onClick={this.resetSearch} class="btn btn-outline-secondary btn-rounded btn-icon">
                                                            <i class="fas fa-redo-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>                    
                                </div>
                                <div className="table-wrap">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    {this.state.checkboxChange && <th> <input type="checkbox" checked={false} onChange={() => this.handleShowOrginal()} />  Select All</th>}
                                                    {!this.state.checkboxChange && <th> <input type="checkbox" checked={this.state.isSelectAll} onChange={() => this.handleSelectAll(this.state.isSelectAll)} />  Select All</th>}
                                                    <th>Username</th>
                                                    <th>Email</th>
                                                    <th>Mobile</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listData}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.listData && this.state.listData.length > 0 && <ReactPagination PageDetails={{pageCount: this.state.pageCount, onPageChange: this.onChange, activePage: (this.state.page - 1), perPage: this.state.itemsPerPage, totalCount: this.state.totalCount}}/>}

            </section>
        );
    }
}


function mapStateToProps(state) {
    return {
      notificationStatus : state.rating.notificationStatus,
      userList : state.users.userList,
    };
}

const actionCreators = {
    pushNotification : ratingActions.pushNotification,
    resetPushNotification : ratingActions.resetPushNotification,
    getList:userActions.getList,
    resetList : userActions.resetList
};
const connectedLogin = connect(mapStateToProps, actionCreators)(PushNotification);
export { connectedLogin as PushNotification };