import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import {store} from './store'
import reportWebVitals from './reportWebVitals';
import NotificationSystem from 'react-notification-system';
import {toastr} from './services'
ReactDOM.render(

    <Provider store={store}>
      <NotificationSystem key="notificationSystem" ref= { (n) => toastr.setNotificationSystem(n)  } />
      <App/>
    </Provider>,
  document.getElementById('root')
);


reportWebVitals();
