import React, { Component, Fragment } from 'react'
import UploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { history, ReactPagination } from '../../../helpers';
import { eventActions } from '../../../store/actions';
import { toastr } from '../../../services'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { eventService } from '../../../services'
import Toggle from 'react-toggle';
import { config } from '../../../config'
import { CSVLink, CSVDownload } from "react-csv";

const headers =
    [
        { label: 'Id', key: 'id' },
        { label: 'Title', key: 'title' },
        { label: 'description', key: 'description' },
        { label: 'EventStatus', key: 'eventStatus' },
        { label: 'EventType', key: 'eventType' },
        { label: 'Location', key: 'location' },
        { label: 'Address', key: 'address' },
        { label: 'Participants', key: 'participants' },
        { label: 'Start Date', key: 'startDate' },
        { label: 'End Date', key: 'endDate' },
        { label: 'Event Count', key: 'eventCount' },
        { label: 'MaxCount', key: 'maxCount' },
        { label: 'Working Time', key: 'workingTime' },
        { label: 'Status', key: 'status' },
    ];

class FetchEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            searchTxt: '',
            limit: 10,
            eventList: [],
            csvData: []
        }
    }
    componentDidMount() {
        this.list()
    }
    componentWillReceiveProps(newProps) {
        if (newProps && newProps.eventList && newProps.eventList.data) {
            let data = newProps.eventList && newProps.eventList.data && newProps.eventList.data.rows
            let request = newProps.eventList && newProps.eventList.request ? newProps.eventList.request : []
            let totalCount = newProps.eventList && newProps.eventList.data && newProps.eventList.data.count ? newProps.eventList.data.count : []
            if (data && data.length == 0) {
                toastr.error("Result Not Found")
            }
            this.setState({ eventList: data, totalCount, pageCount: totalCount / request.limit })
            this.props.resetList()
            console.log('hello',this.state.eventList)
        }
        if (newProps && newProps.deleteStatus && newProps.deleteStatus === true) {
            this.props.resetDelete()
            this.getList()
        }
    }
    list = () => {
        if (this.props && this.props.eventList && this.props.eventList.status == true) {
            let request = this.props.eventList && this.props.eventList.request ? this.props.eventList.request : "";
            if (request.size || request.page || request.searchTxt) {
                this.setState({
                    limit: request.limit,
                    page: request.page,
                    searchTxt: request.searchTxt
                }, () => {
                    this.getList()
                })
            } else {
                this.getList()
            }
        }
        else {
            this.getList()
        }
    }
    getList = () => {
        const { eventStatus, eventTypes } = this.state
        let obj = {}
        if (this.state.searchTxt) {
            obj['page'] = this.state.page
            obj['limit'] = this.state.limit
            obj['searchTxt'] = this.state.searchTxt
            obj['type'] = 'admin'
            obj['csvType'] = 'export'
            // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
            // obj['roleId'] = this.state.authorizedData ? this.state.authorizedData.roleId : ""
        }
        else {
            obj['page'] = this.state.page
            obj['limit'] = this.state.limit
            obj['type'] = 'admin'
            obj['csvType'] = 'export'

            // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
            // obj['roleId'] = this.state.authorizedData ? this.state.authorizedData.roleId : ""
        }
        if (eventStatus) {
            obj['eventStatus'] = eventStatus
        }
        if (eventTypes) {
            obj['eventTypes'] = eventTypes
        }
        this.props.getList(obj)
    }
    formPath = () => {
        history.push('/admin/event/add')
    }
    statusChange = (event) => {
        // const formData = new FormData()
        let status = event.status ? false : true
        let obj = {
            status: status.toString(),
            eventId: event.id
        }
        // formData.append("Request for User Details",JSON.stringify(obj))
        this.props.statusUpdate(JSON.stringify(obj))
        this.getList()

    }
    handleStatusChange = (e) => {
        this.setState({
            eventStatus: e.target.value
        }, () => {
            this.getList()
        })
    }
    handleTypeChange = (e) => {
        this.setState({
            eventTypes: e.target.value
        }, () => {
            this.getList()
        })
    }
    handleEventStatus = (e, id) => {
        let obj = {
            eventStatus: e.target.value,
            eventId: id
        }
        this.props.statusUpdate(JSON.stringify(obj))
        this.getList()
    }
    onChange = (data) => {
        if (this.state.page !== (data.selected + 1)) {
            this.setState({
                page: data.selected + 1
            }, () => {
                this.getList()
            })
        }
    }
    resetSearch = () => {

        this.setState({ searchTxt: '', page: 1, eventStatus: '', eventTypes: '' }, () => {
            this.getList()
        })
    }
    handleInputChange = (e) => {
        e.target.value && e.target.value[0].includes(" ") ? e.target.value = '' : this.setState({ [e.target.name]: e.target.value });
    }
    getNewResult = (e) => {
        e.preventDefault();
        if (this.state.searchTxt) {
            this.setState({ page: 1 }, () => {
                this.getList()
            })
        } else {
            toastr.error("Please type to search")
        }
    }
    itemEdit = (eventId) => {
        history.push('/admin/event/edit/' + eventId, { obj: { eventId } })
    }
    itemDelete = (eventId) => {
        let obj = {
            eventId
        }
        this.props.delete(obj)
    }
    handleDelete = (eventId) => {
        console.log("-----------event--------------",eventId);
        let message = '';
        const toastrConfirmOptions = {
            onOk: () => { this.itemDelete(eventId) },
            onCancel: () => console.log('CANCEL: clicked')
        };
        toastr.customConfirm(message, toastrConfirmOptions, 'Do you want to delete ?');

    }

    navigateMemberPage = (eventData) => {
        history.push('/admin/eventmembers', { eventData: eventData })
    }

    exportCSV = () => {
        let csvDataArray = [];
        let exportData = this.state.eventList;
        exportData && exportData.length > 0 && exportData.map((item, key) => {
            let newObj = {
                id: item.id,
                title: item.title,
                description: item.description,
                eventStatus: item.eventStatus,
                eventTypes: item.eventType,
                location: item.location,
                address: item.address,
                participants: item.participants,
                startDate: item.startDate,
                endDate: item.endDate,
                eventCount: item.eventCount,
                maxCount: item.maxCount,
                workingTime: item.workingTime,
                status: item.status,
            }
            csvDataArray.push(newObj)
            this.setState({
                csvData: csvDataArray
            })
        })
    }

    render() {
        const { limit, page, csvData } = this.state
        let eventList = this.state.eventList && this.state.eventList.map((event, index) => {
            return <tr>
                <td>{(page - 1) * limit + index + 1}</td>
                <td>{event.title}</td>
                <td>{event.eventType}</td>
                {/* <td>{event.eventStatus}</td> */}
                <td className="">
                    {
                        <select className="" onChange={(e) => this.handleEventStatus(e, event.id)}>
                            <option value="yettostart" selected={event.eventStatus == "yettostart"}>Yet to start</option>
                            <option value="ongoing" selected={event.eventStatus == "ongoing"}>Ongoing</option>
                            <option value="done" selected={event.eventStatus == "done"}>Done</option>
                        </select>
                    }
                </td>
                <td>{event.maxCount ? event.maxCount : '-'}</td>
                <td>{event.eventCount}</td>
                {/* <td><Toggle onClick={()=>this.statusChange(event)}  defaultChecked={event.status}
                    Checked={event.status}/></td> */}
                <td><img src={config.apiUrl + '/uploads/events/' + event.imageUrl} height="50px" width="50px" ></img></td>
                <td><button onClick={() => this.navigateMemberPage(event)} className="mem-btn">Members</button></td>
                <td>
                    <a className="tooltip-wrap" onClick={() => this.itemEdit(event.id)}>
                        <EditIcon className="edit-icon" />
                        <div className="tooltip">
                            <div className="arrow"></div>
                            <div className="tooltip-inner">Edit</div>
                        </div>
                    </a>
                    <a className="tooltip-wrap" onClick={() => this.handleDelete(event.id)}>
                        <DeleteIcon className="delete-icon" />
                        <div className="tooltip">
                            <div className="arrow"></div>
                            <div className="tooltip-inner">Delete</div>
                        </div>
                    </a>
                </td>
            </tr>
        })
        return (
            <Fragment>
                <div className="row tit-sec">
                    <div className="col-6 col-md-5">
                        <h4>Event List</h4>
                    </div>
                    <div className="col-6 col-md-7 text-right">
                        <button className="btn add-btn" onClick={this.formPath}><i className="fas fa-plus"></i>Add Event</button>

                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="pt-2 mb-3">
                            <form onSubmit={this.getNewResult} class="align-items-center" >
                                <div class="input-group justify-content-end">
                                    <div className="col-md-3 col-lg-3 input-fields">
                                        <div className="form-group">
                                            <label>Event Status</label>
                                            <select value={this.state.eventStatus} onChange={this.handleStatusChange} className="form-control">
                                                <option value=''>Select Status</option>
                                                <option value="yettostart">Yet to start</option>
                                                <option value="ongoing">Ongoing</option>
                                                <option value="done">Done</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-lg-3 input-fields">
                                        <div className="form-group">
                                            <label>Event Type</label>
                                            <select value={this.state.eventTypes} onChange={this.handleTypeChange} className="form-control">
                                                <option value=''>Select Type</option>
                                                <option value="training">Training</option>
                                                <option value="public">Public</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-lg-3 m-top">
                                        <div className="input-group">
                                            <input type="text" placeholder="Search.." name="searchTxt" value={this.state.searchTxt} onChange={this.handleInputChange} aria-label="Recipient's username" formcontrolname="customSearch" class="form-control ng-pristine ng-valid ng-touched" />
                                            <div class="input-group-append">
                                                <button type="submit" class="btn btn-sm btn-dark" ><i className="fas fa-search"></i></button>
                                                <div class="pl-3">
                                                    <button _ngcontent-wdd-c125="" type="button" onClick={this.resetSearch} class="btn btn-outline-secondary btn-rounded btn-icon">
                                                        <i class="fas fa-redo-alt"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-lg-3 m-top">
                                        <div className="form-group">
                                            <CSVLink
                                                class="btn btn-outline-secondary btn-rounded btn-icon add-btn"
                                                filename={"Events.csv"}
                                                data={csvData}
                                                headers={headers}
                                                onClick={this.exportCSV}
                                            >
                                                Export CSV
                                            </CSVLink>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="table-wrap">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Title</th>
                                            <th>Event Type</th>
                                            <th>Event Status</th>
                                            <th>Max Count</th>
                                            <th>Attend</th>
                                            {/* <th>Status</th> */}
                                            <th>Image</th>
                                            <th>Event Members</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {eventList}
                                    </tbody>
                                </table>
                            </div>
                            {this.state.eventList && this.state.eventList.length == 0 && <div>
                                <h6 className="text-center py-4">No Record Found</h6>
                            </div>}
                        </div>
                    </div>
                </div>
                {this.state.eventList && this.state.eventList.length > 0 && <ReactPagination PageDetails={{ pageCount: this.state.pageCount, onPageChange: this.onChange, activePage: (this.state.page - 1), perPage: this.state.limit, totalCount: this.state.totalCount }} />}
            </Fragment>

        )
    }
}
function mapStateToProps(state) {
    return {
        eventList: state.events.eventList,
        deleteStatus: state.events.deleteStatus
    }
}
const actionCreators = {
    save: eventActions.save,
    getList: eventActions.getList,
    resetList: eventActions.resetList,
    delete: eventActions.delete,
    resetDelete: eventActions.resetDelete,
    statusUpdate: eventActions.statusUpdate
}
const Event = connect(mapStateToProps, actionCreators)(FetchEvent);
export { Event as FetchEvent };