import {ratingConstants} from '../../constants'

const initialState = {
    ratingList : [],
   notificationList:[],
   notificationStatus:'',
   supportList : []
}

export function rating(state = initialState,action){
    switch(action.type){
        case ratingConstants.FETCH_RATINGS_SUCCESS:
            return {
                ...state,
                ratingList:action.ratingList
            }
            case ratingConstants.FETCH_NOTIFICATION_SUCCESS:
                return {
                    ...state,
                    notificationList:action.notificationList
                }
                case ratingConstants.PUSH_NOTIFICATION_SUCCESS:
                    return {
                        ...state,
                        notificationStatus:action.notificationStatus
                    }
                    case ratingConstants.FETCH_SUPPORT_SUCCESS:
                        return {
                            ...state,
                            supportList : action.supportList
                        }
            default:
                return state
    }
}