import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../../helpers';
import { taskActions } from '../../../store/actions'
import { userActions } from '../../../store/actions'

import "react-toggle/style.css";
import { config } from '../../../config';

class ViewNormalUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userData: []
		}
	}

	componentDidMount() {
		if (this.props && this.props.location && this.props.location.state && this.props.location.state.obj) {
			let reqData = this.props.location.state.obj
			this.props.getList(reqData);
		}
	}

	componentWillReceiveProps(newProps) {
		if (newProps && newProps.userList && newProps.userList.data && newProps.userList.data && newProps.userList.data.rows && newProps.userList.data.rows[0]) {
			let data = newProps.userList.data.rows[0]
			this.setState({ userData: data })
			this.props.resetUser()
		}
	}

	listPath = () => {
		history.goBack()
	}

	render() {
		const { userData } = this.state;
		return (
			<section >
				<div className="tit-sec">
					<h4 >View User</h4>
				</div>
				<div class="card">
					<div class="card-body">
						<div className="form-tab">
							<div className="row">
								<div className="col-md-8">
									<div class="form-group row">
										<label className="col-4 col-sm-3 px-0"><b>FirstName</b> </label>
										<div class="col-8 col-sm-8  pr-0">
											{userData && userData.firstName ? userData.firstName : '-'}
										</div>
									</div>
									<div class="form-group row">
										<label className="col-4 col-sm-3 px-0"><b>Last Name</b> </label>
										<div class="col-8 col-sm-8  pr-0">
											{userData && userData.lastName ? userData.lastName : '-'}
										</div>
									</div>
									<div class="form-group row">
										<label className="col-4 col-sm-3 px-0"><b>UserName</b> </label>
										<div class="col-8 col-sm-8  pr-0">
										{userData && userData.userName ? userData.userName : '-'}
										</div>
									</div>
									<div class="form-group row">
										<label className="col-4 col-sm-3 px-0"><b>Email</b> </label>
										<div class="col-8 col-sm-8  pr-0">
											{userData && userData.email ? userData.email : '-'}
										</div>
									</div>
									<div class="form-group row">
										<label className="col-4 col-sm-3 px-0"><b>Gender</b> </label>
										<div class="col-8 col-sm-8  pr-0">
											{userData && userData.gender ? userData.gender : '-'}
										</div>
									</div>
									<div class="form-group row">
										<label className="col-4 col-sm-3 px-0"><b>Date of Birth</b> </label>
										<div class="col-8 col-sm-8  pr-0">
											{userData && userData.dob ? userData.dob : '-'}
										</div>
									</div>
									<div class="form-group row">
										<label className="col-4 col-sm-3 px-0"><b>Mobile Number</b> </label>
										<div class="col-8 col-sm-8  pr-0">
											{userData && userData.mobile ? userData.mobile : '-'}
										</div>
									</div>
									<div class="form-group row">
										<label className="col-4 col-sm-3 px-0"><b>Location</b> </label>
										<div class="col-8 col-sm-8  pr-0">
											{userData && userData.location ? userData.location : '-'}
										</div>
									</div>
									<div class="form-group row">
										<label className="col-4 col-sm-3 px-0"><b>Role</b> </label>
										<div class="col-8 col-sm-8  pr-0">
											{userData && userData.role && userData.role == 5 ? 'User' : '-'}
										</div>
									</div>
									<div class="form-group row">
										<label className="col-4 col-sm-3 px-0"><b>Image</b> </label>
										<div class="col-8 col-sm-8  pr-0">
											<img src={config.apiUrl + '/uploads/users/' + userData.profileImg} height="80px" width="80px"></img>
										</div>
									</div>
								</div>
							</div>
							<div class="text-right">
								<button type="button" className="btn cancel-btn" onClick={this.listPath}><i className="fas fa-arrow-left"></i> Back</button>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}


function mapStateToProps(state) {
	return {
		userList: state.users.userList,
	};
}

const actionCreators = {
	getList: userActions.getList,
	resetUser: userActions.resetUser,
};


const connectedTask = connect(mapStateToProps, actionCreators)(ViewNormalUser);
export { connectedTask as ViewNormalUser };
