import {toastr} from '../services/toastr.service'
// import PubSub from 'pubsub-js';

export function handleError (error){
    if(error instanceof TypeError){
        toastr.error("Network connection failed")
        // PubSub.publish('msg', false);
        Promise.reject(error)
    }
    return error
}