import React, { Component, Fragment } from 'react';
import UploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { history, ReactPagination } from '../../../helpers';
import { eventActions } from '../../../store/actions';
import { toastr } from '../../../services';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { eventService } from '../../../services';
import Toggle from 'react-toggle';
import { config } from '../../../config';
class FetchEventMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      searchTxt: '',
      limit: 10,
      eventList: [],
      listData: [],
      isEventMenberPage: false,
    };
  }
  componentDidMount() {
    this.initial_getList();
  }

  initial_getList = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.eventData &&
      this.props.location.state.eventData.id
    ) {
      this.setState({
        isEventMenberPage: true,
        eventId: this.props.location.state.eventData.id,
        eventName: this.props.location.state.eventData && this.props.location.state.eventData.title,
      });

      let obj = {
        eventId: this.props.location.state.eventData.id,
      };

      this.props.getList(obj);
    } else {
      this.setState({ isEventMenberPage: false, eventId: '', eventName: '' }, () => {
        this.list();
      });
    }
  };

  componentDidUpdate(prevProps) {
    let hash = '';
    if (config.hashName) {
      hash = window.location.hash;
    } else {
      hash = '';
    }

    if (window.location.pathname + hash != config.hashName + prevProps.location.pathname) {
      this.initial_getList();
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps && newProps.eventList && newProps.eventList.data) {
      let data = newProps.eventList && newProps.eventList.data && newProps.eventList.data.rows;
      let request = newProps.eventList && newProps.eventList.request ? newProps.eventList.request : [];
      let totalCount =
        newProps.eventList && newProps.eventList.data && newProps.eventList.data.count
          ? newProps.eventList.data.count
          : [];
      if (data.length == 0) {
        toastr.error('Result Not Found');
      }
      this.setState({ eventList: data, totalCount, pageCount: totalCount / request.limit });
      this.props.resetList();
    }
    if (newProps && newProps.eventsList && newProps.eventsList.data) {
      let data = newProps.eventsList && newProps.eventsList.data && newProps.eventsList.data.rows;
      this.setState({ listData: data });
      this.props.resetEventList();
    }
    if (newProps && newProps.deleteStatus && newProps.deleteStatus === true) {
      this.props.resetDelete();
      let obj = {
        eventId: this.state.eventId,
      };
      this.props.getList(obj);
    }
  }
  list = () => {
    if (this.props && this.props.eventList && this.props.eventList.status == true) {
      let request = this.props.eventList && this.props.eventList.request ? this.props.eventList.request : '';
      if (request.size || request.page || request.searchTxt) {
        this.setState(
          {
            limit: request.limit,
            page: request.page,
            searchTxt: request.searchTxt,
          },
          () => {
            this.getList();
          }
        );
      } else {
        this.getList();
      }
    } else {
      this.getList();
    }
  };
  getList = () => {
    const { eventStatus, eventType } = this.state;
    let obj = {};
    if (this.state.searchTxt) {
      obj['page'] = this.state.page;
      obj['limit'] = this.state.limit;
      obj['searchTxt'] = this.state.searchTxt;
      // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
      // obj['roleId'] = this.state.authorizedData ? this.state.authorizedData.roleId : ""
    } else {
      obj['page'] = this.state.page;
      obj['limit'] = this.state.limit;
      // obj['userId'] = this.state.authorizedData ? this.state.authorizedData.id : ""
      // obj['roleId'] = this.state.authorizedData ? this.state.authorizedData.roleId : ""
    }
    this.props.getList(obj);
    this.props.getEvents();
  };
  formPath = () => {
    history.push('/admin/event/add');
  };
  statusChange = (event) => {
    // const formData = new FormData()
    let contactable = event.contactable ? false : true;
    let obj = {
      contactable: contactable.toString(),
      eventId: event.id,
    };
    // formData.append("Request for User Details",JSON.stringify(obj))
    // this.props.statusUpdate(JSON.stringify(obj))
    // this.getList()
  };
  handleStatusChange = (e) => {
    this.setState(
      {
        eventStatus: e.target.value,
      },
      () => {
        this.getList();
      }
    );
  };
  handleTypeChange = (e) => {
    this.setState(
      {
        eventType: e.target.value,
      },
      () => {
        this.getList();
      }
    );
  };
  handleEventStatus = (e, id) => {
    let obj = {
      eventStatus: e.target.value,
      eventId: id,
    };
    this.props.statusUpdate(JSON.stringify(obj));
    this.getList();
  };
  onChange = (data) => {
    if (this.state.page !== data.selected + 1) {
      this.setState(
        {
          page: data.selected + 1,
        },
        () => {
          this.getList();
        }
      );
    }
  };
  resetSearch = () => {
    this.setState({ searchTxt: '', page: 1, eventStatus: '', eventType: '' }, () => {
      this.getList();
    });
  };
  handleInputChange = (e) => {
    e.target.value && e.target.value[0].includes(' ')
      ? (e.target.value = '')
      : this.setState({ [e.target.name]: e.target.value });
  };
  getNewResult = (e) => {
    e.preventDefault();
    if (this.state.searchTxt) {
      this.setState({ page: 1 }, () => {
        this.getList();
      });
    } else {
      toastr.error('Please type to search');
    }
  };
  itemEdit = (eventId) => {
    history.push('/admin/event/edit/' + eventId, { obj: { eventId } });
  };
  itemDelete = (memberId) => {
    let obj = {
      memberId,
    };
    this.props.delete_eventMember(obj);
  };
  handleDelete = (eventId) => {
    let message = '';
    const toastrConfirmOptions = {
      onOk: () => {
        this.itemDelete(eventId);
      },
      onCancel: () => console.log('CANCEL: clicked'),
    };
    toastr.customConfirm(message, toastrConfirmOptions, 'Do you want to delete ?');
  };

  backNavigation = () => {
    history.goBack();
  };

  render() {
    const { limit, page, listData, isEventMenberPage, eventName } = this.state;
    let eventList =
      this.state.eventList &&
      this.state.eventList.map((event, index) => {
        return (
          <tr>
            <td>{(page - 1) * limit + index + 1}</td>
            {!isEventMenberPage && (
              <td>
                {listData &&
                  listData.map((items) => {
                    if ((items && items.id) == event.eventId) {
                      return items.title;
                    }
                  })}
              </td>
            )}
            {isEventMenberPage && <td>{eventName}</td>}
            <td>{event.name}</td>
            <td>{event.email}</td>
            <td>{event.mobile}</td>
            <td>
              <Toggle
                onClick={() => this.statusChange(event)}
                defaultChecked={event.contactable}
                Checked={event.contactable}
              />
            </td>
            <td>{event.gender}</td>
            <td>{event.dob}</td>
            <td>{Array.isArray(event.languages) ? event.languages.join(', ') : '-'}</td>
            <td>{event.reason}</td>
            <td>
              {/* <a className="tooltip-wrap" onClick={()=>this.itemEdit(event.id)}>
                    <EditIcon className="edit-icon"/>
                    <div className="tooltip">
                        <div className="arrow"></div>
                        <div className="tooltip-inner">Edit</div>
                    </div>
                </a> */}
              <a className="tooltip-wrap" onClick={() => this.handleDelete(event.id)}>
                <DeleteIcon className="delete-icon" />
                <div className="tooltip">
                  <div className="arrow"></div>
                  <div className="tooltip-inner">Delete</div>
                </div>
              </a>
            </td>
          </tr>
        );
      });
    return (
      <Fragment>
        <div className="row tit-sec">
          <div className="col-6 col-md-5">
            <h4>Event Members </h4>
          </div>
          {this.state.isEventMenberPage && (
            <div className="col-6 col-md-7 text-right">
              <button className="btn cancel-btn" onClick={this.backNavigation}>
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          )}
        </div>
        <div className="card">
          <div className="card-body">
            {!this.state.isEventMenberPage && (
              <div className="pt-2 mb-3">
                <div className="d-flex justify-content-end">
                  <form onSubmit={this.getNewResult} class="align-items-center">
                    <div class="input-group">
                      {/* <div className="col-md-4 input-fields">
                                            <div className="form-group">
                                                <label>Event Status</label>
                                                    <select value={this.state.eventStatus} onChange={this.handleStatusChange} className="form-control">
                                                    <option value=''>Select Status</option>
                                                    <option value="yettostart">Yet to start</option>       
                                                    <option value="ongoing">Ongoing</option>    
                                                    <option value="done">Done</option>
                                                    </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 input-fields">
                                            <div className="form-group">
                                                <label>Event Type</label>
                                                    <select value={this.state.eventType} onChange={this.handleTypeChange} className="form-control">
                                                    <option value=''>Select Type</option>
                                                    <option value="training">Training</option>       
                                                    <option value="public">Public</option>    
                                                    </select>
                                            </div>
                                        </div>
                                         */}
                      {/* <div className="col-md-4">
                                        <div className="input-group"> */}
                      <input
                        type="text"
                        placeholder="Search.."
                        name="searchTxt"
                        value={this.state.searchTxt}
                        onChange={this.handleInputChange}
                        aria-label="Recipient's username"
                        formcontrolname="customSearch"
                        class="form-control ng-pristine ng-valid ng-touched"
                      />
                      <div class="input-group-append">
                        <button type="submit" class="btn btn-sm btn-dark">
                          <i className="fas fa-search"></i>
                        </button>
                        <div class="pl-3">
                          <button
                            _ngcontent-wdd-c125=""
                            type="button"
                            onClick={this.resetSearch}
                            class="btn btn-outline-secondary btn-rounded btn-icon"
                          >
                            <i class="fas fa-redo-alt"></i>
                          </button>
                        </div>
                      </div>
                      {/* </div>
                                    </div> */}
                    </div>
                  </form>
                </div>
              </div>
            )}
            <div className="table-wrap">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Event</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>Whatsapp contact</th>
                      <th>Gender</th>
                      <th>DOB</th>
                      <th>Languages</th>
                      <th>Reason</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{eventList}</tbody>
                </table>
              </div>
              {this.state.eventList && this.state.eventList.length == 0 && (
                <div>
                  <h6 className="text-center py-4">No Record Found</h6>
                </div>
              )}
            </div>
          </div>
        </div>
        {!this.state.isEventMenberPage && this.state.eventList && this.state.eventList.length > 0 && (
          <ReactPagination
            PageDetails={{
              pageCount: this.state.pageCount,
              onPageChange: this.onChange,
              activePage: this.state.page - 1,
              perPage: this.state.limit,
              totalCount: this.state.totalCount,
            }}
          />
        )}
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    eventList: state.events.eventMembers,
    eventsList: state.events.eventList,
    deleteStatus: state.events.deleteStatus,
  };
}
const actionCreators = {
  // save : eventActions.save,
  getList: eventActions.getMembers,
  resetList: eventActions.resetMembers,
  getEvents: eventActions.getList,
  resetEventList: eventActions.resetList,
  delete_eventMember: eventActions.delete_eventMember,
  resetDelete: eventActions.resetDelete,
  // statusUpdate:eventActions.statusUpdate
};
const Event = connect(mapStateToProps, actionCreators)(FetchEventMember);
export { Event as FetchEventMember };
