import React, { Fragment, Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { WebHeader , WebFooter } from "../web/components/Ui";
import { config } from "../config";
import {getAcceptedChannel_Listen  } from '../helpers'

let hash = ''
if(config.hashName){
     hash = window.location.hash  
}else{
     hash = ''
}
let location = window.location.pathname+hash;
!location.includes('admin') && require("../web/assets/theme-styles/css/bootstrap.min.css");
!location.includes('admin') && require("../web/assets/theme-styles/css/font-awesome.min.css");
!location.includes('admin') && require("../web/assets/theme-styles/css/themify-icons.css");
!location.includes('admin') && require("../web/assets/theme-styles/css/elegant-icons.css");
!location.includes('admin') && require("../web/assets/theme-styles/css/owl.carousel.min.css");
!location.includes('admin') && require("../web/assets/theme-styles/css/nice-select.css");
!location.includes('admin') && require("../web/assets/theme-styles/css/jquery-ui.min.css");
!location.includes('admin') && require("../web/assets/theme-styles/css/slicknav.min.css");
!location.includes('admin') && require("../web/assets/theme-styles/sass/webStyle.scss");

const drawerWidth = 240;

const styles = theme => ({
	root: {
		display: "flex"
	},
	content: {
		flexGrow: 1,
		marginLeft: theme.spacing(9),
		padding: theme.spacing(3),
		marginTop: theme.spacing(7),
		overflowX: "hidden"
	},
	contentShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	}
});

const importScript = function (src) {
	const tag = document.createElement('script');
	tag.async = false;
	tag.src = src;
	document.body.appendChild(tag);
}

class WebLayout extends Component {
	state = {
		open: true
	};

	componentDidMount() {
		if(location && !location.includes('admin')){
			getAcceptedChannel_Listen()
		}

		importScript(process.env.PUBLIC_URL + "/assets/js/jquery.countdown.min.js");
		importScript(process.env.PUBLIC_URL + "/assets/js/jquery.nice-select.min.js");
		importScript(process.env.PUBLIC_URL + "/assets/js/jquery.zoom.min.js");
		importScript(process.env.PUBLIC_URL + "/assets/js/jquery.dd.min.js");
		importScript(process.env.PUBLIC_URL + "/assets/js/jquery.slicknav.js");
		importScript(process.env.PUBLIC_URL + "/assets/js/owl.carousel.min.js");
		importScript(process.env.PUBLIC_URL + "/assets/js/main.js");
	}

	handleToggleDrawer = () => {
		this.setState(prevState => {
			return { open: !prevState.open };
		});
	};

	componentDidUpdate(){
		
		if(!this.state.stateUpdated){
			this.setState({ stateUpdated : true })
		}
		
	}

	render() {
		const { classes, children } = this.props;
		return (
			<Fragment>
				<div>
					<WebHeader data={children}/>
					<div>
						{children}
					</div>
					<WebFooter data={children}/>
				</div>
			</Fragment>
		);
	}
}

export default withStyles(styles)(WebLayout);
