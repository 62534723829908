import React from "react";
import { connect } from "react-redux";
import AbtBanner from "../../assets/img/img_abtbanr.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ChanImg from "../../assets/img/BoardCommiteePhotos/6.png";
import ChengImg from "../../assets/img/BoardCommiteePhotos/9.png";
import ElenaImg from "../../assets/img/BoardCommiteePhotos/13.png";
import EstherImg from "../../assets/img/BoardCommiteePhotos/12.png";
import JoyImg from "../../assets/img/BoardCommiteePhotos/10.png";
import KanImg from "../../assets/img/BoardCommiteePhotos/2.png";
import CharlesImg from "../../assets/img/BoardCommiteePhotos/14.png";
import pualinglingImg from "../../assets/img/BoardCommiteePhotos/pualingling.png";
import LimImg from "../../assets/img/BoardCommiteePhotos/11.png";
import RobertImg from "../../assets/img/BoardCommiteePhotos/4.png";
import graceImg from "../../assets/img/graceDing.png";
import elenaImg from "../../assets/img/BoardCommiteePhotos/elenalui.png";
import angelineImg from "../../assets/img/BoardCommiteePhotos/angelinekhoo.png";
import TanImg from "../../assets/img/BoardCommiteePhotos/8.png";
import TimImg from "../../assets/img/BoardCommiteePhotos/1.png";
import ZulkifliImg from "../../assets/img/BoardCommiteePhotos/7.png";
import kwanImg from "../../assets/img/BoardCommiteePhotos/kwanchungmin.png";
import LeeImg from "../../assets/img/BoardCommiteePhotos/3.png";
import SengImg from "../../assets/img/BoardCommiteePhotos/5.png";
import { history } from "../../../helpers";
import AboutUsBanner from "../../assets/img/Banner/AboutUs/AboutUsBanner.png";
import BOARD_BANNER from "../../assets/img/NewBanner/AboutOurBoard.png";

class OurBoardComittee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  goToHome = () => {
    history.push("/");
  };
  render() {
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };
    return (
      <div className="board-wrap">
        <nav class="breadcrumb">
          <div className="container d-flex">
            <a class="breadcrumb-item" onClick={this.goToHome}>
              <i className="fas fa-home"></i>
            </a>
            {/* <a class="breadcrumb-item" href="#">About</a> */}
            {/* <span class="breadcrumb-item active">About</span>

                        <span class="breadcrumb-item active">Our Board Committee</span> */}

            <span className="breadcrumb-item active">
              {window.strings.HEADER.ABOUT}
            </span>
            <span className="breadcrumb-item active">
              {window.strings.HEADER.OUR_BOARD_COMITTEE}
            </span>
          </div>
        </nav>
        <Carousel
          className="board-slide"
          swipeable={false}
          draggable={false}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          // autoPlay={this.props.deviceType !== "mobile" ? true : false}
          autoPlay={false}
          arrows={false}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="all 1.2s ease 0s"
          transitionDuration={1000}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          deviceType={this.props.deviceType}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div className="abt-banr">
            <img src={BOARD_BANNER} />
          </div>
          {/* <div className="abt-banr">
                            <img src={AbtBanner}/>
                        </div>
                        <div className="abt-banr">
                            <img src={AbtBanner}/>
                        </div> */}
        </Carousel>
        <div className="container">
          <div className="spad">
            <div className="text-center">
              <h3>{window.strings.ABOUT.ABOUT_HED}</h3>
              <h4>{window.strings.ABOUT.OUR_BRD_COMITT}</h4>

              <h3>{window.strings.ABOUT.HED}</h3>
              <h4>{window.strings.ABOUT.SUBHED}</h4>

              {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </p> */}
            </div>
            <div className="img-gallery">
              <div className="row justify-content-center">
                <div className="col-lg-2 col-12">
                  <img src={TimImg} />
                  <span>
                    <p>{window.strings.ABOUT.BOARDCHAIRMAN}</p>
                    <h6>{window.strings.ABOUT.KOHENGSOONTIM}</h6>
                  </span>
                </div>
                <div className="col-lg-2 col-12">
                  <img style={{ border: '2px solid #BBD9A7', borderTopLeftRadius: '40px', borderBottomRightRadius: '40px' }} src={kwanImg} />
                  <span>
                    <p>Vice Chairman</p>
                    <h6>Mr Kwan Chung Min</h6>
                  </span>
                </div>
                <div className="col-lg-2 col-12">
                  <img src={SengImg} />
                  <span>
                    <p>Honorary Secretary</p>
                    <h6>{window.strings.ABOUT.SENGYUHMING}</h6>
                  </span>
                </div>
                <div className="col-lg-2 col-12">
                  <img src={LimImg} />
                  <span>
                    <p>Honorary Treasurer</p>
                    <h6>{window.strings.ABOUT.LIMWEELIM}</h6>
                  </span>
                </div>
              </div>
            </div>
            <div className="img-gallery">
              <div className="row justify-content-center">
                <div className="col-lg-2 col-12">
                  <img src={KanImg} />
                  <span>
                    {/* <p>{window.strings.ABOUT.BOARDCICECHAIR}</p> */}
                    <p>Honorary Assistant Treasurer</p>
                    <h6>{window.strings.ABOUT.KANSEEKWAN}</h6>
                  </span>
                </div>
                <div className="col-lg-2 col-12">
                  <img src={ChanImg} />
                  <span>
                    <p>{window.strings.ABOUT.BOARDMEMBER}</p>
                    <h6>Dr Chan Kin Ming</h6>
                    <h5>PBM</h5>
                  </span>
                </div>
                <div className="col-lg-2 col-12">
                  <img src={ZulkifliImg} />
                  <span>
                    <p>{window.strings.ABOUT.BOARDMEMBER}</p>
                    <h6>{window.strings.ABOUT.ZULKIFLIMOHAMMED}</h6>
                  </span>
                </div>
                {/* <div className="col-lg-2 col-12">
                                        <img src={TanImg} />
                                        <span>
                                            <p>{window.strings.ABOUT.BOARDMEMBER}</p>
                                            <h6>{window.strings.ABOUT.TANKIANCHOONPBM}</h6>
                                            <h5>{window.strings.ABOUT.PBM}</h5>
                                        </span>
                                    </div> */}
                <div className="col-lg-2 col-12">
                  <img src={ChengImg} />
                  <span>
                    <p>{window.strings.ABOUT.BOARDMEMBER}</p>
                    <h6>{window.strings.ABOUT.CHENGYUANSHAN}</h6>
                  </span>
                </div>
                {/* <div className="col-lg-2 col-12">
                                        <img src={JoyImg} />
                                        <span>
                                            <p>{window.strings.ABOUT.BOARDMEMBER}</p>
                                            <h6>{window.strings.ABOUT.JOYCHEWOONAI}</h6>
                                        </span>
                                    </div> */}
              </div>
            </div>
            {/* <h4 className="text-center">{window.strings.ABOUT.OUR_ADVISOR}</h4> */}



            <div className="img-gallery">
              <div className="row justify-content-center">

                <div className="col-lg-2 col-12">
                  <img src={graceImg} />
                  <span>
                    <p>Board Member</p>
                    <h6>Dr Grace Ding</h6>
                  </span>
                </div>
                <div className="col-lg-2 col-12">
                  <img style={{ border: '2px solid #BBD9A7', borderTopLeftRadius: '40px', borderBottomRightRadius: '40px' }} src={angelineImg} />
                  <span>
                    <p>Board Member</p>
                    <h6>Angeline Khoo Cheok Eng</h6>

                  </span>
                </div>
                <div className="col-lg-2 col-12">
                  <img src={pualinglingImg} style={{ border: '2px solid #BBD9A7', borderTopLeftRadius: '40px', borderBottomRightRadius: '40px' }} />
                  <span>
                    <p>Board Member</p>
                    <h6>Ms Pua Ling Ling Lynn</h6>

                  </span>
                </div>
              </div>
            </div>



            <div className="img-gallery">
              <div className="row justify-content-center">
                <div className="col-lg-2 col-12">
                  <img src={EstherImg} />
                  <span>
                    <p>{window.strings.ABOUT.HONORARYADVISIOR}</p>
                    <h6>{window.strings.ABOUT.ESTHERTANNEEYIUKUMYUPB}</h6>
                    <h5>{window.strings.ABOUT.MUL_PBM}</h5>
                  </span>
                </div>
                <div className="col-lg-2 col-12">
                  <img src={elenaImg} style={{border: '2px solid #BBD9A7', borderTopLeftRadius: '40px', borderBottomRightRadius: '40px'}} />
                  <span>
                    <p>{window.strings.ABOUT.HONORARYADVISIOR}</p>
                    <h6>{window.strings.ABOUT.LUIHAHWAHELENAPBM}</h6>
                    <h5>{window.strings.ABOUT.PBM}</h5>
                  </span>
                </div>
                <div className="col-lg-2 col-12">
                  <img src={CharlesImg} />
                  <span>
                    <p>{window.strings.ABOUT.LEGALADVISIOR}</p>
                    <h6>{window.strings.ABOUT.CHARLESLEONG}</h6>
                  </span>
                </div>
                <div className="col-lg-2 col-12">
                  <img src={LeeImg} />
                  <span>
                    <p>Honorary Advisor</p>
                    <h6>{window.strings.ABOUT.LEESOOKWAN}</h6>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const actionCreators = {};

const connectedOurBoardComittee = connect(
  mapStateToProps,
  actionCreators
)(OurBoardComittee);
export { connectedOurBoardComittee as OurBoardComittee };
